import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import {
  Drawer,
  Button,
  AppBar,
  Toolbar,
  List,
  Typography,
  Divider,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import HomeIcon from '@material-ui/icons/Home'
import HealingIcon from '@material-ui/icons/Healing'
import BugReportIcon from '@material-ui/icons/BugReport'
import BorderInnerIcon from '@material-ui/icons/BorderInner'
import BorderStyleIcon from '@material-ui/icons/BorderStyle'
import LocalHospitalIcon from '@material-ui/icons/LocalHospital'
import ScatterPlotIcon from '@material-ui/icons/ScatterPlot'
import EmojiNatureIcon from '@material-ui/icons/EmojiNature'
import TrendingUpIcon from '@material-ui/icons/TrendingUp'
import FilterListIcon from '@material-ui/icons/FilterList'
import PeopleIcon from '@material-ui/icons/People'
import MapIcon from '@material-ui/icons/Map'
import EventIcon from '@material-ui/icons/Event'
import CallToActionIcon from '@material-ui/icons/CallToAction'
import TonalityIcon from '@material-ui/icons/Tonality'
import SettingsApplications from '@material-ui/icons/SettingsApplications'
import BallotIcon from '@material-ui/icons/Ballot';
import { useTranslation } from 'react-i18next'
import { useLocation, useHistory, Link } from 'react-router-dom'
import { userStore } from '../stores'
import ContextualMenu from './ContextualMenu'
import { ROUTES } from '../constants/routes'
import { getPlantationLogo } from '../constants/utilities'

const drawerWidth = 240

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  title: {
    flexGrow: 1,
  },
  listItemSelect: {
    backgroundColor: '#7e819259',
  },
  logo: {
    width: '100%',
    height: 150,
    margin: '0 auto',
    marginTop: 10,
  },
}))

export default function MiniDrawer({globalsetMenuOpen, closeMenuOrder, setCloseMenuOrder}) {
  const { t } = useTranslation()
  const location = useLocation()
  const history = useHistory()
  const { currentPlantation, user, setUser } = userStore()
  const classes = useStyles()
  const theme = useTheme()
  const [open, setOpen] = useState(false)
  const MENU_LIST = {
    cadastre: {
      title: t('general.cadastre'),
      icon: '',
      items: [
        /* {
          text: t('general.plantation'),
          route: ROUTES.plantation,
          icon: <PublicIcon />,
        }, */
        /* {
          text: t('general.sectors'),
          route: ROUTES.sectors,
          icon: <BorderStyleIcon />,
        }, */
        {
          text: t('general.subdivision'),
          route: ROUTES.subdivision,
          icon: <BorderStyleIcon />,
        },
        {
          text: t('general.plots'),
          route: ROUTES.plots,
          icon: <BorderInnerIcon />,
        },
        {
          text: t('general.eradications'),
          route: ROUTES.eradications,
          icon: <ScatterPlotIcon />,
        },
        {
          text: `${t('general.map')} ${t('general.cadastre')}`,
          route: ROUTES.mapcadastre,
          icon: <MapIcon />,
        },
      ],
    },
    sanitation: {
      title: t('general.sanitation'),
      icon: '',
      items: [
        {
          text: t('general.diseases'),
          route: ROUTES.diseases,
          icon: <LocalHospitalIcon />,
        },
        {
          text: t('general.pests'),
          route: ROUTES.pests,
          icon: <BugReportIcon />,
        },
        {
          text: t('general.treatments'),
          route: ROUTES.treatments,
          icon: <HealingIcon />,
        },
        {
          text: 'Rhynchophorus',
          route: ROUTES.rhynchophorus,
          icon: <CallToActionIcon />,
        },
        {
          text: `${t('general.map')} ${t('general.sanitation')}`,
          route: ROUTES.mapsanitation,
          icon: <MapIcon />,
        },
      ],
    },
    production: {
      title: t('general.production'),
      icon: '',
      items: [
        {
          text: t('general.pollination'),
          route: ROUTES.pollination,
          icon: <EmojiNatureIcon />,
        },
        {
          text: `${t('general.pollination')} ANA`,
          route: ROUTES.pollinationana,
          icon: <EmojiNatureIcon />,
        },
        {
          text: t('general.yield'),
          route: ROUTES.yield,
          icon: <TrendingUpIcon />,
        },
        {
          text: `${t('general.map')} ${t('general.production')}`,
          route: ROUTES.mapproduction,
          icon: <MapIcon />,
        },
      ],
    },
    labors: {
      title: t('general.labors'),
      icon: '',
      items: [
        {
          text: t('general.schedule_labors'),
          route: ROUTES.schedulelabors,
          icon: <EventIcon />,
        },
      ],
    },
    nutrition: {
      title: t('general.nutrition'),
      icon: '',
      items: [
        {
          text: `Plan ${t('general.fertilization')}`,
          route: ROUTES.fertilizationplan,
          icon: <TonalityIcon />,
        }
      ]
    },
    supervision:{
      title: t('general.supervision'),
      icon: '',
      items: [
        {
          text: `${t('general.supervision')} ${t('general.diseases')}`,
          route: ROUTES.supervisiondiseases,
          icon: <BallotIcon />,
        }
      ]
    },
    machinery: {
      title: t('general.machinery'),
      icon: '',
      items: [
        {
          text: `${t('general.map')} ${t('general.machinery')}`,
          route: ROUTES.machinerymap,
          icon: <MapIcon />,
        }
      ]
    },
    adminsanitation: {
      title: `${t('general.administration')}`,
      icon: '',
      items: [
        {
          text: `${t('general.management')} ${t('general.sanitation')}`,
          route: ROUTES.adminsanitation,
          icon: <LocalHospitalIcon />,
        },
        {
          text: `${t('general.management')} ${t('general.production')}`,
          route: ROUTES.adminproduction,
          icon: <TrendingUpIcon />,
        },
        {
          text: `${t('general.management')} ${t('general.labors')}`,
          route: ROUTES.adminlabors,
          icon: <EventIcon />,
        },
        {
          text: `${t('general.management')} ${t('general.nutrition')}`,
          route: ROUTES.adminnutrition,
          icon: <TonalityIcon />,
        },
        {
          text: `${t('general.management')} ${t('general.machinery')}`,
          route: ROUTES.adminmachinery,
          icon: <SettingsApplications />,
        },
        {
          text: `${t('general.management')} general`,
          route: ROUTES.adminseveral,
          icon: <FilterListIcon />,
        },
        {
          text: `${t('general.users')}`,
          route: ROUTES.adminusers,
          icon: <PeopleIcon />,
        },
       
      ],
    },
  }

  useEffect(() => {
    if(closeMenuOrder === 1){
      setOpen(false)
      globalsetMenuOpen(false)
      setCloseMenuOrder(0)
    }
  },[closeMenuOrder, globalsetMenuOpen, setCloseMenuOrder])

  const handleDrawerOpen = () => {
    setOpen(true)
    globalsetMenuOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
    globalsetMenuOpen(false)
  }

  /* const handleClickMenuItem = (route) => {
    if (route === currentUrl) return null
    history.push(route)
    setOpen(false)
  } */

  const myStyle = {
    imageContainer: {
      width: '230px',
      height: open ? '150px' : '0',
    },
    image: {
      /* backgroundImage: open
        ? `url(${getPlantationLogo(currentPlantation)})`
        : '', */
      //backgroundImage: `url(${getPlantationLogo(currentPlantation)})`,
      width: '230px',
      height: open ? '150px' : '0',
      visibility: open ? 'visible' : 'hidden',
    },
    buttonSelectPlantation: {
      visibility: open ? 'visible' : 'hidden',
    },
  }

  const getOptionSelect = (item) => {
    if (String(currentPlantation.plantation) === String(item)) return null

    const newSelectPlantation = user.plantations.filter(
      (x) => String(x.plantation) === String(item)
    )
    if (newSelectPlantation.length <= 0) return null

    setUser({ currentPlantation: newSelectPlantation[0] })
  }

  return (
    <>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {''}
          </Typography>
          <Button color="inherit" onClick={() => history.push(ROUTES.logout)}>
            {t('general.logout')}
          </Button>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
        open={open}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <div style={myStyle.imageContainer}>
          <img
            style={myStyle.image}
            src={`${getPlantationLogo(currentPlantation)}`}
            alt="logo"
          />
        </div>
        <ContextualMenu
          text={currentPlantation.name}
          getOptionSelect={getOptionSelect}
          items={user.plantations.map((x) => ({
            id: x.plantation,
            text: x.name,
          }))}
          style={myStyle.buttonSelectPlantation}
        />
        <List>
          <Link
            to={ROUTES.home}
            style={{ display: 'flex', textDecoration: 'none', color: 'black' }}
          >
            <ListItem
              button
              className={
                location.pathname === ROUTES.home ? classes.listItemSelect : ''
              }
            >
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary={t('general.home')} />
            </ListItem>
          </Link>
        </List>
        <Divider />
        {Object.keys(MENU_LIST).map((module) => (
          <React.Fragment key={module}>
            <List>
              {MENU_LIST[module].items.map((item, index) => (
                <Link
                  key={index}
                  to={item.route}
                  style={{
                    display: 'flex',
                    textDecoration: 'none',
                    color: 'black',
                  }}
                >
                  <ListItem
                    button
                    className={
                      location.pathname === item.route
                        ? classes.listItemSelect
                        : ''
                    }
                  >
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText primary={item.text} />
                  </ListItem>
                </Link>
              ))}
            </List>
            <Divider />
          </React.Fragment>
        ))}
      </Drawer>
    </>
  )
}
