import create from 'zustand'
import { devtools } from 'zustand/middleware'
import { URL_SERVER, paxios } from '../constants/http'
import userStore from './userStore'

const getLaborsServer = async (set, plantation) => {
  try {
    const labors = await paxios.get(`${URL_SERVER}/labors/${plantation}`)
    set({ labors: labors.data.data })
  } catch (error) {
    set({ error })
  }
}

const getLaborsGroupsServer = async (set) => {
  try {
    const laborsGroups = await paxios.get(`${URL_SERVER}/laborsGroups`)
    set({ laborsGroups: laborsGroups.data.data })
  } catch (error) {
    set({ error })
  }
}

const getUnitOfMeasuresServer = async (set) => {
  try {
    const unitOfMeasures = await paxios.get(`${URL_SERVER}/unitOfMeasures`)
    set({ unitOfMeasures: unitOfMeasures.data.data })
  } catch (error) {
    set({ error })
  }
}

const getCostCentersServer = async (set, plantation) => {
  try {
    const costCenters = await paxios.get(`${URL_SERVER}/costCenters/byPlantation/${plantation}`)
    set({ costCenters: costCenters.data.data })
  } catch (error) {
    set({ error })
  }
}

const getLaborsPhasesServer = async (set) => {
  try {
    const laborsPhases = await paxios.get(`${URL_SERVER}/laborsPhases`)
    set({ laborsPhases: laborsPhases.data.data })
  } catch (error) {
    set({ error })
  }
}

const getOriginsQuantityServer = async (set) => {
  try {
    const originsQuantity = await paxios.get(`${URL_SERVER}/originsQuantity`)
    set({ originsQuantity: originsQuantity.data.data })
  } catch (error) {
    set({ error })
  }
}

const getHeadingsServer = async (set, plantation) => {
  try {
    const headings = await paxios.get(`${URL_SERVER}/headings/${plantation}`)
    set({ headings: headings.data.data })
  } catch (error) {
    set({ error })
  }
}

const store = (set) => ({
  labors: [],
  laborsGroups: [],
  unitOfMeasures: [],
  costCenters: [],
  laborsPhases: [],
  originsQuantity: [],
  headings: [],
  error: null,
  getLabors: async () => {
    const currentPlantation = userStore.getState().currentPlantation
    await getLaborsServer(set, currentPlantation.plantation)
  },
  getLaborsGroups: async () => {
    await getLaborsGroupsServer(set)
  },
  getUnitOfMeasures: async () => {
    await getUnitOfMeasuresServer(set)
  },
  getCostCenters: async () => {
    const currentPlantation = userStore.getState().currentPlantation
    await getCostCentersServer(set, currentPlantation.plantation)
  },
  getLaborsPhases: async () => {
    await getLaborsPhasesServer(set)
  },
  getOriginsQuantity: async () => {
    await getOriginsQuantityServer(set)
  },
  getHeadings: async () => {
    const currentPlantation = userStore.getState().currentPlantation
    await getHeadingsServer(set, currentPlantation.plantation)
  },
})

const laborsStore = create(devtools(store, 'labors'))

export default laborsStore
