import { useState, useEffect } from 'react'
import { ButtonGroup, Button, MenuItem, Menu } from '@material-ui/core'
import AssignmentIcon from '@material-ui/icons/Assignment'
import AssessmentIcon from '@material-ui/icons/Assessment'
import AttachmentIcon from '@material-ui/icons/Attachment'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { useTranslation } from 'react-i18next'
import { userStore } from '../../stores'
import { ROUTES } from '../../constants/routes'
import { TitlePage } from '../../components'
import Manage from './Manage'
import LoadExcel from './ByExcel'
import ReportPollinationYear from './reports/PollinationYear'
import ReportEstimateProduction from './reports/EstimateProduction'
import ManageByLine from './ManageByLine'
import LoadExcelByLine from './ByExcelByLine'
import ReportPollinationYearByLine from './reports/PollinationYearByLine'
import ReportEstimateProductionByLine from './reports/EstimateProductionByLine'

export default function Pollination() {
  const { t } = useTranslation()
  const { setUser } = userStore()
  const [selection, setSelection] = useState(1)
  const [openMenuReports, setOpenMenuReports] = useState(null)

  useEffect(() => {
    setUser({ currentUrl: ROUTES.pollination })
  }, [setUser])

  const handleClickMenuReports = (event) => {
    setOpenMenuReports(event.currentTarget)
  }

  const handleCloseMenuReports = (opc) => {
    setOpenMenuReports(null)
    typeof opc === 'number' && setSelection(opc)
  }

  return (
    <>
      <TitlePage text={`${t('general.pollination')}`} />
      <ButtonGroup
        variant="text"
        color="primary"
        aria-label="text primary button group"
        style={{
          width: '100%',
          justifyContent: 'center',
          marginBottom: '20px',
        }}
      >
        <Button
          startIcon={<AssignmentIcon />}
          variant={selection === 1 ? 'outlined' : ''}
          onClick={() => setSelection(1)}
        >
          {t('general.manage')}
        </Button>
        <Button
          startIcon={<AttachmentIcon />}
          variant={selection === 2 ? 'outlined' : ''}
          onClick={() => setSelection(2)}
        >
          {t('general.loadexcel')}
        </Button>
        <Button
          startIcon={<AssignmentIcon />}
          variant={selection === 6 ? 'outlined' : ''}
          onClick={() => setSelection(6)}
        >
          {`${t('general.manage')} ${t('general.byline')}`}
        </Button>
        <Button
          startIcon={<AttachmentIcon />}
          variant={selection === 7 ? 'outlined' : ''}
          onClick={() => setSelection(7)}
        >
          {`${t('general.loadexcel')} ${t('general.byline')}`}
        </Button>
        <Button
          startIcon={<AssessmentIcon />}
          variant={[3, 4, 5].includes(selection) ? 'outlined' : ''}
          onClick={handleClickMenuReports}
        >
          {`${t('general.reports')}`}
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>

      <Menu
        id="simple-menu"
        anchorEl={openMenuReports}
        keepMounted
        open={Boolean(openMenuReports)}
        onClose={handleCloseMenuReports}
      >
        <MenuItem
          selected={selection === 4}
          onClick={() => handleCloseMenuReports(4)}
        >
          {`${t('general.pollinationyear')}`}
        </MenuItem>
        <MenuItem
          selected={selection === 5}
          onClick={() => handleCloseMenuReports(5)}
        >
          {`${t('general.estimateproduction')}`}
        </MenuItem>
        <MenuItem
          selected={selection === 8}
          onClick={() => handleCloseMenuReports(8)}
        >
          {`${t('general.pollinationyear')} ${t('general.byline')}`}
        </MenuItem>
        <MenuItem
          selected={selection === 9}
          onClick={() => handleCloseMenuReports(9)}
        >
          {`${t('general.estimateproduction')} ${t('general.byline')}`}
        </MenuItem>
      </Menu>

      {selection === 1 && <Manage />}
      {selection === 2 && <LoadExcel />}
      {selection === 4 && <ReportPollinationYear />}
      {selection === 5 && <ReportEstimateProduction />}
      {selection === 6 && <ManageByLine />}
      {selection === 7 && <LoadExcelByLine />}
      {selection === 8 && <ReportPollinationYearByLine />}
      {selection === 9 && <ReportEstimateProductionByLine />}
    </>
  )
}
