import { useState } from 'react'
import { ButtonGroup, Button } from '@material-ui/core'
import AssignmentIcon from '@material-ui/icons/Assignment'
import AttachmentIcon from '@material-ui/icons/Attachment'
import { useTranslation } from 'react-i18next'
import { TitlePage } from '../../components'
import Manage from './Manage'
import LoadExcel from './ByExcel'

export default function ExecutionTreatments() {
  const { t } = useTranslation()
  const [selection, setSelection] = useState(1)
  const [, setAnchorEl] = useState(null)

  /* const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  } */

  const handleClose = (opc) => {
    setAnchorEl(null)
    typeof opc === 'number' && setSelection(opc)
  }
  return (
    <>
      <TitlePage text={`${t('general.treatments')}`} />

      <ButtonGroup
        variant="text"
        color="primary"
        aria-label="text primary button group"
        style={{
          width: '100%',
          justifyContent: 'center',
          marginBottom: '20px',
        }}
      >
        <Button
          startIcon={<AssignmentIcon />}
          variant={selection === 1 ? 'outlined' : ''}
          onClick={() => handleClose(1)}
        >
          {t('general.manage')}
        </Button>

        <Button
          startIcon={<AttachmentIcon />}
          variant={selection === 2 ? 'outlined' : ''}
          onClick={() => setSelection(2)}
        >
          {t('general.loadexcel')}
        </Button>
      </ButtonGroup>

      {selection === 1 && <Manage />}
      {selection === 2 && <LoadExcel />}
    </>
  )
}
