import { useEffect } from 'react'
import { userStore } from '../stores'
export default function Logout() {
  const { logout } = userStore()
  useEffect(() => {
    logout()
    //eslint-disable-next-line
  }, [])
  return <div></div>
}
