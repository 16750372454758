import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { useTranslation } from 'react-i18next'
import Button from './Button'

const SelectCreateSchedule = ({
  open,
  setOpen,
  handleClickSchedule,
  handleClickWithoutSchedule,
}) => {
  const { t } = useTranslation()
  return (
    <Dialog open={open} onClose={setOpen}>
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        {`${t('general.select')}`}
      </DialogTitle>
      <DialogContent dividers>
        <Button
          handleButtonClick={handleClickSchedule}
          text={t('general.create_schedule')}
        />
        <Button
          handleButtonClick={handleClickWithoutSchedule}
          text={t('general.register_without_schedule')}
        />
      </DialogContent>
      <DialogActions>
        <Button
          handleButtonClick={() => setOpen(false)}
          text={t('general.cancel')}
        />
      </DialogActions>
    </Dialog>
  )
}

export default SelectCreateSchedule
