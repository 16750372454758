import { makeStyles, Button, CircularProgress } from '@material-ui/core'
import { green } from '@material-ui/core/colors'
const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
    display: 'flex',
    justifyContent: 'center'
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}))
export default function ButtonCustom({
  icon,
  text,
  loading,
  handleButtonClick,
  color = 'primary',
  disabled = false,
  size="medium"
}) {
  const classes = useStyles()

  return (
    <div className={classes.wrapper}>
      <Button
        size={size}
        variant="contained"
        color={color}
        disabled={disabled || loading}
        startIcon={icon}
        onClick={handleButtonClick}
      >
        {text}
      </Button>
      {loading && (
        <CircularProgress size={24} className={classes.buttonProgress} />
      )}
    </div>
  )
}
