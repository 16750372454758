import { useState } from 'react'
import {
  Grid,
  TextField,
  Typography,
  Card,
  CardContent,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import SaveIcon from '@material-ui/icons/Save'
import { userStore } from '../stores'
import Button from './Button'
import { ROUTES } from '../constants/routes'
import Swal from 'sweetalert2/src/sweetalert2.js'
import { URL_SERVER, paxios } from '../constants/http'

export default function ChangePass() {
  const { t } = useTranslation()
  const history = useHistory()
  const { user } = userStore()
  const [saving, setSaving] = useState(false)
  const [pass, setPass] = useState('')
  const [rePass, setRePass] = useState('')

  const validatePass = (pass) => {
    if (pass === '') return true

    if (pass.length < 8) return true

    if (!pass.match(/[A-z]/)) return true

    if (!pass.match(/[A-Z]/)) return true

    if (!pass.match(/\d/)) return true

    return false
  }

  const handleClickSave = () => {
    saveData(user.id, pass)
  }

  const saveData = async (id, pass) => {
    setSaving(true)
    try {
      const response = await paxios.post(`${URL_SERVER}/users/changePass`, {
        id,
        pass,
      })
      if (response.data.status === 'ok') {
        setSaving(false)
        history.push(ROUTES.home)
      } else {
        Swal.fire(t('general.finished'), t('general.datawasnotsaved'), 'error')
        setSaving(false)
      }
    } catch (error) {
      Swal.fire(t('general.finished'), t('general.datawasnotsaved'), 'error')
      setSaving(false)
    }
  }
  
  return (
    <div style={{ marginTop: '90px', marginLeft: '85px', marginRight: '85px' }}>
      <Grid container spacing={2}>
        <Grid item xs={4}></Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={4}></Grid>

        <Grid item xs={4}></Grid>
        <Grid item xs={4}>
          <Card>
            <CardContent>
              <Typography component="h1" variant="h4">
                {t('general.changepass')}
              </Typography>
              <p style={{ height: '20px' }}></p>
              <TextField
                fullWidth
                required
                label={t('login.password')}
                name="password"
                type={'password'}
                value={pass}
                onChange={(e) => setPass(e.target.value)}
                error={validatePass(pass)}
              />
              <p style={{ height: '10px' }}></p>
              <TextField
                fullWidth
                required
                label={`${t('general.confirm')} ${t('login.password')}`}
                name="password"
                type={'password'}
                value={rePass}
                onChange={(e) => setRePass(e.target.value)}
                error={validatePass(rePass) || rePass !== pass}
              />
              {(pass !== rePass ||
                validatePass(pass) ||
                validatePass(rePass)) && (
                <Alert severity="error">{t('general.errorpass')}</Alert>
              )}
              <Button
                icon={<SaveIcon />}
                handleButtonClick={handleClickSave}
                text={t('general.save')}
                loading={saving}
                disabled={
                  pass !== rePass || validatePass(pass) || validatePass(rePass)
                }
              />

              <Button
                handleButtonClick={() => history.push(ROUTES.logout)}
                text={t('general.logout')}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4}></Grid>

        <Grid item xs={4}></Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={4}></Grid>
      </Grid>
    </div>
  )
}
