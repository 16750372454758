import { useState } from "react";
import { ButtonGroup, Button, Grid, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { TitlePage } from "../../../components";
import Workers from "./workers/Workers";
import Quadrilles from "./Quadrilles";
import Companies from "./Companies";

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    marginBottom: 10,
  },
}));

export default function Home() {
  const classes = useStyles();
  const { t } = useTranslation();
  const [selection, setSelection] = useState(1);
  return (
    <>
      <TitlePage text={`${t("general.management")} general`} />
      <Grid
        container
        justify="center"
        spacing={2}
        className={classes.gridContainer}
      >
        <Grid item xs={12} md={10}>
          {selection === 1 && <Workers />}
          {selection === 2 && <Quadrilles />}
          {selection === 3 && <Companies />}
        </Grid>
        <Grid item xs={12} md={2}>
          <ButtonGroup
            variant="text"
            color="primary"
            aria-label="text primary button group"
            orientation="vertical"
            style={{
              width: "100%",
              justifyContent: "center",
              marginBottom: "20px",
            }}
          >
            <Button
              style={{ marginRight: 5 }}
              variant={selection === 1 ? "outlined" : ""}
              onClick={() => setSelection(1)}
            >
              {`${t("general.worker")}(s)`}
            </Button>

            <Button
              style={{ marginRight: 5 }}
              variant={selection === 2 ? "outlined" : ""}
              onClick={() => setSelection(2)}
            >
              {`${t("general.quadrille")}s`}
            </Button>

            <Button
              style={{ marginRight: 5 }}
              variant={selection === 3 ? "outlined" : ""}
              onClick={() => setSelection(3)}
            >
              {`${t("general.companies")}`}
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
    </>
  );
}
