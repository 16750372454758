import { useState, useEffect, forwardRef, useImperativeHandle } from 'react'
import dayjs from 'dayjs'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  makeStyles,
  Grid,
  TextField,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { useTranslation } from 'react-i18next'
import { cadastreStore, sanitationStore, severalStore } from '../../stores'
import { MultiSelect, DatePickerRange, Select } from '../../components'
import {getFormMobile} from  '../../constants/utilities'

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    height: '60vh',
  },
  gridFilterDiseases: {
    marginTop: 10,
    marginBottom: 10,
  },
  gridFilterPests: {
    marginTop: 10,
    marginBottom: 10,
  },
  gridFilterTreatments: {
    marginTop: 10,
    marginBottom: 10,
  },
  alert: {
    marginTop: 10,
    textAlign: 'center',
    alignSelf: 'center',
  },
}))

const Filters = forwardRef(({ accept, layer }, ref) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { diseases, eventsDiseases, pests, statesPests, treatments } =
    sanitationStore()
  const {workers} = severalStore()  
  const { plots } = cadastreStore()
  const [open, setOpen] = useState(false)
  const [eventsShow, setEventsShow] = useState([])
  const [statesShow, setStatesShow] = useState([])
  const [data, setData] = useState({
    plots: [],
    diseases: [],
    events: [],
    pests: [],
    states: [],
    treatments: [],
    form: 1,
    workers: [],
    initialDate: null,
    endDate: null,
  })
  const [errors, setErrors] = useState('')
  const [uriQgis, setUriQgis] = useState("")

  useEffect(() => {
    if (data.diseases.length > 0) {
      setEventsShow(
        eventsDiseases.filter((x) => data.diseases.indexOf(x.disease) !== -1)
      )
    } else {
      setEventsShow([])
    }
  }, [data.diseases, eventsDiseases])

  useEffect(() => {
    if (data.pests.length > 0) {
      setStatesShow(
        statesPests.filter((x) => data.pests.indexOf(x.pest) !== -1)
      )
    } else {
      setStatesShow([])
    }
  }, [data.pests, statesPests])

  useEffect(() =>{
    setUriQgis("");
  },[layer])

  const handleChange = (e) => {
    const { name, value } = e.target
    setData((d) => ({ ...d, [name]: value }))
  }

  const getData = () => {
    return {
      ...data,
      initialDate:
        data.initialDate !== null
          ? dayjs(data.initialDate).format('YYYY-MM-DD')
          : null,
      endDate:
        data.endDate !== null ? dayjs(data.endDate).format('YYYY-MM-DD') : null,
    }
  }

  const addUriQgis = (uri) => {
    setUriQgis(uri);
  }

  useImperativeHandle(ref, () => ({
    setOpen,
    getData,
    setErrors,
    addUriQgis,
  }))
  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="sm"
      fullWidth
      aria-labelledby="confirmation-dialog-title"
      open={open}
    >
      <DialogTitle id="confirmation-dialog-title">
        {`${t('general.filter')}s`}
      </DialogTitle>
      <DialogContent dividers className={classes.dialogContent}>
        <MultiSelect
          options={plots.map((x) => ({ value: x.id, label: x.name }))}
          label={`${t('general.plots')}`}
          onChangeExternal={(values) =>
            handleChange({ target: { name: 'plots', value: values } })
          }
          valuesExternal={data.plots}
        />
        {layer === 'diseases_layer' && (
          <Grid container className={classes.gridFilterDiseases}>
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <MultiSelect
                options={diseases.map((x) => ({ value: x.id, label: x.name }))}
                label={`${t('general.diseases')}`}
                onChangeExternal={(values) =>
                  handleChange({ target: { name: 'diseases', value: values } })
                }
                valuesExternal={data.diseases}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <MultiSelect
                options={eventsShow.map((x) => ({
                  value: x.id,
                  label: x.name,
                }))}
                label={`${t('general.event')}s`}
                onChangeExternal={(values) =>
                  handleChange({ target: { name: 'events', value: values } })
                }
                valuesExternal={data.events}
              />
            </Grid>
          </Grid>
        )}

        {layer === 'pests_layer' && (
          <Grid container className={classes.gridFilterPests}>
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <MultiSelect
                options={pests.map((x) => ({ value: x.id, label: x.name }))}
                label={`${t('general.pests')}`}
                onChangeExternal={(values) =>
                  handleChange({ target: { name: 'pests', value: values } })
                }
                valuesExternal={data.pests}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <MultiSelect
                options={statesShow.map((x) => ({
                  value: x.id,
                  label: x.name,
                }))}
                label={`${t('general.state')}s`}
                onChangeExternal={(values) =>
                  handleChange({ target: { name: 'states', value: values } })
                }
                valuesExternal={data.states}
              />
            </Grid>
          </Grid>
        )}

        {layer === 'treatments_layer' && (
          <Grid container className={classes.gridFilterTreatments}>
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <MultiSelect
                options={treatments.map((x) => ({
                  value: x.id,
                  label: x.name,
                }))}
                label={`${t('general.treatments')}`}
                onChangeExternal={(values) =>
                  handleChange({ target: { name: 'treatments', value: values } })
                }
                valuesExternal={data.treatments}
              />
            </Grid>
          </Grid>
        )}

        {layer === 'tracks_layer' && (
          <Grid container className={classes.gridFilterPests}>
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <Select
                text={t('general.form')}
                name={'form'}
                width={'100%'}
                items={getFormMobile(t)}
                defaultOption0={false}
                value={data.form}
                handleChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <MultiSelect
                options={workers.map((x) => ({
                  value: x.id,
                  label: x.name,
                }))}
                label={`${t('general.workers')}`}
                onChangeExternal={(values) =>
                  handleChange({ target: { name: 'workers', value: values } })
                }
                valuesExternal={data.workers}
              />
            </Grid>
          </Grid>
        )}

        <DatePickerRange
          initialDate={data.initialDate}
          endDate={data.endDate}
          handleDateChange={handleChange}
        />
        {errors !== '' && (
          <Alert className={classes.alert} severity="error">
            {errors}
          </Alert>
        )}
        <TextField
          fullWidth
          label={'URI Qgis'}
          name="uriqgis"
          type={'text'}
          value={uriQgis}
          disabled={true}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)} color="primary">
          {t('general.close')}
        </Button>
        <Button onClick={() => accept({ ...getData() })} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  )
})

Filters.displayName = 'Filters'

export default Filters
