import {
  useState,
  useEffect,
  forwardRef,
  useRef,
  useImperativeHandle,
} from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  makeStyles,
  Typography,
  IconButton,
  Paper,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import MaterialTable from "material-table";
import tableIcons from "../../../../constants/IconsMaterialTable";
import tableTranslations from "../../../../constants/tableTranslations";
import Swal from "sweetalert2/src/sweetalert2.js";
import { useTranslation } from "react-i18next";
import { URL_SERVER, paxios } from "../../../../constants/http";
import {
  nutritionStore,
  cadastreStore,
  userStore,
  laborsStore,
} from "../../../../stores";
import {
  Button,
  PlotsSelector,
  MultiSelect,
  DeleteConfirmation,
} from "../../../../components";

let years = [];
for (let index = 0; index <= 15; index++) {
  years.push({
    value: index + 2015,
    label: index + 2015,
  });
}

const cellStyle = { align: "center", headerStyle: { textAlign: "center" } };

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  gridContainer: {
    marginBottom: 10,
  },
  selectPlot: {
    margin: "auto 0",
  },
  dialogContent: {
    display: "flex",
    flexDirection: "column",
  },
}));

const ListPlan = forwardRef(({ handleClickSearchFractions }, ref) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const plotsSelectorRef = useRef(null);
  const { fertilizers, getFertilizers } = nutritionStore();
  const { plots, getPlots } = cadastreStore();
  const { currentPlantation, user } = userStore();
  const { labors, getLabors } = laborsStore();
  const [open, setOpen] = useState(false);
  const [queryData, setQueryData] = useState({
    years: [],
    products: [],
    plots: [],
  });
  const [plans, setPlans] = useState([]);
  const [rowSelec, setRowSelec] = useState([]);
  const [columns, setColumns] = useState([]);
  const [recordToDelete, setRecordToDelete] = useState({});
  const [openDelete, setOpenDelete] = useState(false);

  useEffect(() => {
    const validateEmpty = (rowData, field) => {
      return rowData[field] === "" ||
        rowData[field] === undefined ||
        rowData[field] === null ||
        isNaN(rowData[field]) ||
        parseInt(rowData[field]) <= 0
        ? t("general.noempty")
        : true;
    };
    const validateNoZero = (rowData, field) => {
      return rowData[field] <= 0 || rowData[field] === "0"
        ? t("general.nozero")
        : true;
    };
    setColumns([
      { title: "ID", field: "id", editable: "never" },
      {
        title: t("general.year"),
        field: "year",
        lookup: {
          ...years.reduce((acc, item) => {
            return { ...acc, [item.value]: item.label };
          }, {}),
        },
        initialEditValue: 2015,
        ...cellStyle,
      },
      {
        title: t("general.plot"),
        field: "plot",
        lookup: {
          0: t("general.notassigned"),
          ...plots.reduce((acc, item) => {
            return { ...acc, [item.id]: item.name };
          }, {}),
        },
        initialEditValue: 0,
        validate: (rowData) => validateNoZero(rowData, "plot"),
        ...cellStyle,
      },
      {
        title: t("general.product"),
        field: "product",
        lookup: {
          0: t("general.notassigned"),
          ...fertilizers.reduce((acc, item) => {
            return { ...acc, [item.id]: item.common_name };
          }, {}),
        },
        initialEditValue: 0,
        validate: (rowData) => validateNoZero(rowData, "product"),
        ...cellStyle,
      },
      {
        title: t("general.palms"),
        field: "palms",
        type: "numeric",
        validate: (rowData) => validateEmpty(rowData, "palms"),
      },
      {
        title: t("general.observations"),
        field: "observations",
      },
      {
        title: t("general.fractions"),
        field: "fractions_count",
        editable: "never",
      },
      {
        title: `${t("general.advance")} %`,
        field: "advance",
        editable: "never",
      },
      {
        title: t("general.labor"),
        field: "labor",
        lookup: {
          0: t("general.notassigned"),
          ...labors
            .filter((x) => x.fertilization === 1)
            .reduce((acc, item) => {
              return { ...acc, [item.id]: item.name };
            }, {}),
        },
        initialEditValue: 0,
        ...cellStyle,
      },
    ]);
  }, [t, plots, fertilizers, labors]);

  useEffect(() => {
    fertilizers.length <= 0 && getFertilizers();
  }, [fertilizers.length, getFertilizers]);

  useEffect(() => {
    plots.length <= 0 && getPlots();
  }, [getPlots, plots]);

  useEffect(() => {
    labors.length <= 0 && getLabors();
  }, [labors.length, getLabors]);

  useImperativeHandle(ref, () => ({
    getData: prepareData,
    setOpen,
    getPlans: getPlans,
  }));

  const prepareData = () => {
    return {
      ...queryData,
      plots: queryData.plots.map((x) => x.id),
      plantation: currentPlantation.plantation,
    };
  };

  const validateBottomSearch = () => {
    if (
      queryData.years.length <= 0 &&
      queryData.products.length <= 0 &&
      queryData.plots.length <= 0
    ) {
      return true;
    }

    return false;
  };

  const handleSelectPlot = (plot) => {
    setQueryData((d) => ({ ...d, plots: plot.length > 0 ? plot : [] }));
    plotsSelectorRef.current !== null &&
      plotsSelectorRef.current.setOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setQueryData((d) => ({ ...d, [name]: value }));
  };

  const getPlans = async (send) => {
    try {
      const response = await paxios.post(
        `${URL_SERVER}/fertilizationPlan/search`,
        send
      );
      response.data.status === "ok" && setPlans(response.data.data);
    } catch (error) {}
  };

  const handleClickButtonSearch = () => {
    const send = prepareData();
    getPlans(send);
  };

  const selectRow = (row) => {
    setRowSelec(row);
    handleClickSearchFractions(row);
  };

  const criterialDisabled = (currentId) => {
    if (rowSelec.length <= 0)
      return {
        disabled: false,
      };

    if (currentId !== rowSelec[0].id) {
      return {
        disabled: true,
        color: "primary",
      };
    } else if (currentId === rowSelec[0].id) {
      return {
        disabled: false,
        checked: true,
      };
    }

    return {
      disabled: false,
    };
  };

  const createPlan = async (data) => {
    try {
      const response = await paxios.post(`${URL_SERVER}/fertilizationPlan`, {
        ...data,
        observations: data.observations ? data.observations : "",
        plantation: currentPlantation.plantation,
      });
      if (response.data.status === "ok") {
        Swal.fire(t("general.finished"), t("general.savesuccess"), "success");
        const send = prepareData();
        getPlans(send);
      } else {
        Swal.fire(t("general.finished"), response.data.message, "error");
      }
    } catch (error) {
      Swal.fire(t("general.finished"), t("general.datawasnotsaved"), "error");
    }
  };

  const onRowAdd = (newData) =>
    new Promise(async (resolve) => {
      await createPlan(newData);
      resolve();
    });

  const updatePlan = async (data) => {
    try {
      const response = await paxios.put(
        `${URL_SERVER}/fertilizationPlan/${data.id}`,
        {
          ...data,
          observations: data.observations ? data.observations : "",
          plantation: currentPlantation.plantation,
        }
      );
      if (response.data.status === "ok") {
        Swal.fire(t("general.finished"), t("general.savesuccess"), "success");
        const send = prepareData();
        getPlans(send);
      } else {
        Swal.fire(t("general.finished"), t("general.datawasnotsaved"), "error");
      }
    } catch (error) {
      Swal.fire(t("general.finished"), t("general.datawasnotsaved"), "error");
    }
  };

  const onRowUpdate = (newData, oldData) =>
    new Promise(async (resolve) => {
      await updatePlan(newData);
      resolve();
    });

  const deleteConfirmation = (item) => {
    if (user.profile !== 1) return;
    setRecordToDelete(item);
    setOpenDelete(true);
  };

  const handleClickDelete = (event, item) => {
    deleteConfirmation(item[0]);
  };

  const deleteRecord = async (item) => {
    try {
      const response = await paxios.delete(
        `${URL_SERVER}/fertilizationPlan/${item.id}`
      );
      if (response.data.status === "ok") {
        Swal.fire(t("general.finished"), t("general.savesuccess"), "success");
        setPlans((d) => d.filter((x) => x.id !== item.id));
        setRowSelec([]);
        handleClickSearchFractions([]);
        setOpenDelete(false);
      } else {
        Swal.fire(t("general.finished"), t("general.datawasnotsaved"), "error");
      }
    } catch (error) {
      Swal.fire(t("general.finished"), t("general.datawasnotsaved"), "error");
    }
  };
  return (
    <>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xl"
        fullWidth
        aria-labelledby="confirmation-dialog-title"
        open={open}
      >
        <DialogTitle id="confirmation-dialog-title">
          {t("general.plans")}
        </DialogTitle>
        <DialogContent dividers className={classes.dialogContent}>
          <Grid container justify="center">
            <Grid item xs={12} md={4} className={classes.selectPlot}>
              <Paper component="form" className={classes.root}>
                <Typography variant="subtitle1">
                  {queryData.plots.length !== 0
                    ? queryData.plots.reduce((acc, item) => {
                        return (acc +=
                          acc !== "" ? "," + item.name : item.name);
                      }, "")
                    : `${t("general.select")} ${t("general.plot")}`}
                </Typography>
                <IconButton
                  type="button"
                  aria-label="search"
                  className={classes.iconButton}
                  onClick={() =>
                    plotsSelectorRef.current !== null &&
                    plotsSelectorRef.current.setOpen(true)
                  }
                >
                  <SearchIcon />
                </IconButton>
              </Paper>
              <PlotsSelector accept={handleSelectPlot} ref={plotsSelectorRef} />
            </Grid>
            <Grid item xs={12} md={4}>
              <MultiSelect
                options={years}
                label={`${t("general.year")}`}
                onChangeExternal={(values) =>
                  handleChange({ target: { name: "years", value: values } })
                }
                valuesExternal={queryData.years}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <MultiSelect
                options={fertilizers.map((x) => ({
                  value: x.id,
                  label: x.common_name,
                }))}
                label={`${t("general.product")}`}
                onChangeExternal={(values) =>
                  handleChange({ target: { name: "products", value: values } })
                }
                valuesExternal={queryData.products}
              />
            </Grid>
          </Grid>
          <MaterialTable
            icons={tableIcons}
            columns={columns}
            title={``}
            data={plans}
            options={{
              exportButton: true,
              exportAllData: true,
              exportDelimiter: ";",
              selection: true,
              showSelectAllCheckbox: false,
              selectionProps: (rowData) => criterialDisabled(rowData.id),
            }}
            localization={i18n.language === "es" ? tableTranslations : {}}
            onSelectionChange={selectRow}
            editable={{
              onRowUpdate: onRowUpdate,
              onRowAdd: onRowAdd,
            }}
            actions={[
              {
                icon: () => <DeleteForeverIcon />,
                tooltip: t("general.delete"),
                onClick: handleClickDelete,
              },
            ]}
          />
        </DialogContent>
        <DialogActions>
          <Button
            handleButtonClick={() => setOpen(false)}
            text={t("general.close")}
          />
          <Button
            handleButtonClick={handleClickButtonSearch}
            text={t("general.search")}
            disabled={validateBottomSearch()}
          />
        </DialogActions>
      </Dialog>
      {openDelete && (
        <DeleteConfirmation
          open={openDelete}
          setOpen={setOpenDelete}
          item={recordToDelete}
          handleOk={deleteRecord}
        />
      )}
    </>
  );
});

export default ListPlan;
