import { useState, useRef, useEffect } from 'react'
import dayjs from 'dayjs'
import {
  Grid,
  Paper,
  Typography,
  makeStyles,
  IconButton,
} from '@material-ui/core'
import Swal from 'sweetalert2/src/sweetalert2.js'
import SearchIcon from '@material-ui/icons/Search'
import MaterialTable from 'material-table'
import tableIcons from '../../../constants/IconsMaterialTable'
import tableTranslations from '../../../constants/tableTranslations'
import { useTranslation } from 'react-i18next'
import { userStore } from '../../../stores'
import { URL_SERVER, paxios } from '../../../constants/http'
import { PlotsSelector, Button, DatePickerRange } from '../../../components'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  gridContainer: {
    marginBottom: 10,
  },
}))

export default function Execution() {
  const classes = useStyles()
  const { currentPlantation } = userStore()
  const { t, i18n } = useTranslation()
  const plotsSelectorRef = useRef(null)
  const [queryData, setQueryData] = useState({
    plots: [],
    initialDate: null,
    endDate: null,
  })
  const [records, setRecords] = useState([])
  const [columns, setColumns] = useState([])

  useEffect(() => {
    setColumns([
      { title: t('general.labor'), field: 'labor_name' },
      { title: t('general.plot'), field: 'plot_name' },
      { title: `${t('general.cost')} ${t('general.schedule')} (U)`, field: 'cost' },
      { title: t('general.quantity'), field: 'quantity' },
      { title: 'Total', field: 'total_cost' },
      { title: `${t('general.quantity')} ${t('general.execution')}`, field: 'quantity_exe' },
      { title: `${t('general.cost')} ${t('general.execution')}`, field: 'cost_total_exe' },
      { title: `${t('general.missing')}`, field: 'missing' },
      { title: `${t('general.people')} ${t('general.execution')}`, field: 'people_exe' },
      { title: `${t('general.fertilization')}`, field: 'is_fertilization' },
    ])
  }, [t])

  const handleSelectPlot = (plot) => {
    setQueryData((d) => ({ ...d, plots: plot.length > 0 ? plot : [] }))
    plotsSelectorRef.current !== null && plotsSelectorRef.current.setOpen(false)
  }

  const handleDateRangeChange = (e) => {
    const { name, value } = e.target
    setQueryData((d) => ({ ...d, [name]: value }))
  }

  const handleClickSearch = () => {
    search()
  }

  const search = () => {
    const data = {
      plots: queryData.plots.map((x) => x.id),
      start:
        queryData.initialDate !== null
          ? dayjs(queryData.initialDate).format('YYYY-MM-DD')
          : null,
      end:
        queryData.endDate !== null
          ? dayjs(queryData.endDate).format('YYYY-MM-DD')
          : null,
      plantation: currentPlantation.plantation,
    }

    getReport(data)
  }

  const getReport = async (data) => {
    try {
      const response = await paxios.post(
        `${URL_SERVER}/laborsSchedule/getReportExecution`,
        data
      )
      if (response.data.status === 'ok') {
        setRecords(response.data.data)
      }
    } catch (error) {
      Swal.fire(t('general.finished'), t('general.errortoquery'), 'error')
    }
  }
  return (
    <>
      <Grid
        container
        justify="center"
        spacing={2}
        className={classes.gridContainer}
      >
        <Grid item xs={12} md={3}>
          <Paper component="form" className={classes.root}>
            <Typography variant="subtitle1">
              {queryData.plots !== 0
                ? queryData.plots.reduce((acc, item) => {
                    return (acc += acc !== '' ? ',' + item.name : item.name)
                  }, '')
                : `${t('general.select')} ${t('general.plot')}`}
            </Typography>
            <IconButton
              type="button"
              aria-label="search"
              className={classes.iconButton}
              onClick={() =>
                plotsSelectorRef.current !== null &&
                plotsSelectorRef.current.setOpen(true)
              }
            >
              <SearchIcon />
            </IconButton>
          </Paper>
          <PlotsSelector accept={handleSelectPlot} ref={plotsSelectorRef} />
        </Grid>

        <Grid item xs={12} md={4}>
          <DatePickerRange
            initialDate={queryData.initialDate}
            endDate={queryData.endDate}
            handleDateChange={handleDateRangeChange}
          />
        </Grid>

        <Button
          icon={<SearchIcon />}
          handleButtonClick={handleClickSearch}
          text={t('general.search')}
          disabled={
            queryData.initialDate === null || queryData.endDate === null
          }
        />
      </Grid>

      <MaterialTable
        icons={tableIcons}
        columns={columns}
        title={t('general.execution')}
        data={records}
        options={{
          exportButton: true,
          exportAllData: true,
          exportDelimiter: ';',
          selection: false,
        }}
        localization={i18n.language === 'es' ? tableTranslations : {}}
      />
    </>
  )
}
