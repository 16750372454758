import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2/src/sweetalert2.js";
import { Grid, makeStyles } from "@material-ui/core";
import MaterialTable, { MTableToolbar } from "material-table";
import { URL_SERVER, paxios } from "../../../constants/http";
import { SanitationRecordsSearch, Button, Select } from "../../../components";
import { userStore, severalStore } from "../../../stores";
import SaveIcon from "@material-ui/icons/Save";
import tableIcons from "../../../constants/IconsMaterialTable";
import tableTranslations from "../../../constants/tableTranslations";

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    marginBottom: 10,
  },
}));

function Generate() {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const { currentPlantation, user } = userStore();
  const { workers } = severalStore();
  const [years, setYears] = useState([]);
  const [census, setCensus] = useState([]);
  const [columns, setColumns] = useState([]);
  const [recodsSelected, setRecodsSelected] = useState({
    groups: [],
    details: [],
  });
  const [workerSelected, setWorkerSelected] = useState(0);
  const [details, setDetails] = useState([]);

  const getYears = async (plantation) => {
    try {
      const response = await paxios.get(
        `${URL_SERVER}/censusDiaseases/getYearsCensus/${plantation}`
      );
      if (response.data.status === "ok") {
        setYears(response.data.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    setColumns([
      { title: t("general.plot"), field: "plot_name" },
      { title: t("general.line"), field: "line" },
      { title: t("general.palms"), field: "census_palms" },
      {
        title: `${t("general.palms")} ${t("general.line")}`,
        field: "line_palms",
      },
    ]);
  }, [t]);

  useEffect(() => {
    getYears(currentPlantation.plantation);
  }, [currentPlantation.plantation]);

  const validateData = (data) => {
    const errors = {};

    if (data.plot === 0 || data.plot === "0")
      errors.plot = t("general.selectplot");

    return errors;
  };

  const dataSearch = (data) => {
    const errors = validateData(data);
    if (true) {
      //Object.keys(errors).length <= 0
      const send = {
        ...data,
        plot:
          data.plot !== "0" && data.plot !== 0
            ? data.plot.map((x) => x.id)
            : [],
        dates:
          data.initialDate !== null && data.endDate !== null
            ? `${data.initialDate},${data.endDate}`
            : "",
        plantation: currentPlantation.plantation,
      };
      getData(send);
    } else {
      const message = Object.keys(errors).reduce((acc, item) => {
        return (acc += `<p>${errors[item]}</p>`);
      }, "");
      Swal.fire(t("general.finished"), `<div>${message}</div>`, "error");
    }
  };

  const getData = async (data) => {
    try {
      const response = await paxios.post(
        `${URL_SERVER}/ordersSupervisionCensusDiseases/searchCensus`,
        data
      );
      if (response.data.status === "ok") {
        setCensus(response.data.data.census_group);
        setDetails(response.data.data.details);
      }
    } catch (error) {
      Swal.fire(t("general.finished"), t("general.errortoquery"), "error");
    }
  };

  const handleChangeWorker = (e) => {
    const { value } = e.target;
    setWorkerSelected(value);
  };

  const validateButtonGenerate = () => {
    if (recodsSelected.groups.length <= 0) return true;
    if (String(workerSelected) === "0") return true;

    return false;
  };

  const selectRow = (rows) => {
    let details = [];
    for (const row of rows) {
      const d = getDetailsGroup(row);
      details = [...details, ...d];
    }

    setRecodsSelected({
      groups: rows,
      details,
    });
  };

  const getDetailsGroup = (group) => {
    return details.filter(
      (x) => x.plot === group.plot && x.line === group.line
    );
  };

  const showDetails = (event, rowData) => {
    const details = getDetailsGroup(rowData);
    console.log(details)
  };

  const generate = async (worker, records) => {
    const send = {
      ...records,
      supervisor: worker,
      plantation: currentPlantation.plantation,
      user: user.id,
    };

    console.log(send);
  };

  const handleClickGenerate = () => {
    generate(workerSelected, recodsSelected);
  };
  return (
    <>
      <SanitationRecordsSearch
        years={years.map((x) => ({ id: x.year, name: x.year }))}
        titleCensus={t("general.census")}
        handleClickSearch={dataSearch}
      />

      <MaterialTable
        icons={tableIcons}
        columns={columns}
        title={t("general.records")}
        data={census}
        options={{
          exportButton: true,
          exportAllData: true,
          exportDelimiter: ";",
          selection: true,
        }}
        localization={i18n.language === "es" ? tableTranslations : {}}
        onSelectionChange={selectRow}
        onRowClick={showDetails}
        components={{
          Toolbar: (props) => (
            <div>
              <MTableToolbar {...props} />
              <Grid
                container
                justify="center"
                spacing={2}
                className={classes.gridContainer}
              >
                <Grid item xs={12} md={3}></Grid>
                <Grid item xs={12} md={3}>
                  <Select
                    text={t("general.supervisor")}
                    width={"100%"}
                    items={workers
                      .filter((x) => x.supervisor === 1)
                      .map((x) => ({ id: x.id, name: x.name }))}
                    defaultOption0={true}
                    value={workerSelected}
                    handleChange={handleChangeWorker}
                  />
                  <Button
                    icon={<SaveIcon />}
                    handleButtonClick={handleClickGenerate}
                    text={t("general.generate")}
                    disabled={validateButtonGenerate()}
                    loading={false}
                  />
                </Grid>
                <Grid item xs={12} md={3}></Grid>
              </Grid>
            </div>
          ),
        }}
      />
    </>
  );
}

export default Generate;
