export const ROUTES = {
    logout: '/logout',
    home: '/home',
    changepass: '/changepass',
    plantation: '/plantation',
    sectors: '/sectors',
    subdivision: '/subdivision',
    plots: '/plots',
    eradications: '/eradications',
    palms: '/palms',
    mapcadastre: '/mapcadastre',
    diseases: '/diseases',
    pests: '/pests',
    treatments: '/treatments',
    rhynchophorus: '/rhynchophorus',
    mapsanitation: '/mapsanitation',
    //production
    pollination: '/pollination',
    pollinationana: '/pollinationana',
    yield: '/yield',
    mapproduction: '/mapproduction',
    //labors
    schedulelabors: '/schedulelabors',
    //nutrition
    fertilizationplan: '/fertilizationplan',
    //supervision
    supervisiondiseases: '/supervisiondiseases',
    //machinery
    machinerymap: '/machinerymap',
    adminsanitation: '/adminsanitation',
    adminproduction: '/adminproduction',
    adminlabors: '/adminlabors',
    adminnutrition: '/adminnutrition',
    adminmachinery: '/adminmachinery',
    adminseveral: '/adminseveral',
    adminusers: '/adminusers'
}