import { useEffect, useState } from 'react'
import Swal from 'sweetalert2/src/sweetalert2.js'
import MaterialTable from 'material-table'
import { useTranslation } from 'react-i18next'
import { sanitationStore, userStore, cadastreStore } from '../../../stores'
import { URL_SERVER, paxios } from '../../../constants/http'
import tableIcons from '../../../constants/IconsMaterialTable'
import tableTranslations from '../../../constants/tableTranslations'

const cellStyle = { align: 'center', headerStyle: { textAlign: 'center' } }


export default function Traps() {
  const { t, i18n } = useTranslation()
  const { currentPlantation } = userStore()
  const { traps, getTraps } = sanitationStore()
  const { plots } = cadastreStore()
  const [columns, setColumns] = useState([])
  const [consulted, setConsulted] = useState(false)

  useEffect(() => {
    (async() => {
        if(traps.length <= 0 && consulted === false){
            await getTraps()
            setConsulted(true)
        }
    })()
  },[consulted, traps.length, getTraps])

  useEffect(() => {
    const validateEmpty = (rowData, field) => {
      return rowData[field] === '' || rowData[field] === undefined || String(rowData[field]) === 'NaN'
        ? t('general.noempty')
        : true
    }

    const validateDate = (rowData, field) => {
        return rowData[field] === '' ||
            rowData[field] === undefined ||
            rowData[field] === null
            ? t('general.date_invalid')
            : true
    }

    const validateNoZero = (rowData, field) => {
        return rowData[field] <= 0 || rowData[field] === '0'
          ? t('general.nozero')
          : true
    }

    setColumns([
      { title: 'ID', field: 'id', editable: 'never' },
      {
        title: t('general.code'),
        field: 'code',
        validate: (rowData) => validateEmpty(rowData, 'code'),
      },
      {
        title: t('general.instalation'),
        field: 'instalation',
        type: 'date',
        validate: (rowData) => validateDate(rowData, 'instalation'),
      },
      {
        title: t('general.type'),
        field: 'type',
        lookup: { 1: 'Feronoma + Fermento' },
        initialEditValue: 1,
        ...cellStyle,
      },
      {
        title: t('general.plot'),
        field: 'plot',
        lookup: {
          0: t('general.notassigned'),
          ...plots.reduce((acc, item) => {
            return { ...acc, [item.id]: item.name }
          }, {}),
        },
        initialEditValue: 0,
        ...cellStyle,
        validate: (rowData) => validateNoZero(rowData, 'plot'),
      },
      {
        title: t('general.state'),
        field: 'state',
        lookup: { 1: t('general.active'), 2: t('general.inactive') },
        initialEditValue: 1,
        ...cellStyle,
      },
      {
        title: t('general.latitude'),
        field: 'latitude',
        type: 'numeric',
        initialEditValue: 0,
        validate: (rowData) => validateEmpty(rowData, 'latitude'),
      },
      {
        title: t('general.longitude'),
        field: 'longitude',
        type: 'numeric',
        initialEditValue: 0,
        validate: (rowData) => validateEmpty(rowData, 'longitude'),
      },
    ])

  }, [t, plots])

  const createTrap = async (data) => {
    try {
      const response = await paxios.post(`${URL_SERVER}/trapsRhynchophorus`, {
          ...data,
          plantation: currentPlantation.plantation
        })
      if (response.data.status === 'ok') {
        Swal.fire(t('general.finished'), t('general.savesuccess'), 'success')
        getTraps()
      } else {
        Swal.fire(t('general.finished'), response.data.message, 'error')
      }
    } catch (error) {
      Swal.fire(t('general.finished'), t('general.datawasnotsaved'), 'error')
    }
  }

  const onRowAddTrap = (newData) =>
    new Promise(async (resolve) => {
      await createTrap(newData)
      resolve()
    })

  const updateTrap = async (data) => {
    try {
      const response = await paxios.put(`${URL_SERVER}/trapsRhynchophorus/${data.id}`, data)
      if (response.data.status === 'ok') {
        Swal.fire(t('general.finished'), t('general.savesuccess'), 'success')
        getTraps()
      } else {
        Swal.fire(t('general.finished'), t('general.datawasnotsaved'), 'error')
      }
    } catch (error) {
      Swal.fire(t('general.finished'), t('general.datawasnotsaved'), 'error')
    }
  }

  const onRowUpdateTrap = (newData, oldData) =>
    new Promise(async (resolve) => {
      await updateTrap(newData)
      resolve()
    })

  return (
    <>
      <MaterialTable
        icons={tableIcons}
        columns={columns}
        title={t('general.traps')}
        data={traps.map((x) => ({ ...x, ti: new Date() }))}
        options={{
          exportButton: true,
          exportAllData: true,
          exportDelimiter: ';',
          selection: false,
        }}
        localization={i18n.language === 'es' ? tableTranslations : {}}
        editable={{
          onRowUpdate: onRowUpdateTrap,
          onRowAdd: onRowAddTrap,
        }}
      />
    </>
  )
}
