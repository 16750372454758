import { useEffect, useState } from "react";
import { machineryStore } from "../../../stores";
import Swal from "sweetalert2/src/sweetalert2.js";
import MaterialTable from "material-table";
import { useTranslation } from "react-i18next";
import { URL_SERVER, paxios } from "../../../constants/http";
import tableIcons from "../../../constants/IconsMaterialTable";
import tableTranslations from "../../../constants/tableTranslations";

const cellStyle = { align: "center", headerStyle: { textAlign: "center" } };

export default function Machines() {
  const { t, i18n } = useTranslation();
  const { machines, getMachines } = machineryStore();
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    machines.length <= 0 && getMachines();
  }, [machines.length, getMachines]);

  useEffect(() => {
    const validateEmpty = (rowData, field) => {
      return rowData[field] === "" || rowData[field] === undefined
        ? t("general.noempty")
        : true;
    };
    setColumns([
      { title: "ID", field: "id", editable: "never" },
      {
        title: `${t("general.description")}`,
        field: "description",
        validate: (rowData) => validateEmpty(rowData, "description"),
      },
      {
        title: `${t("general.brand")}`,
        field: "brand",
        validate: (rowData) => validateEmpty(rowData, "brand"),
      },
      {
        title: t("general.state"),
        field: "state",
        lookup: { 1: t("general.active"), 0: t("general.inactive") },
        initialEditValue: 1,
        ...cellStyle,
      },
    ]);
  }, [t]);

  const create = async (data) => {
    try {
      const response = await paxios.post(`${URL_SERVER}/machinery`, {
        ...data,
      });
      if (response.data.status === "ok") {
        Swal.fire(t("general.finished"), t("general.savesuccess"), "success");
        getMachines();
      } else {
        Swal.fire(t("general.finished"), response.data.message, "error");
      }
    } catch (error) {
      Swal.fire(t("general.finished"), t("general.datawasnotsaved"), "error");
    }
  };

  const onRowAdd = (newData) =>
    new Promise(async (resolve) => {
      await create(newData);
      resolve();
    });

  const update = async (data) => {
    try {
      const response = await paxios.put(`${URL_SERVER}/machinery/${data.id}`, {
        ...data,
      });
      if (response.data.status === "ok") {
        Swal.fire(t("general.finished"), t("general.savesuccess"), "success");
        getMachines();
      } else {
        Swal.fire(t("general.finished"), t("general.datawasnotsaved"), "error");
      }
    } catch (error) {
      Swal.fire(t("general.finished"), t("general.datawasnotsaved"), "error");
    }
  };

  const onRowUpdate = (newData, oldData) =>
    new Promise(async (resolve) => {
      await update(newData);
      resolve();
    });
  return (
    <>
      <MaterialTable
        icons={tableIcons}
        columns={columns}
        title={`${t("general.machine")}s`}
        data={machines}
        options={{
          exportButton: true,
          exportAllData: true,
          exportDelimiter: ";",
          selection: false,
        }}
        localization={i18n.language === "es" ? tableTranslations : {}}
        editable={{
          onRowUpdate: onRowUpdate,
          onRowAdd: onRowAdd,
        }}
      />
    </>
  );
}
