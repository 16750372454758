import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import Swal from 'sweetalert2/src/sweetalert2.js'
import MaterialTable from 'material-table'
import { SanitationRecordsSearch, DeleteConfirmation } from '../../components'
import { useTranslation } from 'react-i18next'
import {
  userStore,
  cadastreStore,
  sanitationStore,
  severalStore,
} from '../../stores'
import { URL_SERVER, paxios } from '../../constants/http'
import tableIcons from '../../constants/IconsMaterialTable'
import tableTranslations from '../../constants/tableTranslations'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'

const cellStyle = { align: 'center', headerStyle: { textAlign: 'center' } }

export default function Census() {
  const { t, i18n } = useTranslation()
  const { currentPlantation, user } = userStore()
  const { traps } = sanitationStore()
  const { plots } = cadastreStore()
  const { workers } = severalStore()
  const [years, setYears] = useState([])
  const [census, setCensus] = useState([])
  const [columns, setColumns] = useState([])
  const [recordToDelete, setRecordToDelete] = useState({})
  const [openDelete, setOpenDelete] = useState(false)

  useEffect(() => {
    const validateEmpty = (rowData, field) => {
      return rowData[field] === '' ||
        rowData[field] === undefined ||
        rowData[field] === null ||
        isNaN(rowData[field])
        ? t('general.noempty')
        : true
    }

    const validateDate = (rowData, field) => {
      return rowData[field] === '' ||
        rowData[field] === undefined ||
        rowData[field] === null
        ? t('general.date_invalid')
        : true
    }

    const validateNoZero = (rowData, field) => {
      return rowData[field] <= 0 || rowData[field] === '0'
        ? t('general.nozero')
        : true
    }

    const validateLength = (rowData, field, length) => {
      return String(rowData[field]).length !== length || isNaN(rowData[field])
        ? t('general.value_invalid')
        : true
    }

    const validateNoZeroEmpty = (rowData, field) => {
      if (
        validateNoZero(rowData, field) === true &&
        validateEmpty(rowData, field) === true
      ) {
        return true
      } else {
        return `${
          validateNoZero(rowData, field) === true
            ? ''
            : validateNoZero(rowData, field)
        } ${
          validateEmpty(rowData, field) === true
            ? ''
            : ', ' + validateEmpty(rowData, field)
        }`
      }
    }

    setColumns([
      { title: 'ID', field: 'id', editable: 'never' },
      {
        title: t('general.reading'),
        field: 'census',
        type: 'numeric',
        validate: (rowData) => validateLength(rowData, 'census', 6),
        initialEditValue: 0,
      },
      {
        title: t('general.date'),
        field: 'date',
        type: 'date',
        validate: (rowData) => validateDate(rowData, 'date'),
      },
      {
        title: t('general.trap'),
        field: 'trap',
        lookup: {
          0: t('general.notassigned'),
          ...traps.reduce((acc, item) => {
            return { ...acc, [item.id]: item.code }
          }, {}),
        },
        initialEditValue: 0,
        ...cellStyle,
        validate: (rowData) => validateNoZero(rowData, 'trap'),
      },
      {
        title: t('general.plot'),
        field: 'plot',
        lookup: {
          0: t('general.notassigned'),
          ...plots.reduce((acc, item) => {
            return { ...acc, [item.id]: item.name }
          }, {}),
        },
        initialEditValue: 0,
        ...cellStyle,
        validate: (rowData) => validateNoZero(rowData, 'plot'),
      },
      {
        title: t('general.males'),
        field: 'males',
        type: 'numeric',
        validate: (rowData) => validateNoZeroEmpty(rowData, 'males'),
      },
      {
        title: t('general.females'),
        field: 'females',
        type: 'numeric',
        validate: (rowData) => validateNoZeroEmpty(rowData, 'females'),
      },
      {
        title: t('general.no_reading'),
        field: 'no_reading',
        lookup: {
          0: 'No',
          1: t('general.yes'),
        },
        initialEditValue: 0,
        ...cellStyle,
      },
      {
        title: t('general.pheromone_change'),
        field: 'pheromone_change',
        lookup: {
          0: 'No',
          1: t('general.yes'),
        },
        initialEditValue: 0,
        ...cellStyle,
      },
      {
        title: t('general.attractive_change'),
        field: 'attractive_change',
        lookup: {
          0: 'No',
          1: t('general.yes'),
        },
        initialEditValue: 0,
        ...cellStyle,
      },
      {
        title: t('general.worker'),
        field: 'worker',
        lookup: {
          0: t('general.notassigned'),
          ...workers.reduce((acc, item) => {
            return { ...acc, [item.id]: item.name }
          }, {}),
        },
        initialEditValue: 0,
        ...cellStyle,
        validate: (rowData) => validateNoZero(rowData, 'worker'),
      },
      { title: t('general.observations'), field: 'observations' },
    ])
  }, [plots, workers, traps, t])

  const getYears = async (plantation) => {
    try {
      const response = await paxios.get(
        `${URL_SERVER}/censusRhynchophorus/getYearsCensus/${plantation}`
      )
      if (response.data.status === 'ok') {
        setYears(response.data.data)
      }
    } catch (error) {}
  }

  useEffect(() => {
    getYears(currentPlantation.plantation)
  }, [currentPlantation.plantation])

  const validateData = (data) => {
    const errors = {}

    if (data.plot === 0 || data.plot === '0')
      errors.plot = t('general.selectplot')

    return errors
  }

  const dataSearch = (data) => {
    const errors = validateData(data)
    if (true) {
      //Object.keys(errors).length <= 0
      const send = {
        ...data,
        plot:
          data.plot !== '0' && data.plot !== 0
            ? data.plot.map((x) => x.id)
            : [],
        dates:
          data.initialDate !== null && data.endDate !== null
            ? `${data.initialDate},${data.endDate}`
            : '',
        plantation: currentPlantation.plantation,
      }
      getCensus(send)
    } else {
      const message = Object.keys(errors).reduce((acc, item) => {
        return (acc += `<p>${errors[item]}</p>`)
      }, '')
      Swal.fire(t('general.finished'), `<div>${message}</div>`, 'error')
    }
  }

  const getCensus = async (data) => {
    try {
      const response = await paxios.post(
        `${URL_SERVER}/censusRhynchophorus/getCensus`,
        data
      )
      if (response.data.status === 'ok') {
        setCensus(response.data.data)
      }
    } catch (error) {
      Swal.fire(t('general.finished'), t('general.errortoquery'), 'error')
    }
  }

  const onRowAdd = (newData) =>
    new Promise(async (resolve) => {
      createCensus(newData)
      resolve()
    })

  const createCensus = async (data) => {
    try {
      const send = {
        ...data,
        date: data.date !== null ? dayjs(data.date).format('YYYY-MM-DD') : null,
        plantation: currentPlantation.plantation,
      }
      const response = await paxios.post(
        `${URL_SERVER}/censusRhynchophorus/registerCensus`,
        send
      )
      if (response.data.status === 'ok') {
        Swal.fire(t('general.finished'), t('general.savesuccess'), 'success')
        setCensus((d) => [...d, { ...response.data.data[0] }])
      } else {
        Swal.fire(t('general.finished'), response.data.message, 'error')
      }
    } catch (error) {
      Swal.fire(t('general.finished'), t('general.datawasnotsaved'), 'error')
    }
  }

  const onRowUpdate = (newData, oldData) =>
    new Promise(async (resolve) => {
      updateCensus(newData)
      resolve()
    })

  const updateCensus = async (data) => {
    try {
      const send = {
        ...data,
        date: data.date !== null ? dayjs(data.date).format('YYYY-MM-DD') : null,
      }
      const response = await paxios.put(
        `${URL_SERVER}/censusRhynchophorus/updateCensus/${data.id}`,
        send
      )
      if (response.data.status === 'ok') {
        Swal.fire(t('general.finished'), t('general.savesuccess'), 'success')
        setCensus((d) => {
          return d.map((x) => {
            if (String(x.id) === String(data.id)) return { ...send }
            return { ...x }
          })
        })
      } else {
        Swal.fire(t('general.finished'), response.data.message, 'error')
      }
    } catch (error) {
      Swal.fire(t('general.finished'), t('general.datawasnotsaved'), 'error')
    }
  }

  const deleteConfirmation = (item) => {
    if (user.profile !== 1) return
    setRecordToDelete(item)
    setOpenDelete(true)
  }

  const handleClickDelete = (event, item) => {
    deleteConfirmation(item)
  }

  const deleteRecord = async (item) => {
    try {
      const response = await paxios.delete(
        `${URL_SERVER}/censusRhynchophorus/${item.id}`
      )
      if (response.data.status === 'ok') {
        Swal.fire(t('general.finished'), t('general.savesuccess'), 'success')
        setCensus((d) => d.filter((x) => x.id !== item.id))
        setOpenDelete(false)
      } else {
        Swal.fire(t('general.finished'), t('general.datawasnotsaved'), 'error')
      }
    } catch (error) {
      Swal.fire(t('general.finished'), t('general.datawasnotsaved'), 'error')
    }
  }
  return (
    <>
      <SanitationRecordsSearch
        years={years.map((x) => ({ id: x.year, name: x.year }))}
        titleCensus={'rhynchophorus'}
        handleClickSearch={dataSearch}
      />

      <MaterialTable
        icons={tableIcons}
        columns={columns}
        title={t('general.censuses')}
        data={census.map((x) => ({
          ...x,
          date: x.date !== null ? x.date + ' 00:00:00' : null,
        }))}
        options={{
          exportButton: true,
          exportAllData: true,
          exportDelimiter: ';',
          selection: false,
        }}
        localization={i18n.language === 'es' ? tableTranslations : {}}
        editable={{
          onRowUpdate: onRowUpdate,
          onRowAdd: onRowAdd,
        }}
        actions={[
          {
            icon: () => <DeleteForeverIcon />,
            tooltip: t('general.cost'),
            onClick: handleClickDelete,
          },
        ]}
      />
      {openDelete && (
        <DeleteConfirmation
          open={openDelete}
          setOpen={setOpenDelete}
          item={recordToDelete}
          handleOk={deleteRecord}
        />
      )}
    </>
  )
}
