import { useEffect } from 'react'
import { userStore } from '../stores'
import { ROUTES } from '../constants/routes'

export default function Home() {
  const { setUser } = userStore()
  useEffect(() => {
    setUser({ currentUrl: ROUTES.home })
  }, [setUser])
  return <div>This is home</div>
}
