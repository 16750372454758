import { useTranslation } from 'react-i18next'
import { userStore } from '../../stores'
import { URL_SERVER, paxios, uploadFile } from '../../constants/http'
import { LoadExcel } from '../../components'

export default function ByExcel() {
  const { currentPlantation } = userStore()
  const { t } = useTranslation()

  const columns = [
    { id: 'date', label: t('general.date') },
    { id: 'plot', label: t('general.plot') },
    { id: 'line', label: t('general.line') },
    { id: 'number_of_palms', label: t('general.number_of_palms') },
    { id: 'good', label: t('general.good') },
    { id: 'helped', label: t('general.helped') },
    { id: 'double', label: t('general.double') },
    { id: 'worker', label: t('general.worker') },
    { id: 'observations', label: t('general.observations') },
    { id: 'errors', label: t('general.errors') },
  ]

  const loadFile = async (file) => {
    try {
      const response = await uploadFile({
        url: `${URL_SERVER}/pollination/loadExcelPollinationByLine`,
        file,
        nameFile: 'file_pollination',
        aditionalData: [
          { name: 'plantation', value: currentPlantation.plantation },
        ],
      })
      return response
    } catch (error) {}
  }

  const saveData = async (records) => {
    try {
      const response = await paxios.post(
        `${URL_SERVER}/pollination/saveExcelPollinationByLine`,
        {
          plantation: currentPlantation.plantation,
          data: records,
        }
      )
      return response
    } catch (error) {}
  }

  return (
    <>
      <LoadExcel columns={columns} loadFile={loadFile} saveData={saveData} />
    </>
  )
}
