import { useState, useRef, useEffect } from 'react'
import {
  Grid,
  Typography,
  makeStyles,
  Paper,
  IconButton,
  TextField,
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import MaterialTable from 'material-table'
import { useTranslation } from 'react-i18next'
import { userStore } from '../../../stores'
import { URL_SERVER, paxios } from '../../../constants/http'
import tableIcons from '../../../constants/IconsMaterialTable'
import tableTranslations from '../../../constants/tableTranslations'
import { Button, PlotsSelector, Select, SubTitle } from '../../../components'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  gridContainer: {
    marginBottom: 10,
  },
}))

export default function EstimateProduction() {
  const classes = useStyles()
  const { currentPlantation } = userStore()
  const plotsSelectorRef = useRef(null)
  const { t, i18n } = useTranslation()
  const [months, setMonths] = useState([])
  const [records, setRecords] = useState([])
  const [columns, setColumns] = useState([])
  const [queryData, setQueryData] = useState({
    plot: [],
    month: '0',
    weight: '',
  })

  const getMonth = async (plantation) => {
    try {
      const response = await paxios.get(
        `${URL_SERVER}/pollination/getMonthsEstimateProduction/${plantation}`
      )
      if (response.data.status === 'ok') {
        setMonths(response.data.data)
      }
    } catch (error) {}
  }

  useEffect(() => {
    getMonth(currentPlantation.plantation)
  }, [currentPlantation.plantation])

  useEffect(() => {
    setColumns([
      { title: t('general.plot'), field: 'plot' },
      { title: t('general.material'), field: 'material' },
      { title: t('general.sowing'), field: 'sowing' },
      { title: t('general.bunches'), field: 'bunches' },
      { title: `${t('general.production')}(Kg)`, field: 'production' },
    ])
  }, [setColumns, t])

  const handleSelectPlot = (plot) => {
    setQueryData((d) => ({ ...d, plot: plot.length > 0 ? plot : 0 }))
    plotsSelectorRef.current !== null && plotsSelectorRef.current.setOpen(false)
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setQueryData((d) => ({ ...d, [name]: value }))
  }

  const handleClickSearch = () => {
    search()
  }

  const search = async () => {
    try {
      const send = {
        ...queryData,
        plot: queryData.plot.length > 0 ? queryData.plot.map((x) => x.id) : [],
        plantation: currentPlantation.plantation,
      }

      const response = await paxios.post(
        `${URL_SERVER}/pollination/getReportEstimateProduction`,
        send
      )

      if (response.data.status === 'ok') {
        setRecords(response.data.data)
      }
    } catch (error) {}
  }
  return (
    <>
      <SubTitle text={t('general.estimateproduction')} />
      <Grid
        container
        justify="center"
        spacing={2}
        className={classes.gridContainer}
      >
        <Grid item xs={12} md={4}>
          <Paper component="form" className={classes.root}>
            <Typography variant="subtitle1">
              {queryData.plot !== 0
                ? queryData.plot.reduce((acc, item) => {
                    return (acc += acc !== '' ? ',' + item.name : item.name)
                  }, '')
                : `${t('general.select')} ${t('general.plot')}`}
            </Typography>
            <IconButton
              type="button"
              aria-label="search"
              className={classes.iconButton}
              onClick={() =>
                plotsSelectorRef.current !== null &&
                plotsSelectorRef.current.setOpen(true)
              }
            >
              <SearchIcon />
            </IconButton>
          </Paper>
          <PlotsSelector accept={handleSelectPlot} ref={plotsSelectorRef} />
        </Grid>

        <Grid item xs={12} md={2}>
          <Select
            name="month"
            text={t('general.month')}
            width={'100%'}
            items={months.map((x) => ({ id: x.month, name: x.month }))}
            defaultOption0={true}
            value={queryData.month}
            handleChange={handleChange}
            required={true}
            error={queryData.month === '0' || queryData.month === 0}
          />
        </Grid>

        <Grid item xs={12} md={2}>
          <TextField
            fullWidth
            required
            label={t('general.middleweight')}
            name="weight"
            type={'number'}
            value={queryData.weight}
            onChange={handleChange}
            error={
              queryData.weight === '' ||
              queryData.weight === '0' ||
              queryData.weight === 0
            }
          />
        </Grid>
      </Grid>

      <Button
        icon={<SearchIcon />}
        handleButtonClick={handleClickSearch}
        text={t('general.search')}
        disabled={
          queryData.month === '0' ||
          queryData.month === 0 ||
          queryData.weight === '' ||
          queryData.weight === '0' ||
          queryData.weight === 0
        }
      />

      <MaterialTable
        icons={tableIcons}
        columns={columns}
        title={t('general.estimateproduction')}
        data={records}
        options={{
          exportButton: true,
          exportAllData: true,
          exportDelimiter: ';',
          selection: false,
        }}
        localization={i18n.language === 'es' ? tableTranslations : {}}
      />
    </>
  )
}
