import { useState, useEffect, useRef, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  addSourceLayer,
  setDataSourceLayer,
  addCircleLayer,
  createSimpleTable,
  geoJsonEmpty,
  dinamicCircleColor2Params,
  dynamicLineColor,
  addLineLayer,
} from '../../components/map/utilities'
import { userStore, mapsStore } from '../../stores'
import { URL_SERVER, paxios, getTokenQgis, generateQgisQuery } from '../../constants/http'
import { Map } from '../../components'
import Filters from './Filters'

export default function Home() {
  const { t } = useTranslation()
  const mapRef = useRef(null)
  const filtersRef = useRef(null)
  const { currentPlantation } = userStore()
  const { colors, getColorsMaps } = mapsStore()
  const [layerSelected, setLayerSelected] = useState('')

  useEffect(() => {
    Object.keys(colors).length <= 0 && getColorsMaps()
  }, [colors, getColorsMaps])


  const featureDiseases = (e) => {
    const data = [
      { name: 'census', value: e.properties.census },
      { name: 'date', value: e.properties.date },
      { name: 'plot', value: e.properties.plot_name },
      { name: 'line', value: e.properties.line },
      { name: 'palm', value: e.properties.palm },
      { name: 'disease', value: e.properties.disease_name },
      { name: 'event', value: e.properties.event_name },
      { name: 'worker', value: e.properties.worker_name },
    ]
    return createSimpleTable(data)
  }

  const featurePests = (e) => {
    const data = [
      { name: 'reading', value: e.properties.reading },
      { name: 'date', value: e.properties.date },
      { name: 'plot', value: e.properties.plot_name },
      { name: 'line', value: e.properties.line },
      { name: 'palm', value: e.properties.palm },
      { name: 'pest', value: e.properties.pest_name },
      { name: 'state', value: e.properties.state_name },
      { name: 'quantity', value: e.properties.quantity },
      { name: 'worker', value: e.properties.worker_name },
    ]
    return createSimpleTable(data)
  }

  const featureTreatments = (e) => {
    const data = [
      { name: 'date', value: e.properties.date },
      { name: 'plot', value: e.properties.plot_name },
      { name: 'line', value: e.properties.line },
      { name: 'palm', value: e.properties.palm },
      { name: 'treatment', value: e.properties.treatment_name },
      { name: 'disease', value: e.properties.disease_name },
      { name: 'event', value: e.properties.event_name },
      { name: 'worker', value: e.properties.worker_name },
    ]
    return createSimpleTable(data)
  }

  const featureTracksForm = (e) => {
    const data = [
      { name: 'date', value: e.properties.date },
      { name: 'plot', value: e.properties.plot_name },
      { name: 'worker', value: e.properties.worker_name },
      { name: 'distance', value: e.properties.length_gps },
      { name: "start_hour", value: e.properties.start_hour },
      { name: "enddate", value: e.properties.end_hour },
      { name: "time", value: e.properties.time },
    ]
    return createSimpleTable(data)
  }

  const filtersLayer = useMemo(
    () => [
      {
        id: 'diseases_layer',
        text: `${t('general.filter')} ${t('general.diseases')}`,
        type: 'circle',
        dinamicColor: colors.diseases ? dinamicCircleColor2Params(
          colors.diseases,
          'disease',
          'event_disease',
          'disease',
          'event'
        ) : null,
        onClick: (id, map) => {
          setLayerSelected(id)
          if (filtersRef.current) {
            filtersRef.current.setOpen(true)
          }
          addSourceLayer(map, id)
        },
        onClickLayer: (mapboxgl, e, layerId, layerData) => {
          //const popup = createPopup(mapboxgl, e, layerData, featureDiseases)
          //popup.addTo(mapRef.current.getMap())
          const html = featureDiseases(layerData);
          mapRef.current.changeSidebarRightContent(html);
          mapRef.current.openSidebarRight()
        },
      },
      {
        id: 'pests_layer',
        text: `${t('general.filter')} ${t('general.pests')}`,
        type: 'circle',
        dinamicColor: colors.pests ? dinamicCircleColor2Params(
          colors.pests,
          'pest',
          'state_pest',
          'pest',
          'state'
        ) : null,
        onClick: (id, map) => {
          setLayerSelected(id)
          if (filtersRef.current) {
            filtersRef.current.setOpen(true)
          }
          addSourceLayer(map, id)
        },
        onClickLayer: (mapboxgl, e, layerId, layerData) => {
          //const popup = createPopup(mapboxgl, e, layerData, featurePests)
          //popup.addTo(mapRef.current.getMap())
          const html = featurePests(layerData);
          mapRef.current.changeSidebarRightContent(html);
          mapRef.current.openSidebarRight()
        },
      },
      {
        id: 'treatments_layer',
        text: `${t('general.filter')} ${t('general.treatments')}`,
        type: 'circle',
        dinamicColor: null,
        onClick: (id, map) => {
          setLayerSelected(id)
          if (filtersRef.current) {
            filtersRef.current.setOpen(true)
          }
          addSourceLayer(map, id)
        },
        onClickLayer: (mapboxgl, e, layerId, layerData) => {
          //const popup = createPopup(mapboxgl, e, layerData, featureTreatments)
          //popup.addTo(mapRef.current.getMap())
          const html = featureTreatments(layerData);
          mapRef.current.changeSidebarRightContent(html);
          mapRef.current.openSidebarRight()
        },
      },
      {
        id: 'tracks_layer',
        text: `${t('general.filter')} ${t('general.track')}s`,
        type: 'line',
        dinamicColor: null,
        onClick: (id, map) => {
          setLayerSelected(id)
          if (filtersRef.current) {
            filtersRef.current.setOpen(true)
          }
          addSourceLayer(map, id)
        },
        onClickLayer: (mapboxgl, e, layerId, layerData) => {
          const html = featureTracksForm(layerData);
          mapRef.current.changeSidebarRightContent(html);
          mapRef.current.openSidebarRight()
        },
      },
    ],
    [t, colors]
  )

  const validateDataDiseases = (data) => {
    const errors = {}

    if (data.dates === '')
      errors.dates = `${t('general.select')}, ${t('general.initialdate')} ${t(
        'general.enddate'
      )}`

    return errors
  }

  const getDataLayer = async (url, parameters, map, layerId) => {
    try {
      const layerObject = filtersLayer.find((x) => x.id === layerId)
      //const layerType = layerObject.type
      const dinamicColor = layerObject.dinamicColor
      const response = await paxios.post(`${URL_SERVER}/${url}`, parameters)
      if (response.data.status === 'ok') {
        if(layerId === 'tracks_layer'){
          setDataSourceLayer(map, layerId, response.data.data);
          const arrayToColors = response.data.data.features.map(x => x.properties.id_unique);
          addLineLayer(map, layerId, dynamicLineColor(arrayToColors, 'id_unique'));
        }else {
          setDataSourceLayer(map, layerId, response.data.data)
          addCircleLayer(map, layerId, dinamicColor)
        }
      }
    } catch (error) {}
  }

  const getLayer = (data, layerSelected) => {
    for (const layer of filtersLayer) {
      setDataSourceLayer(mapRef.current.getMap(), layer.id, geoJsonEmpty)
    }
    let errors = {}
    switch (layerSelected) {
      case 'diseases_layer':
        errors = validateDataDiseases(data)
        if (Object.keys(errors).length <= 0) {
          filtersRef.current.setErrors('')
          getDataLayer(
            'censusDiaseases/getCensusMap',
            data,
            mapRef.current.getMap(),
            layerSelected
          )
        } else {
          const message = Object.keys(errors).reduce((acc, item) => {
            return (acc += `${errors[item]}`)
          }, '')
          filtersRef.current.setErrors(message)
        }
        break
      case 'pests_layer':
        errors = validateDataDiseases(data)
        if (Object.keys(errors).length <= 0) {
          filtersRef.current.setErrors('')
          getDataLayer(
            'readingsPests/getReadingMap',
            data,
            mapRef.current.getMap(),
            layerSelected
          )
        } else {
          const message = Object.keys(errors).reduce((acc, item) => {
            return (acc += `${errors[item]}`)
          }, '')
          filtersRef.current.setErrors(message)
        }
        break
      case 'treatments_layer':
        errors = validateDataDiseases(data)
        if (Object.keys(errors).length <= 0) {
          filtersRef.current.setErrors('')
          getDataLayer(
            'executionTreatments/getExecutionTreatmentsMap',
            data,
            mapRef.current.getMap(),
            layerSelected
          )
        } else {
          const message = Object.keys(errors).reduce((acc, item) => {
            return (acc += `${errors[item]}`)
          }, '')
          filtersRef.current.setErrors(message)
        }
        break
      case 'tracks_layer':
        errors = validateDataDiseases(data)
        if (Object.keys(errors).length <= 0) {
          filtersRef.current.setErrors('')
          getDataLayer(
            'tracks/getTracksForm',
            data,
            mapRef.current.getMap(),
            layerSelected
          )
        } else {
          const message = Object.keys(errors).reduce((acc, item) => {
            return (acc += `${errors[item]}`)
          }, '')
          filtersRef.current.setErrors(message)
        }
        break  

      default:
        break
    }
  }

  const generateUriQgis = async (parameters, layerSelected) => {
    const identifier = await generateQgisQuery(parameters)
    const token = await getTokenQgis();
    let url = ""
    switch (layerSelected) {
      case 'diseases_layer':
        url = "getCensusDiseases";
        break;
      case 'pests_layer':
        url = 'getReadingsPests';
        break;
      case 'treatments_layer':
        url = 'getExecutionTreatments';
        break;
      case 'tracks_layer':
        url = "getTracksForm"
        break
      default:
        break;
    }
    const uri = `${URL_SERVER}/qgis/${url}/${identifier}/${token}`;
    filtersRef.current.addUriQgis(uri)
  }

  const handleClickOk = (data) => {
    const send = {
      ...data,
      dates:
        data.initialDate !== null && data.endDate !== null
          ? `${data.initialDate},${data.endDate}`
          : '',
      plantation: currentPlantation.plantation,
    }
    getLayer(send, layerSelected)
    generateUriQgis(send, layerSelected)
  }
  return (
    <div>
      <Filters ref={filtersRef} layer={layerSelected} accept={handleClickOk} />
      <Map ref={mapRef} filtersLayerExternal={filtersLayer} layerExternalWithoutFilter={useMemo(() => [], [])} />
    </div>
  )
}
