import { useState, forwardRef, useImperativeHandle } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { severalStore } from "../../../../stores";
import { Select } from "../../../../components";

const initialData = {
  state: null,
  supervisor: null,
  company: null,
  removed: null,
};

const UpdateSeveral = forwardRef(({ update }, ref) => {
  const { t } = useTranslation();
  const { companies } = severalStore();
  const [open, setOpen] = useState(false);
  const [workers, setworkers] = useState([]);
  const [data, setData] = useState({ ...initialData });

  const reset = () => {
    setData({ ...initialData });
  };

  useImperativeHandle(ref, () => ({
    setOpen,
    setworkers,
    getWorkers: () => workers,
    reset,
  }));

  const onChange = (e) => {
    const { name, value } = e.target;
    setData((d) => ({ ...d, [name]: value }));
  };

  const handleClickSave = () => {
    update(
      data,
      workers.map((x) => x.id)
    );
  };

  const validateButtonSave = () => {
    if (
      data.state === null &&
      data.supervisor === null &&
      data.company === null
    ) {
      return true;
    }

    return false;
  };

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="sm"
      fullWidth
      aria-labelledby="confirmation-dialog-title"
      open={open}
    >
      <DialogTitle id="confirmation-dialog-title">
        {t("general.edit")}
      </DialogTitle>
      <DialogContent dividers>
        <Select
          name="state"
          text={t("general.state")}
          width={"100%"}
          items={[
            { id: null, name: t("general.select") },
            { id: 1, name: t("general.active") },
            { id: 0, name: t("general.inactive") },
          ]}
          defaultOption0={false}
          value={data.state}
          handleChange={onChange}
          required={false}
        />
        <Select
          name="supervisor"
          text={t("general.supervisor")}
          width={"100%"}
          items={[
            { id: null, name: t("general.select") },
            { id: 1, name: t("general.yes") },
            { id: 0, name: "No" },
          ]}
          defaultOption0={false}
          value={data.supervisor}
          handleChange={onChange}
          required={false}
        />
        <Select
          name="company"
          text={t("general.company")}
          width={"100%"}
          items={[{ id: null, name: t("general.select") }, ...companies]}
          defaultOption0={false}
          value={data.company}
          handleChange={onChange}
          required={false}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)} color="primary">
          {t("general.close")}
        </Button>
        <Button
          onClick={handleClickSave}
          color="primary"
          disabled={validateButtonSave()}
        >
          {t("general.save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
});

UpdateSeveral.displayName = "UpdateSeveral";

export default UpdateSeveral;
