import { useState, useEffect, useCallback } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import Swal from 'sweetalert2/src/sweetalert2.js'
import { useTranslation } from 'react-i18next'
import { severalStore, userStore } from '../../../stores'
import { URL_SERVER, paxios } from '../../../constants/http'
import { Button, DeleteConfirmation } from '../../../components'
import CreateEditExecution from './CreateEditExecution'

export default function ShowExecutions({
  open,
  setOpen,
  schedule,
  searchRef,
  search,
}) {
  const { t } = useTranslation()
  const { workers, quadrilles } = severalStore()
  const { user } = userStore()
  const [executions, setExecutions] = useState([])
  const [executionSelected, setExecutionSelected] = useState(null)
  const [openRegister, setOpenRegister] = useState(false)
  const [openDelete, setOpenDelete] = useState(false)

  const getExecutions = useCallback(async () => {
    try {
      const response = await paxios.get(
        `${URL_SERVER}/laborsExecution/getBySchedule/${schedule.id}`
      )
      if (response.data.status === 'ok') {
        setExecutions(response.data.data)
      }
    } catch (error) {}
  }, [schedule])

  useEffect(() => {
    getExecutions()
  }, [getExecutions])

  useEffect(() => {
    executionSelected &&
      setExecutionSelected({
        ...executions.find((x) => x.id === executionSelected.id),
      })
    // eslint-disable-next-line
  }, [executions])

  const backgroundExecutionSelected = (execution) => {
    let style = {}
    if (!executionSelected) return style

    if (execution.id === executionSelected.id) {
      style = {
        background: '#8484846e',
      }
    }

    return style
  }

  const handleOpenRegister = (execution) => {
    setExecutionSelected(execution)
    setOpenRegister(true)
  }

  const deleteConfirmationExcecution = (execution) => {
    setExecutionSelected(execution)
    setOpenDelete(true)
  }

  const handleClickDelete = (execution) => {
    deleteConfirmationExcecution(execution)
  }

  const deleteExecution = async (execution) => {
    try {
      const response = await paxios.delete(
        `${URL_SERVER}/laborsExecution/${execution.id}`
      )
      if (response.data.status === 'ok') {
        Swal.fire(t('general.finished'), t('general.savesuccess'), 'success')
        setExecutionSelected(null)
        search(searchRef.current.getData())
        setOpenDelete(false)
      } else {
        Swal.fire(t('general.finished'), t('general.datawasnotsaved'), 'error')
      }
    } catch (error) {
      Swal.fire(t('general.finished'), t('general.datawasnotsaved'), 'error')
    }
  }
  return (
    <>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xl"
        fullWidth
        aria-labelledby="confirmation-dialog-title"
        open={open}
      >
        <DialogTitle id="confirmation-dialog-title">
          {`${t('general.executions')}`}
        </DialogTitle>
        <DialogContent dividers>
          <Grid container justify="center" spacing={2}>
            <Grid item xs={12} md={6}>
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>{t('general.date')}</TableCell>
                      <TableCell>{t('general.supervisor')}</TableCell>
                      <TableCell>{t('general.cost')}</TableCell>
                      <TableCell>{t('general.quantity')}</TableCell>
                      <TableCell>{t('general.people')}</TableCell>
                      <TableCell>{t('general.time')}</TableCell>
                      <TableCell>{t('general.observations')}</TableCell>
                      <TableCell>{t('general.edit')}</TableCell>
                      <TableCell>{t('general.delete')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {executions.map((x) => (
                      <TableRow
                        key={x.id}
                        style={{
                          cursor: 'pointer',
                          ...backgroundExecutionSelected(x),
                        }}
                        onClick={() => setExecutionSelected(x)}
                      >
                        <TableCell align="left">{x.date}</TableCell>
                        <TableCell align="left">
                          {workers.find((y) => y.id === x.supervisor)?.name}
                        </TableCell>
                        <TableCell align="left">{x.cost}</TableCell>
                        <TableCell align="left">{x.quantity}</TableCell>
                        <TableCell align="left">{x.people}</TableCell>
                        <TableCell align="left">{x.time}</TableCell>
                        <TableCell align="left">{x.observations}</TableCell>
                        <TableCell align="left">
                          <Button
                            handleButtonClick={() => handleOpenRegister(x)}
                            icon={<EditIcon />}
                          />
                        </TableCell>
                        <TableCell align="left">
                          {user.profile === 1 && (
                            <Button
                              handleButtonClick={() => handleClickDelete(x)}
                              icon={<DeleteForeverIcon />}
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12} md={6}>
              {executionSelected && (
                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>{t('general.worker')}</TableCell>
                        <TableCell>{t('general.quadrille')}</TableCell>
                        <TableCell>{t('general.quantity')}</TableCell>
                        <TableCell>{t('general.start_line')}</TableCell>
                        <TableCell>{t('general.end_line')}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {executionSelected.execution_workers.map((x) => (
                        <TableRow key={x.id}>
                          <TableCell align="left">
                            {workers.find((y) => y.id === x.worker)?.name}
                          </TableCell>
                          <TableCell align="left">
                            {quadrilles.find((y) => y.id === x.quadrille)?.name}
                          </TableCell>
                          <TableCell align="left">{x.quantity}</TableCell>
                          <TableCell align="left">{x.start_line}</TableCell>
                          <TableCell align="left">{x.end_line}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            handleButtonClick={() => {
              setOpen(false)
            }}
            text={t('general.close')}
          />
        </DialogActions>
      </Dialog>
      {openRegister && (
        <CreateEditExecution
          schedule={schedule}
          open={openRegister}
          setOpen={setOpenRegister}
          searchRef={searchRef}
          search={search}
          executionEdit={executionSelected}
        />
      )}

      {openDelete && (
        <DeleteConfirmation
          open={openDelete}
          setOpen={setOpenDelete}
          item={executionSelected}
          handleOk={deleteExecution}
        />
      )}
    </>
  )
}
