import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { useTranslation } from 'react-i18next'

const DeleteConfirmation = ({ open, setOpen, handleOk, item }) => {
  const { t } = useTranslation()
  return (
    <Dialog open={open} onClose={setOpen}>
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        {`${t('general.delete')}`}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {`${t('general.question_delete_record')}`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={() => setOpen(false)} color="primary">
          {`${t('general.cancel')}`}
        </Button>
        <Button onClick={() => handleOk(item)} color="primary">
          {`${t('general.delete')}`}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteConfirmation
