import { useState } from "react";
import MaterialTable from "material-table";
import tableIcons from "../../../../constants/IconsMaterialTable";
import tableTranslations from "../../../../constants/tableTranslations";
import { useTranslation } from "react-i18next";
import { URL_SERVER, paxios } from "../../../../constants/http";
import { SubTitle } from "../../../../components";
import SearchReports from "./SearchReports";

export default function OxidesElements() {
  const { t, i18n } = useTranslation();
  const [records, setRecords] = useState([]);
  const [columnsOxides] = useState([
    { title: t("general.year"), field: "year", editable: "never" },
    { title: t("general.plot"), field: "plot_name", editable: "never" },
    { title: t("general.product"), field: "product", editable: "never" },
    { title: "N", field: "n" },
    { title: "P205", field: "oxide_p" },
    { title: "K2O", field: "oxide_k" },
    { title: "SO4", field: "oxide_s" },
    { title: "CaO", field: "oxide_ca" },
    { title: "MgO", field: "oxide_mg" },
    { title: "Fe2O5", field: "oxide_fe" },
    { title: "CuO", field: "oxide_cu" },
    { title: "MnO", field: "oxide_mn" },
    { title: "ZnO", field: "oxide_zn" },
    { title: "B2O3", field: "oxide_b" },
    { title: "MoO", field: "oxide_mo" },
  ]);

  const [columnsElements] = useState([
    { title: t("general.year"), field: "year", editable: "never" },
    { title: t("general.plot"), field: "plot_name", editable: "never" },
    { title: t("general.product"), field: "product", editable: "never" },
    { title: "N", field: "n" },
    { title: "P", field: "p" },
    { title: "K", field: "k" },
    { title: "S", field: "s" },
    { title: "Ca", field: "ca" },
    { title: "Mg", field: "mg" },
    { title: "Fe", field: "fe" },
    { title: "Cu", field: "cu" },
    { title: "Mn", field: "mn" },
    { title: "Zn", field: "zn" },
    { title: "B", field: "b" },
    { title: "Mo", field: "mo" },
  ]);

  const getData = (data) => {
    getReport(data);
  };

  const getReport = async (dataSend) => {
    try {
      const response = await paxios.post(
        `${URL_SERVER}/fertilizationPlan/executionOxidesElements`,
        dataSend
      );
      if (response.data.status === "ok") {
        setRecords(response.data.data);
      }
    } catch (error) {}
  };
  return (
    <>
      <SubTitle text={`${t("general.oxides")} / ${t("general.elements")}`} />
      <SearchReports getData={getData} discriminated={true} />
      <MaterialTable
        icons={tableIcons}
        columns={columnsOxides}
        title={t("general.oxides")}
        data={records}
        options={{
          exportButton: true,
          exportAllData: true,
          exportDelimiter: ";",
          selection: false,
        }}
        localization={i18n.language === "es" ? tableTranslations : {}}
      />

      <MaterialTable
        icons={tableIcons}
        columns={columnsElements}
        title={t("general.elements")}
        data={records}
        options={{
          exportButton: true,
          exportAllData: true,
          exportDelimiter: ";",
          selection: false,
        }}
        localization={i18n.language === "es" ? tableTranslations : {}}
      />
    </>
  );
}
