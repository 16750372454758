import { useState, useRef, useEffect, useCallback } from 'react'
import dayjs from 'dayjs'
import {
  Grid,
  Paper,
  Typography,
  makeStyles,
  IconButton,
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import Swal from 'sweetalert2/src/sweetalert2.js'
import MaterialTable from 'material-table'
import { useTranslation } from 'react-i18next'
import { userStore, cadastreStore, severalStore } from '../../stores'
import {
  URL_SERVER,
  paxios,
  getQuantityPalmsBetweenLines,
} from '../../constants/http'
import tableIcons from '../../constants/IconsMaterialTable'
import tableTranslations from '../../constants/tableTranslations'
import { Button, PlotsSelector, DatePickerRange } from '../../components'

const cellStyle = { align: 'center', headerStyle: { textAlign: 'center' } }

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  gridContainer: {
    marginBottom: 10,
  },
}))

export default function Manage() {
  const classes = useStyles()
  const { currentPlantation } = userStore()
  const { plots } = cadastreStore()
  const { workers } = severalStore()
  const { t, i18n } = useTranslation()
  const plotsSelectorRef = useRef(null)
  const [queryData, setQueryData] = useState({
    plot: [],
    initialDate: null,
    endDate: null,
  })
  const [columns, setColumns] = useState([])
  const [requestInProgress, setRequestInProgress] = useState(false)
  const [records, setRecords] = useState([])

  const handleChangeEndLine = async (props, data) => {
    const palms = await getQuantityPalmsBetweenLines(
      data.plantation,
      data.plot,
      data.line,
      data.end_line
    )
    data.number_of_palms = palms
    props.onRowDataChange(data)
  }

  const editComponentLine = useCallback(
    (props) => {
      const data = { ...props.rowData }
      return (
        <div className="MuiFormControl-root MuiTextField-root MuiFormControl-fullWidth">
          <div className="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-fullWidth MuiInput-fullWidth MuiInputBase-formControl MuiInput-formControl">
            <input
              type="number"
              className="MuiInputBase-input MuiInput-input"
              min="0"
              value={props.value || 0}
              onChange={(e) => {
                data.line = parseInt(e.target.value) || 0
                props.onRowDataChange(data)
                if (data.end_line > 0)
                  handleChangeEndLine(props, {
                    ...data,
                    plantation: currentPlantation.plantation,
                  })
              }}
            />
          </div>
          {data.line === 0 && (
            <p className="MuiFormHelperText-root Mui-error MuiFormHelperText-filled">
              {t('general.noempty')}
            </p>
          )}
        </div>
      )
    },
    [t, currentPlantation.plantation]
  )

  const editComponentEndLine = useCallback((props) => {
    const data = { ...props.rowData }
    return (
      <div className="MuiFormControl-root MuiTextField-root MuiFormControl-fullWidth">
        <div className="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-fullWidth MuiInput-fullWidth MuiInputBase-formControl MuiInput-formControl">
          <input
            type="number"
            className="MuiInputBase-input MuiInput-input"
            min="0"
            value={props.value || 0}
            onChange={(e) => {
              data.end_line = parseInt(e.target.value) || 0
              props.onRowDataChange(data)
              handleChangeEndLine(props, {
                ...data,
                plantation: currentPlantation.plantation,
              })
            }}
          />
        </div>
        {/* {(data.end_line === '' || isNaN(data.end_line)) && (
            <p className="MuiFormHelperText-root Mui-error MuiFormHelperText-filled">
              {t('general.noempty')}
            </p>
          )} */}
      </div>
    )
  }, [currentPlantation.plantation])

  const editComponentNumberOfPalms = useCallback(
    (props) => {
      const data = { ...props.rowData }
      return (
        <div className="MuiFormControl-root MuiTextField-root MuiFormControl-fullWidth">
          <div className="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-fullWidth MuiInput-fullWidth MuiInputBase-formControl MuiInput-formControl">
            <input
              type="number"
              className="MuiInputBase-input MuiInput-input"
              min="0"
              value={props.value || 0}
              disabled={data.end_line !== 0 ? 'disebled' : ''}
              onChange={(e) => {
                data.number_of_palms = parseInt(e.target.value) || 0
                props.onRowDataChange(data)
              }}
            />
          </div>
          {data.number_of_palms === 0 && (
            <p className="MuiFormHelperText-root Mui-error MuiFormHelperText-filled">
              {t('general.nozero')}
            </p>
          )}
        </div>
      )
    },
    [t]
  )

  useEffect(() => {
    const validateDate = (rowData, field) => {
      return rowData[field] === '' ||
        rowData[field] === undefined ||
        rowData[field] === null
        ? t('general.date_invalid')
        : true
    }

    const validateNoZero = (rowData, field) => {
      return rowData[field] <= 0 || rowData[field] === '0'
        ? t('general.nozero')
        : true
    }

    const validateEmpty = (rowData, field) => {
      return rowData[field] === '' ||
        rowData[field] === undefined ||
        rowData[field] === null ||
        isNaN(rowData[field])
        ? t('general.noempty')
        : true
    }

    const validateNoZeroEmpty = (rowData, field) => {
      if (
        validateNoZero(rowData, field) === true &&
        validateEmpty(rowData, field) === true
      ) {
        return true
      } else {
        return `${
          validateNoZero(rowData, field) === true
            ? ''
            : validateNoZero(rowData, field)
        } ${
          validateEmpty(rowData, field) === true
            ? ''
            : ', ' + validateEmpty(rowData, field)
        }`
      }
    }

    setColumns([
      { title: 'ID', field: 'id', editable: 'never' },
      {
        title: t('general.date'),
        field: 'date',
        type: 'date',
        validate: (rowData) => validateDate(rowData, 'date'),
      },
      {
        title: t('general.plot'),
        field: 'plot',
        lookup: {
          0: t('general.notassigned'),
          ...plots.reduce((acc, item) => {
            return { ...acc, [item.id]: item.name }
          }, {}),
        },
        initialEditValue: 0,
        ...cellStyle,
        validate: (rowData) => validateNoZero(rowData, 'plot'),
      },
      {
        title: t('general.line'),
        field: 'line',
        type: 'numeric',
        validate: (rowData) => validateNoZeroEmpty(rowData, 'line'),
        editComponent: editComponentLine,
      },
      {
        title: t('general.end_line'),
        field: 'end_line',
        type: 'numeric',
        validate: (rowData) => validateEmpty(rowData, 'end_line'),
        initialEditValue: 0,
        editComponent: editComponentEndLine,
      },
      {
        title: t('general.number_of_palms'),
        field: 'number_of_palms',
        type: 'numeric',
        validate: (rowData) => validateNoZeroEmpty(rowData, 'number_of_palms'),
        editComponent: editComponentNumberOfPalms,
      },
      {
        title: t('general.first'),
        field: 'first',
        type: 'numeric',
        initialEditValue: 0,
        validate: (rowData) => validateEmpty(rowData, 'first'),
      },
      {
        title: t('general.state'),
        field: 'state',
        lookup: {
          603: '603',
          605: '605',
          607: '607',
        },
        initialEditValue: 603,
        ...cellStyle,
        validate: (rowData) => validateNoZero(rowData, 'state'),
      },
      {
        title: t('general.second'),
        field: 'second',
        type: 'numeric',
        initialEditValue: 0,
        validate: (rowData) => validateEmpty(rowData, 'second'),
      },
      {
        title: t('general.third'),
        field: 'third',
        type: 'numeric',
        initialEditValue: 0,
        validate: (rowData) => validateEmpty(rowData, 'third'),
      },
      {
        title: t('general.worker'),
        field: 'worker',
        lookup: {
          0: t('general.notassigned'),
          ...workers.reduce((acc, item) => {
            return { ...acc, [item.id]: item.name }
          }, {}),
        },
        initialEditValue: 0,
        ...cellStyle,
        validate: (rowData) => validateNoZero(rowData, 'worker'),
      },
      { title: t('general.observations'), field: 'observations' },
    ])
  }, [
    plots,
    workers,
    t,
    editComponentEndLine,
    editComponentNumberOfPalms,
    editComponentLine,
  ])

  const handleSelectPlot = (plot) => {
    setQueryData((d) => ({ ...d, plot: plot.length > 0 ? plot : 0 }))
    plotsSelectorRef.current !== null && plotsSelectorRef.current.setOpen(false)
  }

  const handleDateRangeChange = (e) => {
    const { name, value } = e.target
    setQueryData((d) => ({ ...d, [name]: value }))
  }

  const handleClickSearch = () => {
    dataSearch()
  }

  const dataSearch = () => {
    const send = {
      ...queryData,
      plot: queryData.plot.length > 0 ? queryData.plot.map((x) => x.id) : [],
      dates:
        queryData.initialDate !== null && queryData.endDate !== null
          ? `${dayjs(queryData.initialDate).format('YYYY-MM-DD')},${dayjs(
              queryData.endDate
            ).format('YYYY-MM-DD')}`
          : '',
      plantation: currentPlantation.plantation,
    }

    getPollinationRecords(send)
  }

  const getPollinationRecords = async (data) => {
    setRequestInProgress(true)
    try {
      const response = await paxios.post(
        `${URL_SERVER}/pollinationAna/getPollinationRecordsByLine`,
        data
      )
      if (response.data.status === 'ok') {
        setRecords(response.data.data)
      }
    } catch (error) {
      Swal.fire(t('general.finished'), t('general.errortoquery'), 'error')
    }
    setRequestInProgress(false)
  }

  const onRowAdd = (newData) =>
    new Promise(async (resolve) => {
      createCensus(newData)
      resolve()
    })

  const createCensus = async (data) => {
    try {
      const send = {
        ...data,
        date: data.date !== null ? dayjs(data.date).format('YYYY-MM-DD') : null,
        observations: data.observations ? data.observations : ""
      }
      const response = await paxios.post(
        `${URL_SERVER}/pollinationAna/registerPollinationByLine`,
        { ...send, plantation: currentPlantation.plantation }
      )
      if (response.data.status === 'ok') {
        Swal.fire(t('general.finished'), t('general.savesuccess'), 'success')
        setRecords((d) => [...d, { ...response.data.data[0] }])
      } else {
        Swal.fire(t('general.finished'), response.data.message, 'error')
      }
    } catch (error) {
      Swal.fire(t('general.finished'), t('general.datawasnotsaved'), 'error')
    }
  }

  const onRowUpdate = (newData, oldData) =>
    new Promise(async (resolve) => {
      updateCensus(newData)
      resolve()
    })

  const updateCensus = async (data) => {
    try {
      const send = {
        ...data,
        date: data.date !== null ? dayjs(data.date).format('YYYY-MM-DD') : null,
      }
      const response = await paxios.put(
        `${URL_SERVER}/pollinationAna/updatePollinationByLine/${data.id}`,
        send
      )
      if (response.data.status === 'ok') {
        Swal.fire(t('general.finished'), t('general.savesuccess'), 'success')
        setRecords((d) => {
          return d.map((x) => {
            if (String(x.id) === String(data.id)) return { ...send }
            return { ...x }
          })
        })
      } else {
        Swal.fire(t('general.finished'), response.data.message, 'error')
      }
    } catch (error) {
      Swal.fire(t('general.finished'), t('general.datawasnotsaved'), 'error')
    }
  }
  return (
    <>
      <Grid
        container
        justify="center"
        spacing={2}
        className={classes.gridContainer}
      >
        <Grid item xs={12} md={3}></Grid>
        <Grid item xs={12} md={3}>
          <Paper component="form" className={classes.root}>
            <Typography variant="subtitle1">
              {queryData.plot !== 0
                ? queryData.plot.reduce((acc, item) => {
                    return (acc += acc !== '' ? ',' + item.name : item.name)
                  }, '')
                : `${t('general.select')} ${t('general.plot')}`}
            </Typography>
            <IconButton
              type="button"
              aria-label="search"
              className={classes.iconButton}
              onClick={() =>
                plotsSelectorRef.current !== null &&
                plotsSelectorRef.current.setOpen(true)
              }
            >
              <SearchIcon />
            </IconButton>
          </Paper>
          <PlotsSelector accept={handleSelectPlot} ref={plotsSelectorRef} />
        </Grid>
        <Grid item xs={12} md={3}>
          <DatePickerRange
            initialDate={queryData.initialDate}
            endDate={queryData.endDate}
            handleDateChange={handleDateRangeChange}
          />
        </Grid>
        <Grid item xs={12} md={3}></Grid>
        <Button
          icon={<SearchIcon />}
          handleButtonClick={handleClickSearch}
          text={t('general.search')}
          disabled={
            queryData.initialDate === null || queryData.endDate === null
          }
          loading={requestInProgress}
        />
      </Grid>

      <MaterialTable
        icons={tableIcons}
        columns={columns}
        title={t('general.pollination')}
        data={records.map((x) => ({
          ...x,
          date: x.date !== null ? x.date + ' 00:00:00' : null,
        }))}
        options={{
          exportButton: true,
          exportAllData: true,
          exportDelimiter: ';',
          selection: false,
        }}
        localization={i18n.language === 'es' ? tableTranslations : {}}
        editable={{
          onRowUpdate: onRowUpdate,
          onRowAdd: onRowAdd,
        }}
      />
    </>
  )
}
