import { useState } from 'react'
import { ButtonGroup, Button, MenuItem, Menu } from '@material-ui/core'
import AssignmentIcon from '@material-ui/icons/Assignment'
import AssessmentIcon from '@material-ui/icons/Assessment'
import AttachmentIcon from '@material-ui/icons/Attachment'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { useTranslation } from 'react-i18next'
import { TitlePage } from '../../components'
import Travels from './travels/Home'
import LoadExcel from './loadExcel/Home'
import ReportProductionYear from './reports/ProductionYear'
import CyclesYield from './reports/CyclesYield'

export default function Yield() {
  const { t } = useTranslation()
  const [selection, setSelection] = useState(1)
  const [openMenuReports, setOpenMenuReports] = useState(null)

  const handleClickMenuReports = (event) => {
    setOpenMenuReports(event.currentTarget)
  }

  const handleCloseMenuReports = (opc) => {
    setOpenMenuReports(null)
    typeof opc === 'number' && setSelection(opc)
  }
  return (
    <>
      <TitlePage text={`${t('general.yield')}`} />

      <ButtonGroup
        variant="text"
        color="primary"
        aria-label="text primary button group"
        style={{
          width: '100%',
          justifyContent: 'center',
          marginBottom: '20px',
        }}
      >
        <Button
          startIcon={<AssignmentIcon />}
          variant={selection === 1 ? 'outlined' : ''}
          onClick={() => setSelection(1)}
        >
          {t('general.travels')}
        </Button>
        <Button
          startIcon={<AttachmentIcon />}
          variant={selection === 2 ? 'outlined' : ''}
          onClick={() => setSelection(2)}
        >
          {`${t('general.loadexcel')}`}
        </Button>
        <Button
          startIcon={<AssessmentIcon />}
          variant={[3, 4, 5].includes(selection) ? 'outlined' : ''}
          onClick={handleClickMenuReports}
        >
          {`${t('general.reports')}`}
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>

      <Menu
        id="simple-menu"
        anchorEl={openMenuReports}
        keepMounted
        open={Boolean(openMenuReports)}
        onClose={handleCloseMenuReports}
      >
        <MenuItem
          selected={selection === 4}
          onClick={() => handleCloseMenuReports(4)}
        >
          {`${t('general.cyclesyield')}`}
        </MenuItem>
        <MenuItem
          selected={selection === 5}
          onClick={() => handleCloseMenuReports(5)}
        >
          {`${t('general.productionyear')}`}
        </MenuItem>
      </Menu>

      {selection === 1 && <Travels />}
      {selection === 2 && <LoadExcel />}
      {selection === 4 && <CyclesYield />}   
      {selection === 5 && <ReportProductionYear />}
    </>
  )
}
