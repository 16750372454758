import { useState, useEffect } from 'react'
import { ButtonGroup, Button, MenuItem, Menu } from '@material-ui/core'
import AssignmentIcon from '@material-ui/icons/Assignment'
import AssessmentIcon from '@material-ui/icons/Assessment'
import AttachmentIcon from '@material-ui/icons/Attachment'
import { useTranslation } from 'react-i18next'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { userStore } from '../../stores'
import { ROUTES } from '../../constants/routes'
import { TitlePage } from '../../components'
import Readings from './Readings'
import PestsPlot from './reports/PestsPlot'
import PestsPlotPest from './reports/PestsPlotPest'
import LoadExcel from './ByExcel'

export default function Pests() {
  const { t } = useTranslation()
  const { setUser } = userStore()
  const [selection, setSelection] = useState(1)
  const [openMenuReports, setOpenMenuReports] = useState(null)

  useEffect(() => {
    setUser({ currentUrl: ROUTES.diseases })
  }, [setUser])

  const handleClickMenuReports = (event) => {
    setOpenMenuReports(event.currentTarget)
  }

  const handleCloseMenuReports = (opc) => {
    setOpenMenuReports(null)
    typeof opc === 'number' && setSelection(opc)
  }

  return (
    <>
        <TitlePage text={`${t('general.pests')}`} />
        <ButtonGroup
        variant="text"
        color="primary"
        aria-label="text primary button group"
        style={{
          width: '100%',
          justifyContent: 'center',
          marginBottom: '20px',
        }}
      >
        <Button
          startIcon={<AssignmentIcon />}
          variant={selection === 1 ? 'outlined' : ''}
          onClick={() => setSelection(1)}
        >
          {t('general.readings')}
        </Button>
        <Button
          startIcon={<AttachmentIcon />}
          variant={selection === 4 ? 'outlined' : ''}
          onClick={() => setSelection(4)}
        >
          {t('general.loadexcel')}
        </Button>
        <Button
          startIcon={<AssessmentIcon />}
          variant={[2, 3].includes(selection) ? 'outlined' : ''}
          onClick={handleClickMenuReports}
        >
          {`${t('general.reports')}`}
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>

      <Menu
        id="simple-menu"
        anchorEl={openMenuReports}
        keepMounted
        open={Boolean(openMenuReports)}
        onClose={handleCloseMenuReports}
      >
        <MenuItem
          selected={selection === 2}
          onClick={() => handleCloseMenuReports(2)}
        >
          {`${t('general.pests')} (${t('general.plot')})`}
        </MenuItem>
        <MenuItem
          selected={selection === 3}
          onClick={() => handleCloseMenuReports(3)}
        >
          {`${t('general.pests')} (${t('general.plot')}/${t('general.pest')})`}
        </MenuItem>
      </Menu>
      {selection === 1 && <Readings />}
      {selection === 2 && <PestsPlot />}
      {selection === 3 && <PestsPlotPest />}
      {selection === 4 && <LoadExcel />}
    </>
  )
}
