import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import MaterialTable from "material-table";
import tableIcons from "../../constants/IconsMaterialTable";
import tableTranslations from "../../constants/tableTranslations";
import { useTranslation } from "react-i18next";
import { laborsStore, machineryStore, severalStore } from "../../stores";

const ResultSearch = forwardRef(({ resultLabors, getLaborsSelected }, ref) => {
  const { t, i18n } = useTranslation();
  const { labors } = laborsStore();
  const [laborsShow, setLaborsShow] = useState([]);
  const { machines, equipment, unitsMachinery } = machineryStore();
  const { workers } = severalStore();
  const [open, setOpen] = useState(false);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    labors.length > 0 && setLaborsShow(labors.filter((x) => x.machinery === 1));
  }, [labors]);

  useEffect(() => {
    setColumns([
      { title: "ID", field: "id", editable: "never" },
      {
        title: t("general.machine"),
        field: "machine_id",
        lookup: {
          ...machines.reduce((acc, item) => {
            return { ...acc, [item.id]: item.description };
          }, {}),
        },
      },
      {
        title: t("general.equipment"),
        field: "equipment_id",
        lookup: {
          ...equipment.reduce((acc, item) => {
            return { ...acc, [item.id]: item.description };
          }, {}),
        },
      },
      {
        title: t("general.labor"),
        field: "labor_id",
        lookup: {
          ...laborsShow.reduce((acc, item) => {
            return { ...acc, [item.id]: item.name };
          }, {}),
        },
      },
      {
        title: t("general.worker"),
        field: "worker",
        lookup: {
          ...workers.reduce((acc, item) => {
            return { ...acc, [item.id]: item.name };
          }, {}),
        },
      },
      {
        title: t("general.initialdate"),
        field: "start_date",
        editable: "never",
      },
      {
        title: t("general.start_hour"),
        field: "start_hour",
        editable: "never",
      },
      { title: t("general.enddate"), field: "end_date", editable: "never" },
      { title: t("general.end_hour"), field: "end_hour", editable: "never" },
      { title: t("general.time"), field: "time", editable: "never" },
      {
        title: `${t("general.distance")} GPS`,
        field: "length_gps",
        editable: "never",
      },
      {
        title: t("general.start_hour_meter"),
        field: "start_hour_meter",
        editable: "never",
      },
      {
        title: t("general.end_hour_meter"),
        field: "end_hour_meter",
        editable: "never",
      },
      {
        title: `${t("general.time")} ${t("general.hour_meter")}`,
        field: "hour_meter",
        editable: "never",
      },
      {
        title: t("general.start_odometer"),
        field: "start_odometer",
        editable: "never",
      },
      {
        title: t("general.end_odometer"),
        field: "end_odometer",
        editable: "never",
      },
      {
        title: `${t("general.distance")} ${t("general.odometer")}`,
        field: "odometer",
        editable: "never",
      },
      { title: t("general.gas"), field: "gas", editable: "never" },
      {
        title: t("general.unit"),
        field: "unit",
        lookup: {
          ...unitsMachinery.reduce((acc, item) => {
            return { ...acc, [item.id]: item.name };
          }, {}),
        },
      },
      { title: t("general.quantity"), field: "quantity", editable: "never" },
      {
        title: t("general.observations"),
        field: "observations",
        editable: "never",
      },
    ]);
  }, [t, machines, equipment, laborsShow, workers, unitsMachinery]);

  useImperativeHandle(ref, () => ({
    setOpen,
  }));

  const handleCLickShow = (event, item) => {
    getLaborsSelected([item]);
  };

  const handleClickShowAll = () => {
    getLaborsSelected(resultLabors);
  }
  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="xl"
      fullWidth
      aria-labelledby="confirmation-dialog-title"
      open={open}
    >
      <DialogContent dividers>
        <MaterialTable
          icons={tableIcons}
          columns={columns}
          title={
            <Button onClick={handleClickShowAll} color="primary">
              {`${t("general.show")} ${t("general.all")}`}
            </Button>
          }
          data={resultLabors}
          options={{
            exportButton: true,
            exportAllData: true,
            exportDelimiter: ";",
            selection: false,
          }}
          localization={i18n.language === "es" ? tableTranslations : {}}
          actions={[
            {
              icon: () => <Visibility />,
              tooltip: t("general.show"),
              onClick: handleCLickShow,
            },
          ]}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)} color="primary">
          {t("general.close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
});

ResultSearch.displayName = "ResultSearch";

export default ResultSearch;
