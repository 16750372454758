import { useState } from 'react'
import { ButtonGroup, Button, MenuItem, Menu } from '@material-ui/core'
import AssessmentIcon from '@material-ui/icons/Assessment'
import AttachmentIcon from '@material-ui/icons/Attachment'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { useTranslation } from 'react-i18next'
import { TitlePage } from '../../components'
import CalendarLabors from './calendar/CalendarLabors'
import ReportBudget from './reports/Budget'
import ReportExecution from './reports/Execution'
import ByExcelSchedule from './ByExcelSchedule'
import ByExcelExecutions from './ByExcelExecutions'

export default function Schedule() {
  const { t } = useTranslation()
  const [selection, setSelection] = useState(1)
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (opc) => {
    setAnchorEl(null)
    typeof opc === 'number' && setSelection(opc)
  }
  return (
    <>
      <TitlePage text={`${t('general.schedule_labors')}`} />

      <ButtonGroup
        variant="text"
        color="primary"
        aria-label="text primary button group"
        style={{
          width: '100%',
          justifyContent: 'center',
          marginBottom: '20px',
        }}
      >
        <Button
          style={{ marginRight: 5 }}
          variant={selection === 1 ? 'outlined' : ''}
          onClick={() => setSelection(1)}
        >
          {`${t('general.calendar')}`}
        </Button>

        <Button
          startIcon={<AttachmentIcon />}
          variant={selection === 4 ? 'outlined' : ''}
          onClick={() => setSelection(4)}
        >
          {`${t('general.loadexcel')} ${t('general.schedule')}`}
        </Button>

        <Button
          startIcon={<AttachmentIcon />}
          variant={selection === 5 ? 'outlined' : ''}
          onClick={() => setSelection(5)}
        >
          {`${t('general.loadexcel')} ${t('general.executions')}`}
        </Button>

        <Button
          startIcon={<AssessmentIcon />}
          variant={selection === 2 || selection === 3 ? 'outlined' : ''}
          onClick={handleClick}
        >
          {`${t('general.reports')}`}
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem selected={selection === 2} onClick={() => handleClose(2)}>
          {`${t('general.budget')}`}
        </MenuItem>
        <MenuItem selected={selection === 3} onClick={() => handleClose(3)}>
          {`${t('general.execution')}`}
        </MenuItem>
      </Menu>

      {selection === 1 && <CalendarLabors />}
      {selection === 2 && <ReportBudget />}
      {selection === 3 && <ReportExecution />}
      {selection === 4 && <ByExcelSchedule />}
      {selection === 5 && <ByExcelExecutions />}
    </>
  )
}
