import { useState, useEffect } from 'react'
import { ButtonGroup, Button } from '@material-ui/core'
import { userStore } from '../../stores'
import { useTranslation } from 'react-i18next'
import { ROUTES } from '../../constants/routes'
import { TitlePage } from '../../components'
import ByPalm from './ByPalm'
import ByExcel from './ByExcel'
import ByCensusSanitary from './ByCensusSanitary'

export default function Eradications() {
  const { setUser } = userStore()
  const { t } = useTranslation()
  const [selection, setSelection] = useState(1)

  useEffect(() => {
    setUser({ currentUrl: ROUTES.eradications })
  }, [setUser])

  return (
    <>
      <TitlePage text={`${t('general.eradications')}`} />
      <ButtonGroup
        variant="text"
        color="primary"
        aria-label="text primary button group"
        style={{
          width: '100%',
          justifyContent: 'center',
          marginBottom: '20px',
        }}
      >
        <Button
          style={{ marginRight: 5 }}
          variant={selection === 1 ? 'outlined' : ''}
          onClick={() => setSelection(1)}
        >
          {`${t('general.by')} ${t('general.palm')}`}
        </Button>
        <Button
          style={{ marginLeft: 5 }}
          variant={selection === 2 ? 'outlined' : ''}
          onClick={() => setSelection(2)}
        >
          {`${t('general.by')} Excel`}
        </Button>
        <Button
          style={{ marginLeft: 5 }}
          variant={selection === 3 ? 'outlined' : ''}
          onClick={() => setSelection(3)}
        >
          {`${t('general.by')} ${t('general.censussanitary')}`}
        </Button>
      </ButtonGroup>

      {selection === 1 && <ByPalm />}
      {selection === 2 && <ByExcel />}
      {selection === 3 && <ByCensusSanitary />}
    </>
  )
}
