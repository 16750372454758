import { useState, useEffect } from 'react'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { ButtonGroup, Button, MenuItem, Menu } from '@material-ui/core'
import { v4 as uuidv4 } from 'uuid'

const MenuDropdown = ({ parentSelected, selectParent, title, Icon, items }) => {
  const [ids, setIds] = useState([])
  const [idMenu, setIdMenu] = useState('')
  const [selection, setSelection] = useState(0)
  const [anchorEl, setAnchorEl] = useState(null)

  useEffect(() => {
    setIdMenu(uuidv4())
    setIds(items.map((x) => x.id))
  }, [items])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (opc) => {
    setAnchorEl(null)
    if (typeof opc === 'number') {
      setSelection(opc)
      selectParent(opc)
    }
  }
  return (
    <>
      <ButtonGroup
        variant="text"
        color="primary"
        aria-label="text primary button group"
        style={{
          justifyContent: 'center',
        }}
      >
        <Button
          startIcon={<Icon />}
          variant={ids.includes(parentSelected) ? 'outlined' : ''}
          onClick={handleClick}
          aria-controls={idMenu}
          aria-haspopup="true"
          color="primary"
        >
          {title}
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>

      <Menu
        id={idMenu}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {items.map((x) => (
          <MenuItem
            key={x.id}
            selected={selection === x.id}
            onClick={() => handleClose(x.id)}
          >
            {x.text}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default MenuDropdown
