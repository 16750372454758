import {
  FormControl,
  FormHelperText,
  TextField
} from '@material-ui/core'
import { capitalize } from '../constants/utilities'

export default function FormInput({
  style = {},
  name,
  title,
  value,
  onChange,
  error = false,
  errorMessage = 'Error',
  type = 'text',
  multiline = false,
}) {
  return (
    <FormControl style={{ width: '100%', ...style }} error={error}>
      <TextField
        id={`component-${name}`}
        label={capitalize(title)}
        name={name}
        value={value || ''}
        onChange={onChange}
        aria-describedby={`component-${name}-text`}
        type={type}
        multiline={multiline}
      />
      {error && (
        <FormHelperText id={`component-${name}-text`}>
          {errorMessage}
        </FormHelperText>
      )}
    </FormControl>
  )
}
