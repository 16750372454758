import React from "react";
import ReactDOM from "react-dom";
import { Grid, Accordion, AccordionSummary, AccordionDetails, Typography } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";
import { URL_SERVER, paxios } from "../../constants/http";
import Button from "../Button";
import ButtonControl from "./ButtonControl";

export const nodeToString = (node) => {
  let tmpNode = document.createElement("div");
  tmpNode.appendChild(node.cloneNode(true));
  const str = tmpNode.innerHTML;
  tmpNode = node = null; // prevent memory leaks in IE
  return str.replace("<", "&lt;").replace(">", "&gt;");
};

export const createPopup = (mapboxgl, event, element, getHtml) => {
  return new mapboxgl.Popup()
    .setLngLat(event.lngLat)
    .setDOMContent(getHtml(element));
};

export const createSimpleTable = (data) => {
  const JSX = ({ data }) => {
    const { t } = useTranslation();
    return (
      <Grid container spacing={1}>
        {data.map((x, index) => (
          <React.Fragment key={index}>
            <Grid item xs={6}>
              {t(`general.${x.name}`)}
            </Grid>
            <Grid item xs={6}>
              {x.value}
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
    );
  };
  const tooltipNode = document.createElement("div");
  ReactDOM.render(<JSX data={data} />, tooltipNode);
  return tooltipNode;
};

export const featurePlantation = (e) => {
  const data = [
    { name: "name", value: e.properties.name },
    { name: "area", value: e.properties.area },
    { name: "perimeter", value: e.properties.perimeter },
  ];
  return createSimpleTable(data);
};

export const featurePlot = (e) => {
  const data = [
    { name: "name", value: e.properties.name },
    { name: "area", value: e.properties.area },
    { name: "area_net", value: e.properties.area_net },
    { name: "sowing", value: e.properties.sowing },
    { name: "material", value: e.properties.material },
  ];
  return createSimpleTable(data);
};

export const createInputUriQgis = (uri, title="") => {
  const JSX = ({ uri, title }) => {
    return (
      <>
        <p>URI Qgis {title}</p>
        <input value={uri} disabled="disabled" style={{width: '100%'}} />
      </>
    );
  };
  const tooltipNode = document.createElement("div");
  ReactDOM.render(<JSX uri={uri} title={title} />, tooltipNode);
  return tooltipNode;
}

const getLayerPalms = async (plot, map) => {
  try {
    const response = await paxios.get(`${URL_SERVER}/palms/getMap/${plot.id}`);
    map.getSource("palms_" + plot.id).setData(response.data);
  } catch (error) {
    return null;
  }
};

const removeLayerPlams = (plot, map) => {
  map
    .getSource("palms_" + plot.id)
    .setData({ type: "FeatureCollection", features: [] });
};

const handleClickFeaturePlotAction = (properties, map) => {
  const palmsIsVisible =
    map.getSource("palms_" + properties.id)._data.features.length > 0
      ? true
      : false;

  if (palmsIsVisible) {
    removeLayerPlams(properties, map);
  } else {
    getLayerPalms(properties, map);
  }
};

export const featurePlotAction = (e, properties, map, sidebarContent) => {
  const palmsIsVisible =
    map.getSource("palms_" + properties.id)._data.features.length > 0
      ? true
      : false;

  //const content = e.target._content
  //const root = content.getElementsByClassName('MuiGrid-root MuiGrid-container')
  const contentButton = document.createElement("div");
  ReactDOM.render(
    <Button
      handleButtonClick={() => handleClickFeaturePlotAction(properties, map)}
      text={`${
        palmsIsVisible
          ? `${i18n.t("general.hide")} ${i18n.t("general.palms")}`
          : `${i18n.t("general.show")} ${i18n.t("general.palms")}`
      }`}
    />,
    contentButton
  );
  //root[0].appendChild(contentButton)
  sidebarContent.appendChild(contentButton);
};

export const featurePalms = (e) => {
  const data = [
    { name: "line", value: e.properties.line },
    { name: "palm", value: e.properties.palm },
    { name: "sowing", value: e.properties.sowing },
    { name: "material", value: e.properties.material },
    { name: "state", value: e.properties.state_text },
    { name: "date_eradication", value: e.properties.date_eradication },
    { name: "reason_eradication", value: e.properties.reason_eradication },
  ];
  return createSimpleTable(data);
};

export const toggleLayer = (idLayer, map) => {
  const visibility = map.getLayoutProperty(idLayer, "visibility");
  if (visibility === "visible") {
    map.setLayoutProperty(idLayer, "visibility", "none");
  } else {
    map.setLayoutProperty(idLayer, "visibility", "visible");
  }
};

const renderAccordionLayerCustomControl = (container,toggleableLayers, isBase, map) => {
  ReactDOM.render(
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>{`${i18n.t(`general.filter`)}s`}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div style={{display: 'flex', flexDirection: 'column'}}>
          {
            toggleableLayers.map(x => {
              const id = x.id;
              const text = x.text;
              if (!isBase) {
                container.id = "menulayers_" + id;
              }
              return <ButtonControl
                key={id}
                text={text}
                onClick={() => x.onClick(id, map)}
              />
            })
          }
        </div>
      </AccordionDetails>
    </Accordion>,
    container
  );
}

export class LayerCustomControl {
  constructor(toggleableLayerIds, top, isBase) {
    this.toggleableLayerIds = toggleableLayerIds;
    this.top = top;
    this.isBase = isBase;
  }
  onAdd(map) {
    this._map = map;
    this._container = document.createElement("div");
    renderAccordionLayerCustomControl(this._container, this.toggleableLayerIds, this.isBase, map)
    this._container.className = "mapboxgl-ctrl menulayers";
    if (this.isBase) {
      this._container.id = "menulayers_base";
    }

    this._container.style.top = this.top + "px";

    /* for (let i = 0; i < this.toggleableLayerIds.length; i++) {
      const id = this.toggleableLayerIds[i].id;
      const text = this.toggleableLayerIds[i].text;
      if (!this.isBase) {
        this._container.id = "menulayers_" + id;
      }
      const containerButton = document.createElement("div");
      ReactDOM.render(
        <ButtonControl
          text={text}
          onClick={() => this.toggleableLayerIds[i].onClick(id, map)}
        />,
        containerButton
      );
      this._container.appendChild(containerButton);
    } */
    return this._container;
  }

  onRemove() {
    this._container.parentNode.removeChild(this._container);
    this._map = undefined;
  }
}

export const addSourceLayer = (map, layerId) => {
  if (!map.getSource(layerId)) {
    map.addSource(layerId, {
      type: "geojson",
      data: { type: "FeatureCollection", features: [] },
      cluster: false,
    });
  }
};

export const setDataSourceLayer = (map, layerId, data) => {
  if (map.getSource(layerId)) map.getSource(layerId).setData(data);
};

export const addCircleLayer = (map, layerId, dinamicColor = null) => {
  if (!map.getLayer(`${layerId}_layer`)) {
    map.addLayer({
      id: `${layerId}_layer`,
      type: "circle",
      source: `${layerId}`,
      paint: {
        "circle-radius": 6,
        "circle-color": dinamicColor ? dinamicColor : "#006600",
      },
    });
  }
};

export const addFillLayer = (map, layerId, dinamicColor = null) => {
  if (!map.getLayer(`${layerId}_layer`)) {
    map.addLayer({
      id: `${layerId}_layer`,
      type: "fill",
      source: `${layerId}`,
      paint: {
        "fill-color": dinamicColor ? dinamicColor : "#5FB404",
        "fill-outline-color": "#5FB404",
        "fill-opacity": 0.7,
      },
    });
  }
};

export const addLineLayer = (map, layerId, dinamicColor = null) => {
  if (!map.getLayer(`${layerId}_layer`)) {
    map.addLayer({
      id: `${layerId}_layer`,
      type: "line",
      source: `${layerId}`,
      paint: {
        "line-color": dinamicColor ? dinamicColor : "#006600",
        "line-width": 4,
      },
    });
    map.on("mouseenter", `${layerId}_layer`, function () {
      map.getCanvas().style.cursor = "pointer";
    });

    map.on("mouseleave", `${layerId}_layer`, function () {
      map.getCanvas().style.cursor = "";
    });
  }
};

export const dinamicFillColorRange = (colors, field) => {
  const response = ["case", ["<", ["get", field], 0], "#5FB404"];
  for (let item of colors) {
    response.push(
      [
        "all",
        [">=", ["get", field], item.start],
        ["<=", ["get", field], item.end],
      ],
      item.color
    );
  }
  response.push("#5FB404");
  return response;
};

export const dinamicCircleColor2Params = (
  colors,
  field1,
  field2,
  param1,
  param2
) => {
  const response = ["case", [">", ["get", field1], 5000], "#5FB404"];
  for (let item of colors) {
    response.push(
      [
        "all",
        ["==", ["get", field1], item[param1]],
        ["==", ["get", field2], item[param2]],
      ],
      item.color
    );
  }
  response.push("#F40B4E");
  return response;
};

export const geoJsonEmpty = { type: "FeatureCollection", features: [] };

export const randomColor = () => {
  let color = Math.floor(Math.random() * 16777215).toString(16);
  if (color.length === 5) color = color + "0";
  return `#${color}`;
};

export const dynamicLineColor = (arrayToColors, property="machinery") => {
  const response = ["match", ["get", property]];
  for (const item of arrayToColors) {
    if (item !== 99451) response.push(String(item), randomColor());
  }

  response.push("#5FB404");
  return response;
};
