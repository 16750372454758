import create from 'zustand'
import { devtools } from 'zustand/middleware'
import { URL_SERVER, paxios } from '../constants/http'
import userStore from './userStore'

import { localStorageSelect, localStorageCreateUpdate } from '../localStorage'

const getDiseasesServer = async (set) => {
  try {
    const diseases = await paxios.get(`${URL_SERVER}/diseases`)
    localStorageCreateUpdate('diseases', diseases.data.data)
    set({ diseases: diseases.data.data })
  } catch (error) {
    set({ error })
  }
}

const getEventsDiseasesServer = async (set) => {
  try {
    const eventsDiseases = await paxios.get(`${URL_SERVER}/eventsDiseases`)
    localStorageCreateUpdate('eventsDiseases', eventsDiseases.data.data)
    set({ eventsDiseases: eventsDiseases.data.data })
  } catch (error) {
    set({ error })
  }
}

const getPhasesEventsDiseasesServer = async (set) => {
  try {
    const phases = await paxios.get(`${URL_SERVER}/eventsDiseases/phases`)
    localStorageCreateUpdate('phasesEventsDiseases', phases.data.data)
    set({ phasesEventsDiseases: phases.data.data })
  } catch (error) {
    set({ error })
  }
}

const getTreatmentsServer = async (set) => {
  try {
    const treatments = await paxios.get(`${URL_SERVER}/treatments`)
    localStorageCreateUpdate('treatments', treatments.data.data)
    set({ treatments: treatments.data.data })
  } catch (error) {
    set({ error })
  }
}

const getPestsServer = async (set) => {
  try {
    const pests = await paxios.get(`${URL_SERVER}/pests`)
    localStorageCreateUpdate('pests', pests.data.data)
    set({ pests: pests.data.data })
  } catch (error) {
    set({ error })
  }
}

const getStatesPestsServer = async (set) => {
  try {
    const statesPests = await paxios.get(`${URL_SERVER}/statesPests`)
    localStorageCreateUpdate('statesPests', statesPests.data.data)
    set({ statesPests: statesPests.data.data })
  } catch (error) {
    set({ error })
  }
}

const getTrapsServer = async (set, plantation) => {
  try {
    if(!plantation) return
    const traps = await paxios.get(`${URL_SERVER}/trapsRhynchophorus/${plantation}`)
    localStorageCreateUpdate('trapsRhynchophorus', traps.data.data)
    set({ traps: traps.data.data })
  } catch (error) {
    set({ error })
  }
}

const store = (set) => ({
  diseases: [],
  eventsDiseases: [],
  treatments: [],
  pests: [],
  statesPests: [],
  phasesEventsDiseases: {},
  traps: [],
  error: null,
  getDiseases: async () => {
    await getDiseasesServer(set)
  },
  getEventsDiseases: async () => {
    await getEventsDiseasesServer(set)
  },
  getTreatments: async () => {
    await getTreatmentsServer(set)
  },
  getPests: async () => {
    await getPestsServer(set)
  },
  getStatesPests: async () => {
    await getStatesPestsServer(set)
  },
  getPhasesEventsDiseases: async () => {
    await getPhasesEventsDiseasesServer(set)
  },
  getTraps: async () => {
    const currentPlantation = userStore.getState().currentPlantation
    console.log(currentPlantation)
    await getTrapsServer(set, currentPlantation.plantation);
  },

  loadLocalStorageSanitation: async () => {
    const diseasesLocal = localStorageSelect('diseases')
    diseasesLocal === null
      ? await getDiseasesServer(set)
      : set({ diseases: diseasesLocal })

    const eventsDiseasesLocal = localStorageSelect('eventsDiseases')
    eventsDiseasesLocal === null
      ? await getEventsDiseasesServer(set)
      : set({ eventsDiseases: eventsDiseasesLocal })

    const phasesEventsDiseasesLocal = localStorageSelect('phasesEventsDiseases')
    phasesEventsDiseasesLocal === null
      ? await getPhasesEventsDiseasesServer(set)
      : set({ phasesEventsDiseases: phasesEventsDiseasesLocal })

    const treatmentsLocal = localStorageSelect('treatments')
    treatmentsLocal === null
      ? await getTreatmentsServer(set)
      : set({ treatments: treatmentsLocal })

    const pestsLocal = localStorageSelect('pests')
    pestsLocal === null ? await getPestsServer(set) : set({ pests: pestsLocal })

    const statesPestsLocal = localStorageSelect('statesPests')
    statesPestsLocal === null
      ? await getStatesPestsServer(set)
      : set({ statesPests: statesPestsLocal })

    const traps = localStorageSelect('trapsRhynchophorus')  
    traps === null ? await getTrapsServer(set) : set({traps: traps})
  },
})

const sanitationStore = create(devtools(store, 'sanitation'))

export default sanitationStore
