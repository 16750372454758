import { useState, useEffect, useCallback } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core'
import MaterialTable from 'material-table'
import dayjs from 'dayjs'
import Swal from 'sweetalert2/src/sweetalert2.js'
import { v4 as uuidv4 } from 'uuid'
import { useTranslation } from 'react-i18next'
import { userStore, severalStore } from '../../../stores'
import {
  URL_SERVER,
  paxios,
  getQuantityPalmsBetweenLines,
} from '../../../constants/http'
import {
  Button,
  FormInput,
  Select as SelectCustom,
  DatePicker,
  SubTitle,
} from '../../../components'
import tableIcons from '../../../constants/IconsMaterialTable'
import tableTranslations from '../../../constants/tableTranslations'

const executionInitial = {
  id: null,
  schedule: null,
  date: new Date(),
  cost: 0,
  supervisor: 0,
  time: 0,
  observations: '',
  workers: [],
  problemQuantity: false,
}

export default function CreateEditExecution({
  open,
  setOpen,
  schedule,
  searchRef,
  search,
  executionEdit = null,
}) {
  const { t, i18n } = useTranslation()
  const { workers, quadrilles } = severalStore()
  const { currentPlantation } = userStore()
  const [execution, setExecution] = useState({
    ...executionInitial,
  })
  const [columns, setColumns] = useState([])

  const editComponentWorkers = useCallback(
    (props) => {
      const data = { ...props.rowData }
      return (
        <Select
          value={props.value !== undefined ? props.value : 0}
          onChange={(e) => {
            data.worker = parseInt(e.target.value)
            props.onRowDataChange(data)
          }}
          disabled={String(data.quadrille) !== '0'}
        >
          <MenuItem key={0} value={0}>
            {t('general.notassigned')}
          </MenuItem>
          {workers.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      )
    },
    [t, workers]
  )

  const editComponentQuadrille = useCallback(
    (props) => {
      const data = { ...props.rowData }
      return (
        <Select
          value={props.value !== undefined ? props.value : 0}
          onChange={(e) => {
            data.quadrille = parseInt(e.target.value)
            props.onRowDataChange(data)
          }}
          disabled={String(data.worker) !== '0'}
        >
          <MenuItem key={0} value={0}>
            {t('general.notassigned')}
          </MenuItem>
          {quadrilles.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      )
    },
    [t, quadrilles]
  )

  const handleChangeEndLine = useCallback(
    async (props, data) => {
      if (schedule.plot.length <= 0) return
      const palms = await getQuantityPalmsBetweenLines(
        data.plantation,
        schedule.plot[0].id,
        data.start_line,
        data.end_line
      )
      data.quantity = palms
      props.onRowDataChange(data)
    },
    [schedule.plot]
  )

  const editComponentStartLine = useCallback(
    (props) => {
      const data = { ...props.rowData }
      return (
        <div className="MuiFormControl-root MuiTextField-root MuiFormControl-fullWidth">
          <div className="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-fullWidth MuiInput-fullWidth MuiInputBase-formControl MuiInput-formControl">
            <input
              type="number"
              className="MuiInputBase-input MuiInput-input"
              min="0"
              value={props.value || 0}
              disabled={schedule.plot.length === 0 ? 'disabled' : ''}
              onChange={(e) => {
                data.start_line = parseInt(e.target.value) || 0
                props.onRowDataChange(data)
                if (data.end_line > 0)
                  handleChangeEndLine(props, {
                    ...data,
                    plantation: currentPlantation.plantation,
                  })
              }}
            />
          </div>
        </div>
      )
    },
    [currentPlantation.plantation, schedule.plot.length, handleChangeEndLine]
  )

  const editComponentEndLine = useCallback(
    (props) => {
      const data = { ...props.rowData }
      return (
        <div className="MuiFormControl-root MuiTextField-root MuiFormControl-fullWidth">
          <div className="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-fullWidth MuiInput-fullWidth MuiInputBase-formControl MuiInput-formControl">
            <input
              type="number"
              className="MuiInputBase-input MuiInput-input"
              min="0"
              value={props.value || 0}
              disabled={schedule.plot.length === 0 ? 'disabled' : ''}
              onChange={(e) => {
                data.end_line = parseInt(e.target.value) || 0
                props.onRowDataChange(data)
                handleChangeEndLine(props, {
                  ...data,
                  plantation: currentPlantation.plantation,
                })
              }}
            />
          </div>
        </div>
      )
    },
    [currentPlantation.plantation, schedule.plot.length, handleChangeEndLine]
  )

  const editComponentQuantity = useCallback(
    (props) => {
      const data = { ...props.rowData }
      return (
        <div className="MuiFormControl-root MuiTextField-root MuiFormControl-fullWidth">
          <div className="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-fullWidth MuiInput-fullWidth MuiInputBase-formControl MuiInput-formControl">
            <input
              type="number"
              className="MuiInputBase-input MuiInput-input"
              min="0"
              value={props.value || 0}
              disabled={data.end_line !== 0 ? 'disabled' : ''}
              onChange={(e) => {
                data.quantity = parseInt(e.target.value) || 0
                props.onRowDataChange(data)
              }}
            />
          </div>
          {data.quantity === 0 && (
            <p className="MuiFormHelperText-root Mui-error MuiFormHelperText-filled">
              {t('general.nozero')}
            </p>
          )}
        </div>
      )
    },
    [t]
  )

  useEffect(() => {
    const validateNoZero = (rowData, field) => {
      return rowData[field] <= 0 || rowData[field] === '0'
        ? t('general.nozero')
        : true
    }

    const validateEmpty = (rowData, field) => {
      return rowData[field] === '' ||
        rowData[field] === undefined ||
        rowData[field] === null ||
        isNaN(rowData[field])
        ? t('general.noempty')
        : true
    }
    const validateNoZeroEmpty = (rowData, field) => {
      if (
        validateNoZero(rowData, field) === true &&
        validateEmpty(rowData, field) === true
      ) {
        return true
      } else {
        return `${
          validateNoZero(rowData, field) === true
            ? ''
            : validateNoZero(rowData, field)
        } ${
          validateEmpty(rowData, field) === true
            ? ''
            : ', ' + validateEmpty(rowData, field)
        }`
      }
    }
    setColumns([
      {
        title: t('general.worker'),
        field: 'worker',
        lookup: {
          0: t('general.notassigned'),
          ...workers.reduce((acc, item) => {
            acc[item.id] = item.name
            return acc
          }, {}),
        },
        initialEditValue: 0,
        validate: (rowData) =>
          String(rowData.worker) === '0' && String(rowData.quadrille) === '0'
            ? t('general.nozero')
            : true,
        editComponent: editComponentWorkers,
      },
      {
        title: t('general.quadrille'),
        field: 'quadrille',
        lookup: {
          0: t('general.notassigned'),
          ...quadrilles.reduce((acc, item) => {
            acc[item.id] = item.name
            return acc
          }, {}),
        },
        initialEditValue: 0,
        validate: (rowData) =>
          String(rowData.worker) === '0' && String(rowData.quadrille) === '0'
            ? t('general.nozero')
            : true,
        editComponent: editComponentQuadrille,
      },
      {
        title: t('general.quantity'),
        field: 'quantity',
        type: 'numeric',
        initialEditValue: 0,
        validate: (rowData) => validateNoZeroEmpty(rowData, 'quantity'),
        editComponent: editComponentQuantity,
      },
      {
        title: t('general.start_line'),
        field: 'start_line',
        type: 'numeric',
        initialEditValue: 0,
        editComponent: editComponentStartLine,
      },
      {
        title: t('general.end_line'),
        field: 'end_line',
        type: 'numeric',
        initialEditValue: 0,
        editComponent: editComponentEndLine,
      },
    ])
  }, [
    workers,
    quadrilles,
    t,
    editComponentWorkers,
    editComponentQuadrille,
    editComponentStartLine,
    editComponentEndLine,
    editComponentQuantity,
  ])

  useEffect(() => {
    setExecution((d) => ({ ...d, cost: schedule.cost, schedule: schedule.id }))
  }, [schedule])

  useEffect(() => {
    const quantityRegister = execution.workers.reduce((acc, item) => {
      if (typeof item.id === 'string') {
        return acc + item.quantity
      } else {
        if (item.edit) {
          return acc + item.diff
        }
      }
      return acc
    }, 0)

    const quantityTotal = schedule.completed + quantityRegister

    if (quantityTotal > schedule.quantity) {
      setExecution((d) => ({ ...d, problemQuantity: true }))
    } else {
      setExecution((d) => ({ ...d, problemQuantity: false }))
    }
  }, [execution.workers, schedule])

  useEffect(() => {
    if (executionEdit) {
      setExecution({
        id: executionEdit.id,
        schedule: executionEdit.schedule,
        date: executionEdit.date + '  00:00:00',
        cost: executionEdit.cost,
        supervisor: executionEdit.supervisor,
        time: executionEdit.time,
        observations: executionEdit.observations,
        workers: executionEdit.execution_workers.map((x) => ({
          ...x,
          edit: false,
          diff: 0,
        })),
        problemQuantity: false,
      })
    }
  }, [executionEdit])

  const handleOnChange = (e) => {
    let { name, value } = e.target
    setExecution((d) => ({ ...d, [name]: value }))
  }

  const onRowAdd = (newData) =>
    new Promise(async (resolve) => {
      setExecution((d) => ({
        ...d,
        workers: [
          ...d.workers,
          { id: uuidv4(), ...newData, edit: false, diff: 0 },
        ],
      }))
      resolve()
    })

  const calculateDiffQuantityEdit = (newData, oldData) => {
    if (parseFloat(newData.quantity) > parseFloat(oldData.quantity))
      return parseFloat(newData.quantity) - parseFloat(oldData.quantity)

    if (parseFloat(oldData.quantity) > parseFloat(newData.quantity)) return 0

    return 0
  }

  const onRowUpdate = (newData, oldData) =>
    new Promise(async (resolve) => {
      setExecution((d) => ({
        ...d,
        workers: [
          ...d.workers.map((x) =>
            String(x.id) === String(newData.id)
              ? {
                  ...newData,
                  edit:
                    calculateDiffQuantityEdit(newData, oldData) === 0
                      ? false
                      : true,
                  diff: calculateDiffQuantityEdit(newData, oldData),
                }
              : { ...x }
          ),
        ],
      }))
      resolve()
    })

  const validateButtonSaveActive = () => {
    if (execution.supervisor === '0' || execution.supervisor === 0) return true
    if (execution.date === null || execution.date === '') return true
    if (execution.time === '' || String(execution.time) === '0') return true

    if (execution.cost === '' || String(execution.cost) === '0') return true

    if (execution.workers.length <= 0) return true

    return false
  }

  const prepareData = () => {
    const send = {
      ...execution,
      date:
        execution.date !== null
          ? dayjs(execution.date).format('YYYY-MM-DD')
          : null,
      workers: execution.workers.map((x) => ({
        ...x,
        id: typeof x.id === 'string' ? null : x.id,
      })),
      plantation: currentPlantation.plantation,
      observations: '',
    }

    return send
  }

  const saveData = () => {
    const send = prepareData()
    if (send.id) {
      const sendEdit = {
        ...send,
        workers: send.workers.map((y) => ({
          ...y,
          execution: executionEdit.id,
        })),
      }
      updateExecution(sendEdit)
    } else {
      createExecution(send)
    }
  }

  const createExecution = async (send) => {
    try {
      const response = await paxios.post(`${URL_SERVER}/laborsExecution`, send)
      if (response.data.status === 'ok') {
        Swal.fire(t('general.finished'), t('general.savesuccess'), 'success')
        search(searchRef.current.getData())
        setExecution({
          supervisor: 0,
          time: 0,
          observations: '',
          workers: [],
          problemQuantity: false,
        })
        setOpen(false)
      } else {
        Swal.fire(t('general.finished'), t('general.datawasnotsaved'), 'error')
      }
    } catch (error) {
      Swal.fire(t('general.finished'), t('general.datawasnotsaved'), 'error')
    }
  }

  const updateExecution = async (send) => {
    try {
      const response = await paxios.put(
        `${URL_SERVER}/laborsExecution/${send.id}`,
        send
      )
      if (response.data.status === 'ok') {
        Swal.fire(t('general.finished'), t('general.savesuccess'), 'success')
        search(searchRef.current.getData())
      } else {
        Swal.fire(t('general.finished'), t('general.datawasnotsaved'), 'error')
      }
    } catch (error) {
      Swal.fire(t('general.finished'), t('general.datawasnotsaved'), 'error')
    }
  }

  const handleClickSave = () => {
    saveData()
  }

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="md"
      fullWidth
      aria-labelledby="confirmation-dialog-title"
      open={open}
    >
      <DialogTitle id="confirmation-dialog-title">
        {`${!executionEdit ? t('general.register') : t('general.edit')} ${t(
          'general.execution'
        )}`}
      </DialogTitle>

      <DialogContent dividers>
        <Typography variant="h6" color={'primary'} align="center">
          {`${t('general.programmed')}: ${schedule.quantity} / ${t(
            'general.completed'
          )}: ${schedule.completed} / ${t('general.remaining')}: ${
            schedule.quantity - schedule.completed
          }`}
        </Typography>
        <Grid container justify="center" spacing={2}>
          <Grid item xs={12} md={6}>
            <SelectCustom
              name="supervisor"
              text={t('general.supervisor')}
              width={'100%'}
              items={workers.filter((x) => x.supervisor === 1)}
              defaultOption0={true}
              value={execution.supervisor}
              handleChange={handleOnChange}
              required={true}
              error={execution.supervisor === '0' || execution.supervisor === 0}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <DatePicker
              label={`${t('general.date')}`}
              selectedDate={execution.date}
              handleDateChange={handleOnChange}
              name="date"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormInput
              name={'cost'}
              title={t('general.cost')}
              value={execution.cost}
              onChange={handleOnChange}
              type="number"
              error={execution.cost === '' || String(execution.cost) === '0'}
              errorMessage={`${t('general.value_invalid')}`}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormInput
              name={'time'}
              title={t('general.time')}
              value={execution.time}
              onChange={handleOnChange}
              type="number"
              error={execution.time === '' || String(execution.time) === '0'}
              errorMessage={`${t('general.value_invalid')}`}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <FormInput
              name={'observations'}
              title={t('general.observations')}
              value={execution.observations}
              onChange={handleOnChange}
              multiline={true}
            />
          </Grid>
        </Grid>

        <Grid container justify="center" spacing={2}>
          <SubTitle text={t('general.workers')} />
          {execution.problemQuantity && (
            <Typography variant="h6" color={'error'} align="center">
              {t('general.quantity_exceeds_schedule')}
            </Typography>
          )}
          <Grid item xs={12} md={12}>
            <MaterialTable
              icons={tableIcons}
              columns={columns}
              title={''}
              data={execution.workers}
              options={{
                exportButton: false,
                exportAllData: false,
                exportDelimiter: ';',
                selection: false,
              }}
              localization={i18n.language === 'es' ? tableTranslations : {}}
              editable={{
                onRowUpdate: onRowUpdate,
                onRowAdd: onRowAdd,
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button
          handleButtonClick={() => {
            setOpen(false)
          }}
          text={t('general.close')}
        />
        <Button
          handleButtonClick={handleClickSave}
          text={t('general.save')}
          disabled={validateButtonSaveActive() || execution.problemQuantity}
        />
      </DialogActions>
    </Dialog>
  )
}
