import { useEffect, useState } from 'react'
import Swal from 'sweetalert2/src/sweetalert2.js'
import MaterialTable from 'material-table'
import { useTranslation } from 'react-i18next'
import { sanitationStore } from '../../../stores'
import { URL_SERVER, paxios } from '../../../constants/http'
import tableIcons from '../../../constants/IconsMaterialTable'
import tableTranslations from '../../../constants/tableTranslations'

const cellStyle = { align: 'center', headerStyle: { textAlign: 'center' } }

export default function Treatments() {
  const { t, i18n } = useTranslation()
  const { treatments, getTreatments } = sanitationStore()
  const [columns, setColumns] = useState([])

  useEffect(() => {
    const validateEmpty = (rowData, field) => {
      return rowData[field] === '' || rowData[field] === undefined
        ? t('general.noempty')
        : true
    }
    setColumns([
      { title: 'ID', field: 'id', editable: 'never' },
      {
        title: t('general.name'),
        field: 'name',
        validate: (rowData) => validateEmpty(rowData, 'name'),
      },
      {
        title: t('general.description'),
        field: 'description',
        validate: (rowData) => validateEmpty(rowData, 'description'),
      },
      {
        title: t("general.state"),
        field: "removed",
        lookup: { 0: t("general.active"), 1: t("general.inactive") },
        initialEditValue: 0,
        ...cellStyle,
      },
    ])

  }, [t])

  const createTreatments = async (data) => {
    try {
      const response = await paxios.post(`${URL_SERVER}/treatments`, data)
      if (response.data.status === 'ok') {
        Swal.fire(t('general.finished'), t('general.savesuccess'), 'success')
        getTreatments()
      } else {
        Swal.fire(t('general.finished'), response.data.message, 'error')
      }
    } catch (error) {
      Swal.fire(t('general.finished'), t('general.datawasnotsaved'), 'error')
    }
  }

  const onRowAddTreatments = (newData) =>
    new Promise(async (resolve) => {
      await createTreatments(newData)
      resolve()
    })

  const updateTreatments = async (data) => {
    try {
      const response = await paxios.put(`${URL_SERVER}/treatments/${data.id}`, data)
      if (response.data.status === 'ok') {
        Swal.fire(t('general.finished'), t('general.savesuccess'), 'success')
        getTreatments()
      } else {
        Swal.fire(t('general.finished'), t('general.datawasnotsaved'), 'error')
      }
    } catch (error) {
      Swal.fire(t('general.finished'), t('general.datawasnotsaved'), 'error')
    }
  }

  const onRowUpdateTreatments = (newData, oldData) =>
    new Promise(async (resolve) => {
      await updateTreatments(newData)
      resolve()
    })

  return (
    <>
      <MaterialTable
        icons={tableIcons}
        columns={columns}
        title={t('general.treatments')}
        data={treatments.map((x) => ({ ...x, ti: new Date() }))}
        options={{
          exportButton: true,
          exportAllData: true,
          exportDelimiter: ';',
          selection: false,
        }}
        localization={i18n.language === 'es' ? tableTranslations : {}}
        editable={{
          onRowUpdate: onRowUpdateTreatments,
          onRowAdd: onRowAddTreatments,
        }}
      />
    </>
  )
}
