import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./i18n";
import { ThemeProvider, createMuiTheme } from '@material-ui/core';

const outerTheme = createMuiTheme({
  palette: {
    /* primary: {
      main: '#81c784'
    }, */
    secondary: {
      main: '#E9320B',
      light: '#FC8065'
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback="loading">
      <ThemeProvider theme={outerTheme}>
        <App />
      </ThemeProvider>
    </Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);
