import {
  Modal,
  makeStyles,
  Card,
  CardContent,
  Typography,
  Divider,
  CardHeader,
  IconButton,
} from '@material-ui/core'
import AttachmentIcon from '@material-ui/icons/Attachment'
import CloseIcon from '@material-ui/icons/Close'

export default function ModalCustom({
  children,
  title,
  width,
  height,
  open,
  setOpen,
  icon=<AttachmentIcon />
}) {
  const useStyles = makeStyles((theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      
    },
    paper: {
      position: 'absolute',
      width: width,
      height: height,
      backgroundColor: theme.palette.background.paper,
      outline: 'none',
      border: '2px solid #fff',
      paddingBottom: 20,
      borderRadius: 0
      //boxShadow: theme.shadows[5],
      //padding: theme.spacing(2, 4, 3),
    },
  }))

  const classes = useStyles()
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={classes.modal}
    >
      <Card className={classes.paper}>
        <CardHeader
          avatar={icon}
          action={
            <IconButton aria-label="close" onClick={() => setOpen(false)}>
              <CloseIcon />
            </IconButton>
          }
          title={
            <Typography variant="h5" component="h2">
              {title}
            </Typography>
          }
          subheader=""
        />
        <Divider />
        <CardContent>{children}</CardContent>
        <Divider />
        
      </Card>
    </Modal>
  )
}
