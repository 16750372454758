import { useTranslation } from 'react-i18next'
import { userStore } from '../../stores'
import { URL_SERVER, paxios, uploadFile } from '../../constants/http'
import { LoadExcel } from '../../components'

export default function ByExcelExecutions() {
  const { currentPlantation } = userStore()
  const { t } = useTranslation()

  const columns = [
    { id: 'id_schedule', label: t('general.schedule') },
    { id: 'date', label: t('general.date') },
    { id: 'supervisor', label: t('general.supervisor') },
    { id: 'worker', label: t('general.worker') },
    { id: 'quadrille', label: t('general.quadrille') },
    { id: 'quantity', label: t('general.quantity') },
    { id: 'time', label: t('general.time') },
    { id: 'start_line', label: t('general.start_line') },
    { id: 'end_line', label: t('general.end_line') },
    { id: 'observations', label: t('general.observations') },
    { id: 'errors', label: t('general.errors') },
  ]

  const loadFile = async (file) => {
    try {
      const response = await uploadFile({
        url: `${URL_SERVER}/laborsExecution/loadExcelExecution`,
        file,
        nameFile: 'file_executions',
        aditionalData: [
          { name: 'plantation', value: currentPlantation.plantation },
        ],
      })
      return response
    } catch (error) {}
  }

  const saveData = async (records) => {
    try {
      const response = await paxios.post(
        `${URL_SERVER}/laborsExecution/saveExcelExecution`,
        {
          plantation: currentPlantation.plantation,
          data: records,
        }
      )
      return response
    } catch (error) {}
  }

  return (
    <>
      <LoadExcel columns={columns} loadFile={loadFile} saveData={saveData} />
    </>
  )
}
