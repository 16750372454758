export const getPlantationLogo = (currentPlantation) => {
  if (!currentPlantation) return ''
  return `./img/plantationsLogos/${currentPlantation.plantation}.png`
}

export const generatePalmId = (data) => {
  let { plantation, plot, line, palm } = data
  let palm_id = 0
  if (plot < 10) {
    plot = `000${plot}`
  } else if (plot > 9 && plot < 100) {
    plot = `00${plot}`
  } else if (plot > 99 && plot < 1000) {
    plot = `0${plot}`
  }

  if (line < 10) {
    line = `00${line}`
  } else if (line > 9 && line < 100) {
    line = `0${line}`
  }

  if (palm < 10) {
    palm = `00${palm}`
  } else if (palm > 9 && palm < 100) {
    palm = `0${palm}`
  }

  palm_id = `${plantation}${plot}${line}${palm}`

  return palm_id
}

export const formatNumber = (number) => {
  return new Intl.NumberFormat('de-DE').format(number)
}

export const validateEmail = (email) => {
  // eslint-disable-next-line
  const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
  return regex.test(email)
}

export const capitalize = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const getFormMobile = (t) => {
  return [
    {id: 1, name: t('general.diseases')},
    {id: 2, name: t('general.pests')}
  ]
}
