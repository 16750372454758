import { useState } from 'react'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

export default function ContextualMenu({ text, getOptionSelect, items, style=null }) {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClickItem = (id) => {
    handleClose()
    getOptionSelect(id)
  }

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        style={style!==null ? style : {}}
      >
        {text}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {items.map((x) => (
          <MenuItem key={x.id} onClick={() => handleClickItem(x.id)}>
            {x.text}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}
