import { useState, useRef } from 'react'
import {
  Grid,
  Paper,
  Typography,
  makeStyles,
  IconButton,
  RadioGroup,
  Radio,
  FormControlLabel,
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import AssignmentReturnedIcon from '@material-ui/icons/AssignmentReturned'
import { useTranslation } from 'react-i18next'
import Swal from 'sweetalert2/src/sweetalert2.js'
import { URL_SERVER, downloadFile } from '../../../constants/http'
import {
  PlotsSelector,
  Button,
  DiseasesSelector,
  SubTitle,
} from '../../../components'
import { userStore } from '../../../stores'


const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  gridContainer: {
    marginBottom: 10,
  },
}))

export default function CompleteHistory() {
  const classes = useStyles()
  const { t } = useTranslation()
  const { currentPlantation } = userStore()
  const plotsSelectorRef = useRef(null)
  const diseasesSelectorRef = useRef(null)
  const [queryData, setQueryData] = useState({
    plot: 0,
    diseases: 0,
    type: '1',
  })

  const handleSelectPlot = (plot) => {
    setQueryData((d) => ({ ...d, plot: plot.length > 0 ? plot : 0 }))
    plotsSelectorRef.current !== null && plotsSelectorRef.current.setOpen(false)
  }

  const handleSelectDiseases = (diseases) => {
    setQueryData((d) => ({
      ...d,
      diseases: diseases.length > 0 ? diseases : 0,
    }))
    diseasesSelectorRef.current !== null &&
      diseasesSelectorRef.current.setOpen(false)
  }

  const handleRadioChange = (e) => {
    const { name, value } = e.target
    setQueryData((d) => ({ ...d, [name]: value }))
  }

  const validateData = (data) => {
    const errors = {}

    if (data.plot === 0 || data.plot === '0')
      errors.plot = t('general.selectplot')

    if (data.diseases === 0 || data.diseases === '0')
      errors.diseases = t('general.selectDisease')

    return errors
  }

  const handleClickSearch = (data) => {
    const errors = validateData(data)
    if (Object.keys(errors).length <= 0) {
      const send = {
        ...data,
        plot: data.plot !== '0' && data.plot !== 0 ? data.plot[0].id : 0,
        plantation: currentPlantation.plantation,
        diseases:
          data.plot !== '0' && data.plot !== 0 ? data.diseases[0].id : 0,
      }
      //console.log(send)
      getExcel(send)
    } else {
      const message = Object.keys(errors).reduce((acc, item) => {
        return (acc += `<p>${errors[item]}</p>`)
      }, '')
      Swal.fire(t('general.finished'), `<div>${message}</div>`, 'error')
    }
  }

  const getExcel = (data) => {
    try {
      downloadFile(
        `${URL_SERVER}/censusDiaseases/getExcelReportHistoricalCompleteDiseases`,
        t('general.completehistory') + '.xlsx',
        data
      )
    } catch (error) {
      Swal.fire(t('general.finished'), t('general.errortoquery'), 'error')
    }
  }

  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      spacing={2}
      className={classes.gridContainer}
    >
      <SubTitle text={t('general.completehistory')} />
      <Grid item xs={12} md={4}>
        <Paper component="form" className={classes.root}>
          <Typography variant="subtitle1">
            {queryData.plot !== 0
              ? queryData.plot.reduce((acc, item) => {
                  return (acc += acc !== '' ? ',' + item.name : item.name)
                }, '')
              : `${t('general.select')} ${t('general.plot')}`}
          </Typography>
          <IconButton
            type="button"
            aria-label="search"
            className={classes.iconButton}
            onClick={() =>
              plotsSelectorRef.current !== null &&
              plotsSelectorRef.current.setOpen(true)
            }
          >
            <SearchIcon />
          </IconButton>
        </Paper>
        <PlotsSelector
          accept={handleSelectPlot}
          ref={plotsSelectorRef}
          single={true}
        />
      </Grid>
      <Grid item xs={12} md={5}>
        <Paper component="form" className={classes.root}>
          <Typography variant="subtitle1">
            {queryData.diseases !== 0
              ? queryData.diseases.reduce((acc, item) => {
                  return (acc += acc !== '' ? ',' + item.name : item.name)
                }, '')
              : `${t('general.select')} ${t('general.disease')}`}
          </Typography>
          <IconButton
            type="button"
            aria-label="search"
            className={classes.iconButton}
            onClick={() =>
              diseasesSelectorRef.current !== null &&
              diseasesSelectorRef.current.setOpen(true)
            }
          >
            <SearchIcon />
          </IconButton>
        </Paper>
        <DiseasesSelector
          accept={handleSelectDiseases}
          ref={diseasesSelectorRef}
          single={true}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <RadioGroup
          row
          aria-label="plots"
          name="type"
          value={queryData.type}
          onChange={handleRadioChange}
        >
          <FormControlLabel
            value="1"
            control={<Radio />}
            label={t('general.census')}
          />
          <FormControlLabel
            value="2"
            control={<Radio />}
            label={t('general.month')}
          />
        </RadioGroup>
      </Grid>
      <Button
        icon={<AssignmentReturnedIcon />}
        handleButtonClick={() =>
          handleClickSearch({
            ...queryData,
          })
        }
        text={t('general.downloadexcel')}
      />
    </Grid>
  )
}
