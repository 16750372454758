import { useState } from 'react'
import { Button } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import SaveIcon from '@material-ui/icons/Save'
import Swal from 'sweetalert2/src/sweetalert2.js'
import { useTranslation } from 'react-i18next'
import { userStore } from '../../stores'
import { URL_SERVER, paxios, uploadFile } from '../../constants/http'
import {Modal, InputFile, SimpleTable} from '../../components'

const styles = {
  containerButtonLoadFile: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10px',
  },
  button: {
    marginBottom: 15,
  },
}

export default function ModalLoadExcel({ setOpen, open, plot, getPalms }) {
  const { currentPlantation } = userStore()
  const { t } = useTranslation()
  const [selectedFile, setSelectedFile] = useState(null)
  const [message, setMessage] = useState('')
  const [messageError, setMessageError] = useState('')
  const [requestInProgress, setRequestInProgress] = useState(false)
  const [records, setRecords] = useState([])
  const [refInputFile, setRefInputFile] = useState(null)

  const columns = [
    { id: 'line', label: t('general.line') }, //minWidth: 170
    { id: 'palm', label: t('general.palm') },
    { id: 'sowing', label: t('general.sowing') },
    { id: 'state', label: t('general.state') },
    { id: 'material', label: t('general.material') },
    { id: 'date_eradication', label: t('general.date_eradication') },
    { id: 'reason_eradication_text', label: t('general.reason_eradication') },
    { id: 'errors', label: t('general.errors') },
  ]

  const addRefInputFile = (ref) => {
    setRefInputFile(ref)
  }

  const onFileChange = (event) => {
    setSelectedFile(event.target.files[0])
    !event.target.files[0] && setRecords([])
    setMessageError('')
  }

  const handleClickLoadFile = () => {
    loadFile(selectedFile)
  }

  const loadFile = async (file) => {
    setRequestInProgress(true)
    setMessage(t('general.processingfile'))
    try {
      const response = await uploadFile({
        url: `${URL_SERVER}/palms/loadExcelPalms/${plot}`,
        file,
        nameFile: 'file_palms',
        aditionalData: [
          { name: 'plantation', value: currentPlantation.plantation },
        ],
      })

      if (response.status === 'ok') {
        setRecords(response.data)
        setSelectedFile(null)
        if(refInputFile){
          refInputFile.current.value = ''
        } 
        
      } else if (response.status === 'error') {
        switch (response.message) {
          case 'Incomplete columns':
            setMessageError(t(`general.incompletecolumns`))
            break
          case 'No data':
            setMessageError(t(`general.nodata`))
            break
          default:
            break
        }
      }
    } catch (error) {}

    setRequestInProgress(false)
    setMessage('')
  }

  const handleCliclSaveData = () => {
    saveData()
  }

  const saveData = async () => {
    setRequestInProgress(true)
    setMessage(t('general.savingdata'))

    try {
      const response = await paxios.post(`${URL_SERVER}/palms/saveExcelPalms`, {
        plantation: currentPlantation.plantation,
        data: records,
      })
      if (response.data.status === 'ok') {
        Swal.fire(t('general.finished'), t('general.savesuccess'), 'success')
        setRecords([])
        getPalms(plot)
      }
    } catch (error) {
      Swal.fire(t('general.finished'), t('general.datawasnotsaved'), 'error')
    }

    setRequestInProgress(false)
    setMessage('')
    setOpen(false)
  }

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title={t('general.loadexcel')}
      width="80%"
    >
      <InputFile selectedFile={selectedFile} onFileChange={onFileChange} setRefInputFile={addRefInputFile} />
      <div style={styles.containerButtonLoadFile}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<CloudUploadIcon />}
          style={styles.button}
          onClick={handleClickLoadFile}
          disabled={requestInProgress || !selectedFile}
        >
          {t('general.loadfile')}
        </Button>
      </div>
      {message !== '' && <Alert severity="info">{message}</Alert>}
      {messageError !== '' && <Alert severity="error">{messageError}</Alert>}
      {records.length > 0 && <SimpleTable columns={columns} rows={records} />}
      {records.length > 0 && (
        <div style={styles.containerButtonLoadFile}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<SaveIcon />}
            onClick={handleCliclSaveData}
          >
            {t('general.save')}
          </Button>
        </div>
      )}
    </Modal>
  )
}
