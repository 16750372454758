import { useEffect, useState } from 'react'
import Swal from 'sweetalert2/src/sweetalert2.js'
import MaterialTable from 'material-table'
import TrendingUpIcon from '@material-ui/icons/TrendingUp'
import { useTranslation } from 'react-i18next'
import { laborsStore, userStore } from '../../../stores'
import { URL_SERVER, paxios } from '../../../constants/http'
import tableIcons from '../../../constants/IconsMaterialTable'
import tableTranslations from '../../../constants/tableTranslations'
import CostLabor from './CostLabor'

const cellStyle = { align: 'center', headerStyle: { textAlign: 'center' } }

export default function Labors() {
  const { t, i18n } = useTranslation()
  const {
    labors,
    getLabors,
    costCenters,
    getCostCenters,
    laborsGroups,
    getLaborsGroups,
    unitOfMeasures,
    getUnitOfMeasures,
    laborsPhases,
    getLaborsPhases,
    originsQuantity,
    getOriginsQuantity,
    headings,
    getHeadings,
  } = laborsStore()
  const { currentPlantation, user } = userStore()
  const [columns, setColumns] = useState([])
  const [showDialogCosts, setShowDialogCosts] = useState(false)
  const [laborShowDialogCosts, setLaborShowDialogCosts] = useState({})

  useEffect(() => {
    labors.length <= 0 && getLabors()
    costCenters.length <= 0 && getCostCenters()
    laborsGroups.length <= 0 && getLaborsGroups()
    unitOfMeasures.length <= 0 && getUnitOfMeasures()
    laborsPhases.length <= 0 && getLaborsPhases()
    originsQuantity.length <= 0 && getOriginsQuantity()
    headings.length <= 0 && getHeadings()
  }, [
    labors.length,
    getLabors,
    costCenters.length,
    getCostCenters,
    laborsGroups.length,
    getLaborsGroups,
    unitOfMeasures.length,
    getUnitOfMeasures,
    laborsPhases.length,
    getLaborsPhases,
    originsQuantity.length,
    getOriginsQuantity,
    headings.length,
    getHeadings,
  ])

  useEffect(() => {
    const validateEmpty = (rowData, field) => {
      return rowData[field] === '' || rowData[field] === undefined
        ? t('general.noempty')
        : true
    }

    setColumns([
      { title: 'ID', field: 'id', editable: 'never' },
      {
        title: t('general.code'),
        field: 'code',
        validate: (rowData) => validateEmpty(rowData, 'code'),
      },
      {
        title: t('general.name'),
        field: 'name',
        validate: (rowData) => validateEmpty(rowData, 'name'),
      },
      {
        title: t('general.phase'),
        field: 'phase',
        lookup: {
          0: t('general.notassigned'),
          ...laborsPhases.reduce((acc, item) => {
            return { ...acc, [item.id]: item.name }
          }, {}),
        },
        initialEditValue: 0,
        ...cellStyle,
      },
      {
        title: t('general.group'),
        field: 'group',
        lookup: {
          0: t('general.notassigned'),
          ...laborsGroups.reduce((acc, item) => {
            return { ...acc, [item.id]: item.name }
          }, {}),
        },
        initialEditValue: 0,
        ...cellStyle,
      },
      {
        title: t('general.heading'),
        field: 'heading',
        lookup: {
          0: t('general.notassigned'),
          ...headings.reduce((acc, item) => {
            return { ...acc, [item.id]: item.name }
          }, {}),
        },
        initialEditValue: 0,
        ...cellStyle,
      },
      {
        title: t('general.unit_of_measures'),
        field: 'unit_of_measure',
        lookup: {
          0: t('general.notassigned'),
          ...unitOfMeasures.reduce((acc, item) => {
            return { ...acc, [item.id]: item.name }
          }, {}),
        },
        initialEditValue: 0,
        ...cellStyle,
      },
      {
        title: t('general.origins_quantity'),
        field: 'origin_quantity',
        lookup: {
          0: t('general.notassigned'),
          ...originsQuantity.reduce((acc, item) => {
            return { ...acc, [item.id]: item.name }
          }, {}),
        },
        initialEditValue: 0,
        ...cellStyle,
      },
      {
        title: `${t('general.requires')} ${t('general.plot')}`,
        field: 'requires_plot',
        lookup: { 1: t('general.yes'), 0: 'No' },
        initialEditValue: 1,
        ...cellStyle,
      },
      {
        title: `${t('general.fertilization')}`,
        field: 'fertilization',
        lookup: { 1: t('general.yes'), 0: 'No' },
        initialEditValue: 0,
        ...cellStyle,
      },
      {
        title: `${t('general.machinery')}`,
        field: 'machinery',
        lookup: { 1: t('general.yes'), 0: 'No' },
        initialEditValue: 0,
        ...cellStyle,
      },
      {
        title: t('general.state'),
        field: 'state',
        lookup: { 1: t('general.active'), 0: t('general.inactive') },
        initialEditValue: 1,
        ...cellStyle,
      },
    ])
  }, [t, laborsPhases, laborsGroups, unitOfMeasures, originsQuantity, headings])

  const createLabor = async (data) => {
    try {
      const response = await paxios.post(`${URL_SERVER}/labors`, {
        ...data,
        user: user.id,
        plantation: currentPlantation.plantation,
      })
      if (response.data.status === 'ok') {
        Swal.fire(t('general.finished'), t('general.savesuccess'), 'success')
        getLabors()
      } else {
        Swal.fire(t('general.finished'), response.data.message, 'error')
      }
    } catch (error) {
      Swal.fire(t('general.finished'), t('general.datawasnotsaved'), 'error')
    }
  }

  const onRowAddLabor = (newData) =>
    new Promise(async (resolve) => {
      await createLabor(newData)
      resolve()
    })

  const updateLabor = async (data) => {
    try {
      const response = await paxios.put(`${URL_SERVER}/labors/${data.id}`, {
        ...data,
        user: user.id,
        plantation: currentPlantation.plantation,
      })
      if (response.data.status === 'ok') {
        Swal.fire(t('general.finished'), t('general.savesuccess'), 'success')
        getLabors()
      } else {
        Swal.fire(t('general.finished'), t('general.datawasnotsaved'), 'error')
      }
    } catch (error) {
      Swal.fire(t('general.finished'), t('general.datawasnotsaved'), 'error')
    }
  }

  const onRowUpdateLabor = (newData, oldData) =>
    new Promise(async (resolve) => {
      await updateLabor(newData)
      resolve()
    })

  const handleClickShowDialogCosts = (event, rowData) => {
    setLaborShowDialogCosts(rowData)
    setShowDialogCosts(true)
  }

  return (
    <>
      <MaterialTable
        icons={tableIcons}
        columns={columns}
        title={`${t('general.labors')}`}
        data={labors.map((x) => ({ ...x, ti: new Date() }))}
        options={{
          exportButton: true,
          exportAllData: true,
          exportDelimiter: ';',
          selection: false,
        }}
        localization={i18n.language === 'es' ? tableTranslations : {}}
        editable={{
          onRowUpdate: onRowUpdateLabor,
          onRowAdd: onRowAddLabor,
        }}
        actions={[
          {
            icon: () => <TrendingUpIcon />,
            tooltip: t('general.cost'),
            onClick: handleClickShowDialogCosts,
          },
        ]}
      />
      {showDialogCosts && (
        <CostLabor
          labor={laborShowDialogCosts}
          open={showDialogCosts}
          setOpen={setShowDialogCosts}
        />
      )}
    </>
  )
}
