import React, { useEffect, useState } from 'react'
import { Grid, Typography } from '@material-ui/core'
import BorderStyleIcon from '@material-ui/icons/BorderStyle'
import { useTranslation } from 'react-i18next'
import { cadastreStore } from '../../../stores'
import { URL_SERVER, paxios } from '../../../constants/http'
import { Modal, CheckBox } from '../../../components'

export default function PermitsPlantation({ open, setOpen, user }) {
  const { t } = useTranslation()
  const { plantations } = cadastreStore()
  const [permits, setPermits] = useState([])

  const getPermitsPlantation = async () => {
    try {
      const response = await paxios.get(`${URL_SERVER}/permitsPlantation`)
      if (response.data.status === 'ok') {
        setPermits(response.data.data)
      }
    } catch (error) {}
  }

  useEffect(() => {
    getPermitsPlantation()
  }, [])

  const onChangeCheckBox = (e) => {
    const { name, value, checked } = e.target
    savePermitPlantation(value, name, checked ? 1 : 0)
  }

  const savePermitPlantation = async (user, plantation, state) => {
    try {
      const response = await paxios.put(`${URL_SERVER}/permitsPlantation`, {
        user,
        plantation,
        state,
      })

      if (response.data.status === 'ok') {
        getPermitsPlantation()
      }
    } catch (error) {}
  }

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title={`${t('general.plantationpermits')}: ${user.name} ${
        user.last_name
      }`}
      icon={<BorderStyleIcon />}
      width="40%"
    >
      <Grid container spacing={2}>
        {permits
          .filter((z) => z.user === user.id)
          .map((x) => (
            <React.Fragment key={x.id}>
              <Grid item xs={6} alignContent="center">
                <Typography component="h6" variant="h6">
                  {
                    plantations.find(
                      (y) => String(y.id) === String(x.plantation)
                    ).name
                  }
                </Typography>
              </Grid>
              <Grid item xs={6} alignContent="center">
                <CheckBox
                  name={String(x.plantation)}
                  value={x.user}
                  label={''}
                  checked={x.state === 1}
                  onChange={onChangeCheckBox}
                />
              </Grid>
            </React.Fragment>
          ))}
      </Grid>
    </Modal>
  )
}
