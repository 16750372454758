import { useState, forwardRef, useImperativeHandle } from 'react'
import { Grid, Button, makeStyles } from '@material-ui/core'
import { MultipleSelect } from 'react-select-material-ui'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  textSelectAll: {
    fontSize: 11,
    marginRight: 30
  },
}))

const MultiSelect = forwardRef(
  (
    {
      options,
      label,
      helperText = '',
      msgNoOptionsAvailable = '',
      msgNoOptionsMatchFilter = '',
      onChangeExternal = null,
      valuesExternal = [],
    },
    ref
  ) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const [selected, setSelected] = useState([])

    const handleSelectAll = () => {
      setSelected(options.map(x => x.value))
      onChangeExternal && onChangeExternal(options.map(x => x.value))
    }

    const getSelected = () => {
      return selected
    }

    useImperativeHandle(ref, () => ({
      getSelected,
    }))
    return (
      <div>
        <Grid container justify="flex-end">
          <Grid item>
            <Button size="small" variant="outlined" className={classes.textSelectAll} onClick={handleSelectAll}>
              {`${t('general.select')} ${t('general.all')}`}
            </Button>
          </Grid>
        </Grid>
        <MultipleSelect
          label={label}
          values={onChangeExternal ? valuesExternal : selected}
          options={options}
          helperText={helperText}
          onChange={(values) => {
            setSelected(values)
            onChangeExternal && onChangeExternal(values)
          }}
          SelectProps={{
            isCreatable: false,
            msgNoOptionsAvailable: msgNoOptionsAvailable,
            msgNoOptionsMatchFilter: msgNoOptionsMatchFilter,
          }}
        />
      </div>
    )
  }
)

MultiSelect.displayName = 'MultiSelect'

export default MultiSelect
