import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Swal from 'sweetalert2/src/sweetalert2.js'
import MaterialTable from 'material-table'
import { SubTitle } from '../../../components'
import { URL_SERVER, paxios } from '../../../constants/http'
import tableIcons from '../../../constants/IconsMaterialTable'
import tableTranslations from '../../../constants/tableTranslations'
import IncidentStatisticsSearch from './IncidentStatisticsSearch'
import { userStore } from '../../../stores'

export default function IncidentStatistics() {
  const { t, i18n } = useTranslation()
  const { currentPlantation } = userStore()
  const [reports, setReports] = useState([])
  const [columns, setColumns] = useState([])

  useEffect(() => {
    setColumns([
      { title: t('general.plot'), field: 'plot' },
      { title: t('general.disease'), field: 'disease' },
      { title: t('general.inicialspalms'), field: 'inicials_palms' },
      { title: t('general.activepalms'), field: 'active_palms' },
      { title: t('general.monthcases'), field: 'month' },
      { title: t('general.newscases'), field: 'news' },
      { title: t('general.inmanagement'), field: 'management' },
      { title: t('general.discharge'), field: 'discharge' },
      { title: t('general.eradicated'), field: 'eradicated' },
      {
        title: t('general.accumulatederadication'),
        field: 'accumulated_eradication',
      },
      { title: t('general.accumulatedcases'), field: 'accumulated_cases' },
      { title: t('general.incidencemonth'), field: 'incidence_month' },
      { title: t('general.incidencecurrent'), field: 'incidence_current' },
      {
        title: t('general.incidenceaccumulated'),
        field: 'incidence_accumulated',
      },
      { title: t('general.lethality'), field: 'lethality' },
    ])
  }, [t])

  const validateData = (data) => {
    const errors = {}

    if (data.year === 0 || data.year === '0' || data.year === 'Invalid Date')
      errors.year = t('general.date_invalid')

    return errors
  }

  const dataSearch = (data) => {
    const errors = validateData(data)
    if (Object.keys(errors).length <= 0) {
      const send = {
        ...data,
        plot: data.plot.length > 0 ? data.plot.map((x) => x.id) : 0,
        plantation: currentPlantation.plantation,
        diseases:
          data.diseases.length > 0 ? data.diseases.map((x) => x.id) : 0,
      }
      getReport(send)
    } else {
      const message = Object.keys(errors).reduce((acc, item) => {
        return (acc += `<p>${errors[item]}</p>`)
      }, '')
      Swal.fire(t('general.finished'), `<div>${message}</div>`, 'error')
    }
  }

  const getReport = async (data) => {
    try {
      const response = await paxios.post(
        `${URL_SERVER}/censusDiaseases/getReportIncidencesStatistics`,
        data
      )
      if (response.data.status === 'ok') {
        setReports(response.data.data)
      }
    } catch (error) {
      Swal.fire(t('general.finished'), t('general.errortoquery'), 'error')
    }
  }

  return (
    <>
      <SubTitle text={t('general.incidentstatistics')} />
      <IncidentStatisticsSearch
        titleCensus={t('general.census')}
        handleClickSearch={dataSearch}
      />

      <MaterialTable
        icons={tableIcons}
        columns={columns}
        title={t('general.incidentstatistics')}
        data={reports}
        options={{
          exportButton: true,
          exportAllData: true,
          exportDelimiter: ';',
          selection: false,
        }}
        localization={i18n.language === 'es' ? tableTranslations : {}}
      />
    </>
  )
}
