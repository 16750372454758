import { useState } from 'react'
import { Button } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import SaveIcon from '@material-ui/icons/Save'
import Swal from 'sweetalert2/src/sweetalert2.js'
import { useTranslation } from 'react-i18next'
import InputFile from './InputFile'
import SimpleTable from './SimpleTable'

const styles = {
  containerButtonLoadFile: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10px',
  },
  button: {
    marginBottom: 15,
  },
}

export default function ByExcel({ loadFile, saveData, columns }) {
  const { t } = useTranslation()
  const [selectedFile, setSelectedFile] = useState(null)
  const [message, setMessage] = useState('')
  const [messageError, setMessageError] = useState('')
  const [requestInProgress, setRequestInProgress] = useState(false)
  const [records, setRecords] = useState([])
  const [refInputFile, setRefInputFile] = useState(null)

  const addRefInputFile = (ref) => {
    setRefInputFile(ref)
  }

  const onFileChange = (event) => {
    setSelectedFile(event.target.files[0])
    !event.target.files[0] && setRecords([])
    setMessageError('')
  }

  const handleClickLoadFile = async () => {
    setRequestInProgress(true)
    setMessage(t('general.processingfile'))
    const response = await loadFile(selectedFile)
    if (response.status === 'ok') {
      setRecords(response.data)
      setSelectedFile(null)
      if (refInputFile) {
        refInputFile.current.value = ''
      }
    } else if (response.status === 'error') {
      setRecords([])
      switch (response.message) {
        case 'Incomplete columns':
          setMessageError(t(`general.incompletecolumns`))
          break
        case 'No data':
          setMessageError(t(`general.nodata`))
          break
        default:
          break
      }
    }

    setRequestInProgress(false)
    setMessage('')
  }

  const handleClickSaveData = async () => {
    setRequestInProgress(true)
    setMessage(t('general.savingdata'))

    const response = await saveData(records)
    if (response.data.status === 'ok') {
      Swal.fire(t('general.finished'), t('general.savesuccess'), 'success')
      setRecords([])
    }

    setRequestInProgress(false)
    setMessage('')
  }
  return (
    <>
      <InputFile
        selectedFile={selectedFile}
        onFileChange={onFileChange}
        setRefInputFile={addRefInputFile}
      />
      <div style={styles.containerButtonLoadFile}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<CloudUploadIcon />}
          style={styles.button}
          onClick={handleClickLoadFile}
          disabled={requestInProgress || !selectedFile}
        >
          {t('general.loadfile')}
        </Button>
      </div>
      {message !== '' && <Alert severity="info">{message}</Alert>}
      {messageError !== '' && <Alert severity="error">{messageError}</Alert>}
      {records.length > 0 && <SimpleTable columns={columns} rows={records} />}
      {records.length > 0 && (
        <div style={styles.containerButtonLoadFile}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<SaveIcon />}
            onClick={handleClickSaveData}
          >
            {t('general.save')}
          </Button>
        </div>
      )}
    </>
  )
}
