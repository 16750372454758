import { useState, useEffect, forwardRef, useImperativeHandle } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  makeStyles,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { sanitationStore } from '../stores'
import { CheckBox } from '../components'

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  gridFilter: {
    marginBottom: 10,
  },
}))

const DiseasesSelector = forwardRef(({ accept, single = false }, ref) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { diseases, getDiseases } = sanitationStore()
  const [open, setOpen] = useState(false)
  const [diseasesShow, setDiseasesShow] = useState([])
  const [diseasesSelected, setDiseasesSelected] = useState([])

  useEffect(() => {
    diseases.length <= 0 && getDiseases()
    diseases.length > 0 && setDiseasesShow([...diseases])
  }, [getDiseases, diseases])

  const onChangeCheckBox = (e) => {
    const { value, checked } = e.target
    if (!single) {
      if (checked) {
        setDiseasesSelected((d) => [
          ...d,
          diseasesShow.filter((x) => String(x.id) === value)[0],
        ])
      } else if (!checked) {
        setDiseasesSelected((d) => [...d.filter((x) => String(x.id) !== value)])
      }
    } else {
      setDiseasesSelected([
        diseasesShow.filter((x) => String(x.id) === value)[0],
      ])
    }
  }

  useImperativeHandle(ref, () => ({
    setOpen,
  }))

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="md"
      fullWidth
      aria-labelledby="confirmation-dialog-title"
      open={open}
    >
      <DialogTitle id="confirmation-dialog-title">
        {t('general.diseases')}
      </DialogTitle>

      <DialogContent dividers className={classes.dialogContent}>
        {!single ? (
          diseasesShow.map((x) => (
            <CheckBox
              key={x.id}
              name={'plot'}
              value={x.id}
              label={x.name}
              checked={diseasesSelected.some((y) => y.id === x.id)}
              onChange={onChangeCheckBox}
            />
          ))
        ) : (
          <RadioGroup
            aria-label="plots"
            name="plots"
            value={diseasesSelected.length <= 0 ? 0 : diseasesSelected[0].id}
            onChange={onChangeCheckBox}
          >
            {diseasesShow.map((x) => (
              <FormControlLabel
                key={x.id}
                value={x.id}
                control={<Radio />}
                label={x.name}
              />
            ))}
          </RadioGroup>
        )}
      </DialogContent>

      <DialogActions>
        <Button
          onClick={() => {
            setDiseasesSelected([])
            accept([])
          }}
          color="primary"
        >
          {t('general.clean')}
        </Button>
        <Button onClick={() => setOpen(false)} color="primary">
          {t('general.close')}
        </Button>
        <Button onClick={() => accept([...diseasesSelected])} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  )
})

DiseasesSelector.displayName = 'DiseasesSelector'

export default DiseasesSelector
