import { useState, useEffect } from "react";
import { ButtonGroup, Button } from "@material-ui/core";
import AssessmentIcon from "@material-ui/icons/Assessment";
import AttachmentIcon from '@material-ui/icons/Attachment'
import { useTranslation } from "react-i18next";
import { TitlePage, MenuDropdown } from "../../../components";
import CalendarFertilizationPlan from "./calendar/Home";
import Executions from "./reports/Executions";
import OxidesElements from "./reports/OxidesElements";
import ByExcelPlan from "./ByExcelPlan";
import ByExcelExecutions from "./ByExcelExecutions";

export default function Home() {
  const { t } = useTranslation();
  const [selection, setSelection] = useState(1);
  const [menuReports, setMenuReports] = useState([]);
  useEffect(() => {
    setMenuReports([
      { id: 2, text: t("general.executions") },
      { id: 3, text: `${t("general.oxides")}/${t("general.elements")}` },
    ]);
  }, [t]);

  return (
    <>
      <TitlePage text={`Plan ${t("general.fertilization")}`} />

      <ButtonGroup
        variant="text"
        color="primary"
        aria-label="text primary button group"
        style={{
          width: "100%",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <Button
          style={{ marginRight: 5 }}
          variant={selection === 1 ? "outlined" : ""}
          onClick={() => setSelection(1)}
        >
          {`Plan`}
        </Button>

        <Button
          startIcon={<AttachmentIcon />}
          variant={selection === 4 ? 'outlined' : ''}
          onClick={() => setSelection(4)}
        >
          {`${t('general.loadexcel')} plan`}
        </Button>

        <Button
          startIcon={<AttachmentIcon />}
          variant={selection === 5 ? 'outlined' : ''}
          onClick={() => setSelection(5)}
        >
          {`${t('general.loadexcel')} ${t('general.executions')}`}
        </Button>

        <MenuDropdown
          parentSelected={selection}
          selectParent={setSelection}
          title={t("general.reports")}
          Icon={AssessmentIcon}
          items={menuReports}
        />
      </ButtonGroup>

      {selection === 1 && <CalendarFertilizationPlan />}
      {selection === 2 && <Executions />}
      {selection === 3 && <OxidesElements />}
      {selection === 4 && <ByExcelPlan />}
      {selection === 5 && <ByExcelExecutions />}
    </>
  );
}
