import { useEffect, useState } from 'react'
import Swal from 'sweetalert2/src/sweetalert2.js'
import MaterialTable from 'material-table'
import { useTranslation } from 'react-i18next'
import { severalStore, userStore } from '../../../stores'
import { URL_SERVER, paxios } from '../../../constants/http'
import tableIcons from '../../../constants/IconsMaterialTable'
import tableTranslations from '../../../constants/tableTranslations'

export default function Vehicles() {
  const { t, i18n } = useTranslation()
  const { vehicles, getVehicles } = severalStore()
  const { currentPlantation, user } = userStore()
  const [columns, setColumns] = useState([])

  useEffect(() => {
    const validateEmpty = (rowData, field) => {
      return String(rowData[field]) === '' || String(rowData[field]) === 'NaN'
        ? t('general.noempty')
        : true
    }

    const validateNoZero = (rowData, field) => {
      return String(rowData[field]) === '0' ? t('general.nozero') : true
    }

    const validateNoZeroEmpty = (rowData, field) => {
      if (
        validateNoZero(rowData, field) === true &&
        validateEmpty(rowData, field) === true
      ) {
        return true
      } else {
        return `${
          validateNoZero(rowData, field) === true
            ? ''
            : validateNoZero(rowData, field)
        } ${
          validateEmpty(rowData, field) === true
            ? ''
            : ', ' + validateEmpty(rowData, field)
        }`
      }
    }
    setColumns([
      { title: 'ID', field: 'id', editable: 'never' },
      {
        title: t('general.plate'),
        field: 'plate',
        validate: (rowData) => validateEmpty(rowData, 'plate'),
      },
      {
        title: t('general.driver'),
        field: 'driver',
        validate: (rowData) => validateEmpty(rowData, 'driver'),
      },
      {
        title: t('general.capacity'),
        field: 'capacity',
        type: 'numeric',
        initialEditValue: 0,
        validate: (rowData) => validateNoZeroEmpty(rowData, 'capacity'),
      },
      {
        title: t('general.observations'),
        field: 'observations',
      },
    ])
  }, [t])

  const createVehicles = async (data) => {
    try {
      const response = await paxios.post(`${URL_SERVER}/vehicles`, {
        ...data,
        user: user.id,
        plantation: currentPlantation.plantation,
      })
      if (response.data.status === 'ok') {
        Swal.fire(t('general.finished'), t('general.savesuccess'), 'success')
        getVehicles()
      } else {
        Swal.fire(t('general.finished'), response.data.message, 'error')
      }
    } catch (error) {
      Swal.fire(t('general.finished'), t('general.datawasnotsaved'), 'error')
    }
  }

  const onRowAddVehicles = (newData) =>
    new Promise(async (resolve) => {
      await createVehicles(newData)
      resolve()
    })

  const updateVehicles = async (data) => {
    try {
      const response = await paxios.put(`${URL_SERVER}/vehicles/${data.id}`, {
        ...data,
        user: user.id,
        plantation: currentPlantation.plantation,
      })
      if (response.data.status === 'ok') {
        Swal.fire(t('general.finished'), t('general.savesuccess'), 'success')
        getVehicles()
      } else {
        Swal.fire(t('general.finished'), t('general.datawasnotsaved'), 'error')
      }
    } catch (error) {
      Swal.fire(t('general.finished'), t('general.datawasnotsaved'), 'error')
    }
  }

  const onRowUpdateVehicles = (newData, oldData) =>
    new Promise(async (resolve) => {
      await updateVehicles(newData)
      resolve()
    })

  return (
    <>
      <MaterialTable
        icons={tableIcons}
        columns={columns}
        title={`${t('general.vehicle')}s`}
        data={vehicles.map((x) => ({ ...x, ti: new Date() }))}
        options={{
          exportButton: true,
          exportAllData: true,
          exportDelimiter: ';',
          selection: false,
        }}
        localization={i18n.language === 'es' ? tableTranslations : {}}
        editable={{
          onRowUpdate: onRowUpdateVehicles,
          onRowAdd: onRowAddVehicles,
        }}
      />
    </>
  )
}
