import { useState, useEffect } from 'react'
import { ButtonGroup, Button } from '@material-ui/core'
import AssignmentIcon from '@material-ui/icons/Assignment'
import AssessmentIcon from '@material-ui/icons/Assessment'
import AttachmentIcon from '@material-ui/icons/Attachment'
import Ballot from '@material-ui/icons/Ballot'
import { useTranslation } from 'react-i18next'
import { userStore } from '../../stores'
import { ROUTES } from '../../constants/routes'
import { TitlePage, MenuDropdown } from '../../components'
import Census from './Census'
import IncidentStatistics from './reports/IncidentStatistics'
import CompleteHistory from './reports/CompleteHistory'
import LoadExcel from './ByExcel'
import OrderLaborGenerar from './orderLabor/Generate'
import OrderLaborAdministration from './orderLabor/Administration'
import NewCases from './reports/NewCases'
import Recidivist from './reports/Recidivist'


export default function Diseases() {
  const { t } = useTranslation()
  const { setUser } = userStore()
  const [selection, setSelection] = useState(1)
  const [menuOrderLabor, setMenuOrderLabor] = useState([])
  const [menuReports, setMenuReports] = useState([])

  useEffect(() => {
    setMenuOrderLabor([
      { id: 5, text: t('general.generate') },
      { id: 6, text: t('general.administration') },
    ])

    setMenuReports([
      { id: 2, text: t('general.incidentstatistics') },
      { id: 3, text: t('general.completehistory') },
      { id: 7, text: t('general.new_cases') },
      { id: 8, text: `${t('general.recidivist')}s` },
    ])
  },[t])

  useEffect(() => {
    setUser({ currentUrl: ROUTES.diseases })
  }, [setUser])

  return (
    <>
      <TitlePage text={`${t('general.diseases')}`} />
      <ButtonGroup
        variant="text"
        color="primary"
        aria-label="text primary button group"
        style={{
          width: '100%',
          justifyContent: 'center',
          marginBottom: '20px',
        }}
      >
        <Button
          startIcon={<AssignmentIcon />}
          variant={selection === 1 ? 'outlined' : ''}
          onClick={() => setSelection(1)}
        >
          {t('general.censuses')}
        </Button>
        <Button
          startIcon={<AttachmentIcon />}
          variant={selection === 4 ? 'outlined' : ''}
          onClick={() => setSelection(4)}
        >
          {t('general.loadexcel')}
        </Button>
        <MenuDropdown
          parentSelected={selection}
          selectParent={setSelection}
          title={`${t('general.order')} ${t('general.labor')}`}
          Icon={Ballot}
          items={menuOrderLabor}
        />
        <MenuDropdown
          parentSelected={selection}
          selectParent={setSelection}
          title={t('general.reports')}
          Icon={AssessmentIcon}
          items={menuReports}
        />
      </ButtonGroup>

      {selection === 1 && <Census />}
      {selection === 2 && <IncidentStatistics />}
      {selection === 3 && <CompleteHistory />}
      {selection === 4 && <LoadExcel />}
      {selection === 5 && <OrderLaborGenerar />}
      {selection === 6 && <OrderLaborAdministration />}
      {selection === 7 && <NewCases />}
      {selection === 8 && <Recidivist />}
    </>
  )
}
