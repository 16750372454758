import { Divider } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import SubTitle from '../../../components/SubTitle'
import Travels from './Travels'
import Details from './Details'

export default function Home() {
    const { t } = useTranslation()
    return (
        <>
          <SubTitle text={t('general.travels')} />  
          <Travels />
          <Divider variant="middle" style={{marginTop: 20, marginBottom: 20}} />
          <SubTitle text={t('general.detailstravel')} />  
          <Details />
        </>
    )
}
