import create from 'zustand'
import { devtools } from 'zustand/middleware'
import { URL_SERVER, paxios } from '../constants/http'
import userStore from './userStore'

import { localStorageSelect, localStorageCreateUpdate } from '../localStorage'

const getWorkersServer = async (set, plantation) => {
  try {
    const workers = await paxios.get(`${URL_SERVER}/workers/${plantation}`)
    localStorageCreateUpdate('workers', workers.data.data)
    set({ workers: workers.data.data })
  } catch (error) {
    set({ error })
  }
}

const getVehiclesServer = async (set, plantation) => {
  try {
    const vehicles = await paxios.get(`${URL_SERVER}/vehicles/${plantation}`)
    localStorageCreateUpdate('vehicles', vehicles.data.data)
    set({ vehicles: vehicles.data.data })
  } catch (error) {
    set({ error })
  }
}

const getQuadrillesServer = async (set, plantation) => {
  try {
    const quadrilles = await paxios.get(
      `${URL_SERVER}/quadrilles/${plantation}`
    )
    localStorageCreateUpdate('quadrilles', quadrilles.data.data)
    set({ quadrilles: quadrilles.data.data })
  } catch (error) {
    set({ error })
  }
}

const getCompaniesServer = async (set, plantation) => {
  try {
    const companies = await paxios.get(`${URL_SERVER}/companies`)
    localStorageCreateUpdate('companies', companies.data.data)
    set({ companies: companies.data.data })
  } catch (error) {
    set({ error })
  }
}

const store = (set) => ({
  workers: [],
  vehicles: [],
  quadrilles: [],
  companies: [],
  errors: null,
  getWorkers: async () => {
    const currentPlantation = userStore.getState().currentPlantation
    await getWorkersServer(set, currentPlantation.plantation)
  },
  getVehicles: async () => {
    const currentPlantation = userStore.getState().currentPlantation
    await getVehiclesServer(set, currentPlantation.plantation)
  },
  getQuadrilles: async () => {
    const currentPlantation = userStore.getState().currentPlantation
    await getQuadrillesServer(set, currentPlantation.plantation)
  },
  getCompanies: async () => {
    const currentPlantation = userStore.getState().currentPlantation
    await getCompaniesServer(set, currentPlantation.plantation)
  },
  loadLocalStorageSeveral: async () => {
    const workersLocal = localStorageSelect('workers')
    if (workersLocal === null) {
      const currentPlantation = userStore.getState().currentPlantation
      await getWorkersServer(set, currentPlantation.plantation)
    } else {
      set({ workers: workersLocal })
    }

    const vehiclesLocal = localStorageSelect('vehicles')
    if (vehiclesLocal === null) {
      const currentPlantation = userStore.getState().currentPlantation
      await getVehiclesServer(set, currentPlantation.plantation)
    } else {
      set({ vehicles: vehiclesLocal })
    }

    const quadrillesLocal = localStorageSelect('quadrilles')
    if (quadrillesLocal === null) {
      const currentPlantation = userStore.getState().currentPlantation
      await getQuadrillesServer(set, currentPlantation.plantation)
    } else {
      set({ quadrilles: quadrillesLocal })
    }

    const companiesLocal = localStorageSelect('companies')
    if (companiesLocal === null) {
      const currentPlantation = userStore.getState().currentPlantation
      await getCompaniesServer(set, currentPlantation.plantation)
    } else {
      set({ companies: companiesLocal })
    }
  },
})

const severalStore = create(devtools(store, 'several'))

export default severalStore
