import { useState, useRef, useEffect } from "react";
import dayjs from "dayjs";
import {
  Grid,
  Paper,
  Typography,
  makeStyles,
  IconButton,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import BorderStyleIcon from "@material-ui/icons/BorderStyle";
import MaterialTable, { MTableToolbar } from "material-table";
import tableIcons from "../../../constants/IconsMaterialTable";
import tableTranslations from "../../../constants/tableTranslations";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2/src/sweetalert2.js";
import { URL_SERVER, paxios } from "../../../constants/http";
import {
  PlotsSelector,
  Button,
  DiseasesSelector,
  SubTitle,
  DatePickerRange,
  Modal,
} from "../../../components";
import { userStore } from "../../../stores";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  gridContainer: {
    marginBottom: 10,
  },
}));

export default function Recidivist() {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const { currentPlantation } = userStore();
  const plotsSelectorRef = useRef(null);
  const diseasesSelectorRef = useRef(null);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [reports, setReports] = useState([]);
  const [details, setDetails] = useState([]);
  const [detailsShow, setDetailsShow] = useState([]);
  const [columns, setColumns] = useState([]);
  const [columnsDetails, setColumnsDetails] = useState([]);
  const [showModalDetails, setShowModalDetails] = useState(false);
  const [queryData, setQueryData] = useState({
    plots: [],
    disease: [],
    initialDate: null,
    endDate: null,
  });

  useEffect(() => {
    setColumns([
      { title: t("general.plot"), field: "plot_name" },
      { title: t("general.month"), field: "month" },
      { title: t("general.quantity"), field: "quantity" },
    ]);

    setColumnsDetails([
      { title: t("general.census"), field: "census" },
      { title: t("general.date"), field: "date" },
      { title: t("general.plot"), field: "plot_name" },
      { title: t("general.line"), field: "line" },
      { title: t("general.palm"), field: "palm" },
      { title: t("general.disease"), field: "disease_name" },
      { title: t("general.event"), field: "event_name" },
      { title: t("general.worker"), field: "worker_name" },
      { title: t("general.observations"), field: "observations" },
    ]);
  }, [t]);

  const handleSelectPlot = (plot) => {
    setQueryData((d) => ({ ...d, plots: plot.length > 0 ? plot : [] }));
    plotsSelectorRef.current !== null &&
      plotsSelectorRef.current.setOpen(false);
  };

  const handleSelectDiseases = (diseases) => {
    setQueryData((d) => ({
      ...d,
      disease: diseases.length > 0 ? diseases : 0,
    }));
    diseasesSelectorRef.current !== null &&
      diseasesSelectorRef.current.setOpen(false);
  };

  const handleDateRangeChange = (e) => {
    const { name, value } = e.target;
    setQueryData((d) => ({ ...d, [name]: value }));
  };

  const dataSearch = () => {
    const send = {
      ...queryData,
      plots: queryData.plots.length > 0 ? queryData.plots.map((x) => x.id) : [],
      dates:
        queryData.initialDate !== null && queryData.endDate !== null
          ? `${dayjs(queryData.initialDate).format("YYYY-MM-DD")},${dayjs(
              queryData.endDate
            ).format("YYYY-MM-DD")}`
          : "",
      disease: queryData.disease.length > 0 ? queryData.disease[0].id : 0,
      plantation: currentPlantation.plantation,
    };

    return send;
  };

  const getReport = async (data) => {
    try {
      setRequestInProgress(true);
      const response = await paxios.post(
        `${URL_SERVER}/censusDiaseases/getReportRecidivist`,
        data
      );
      //if (response.data.status === "ok") {
      setReports(response.data.group);
      setDetails(response.data.details);
      setRequestInProgress(false);
      //}
    } catch (error) {
      Swal.fire(t("general.finished"), t("general.errortoquery"), "error");
      setRequestInProgress(false);
    }
  };

  const handleClickSearch = () => {
    const data = dataSearch();
    getReport(data);
  };

  const handleRowClick = (e, row) => {
    const d = details.filter(
      (x) => x.plot_name === row.plot_name && x.month_number === row.month
    );

    setDetailsShow(d);
    setShowModalDetails(true);
  };

  const handleClickDetails = () => {
    setDetailsShow(details);
    setShowModalDetails(true);
  };
  return (
    <>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        spacing={2}
        className={classes.gridContainer}
      >
        <SubTitle text={`${t("general.recidivist")}s`} />
        <Grid item xs={12} md={4}>
          <Paper component="form" className={classes.root}>
            <Typography variant="subtitle1">
              {queryData.plots.length > 0
                ? queryData.plots.reduce((acc, item) => {
                    return (acc += acc !== "" ? "," + item.name : item.name);
                  }, "")
                : `${t("general.select")} ${t("general.plot")}`}
            </Typography>
            <IconButton
              type="button"
              aria-label="search"
              className={classes.iconButton}
              onClick={() =>
                plotsSelectorRef.current !== null &&
                plotsSelectorRef.current.setOpen(true)
              }
            >
              <SearchIcon />
            </IconButton>
          </Paper>
          <PlotsSelector
            accept={handleSelectPlot}
            ref={plotsSelectorRef}
            single={false}
          />
        </Grid>

        <Grid item xs={12} md={5}>
          <Paper component="form" className={classes.root}>
            <Typography variant="subtitle1">
              {queryData.disease.length > 0
                ? queryData.disease.reduce((acc, item) => {
                    return (acc += acc !== "" ? "," + item.name : item.name);
                  }, "")
                : `${t("general.select")} ${t("general.disease")}`}
            </Typography>
            <IconButton
              type="button"
              aria-label="search"
              className={classes.iconButton}
              onClick={() =>
                diseasesSelectorRef.current !== null &&
                diseasesSelectorRef.current.setOpen(true)
              }
            >
              <SearchIcon />
            </IconButton>
          </Paper>
          <DiseasesSelector
            accept={handleSelectDiseases}
            ref={diseasesSelectorRef}
            single={true}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <DatePickerRange
            initialDate={queryData.initialDate}
            endDate={queryData.endDate}
            handleDateChange={handleDateRangeChange}
          />
        </Grid>

        <Button
          icon={<SearchIcon />}
          handleButtonClick={handleClickSearch}
          text={t("general.search")}
          disabled={
            queryData.initialDate === null ||
            queryData.endDate === null ||
            queryData.disease.length === 0
          }
          loading={requestInProgress}
        />
      </Grid>

      <MaterialTable
        icons={tableIcons}
        columns={columns}
        title={`${t("general.recidivist")}s`}
        data={reports}
        onRowClick={handleRowClick}
        options={{
          exportButton: true,
          exportAllData: true,
          exportDelimiter: ";",
          selection: false,
        }}
        localization={i18n.language === "es" ? tableTranslations : {}}
        components={{
          Toolbar: (props) => (
            <div>
              <MTableToolbar {...props} />
              <div style={{ padding: "0px 10px" }}>
                {reports.length > 0 && (
                  <Button
                    icon={<SearchIcon />}
                    handleButtonClick={handleClickDetails}
                    text={t("general.details")}
                  />
                )}
              </div>
            </div>
          ),
        }}
      />

      <Modal
        open={showModalDetails}
        setOpen={setShowModalDetails}
        title={`${t("general.details")}`}
        icon={<BorderStyleIcon />}
        width="70%"
      >
        <MaterialTable
          icons={tableIcons}
          columns={columnsDetails}
          title={''}
          data={detailsShow}
          options={{
            exportButton: true,
            exportAllData: true,
            exportDelimiter: ";",
            selection: false,
          }}
          localization={i18n.language === "es" ? tableTranslations : {}}
        />
      </Modal>
    </>
  );
}
