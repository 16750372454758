import {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Paper,
  Typography,
  makeStyles,
  IconButton,
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import { Alert } from '@material-ui/lab'
import dayjs from 'dayjs'
import Swal from 'sweetalert2/src/sweetalert2.js'
import { useTranslation } from 'react-i18next'
import { laborsStore, userStore, cadastreStore } from '../../../stores'
import { URL_SERVER, paxios } from '../../../constants/http'
import {
  Button,
  PlotsSelector,
  FormInput,
  Select,
  DatePickerRange,
  DeleteConfirmation,
} from '../../../components'
import CreateEditExecution from './CreateEditExecution'
import ShowExecutions from './ShowExecutions'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  gridContainer: {
    marginBottom: 10,
  },
  selectPlot: {
    margin: 'auto 0',
  },
  dialogContent: {
    /* display: 'flex',
    flexDirection: 'row', */
  },
  executionsButtonWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
}))

const eventInitial = {
  id: null,
  title: '',
  labor: 0,
  start_date: new Date(),
  end_date: new Date(),
  cost: 0,
  plot: [],
  cost_center: 0,
  quantity: 0,
  people: 0,
  observations: '',
  actualization: '',
  plantation: 0,
  is_schedule: true,
}

let errorRequirePlot = false
let errorMoreQuantity = false

const CreateEditEvent = forwardRef(
  ({ event, dateNew, searchRef, search, setEventSelectedCalendar }, ref) => {
    const { t } = useTranslation()
    const plotsSelectorRef = useRef(null)
    const { currentPlantation, user } = userStore()
    const { labors, costCenters } = laborsStore()
    const { plots } = cadastreStore()
    const classes = useStyles()
    const [open, setOpen] = useState(false)
    const [openRegister, setOpenRegister] = useState(false)
    const [openShow, setOpenShow] = useState(false)
    const [palmsPlot, setPalmsPlot] = useState(0)
    const [openDelete, setOpenDelete] = useState(false)
    const [eventActive, setEventActive] = useState({
      ...eventInitial,
      start_date:
        dateNew !== '' ? new Date(dateNew + '  00:00:00') : new Date(),
      end_date: dateNew !== '' ? new Date(dateNew + '  00:00:00') : new Date(),
    })

    useEffect(() => {
      if (event) {
        setEventActive(
          event.publicId
            ? {
                ...event,
                id: event.publicId,
                ...event.extendedProps,
                plot: event.extendedProps.plot
                  ? plots.filter((x) => x.id === event.extendedProps.plot)
                  : [],
                start_date: new Date(
                  event.extendedProps.start_date + '  00:00:00'
                ),
                end_date: new Date(event.extendedProps.end_date + '  00:00:00'),
              }
            : { ...eventInitial }
        )
        setPalmsPlot(event.extendedProps.quantity)
      }else {
        setEventActive({ ...eventInitial })
      }
    }, [event, plots])

    useEffect(() => {
      if (
        String(eventActive.labor) !== '0' &&
        (String(eventActive.cost) === '' || String(eventActive.cost) === '0')
      ) {
        setEventActive((d) => ({
          ...d,
          cost: labors.find((x) => x.id === d.labor)?.cost,
        }))
      }
    }, [eventActive.labor, eventActive.cost, labors])

    useEffect(() => {
      ;(async () => {
        const labor = labors.find((x) => x.id === eventActive.labor)
        if (labor !== undefined && eventActive.plot.length > 0) {
          if (labor.origin_quantity === 1) {
            const response = await paxios.get(
              `${URL_SERVER}/palms/getQuantityActivePalmsPlot/${eventActive.plot[0].id}`
            )
            if (response.data.status === 'ok') {
              if (
                String(eventActive.quantity) === '0' ||
                String(eventActive.quantity) === ''
              ) {
                setEventActive((d) => ({ ...d, quantity: response.data.data }))
              }
              setPalmsPlot(response.data.data)
            } else if (response.data.status === 'error') {
              setEventActive((d) => ({ ...d, quantity: 0 }))
              setPalmsPlot(0)
            }
          } else {
            palmsPlot !== 0 && setPalmsPlot(0)
          }
        }
      })()
    }, [
      eventActive.labor,
      eventActive.plot,
      labors,
      palmsPlot,
      eventActive.quantity,
    ])

    useEffect(() => {
      if (dateNew !== '') {
        setEventActive((d) => ({
          ...d,
          start_date:
            dateNew !== '' ? new Date(dateNew + '  00:00:00') : new Date(),
          end_date:
            dateNew !== '' ? new Date(dateNew + '  00:00:00') : new Date(),
        }))
      }
    }, [dateNew])

    useImperativeHandle(ref, () => ({
      setOpen,
    }))

    const handleSelectPlot = (plot) => {
      setEventActive((d) => ({ ...d, plot: plot.length > 0 ? plot : [] }))
      plotsSelectorRef.current !== null &&
        plotsSelectorRef.current.setOpen(false)
    }

    const handleOnChange = (e) => {
      let { name, value } = e.target
      if (name === 'initialDate') name = 'start_date'
      if (name === 'endDate') name = 'end_date'
      setEventActive((d) => ({ ...d, [name]: value }))
    }

    const prepareData = () => {
      const send = {
        ...eventActive,
        start_date:
          eventActive.start_date !== null
            ? dayjs(eventActive.start_date).format('YYYY-MM-DD')
            : null,
        end_date:
          eventActive.end_date !== null
            ? dayjs(eventActive.end_date).format('YYYY-MM-DD')
            : null,
        plot: eventActive.plot.length > 0 ? eventActive.plot[0].id : null,
        plantation: currentPlantation.plantation,
      }

      return send
    }

    const saveData = async () => {
      const send = prepareData()
      if (send.id) {
        updateSchedule(send)
      } else {
        createSchedule(send)
      }
    }

    const createSchedule = async (send) => {
      try {
        const response = await paxios.post(`${URL_SERVER}/laborsSchedule`, send)
        if (response.data.status === 'ok') {
          Swal.fire(t('general.finished'), t('general.savesuccess'), 'success')
          search(searchRef.current.getData())
          setEventActive({ ...eventInitial })
          setOpen(false)
        } else {
          Swal.fire(
            t('general.finished'),
            t('general.datawasnotsaved'),
            'error'
          )
        }
      } catch (error) {
        Swal.fire(t('general.finished'), t('general.datawasnotsaved'), 'error')
      }
    }

    const updateSchedule = async (send) => {
      try {
        const response = await paxios.put(
          `${URL_SERVER}/laborsSchedule/${send.id}`,
          send
        )
        if (response.data.status === 'ok') {
          Swal.fire(t('general.finished'), t('general.savesuccess'), 'success')
          search(searchRef.current.getData())
        } else {
          Swal.fire(
            t('general.finished'),
            t('general.datawasnotsaved'),
            'error'
          )
        }
      } catch (error) {
        Swal.fire(t('general.finished'), t('general.datawasnotsaved'), 'error')
      }
    }

    const handleClickSave = () => {
      saveData()
    }

    const validateButtonSaveActive = () => {
      if (eventActive.cost_center === '0' || eventActive.cost_center === 0)
        return true
      if (eventActive.labor === '0' || eventActive.labor === 0) return true
      if (eventActive.quantity === '' || String(eventActive.quantity) === '0')
        return true

      if (eventActive.cost === '' || String(eventActive.cost) === '0')
        return true

      if (eventActive.people === '' || String(eventActive.people) === '0')
        return true

      const labor = labors.find((x) => x.id === eventActive.labor)
      if (labor.requires_plot === 1) {
        if (eventActive.plot.length <= 0) {
          errorRequirePlot = true
          return true
        } else {
          errorRequirePlot = false
        }
      } else {
        errorRequirePlot = false
      }

      if (labor.origin_quantity === 1 && labor.requires_plot === 1) {
        if (eventActive.quantity > palmsPlot) {
          errorMoreQuantity = true
          return true
        } else {
          errorMoreQuantity = false
        }
      } else {
        errorMoreQuantity = false
      }

      return false
    }

    const handleClickRegister = () => {
      setOpenRegister(true)
    }

    const handleClickShow = () => {
      setOpenShow(true)
    }

    const handleClickDelete = () => {
      setOpenDelete(true)
    }

    const deleteSchedule = async (schedule) => {
      try {
        const response = await paxios.delete(
          `${URL_SERVER}/laborsSchedule/${schedule.id}`
        )
        if (response.data.status === 'ok') {
          Swal.fire(t('general.finished'), t('general.savesuccess'), 'success')
          search(searchRef.current.getData())
          setOpenDelete(false)
          setEventSelectedCalendar(null)
          setEventActive({ ...eventInitial })
          setOpen(false)
        } else {
          Swal.fire(
            t('general.finished'),
            t('general.datawasnotsaved'),
            'error'
          )
        }
      } catch (error) {
        Swal.fire(t('general.finished'), t('general.datawasnotsaved'), 'error')
      }
    }
    return (
      <>
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          maxWidth="md"
          fullWidth
          aria-labelledby="confirmation-dialog-title"
          open={open}
        >
          <DialogTitle id="confirmation-dialog-title">
            {event?.publicId ? t('general.edit') : t('general.new')}
          </DialogTitle>
          <DialogContent dividers className={classes.dialogContent}>
            {event?.publicId && (
              <Typography variant="h6" color={'primary'} align="center">
                {`${t('general.programmed')}: ${eventActive.quantity} / ${t(
                  'general.completed'
                )}: ${eventActive.completed} / ${t('general.remaining')}: ${
                  eventActive.quantity - eventActive.completed
                }`}
              </Typography>
            )}
            <Grid
              container
              justify="center"
              spacing={2}
              className={classes.gridContainer}
            >
              <Grid item xs={12} md={6} className={classes.selectPlot}>
                <Paper component="form" className={classes.root}>
                  <Typography variant="subtitle1">
                    {eventActive.plot !== 0
                      ? eventActive.plot.reduce((acc, item) => {
                          return (acc +=
                            acc !== '' ? ',' + item.name : item.name)
                        }, '')
                      : `${t('general.select')} ${t('general.plot')}`}
                  </Typography>
                  <IconButton
                    type="button"
                    aria-label="search"
                    className={classes.iconButton}
                    onClick={() =>
                      plotsSelectorRef.current !== null &&
                      plotsSelectorRef.current.setOpen(true)
                    }
                  >
                    <SearchIcon />
                  </IconButton>
                </Paper>
                <PlotsSelector
                  accept={handleSelectPlot}
                  ref={plotsSelectorRef}
                  single={true}
                />
                {errorRequirePlot && (
                  <Alert severity="error">
                    {t('general.error_require_plot')}
                  </Alert>
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                <Select
                  name="cost_center"
                  text={t('general.cost_centers')}
                  width={'100%'}
                  items={costCenters}
                  defaultOption0={true}
                  value={eventActive.cost_center}
                  handleChange={handleOnChange}
                  required={true}
                  error={
                    eventActive.cost_center === '0' ||
                    eventActive.cost_center === 0
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Select
                  name="labor"
                  text={t('general.labor')}
                  width={'100%'}
                  items={labors}
                  defaultOption0={true}
                  value={eventActive.labor}
                  handleChange={handleOnChange}
                  required={true}
                  error={eventActive.labor === '0' || eventActive.labor === 0}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormInput
                  name={'quantity'}
                  title={t('general.quantity')}
                  value={eventActive.quantity}
                  onChange={handleOnChange}
                  type={'number'}
                  error={
                    eventActive.quantity === '' ||
                    String(eventActive.quantity) === '0'
                  }
                  errorMessage={`${t('general.value_invalid')}`}
                />
                {errorMoreQuantity && (
                  <Alert severity="error">
                    {t('general.error_register_more_quantity')}
                  </Alert>
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                <FormInput
                  name={'cost'}
                  title={t('general.cost')}
                  value={eventActive.cost}
                  onChange={handleOnChange}
                  type="number"
                  error={
                    eventActive.cost === '' || String(eventActive.cost) === '0'
                  }
                  errorMessage={`${t('general.value_invalid')}`}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormInput
                  name={'people'}
                  title={t('general.people')}
                  value={eventActive.people}
                  onChange={handleOnChange}
                  type="number"
                  error={
                    eventActive.people === '' ||
                    String(eventActive.people) === '0'
                  }
                  errorMessage={`${t('general.value_invalid')}`}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <DatePickerRange
                  initialDate={eventActive.start_date}
                  endDate={eventActive.end_date}
                  handleDateChange={handleOnChange}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <FormInput
                  name={'observations'}
                  title={t('general.observations')}
                  value={eventActive.observations}
                  onChange={handleOnChange}
                  multiline={true}
                />
              </Grid>
              {event?.publicId && (
                <Grid
                  item
                  xs={12}
                  md={12}
                  className={classes.executionsButtonWrapper}
                >
                  <Button
                    handleButtonClick={handleClickShow}
                    text={`${t('general.show')} ${t('general.executions')}`}
                  />
                  <Button
                    handleButtonClick={handleClickRegister}
                    text={`${t('general.register')} ${t('general.execution')}`}
                  />
                  {user.profile === 1 && (
                    <Button
                      handleButtonClick={handleClickDelete}
                      text={`${t('general.delete')}`}
                      color={'secondary'}
                    />
                  )}
                </Grid>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              handleButtonClick={() => {
                setEventActive({ ...eventInitial })
                setOpen(false)
              }}
              text={t('general.close')}
            />
            <Button
              handleButtonClick={handleClickSave}
              text={t('general.save')}
              disabled={validateButtonSaveActive()}
            />
          </DialogActions>
        </Dialog>
        {openRegister && (
          <CreateEditExecution
            schedule={eventActive}
            open={openRegister}
            setOpen={setOpenRegister}
            searchRef={searchRef}
            search={search}
          />
        )}
        {openShow && (
          <ShowExecutions
            schedule={eventActive}
            open={openShow}
            setOpen={setOpenShow}
            searchRef={searchRef}
            search={search}
          />
        )}

        {openDelete && (
          <DeleteConfirmation
            open={openDelete}
            setOpen={setOpenDelete}
            item={eventActive}
            handleOk={deleteSchedule}
          />
        )}
      </>
    )
  }
)

CreateEditEvent.displayName = 'CreateEditEvent'

export default CreateEditEvent
