import { useState, useRef } from "react";
import {
  Grid,
  makeStyles,
  Typography,
  IconButton,
  Paper,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { useTranslation } from "react-i18next";
import { nutritionStore, userStore } from "../../../../stores";
import {
  Button,
  PlotsSelector,
  MultiSelect,
  CheckBox,
} from "../../../../components";

let years = [];
for (let index = 0; index <= 15; index++) {
  years.push({
    value: index + 2015,
    label: index + 2015,
  });
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  gridContainer: {
    marginBottom: 10,
  },
  selectPlot: {
    margin: "auto 0",
  },
  dialogContent: {
    display: "flex",
    flexDirection: "column",
  },
}));

export default function SearchReports({ getData, discriminated = false }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const plotsSelectorRef = useRef(null);
  const { fertilizers } = nutritionStore();
  const { currentPlantation } = userStore();
  const [discriminatedValue, setDiscriminatedValue] = useState(0);
  const [queryData, setQueryData] = useState({
    years: [],
    products: [],
    plots: [],
  });

  const handleSelectPlot = (plot) => {
    setQueryData((d) => ({ ...d, plots: plot.length > 0 ? plot : [] }));
    plotsSelectorRef.current !== null &&
      plotsSelectorRef.current.setOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setQueryData((d) => ({ ...d, [name]: value }));
  };

  const validateBottomSearch = () => {
    if (
      queryData.years.length <= 0 &&
      queryData.products.length <= 0 &&
      queryData.plots.length <= 0
    ) {
      return true;
    }

    return false;
  };

  const prepareData = () => {
    return {
      ...queryData,
      plots: queryData.plots.map((x) => x.id),
      plantation: currentPlantation.plantation,
      discriminated: discriminatedValue,
    };
  };

  const handleClickButtonSearch = () => {
    const send = prepareData();
    getData(send);
  };

  const onChangeCheckBox = (e) => {
    const { checked } = e.target;
    if (checked) {
      setDiscriminatedValue(1);
    } else if (!checked) {
      setDiscriminatedValue(0);
    }
  };
  return (
    <>
      <Grid container justify="center">
        <Grid item xs={12} md={4} className={classes.selectPlot}>
          <Paper component="form" className={classes.root}>
            <Typography variant="subtitle1">
              {queryData.plots.length !== 0
                ? queryData.plots.reduce((acc, item) => {
                    return (acc += acc !== "" ? "," + item.name : item.name);
                  }, "")
                : `${t("general.select")} ${t("general.plot")}`}
            </Typography>
            <IconButton
              type="button"
              aria-label="search"
              className={classes.iconButton}
              onClick={() =>
                plotsSelectorRef.current !== null &&
                plotsSelectorRef.current.setOpen(true)
              }
            >
              <SearchIcon />
            </IconButton>
          </Paper>
          <PlotsSelector accept={handleSelectPlot} ref={plotsSelectorRef} />
        </Grid>
        <Grid item xs={12} md={4}>
          <MultiSelect
            options={years}
            label={`${t("general.year")}`}
            onChangeExternal={(values) =>
              handleChange({ target: { name: "years", value: values } })
            }
            valuesExternal={queryData.years}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <MultiSelect
            options={fertilizers.map((x) => ({
              value: x.id,
              label: x.common_name,
            }))}
            label={`${t("general.product")}`}
            onChangeExternal={(values) =>
              handleChange({ target: { name: "products", value: values } })
            }
            valuesExternal={queryData.products}
          />
        </Grid>
      </Grid>
      {discriminated && (
        <Grid container justify="center">
          <CheckBox
            name={"discrimiated"}
            value={discriminatedValue}
            label={`${t("general.discriminated")} ${t("general.product")}`}
            checked={discriminatedValue === 1}
            onChange={onChangeCheckBox}
          />
        </Grid>
      )}

      <Button
        handleButtonClick={handleClickButtonSearch}
        text={t("general.search")}
        disabled={validateBottomSearch()}
      />
    </>
  );
}
