import { useState, useEffect, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { ButtonGroup, Button, MenuItem, Select } from '@material-ui/core'
import MaterialTable from 'material-table'
import AppsIcon from '@material-ui/icons/Apps'
import CropFreeIcon from '@material-ui/icons/CropFree'
import AttachmentIcon from '@material-ui/icons/Attachment'
import dayjs from 'dayjs'
import Swal from 'sweetalert2/src/sweetalert2.js'
import { useTranslation } from 'react-i18next'
import { userStore, cadastreStore } from '../../stores'
import { ROUTES } from '../../constants/routes'
import { URL_SERVER, paxios, downloadFile } from '../../constants/http'
import tableIcons from '../../constants/IconsMaterialTable'
import tableTranslations from '../../constants/tableTranslations'
import {TitlePage} from '../../components'
import ModalLoadExcel from './ModalLoadExcel'

export default function Plots() {
  const history = useHistory()
  const { setUser, currentPlantation } = userStore()
  const { plots, getPlots, sectors, materials } = cadastreStore()
  const { t, i18n } = useTranslation()
  const [columns, setColumns] = useState([])
  const [materialsSelector, setMaterialsSelector] = useState({})
  const [rowSelec, setRowSelec] = useState([])
  const [showModalLoadExcel, setShowModalLoadExcel] = useState(false)
  const [levels, setLevels] = useState([])

  useEffect(() => {
    setUser({ currentUrl: ROUTES.plots })
  }, [setUser])

  useEffect(() => {
    const materialsTemp = {}
    for (const material of materials) {
      materialsTemp[material.id] = material.name
    }
    setMaterialsSelector(materialsTemp)
  }, [materials])

  const getSectorSelectorByOrder = (sectors, order, parent = 0) => {
    if (order === 1) {
      return sectors
        .filter((x) => x.order === order)
        .reduce((acc, item) => {
          acc[item.id] = item.name
          return acc
        }, {})
    }

    if (parent === 0) {
      return sectors
        .filter((x) => x.order === order)
        .reduce((acc, item) => {
          acc[item.id] = item.name
          return acc
        }, {})
    }

    if (parent !== 0 && parent !== false) {
      return sectors
        .filter((x) => x.order === order && x.parent === parent)
        .reduce((acc, item) => {
          acc[item.id] = item.name
          return acc
        }, {})
    }
  }

  const getTitleColumnSectors = (levels, order) => {
    return levels.filter((x) => x.order === order).length > 0
      ? levels.filter((x) => x.order === order)[0].level
      : `Sector ${order}`
  }

  const filterSectors = useCallback(
    (sectors, order) => {
      return (props) => {
        const data = { ...props.rowData }
        return (
          <Select
            value={props.value !== undefined ? props.value : 0}
            onChange={(e) => {
              data[`sector${order}`] = parseInt(e.target.value)
              for (let i = order + 1; i <= 4; i++) {
                data[`sector${i}`] = 0
              }
              props.onRowDataChange(data)
            }}
          >
            <MenuItem key={0} value={0}>
              {t('general.notassigned')}
            </MenuItem>
            {Object.keys(
              getSectorSelectorByOrder(
                sectors,
                order,
                order > 1 && data[`sector${order - 1}`]
              )
            ).map((item) => (
              <MenuItem key={item} value={item}>
                {
                  getSectorSelectorByOrder(
                    sectors,
                    order,
                    order > 1 && data[`sector${order - 1}`]
                  )[item]
                }
              </MenuItem>
            ))}
          </Select>
        )
      }
    },
    [t]
  )

  useEffect(() => {
    const validateEmpty = (rowData, field) => {
      return rowData[field] === '' || rowData[field] === undefined
        ? t('general.noempty')
        : true
    }

    const validateNoZero = (rowData, field) => {
      return String(rowData[field]) === '0' ? t('general.noempty') : true
    }

    setColumns([
      { title: 'ID', field: 'id', editable: 'never' },
      {
        title: t('general.name'),
        field: 'name',
        validate: (rowData) => validateEmpty(rowData, 'name'),
        initialEditValue: '',
      },
      {
        title: t('general.area'),
        field: 'area',
        type: 'numeric',
        editable: 'never',
        initialEditValue: 0,
      },
      {
        title: t('general.area_net'),
        field: 'area_net',
        type: 'numeric',
        initialEditValue: 0,
      },
      {
        title: t('general.sowing'),
        field: 'sowing',
        type: 'date',
        initialEditValue: '1969-12-31 00:00:00',
      },
      {
        title: t('general.density'),
        field: 'density',
        type: 'numeric',
        initialEditValue: 0,
      },
      {
        title: t('general.palms'),
        field: 'palms',
        type: 'numeric',
        initialEditValue: 0,
      },
      {
        title: t('general.material'),
        field: 'material',
        lookup: { 0: t('general.notassigned'), ...materialsSelector },
        initialEditValue: 0,
      },
      {
        title: getTitleColumnSectors(levels, 1),
        field: 'sector1',
        lookup: {
          0: t('general.notassigned'),
          ...getSectorSelectorByOrder(sectors, 1),
        },
        initialEditValue: 0,
        validate: (rowData) => validateNoZero(rowData, 'sector1'),
        editComponent: filterSectors(sectors, 1),
      },
      {
        title: getTitleColumnSectors(levels, 2),
        field: 'sector2',
        lookup: {
          0: t('general.notassigned'),
          ...getSectorSelectorByOrder(sectors, 2),
        },
        initialEditValue: 0,
        editComponent: filterSectors(sectors, 2),
      },
      {
        title: getTitleColumnSectors(levels, 3),
        field: 'sector3',
        lookup: {
          0: t('general.notassigned'),
          ...getSectorSelectorByOrder(sectors, 3),
        },
        initialEditValue: 0,
        editComponent: filterSectors(sectors, 3),
      },
      {
        title: getTitleColumnSectors(levels, 4),
        field: 'sector4',
        lookup: {
          0: t('general.notassigned'),
          ...getSectorSelectorByOrder(sectors, 4),
        },
        initialEditValue: 0,
        editComponent: filterSectors(sectors, 4),
      },
    ])
  }, [sectors, t, materialsSelector, levels, filterSectors, plots])

  const getLevelsAggregation = async (plantation) => {
    try {
      const response = await paxios.get(
        `${URL_SERVER}/plantations/levesAggregation/${plantation}`
      )

      response.data.status === 'ok' && setLevels(response.data.data)
    } catch (error) {}
  }

  useEffect(() => {
    getLevelsAggregation(currentPlantation.plantation)
  }, [currentPlantation.plantation])

  const updatePlot = async (data) => {
    try {
      const send = {
        ...data,
        sowing:
          data.sowing !== null ? dayjs(data.sowing).format('YYYY-MM-DD') : null,
      }
      const response = await paxios.put(`${URL_SERVER}/plots/${data.id}`, send)
      if (response.data.status === 'ok') {
        Swal.fire(t('general.finished'), t('general.savesuccess'), 'success')
        getPlots()
      } else {
        Swal.fire(t('general.finished'), t('general.datawasnotsaved'), 'error')
      }
    } catch (error) {
      Swal.fire(t('general.finished'), t('general.datawasnotsaved'), 'error')
    }
  }

  const onRowUpdate = (newData, oldData) =>
    new Promise(async (resolve) => {
      await updatePlot(newData)
      resolve()
    })

  const createPlot = async (data) => {
    try {
      const send = {
        ...data,
        sowing:
          data.sowing !== null ? dayjs(data.sowing).format('YYYY-MM-DD') : null,
        plantation: currentPlantation.plantation,
      }
      const response = await paxios.post(`${URL_SERVER}/plots`, send)
      if (response.data.status === 'ok') {
        Swal.fire(t('general.finished'), t('general.savesuccess'), 'success')
        getPlots()
      } else {
        Swal.fire(t('general.finished'), response.data.message, 'error')
      }
    } catch (error) {
      Swal.fire(t('general.finished'), t('general.datawasnotsaved'), 'error')
    }
  }

  const onRowAdd = (newData) =>
    new Promise(async (resolve) => {
      await createPlot(newData)
      resolve()
    })

  const selectRow = (row) => {
    setRowSelec(row)
    if (row.length > 0){
      setUser({ plotSelected: row[0].id })
    }
  }

  const criterialDisabled = (currentId) => {
    if (rowSelec.length <= 0)
      return {
        disabled: false,
      }

    if (currentId !== rowSelec[0].id) {
      return {
        disabled: true,
        color: 'primary',
      }
    } else if (currentId === rowSelec[0].id) {
      return {
        disabled: false,
        checked: true,
      }
    }

    return {
      disabled: false,
    }
  }

  const handleClickGenerateQrAll = () => {
    dowloadPdfQr(true, null, 'Qrs.pdf')
  }

  const handleClickGenerateQrPlot = () => {
    const plots = rowSelec.map((x) => x.id)
    dowloadPdfQr(false, plots, `Qr-${rowSelec[0].name}.pdf`)
  }

  const dowloadPdfQr = (all, plots, fileName) => {
    const dataSend = {
      plantation: currentPlantation.plantation,
      all,
      plots,
    }
    downloadFile(`${URL_SERVER}/plots/generateQrPlots`, fileName, dataSend)
  }

  const handleClickLoadExcel = () => {
    setShowModalLoadExcel(true)
  }

  const handleClickShowPalms = () => {
    if (rowSelec.length > 0) {
      setUser({ plotSelected: rowSelec[0].id })
      history.push(ROUTES.palms)
    }
  }

  return (
    <>
      <TitlePage text={`${t('general.plots')} ${t('general.plantation')}`} />
      <ButtonGroup
        variant="text"
        color="primary"
        aria-label="text primary button group"
        style={{
          width: '100%',
          justifyContent: 'center',
          marginBottom: '20px',
        }}
      >
        <Button
          startIcon={<AppsIcon />}
          disabled={rowSelec.length <= 0 ? true : false}
          onClick={handleClickShowPalms}
        >
          {t('general.showpalms')}
        </Button>
        <Button
          startIcon={<CropFreeIcon />}
          disabled={rowSelec.length <= 0 ? true : false}
          onClick={handleClickGenerateQrPlot}
        >
          {`${t('general.generateqr')} ${t('general.plot')}`}
        </Button>
        <Button
          startIcon={<CropFreeIcon />}
          onClick={handleClickGenerateQrAll}
        >{`${t('general.generateqr')} ${t('general.plots')}`}</Button>
        <Button startIcon={<AttachmentIcon />} onClick={handleClickLoadExcel}>
          {t('general.loadexcel')}
        </Button>
      </ButtonGroup>

      <MaterialTable
        icons={tableIcons}
        columns={columns}
        title={t('general.plots')}
        data={plots.map((x) => ({
          ...x,
          sowing: x.sowing !== null ? x.sowing + ' 00:00:00' : null,
        }))}
        options={{
          exportButton: true,
          exportAllData: true,
          exportDelimiter: ';',
          selection: true,
          showSelectAllCheckbox: false,
          selectionProps: (rowData) => criterialDisabled(rowData.id),
        }}
        localization={i18n.language === 'es' ? tableTranslations : {}}
        editable={{
          onRowUpdate: onRowUpdate,
          onRowAdd: onRowAdd,
        }}
        onSelectionChange={selectRow}
      />
      {showModalLoadExcel && (
        <ModalLoadExcel
          setOpen={setShowModalLoadExcel}
          open={showModalLoadExcel}
        />
      )}
    </>
  )
}
