import { useState, useEffect } from 'react'
import { ButtonGroup, Button, MenuItem, Menu } from '@material-ui/core'
import AssignmentIcon from '@material-ui/icons/Assignment'
import AssessmentIcon from '@material-ui/icons/Assessment'
import AttachmentIcon from '@material-ui/icons/Attachment'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { useTranslation } from 'react-i18next'
import { userStore } from '../../stores'
import { ROUTES } from '../../constants/routes'
import { TitlePage } from '../../components'
import Manage from './Manage'
import LoadExcel from './ByExcel'
import ReportPollinationYear from './reports/PollinationYear'
import ManageByLine from './ManageByLine'
import LoadExcelByLine from './ByExcelByLine'
import ReportPollinationYearByLine from './reports/PollinationYearByLine'

export default function Pollination() {
  const { t } = useTranslation()
  const { setUser } = userStore()
  const [selection, setSelection] = useState(1)
  const [openMenuReports, setOpenMenuReports] = useState(null)

  useEffect(() => {
    setUser({ currentUrl: ROUTES.pollination })
  }, [setUser])

  const handleClickMenuReports = (event) => {
    setOpenMenuReports(event.currentTarget)
  }

  const handleCloseMenuReports = (opc) => {
    setOpenMenuReports(null)
    typeof opc === 'number' && setSelection(opc)
  }

  return (
    <>
      <TitlePage text={`${t('general.pollination')} ANA`} />
      <ButtonGroup
        variant="text"
        color="primary"
        aria-label="text primary button group"
        style={{
          width: '100%',
          justifyContent: 'center',
          marginBottom: '20px',
        }}
      >
        <Button
          startIcon={<AssignmentIcon />}
          variant={selection === 1 ? 'outlined' : ''}
          onClick={() => setSelection(1)}
        >
          {t('general.manage')}
        </Button>
        <Button
          startIcon={<AttachmentIcon />}
          variant={selection === 3 ? 'outlined' : ''}
          onClick={() => setSelection(3)}
        >
          {t('general.loadexcel')}
        </Button>
        <Button
          startIcon={<AssignmentIcon />}
          variant={selection === 2 ? 'outlined' : ''}
          onClick={() => setSelection(2)}
        >
          {`${t('general.manage')} ${t('general.byline')}`}
        </Button>
        <Button
          startIcon={<AttachmentIcon />}
          variant={selection === 5 ? 'outlined' : ''}
          onClick={() => setSelection(5)}
        >
          {`${t('general.loadexcel')} ${t('general.byline')}`}
        </Button>
        <Button
          startIcon={<AssessmentIcon />}
          variant={[4].includes(selection) ? 'outlined' : ''}
          onClick={handleClickMenuReports}
        >
          {`${t('general.reports')}`}
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>

      <Menu
        id="simple-menu"
        anchorEl={openMenuReports}
        keepMounted
        open={Boolean(openMenuReports)}
        onClose={handleCloseMenuReports}
      >
        <MenuItem
          selected={selection === 4}
          onClick={() => handleCloseMenuReports(4)}
        >
          {`${t('general.pollinationyear')}`}
        </MenuItem>
        <MenuItem
          selected={selection === 6}
          onClick={() => handleCloseMenuReports(6)}
        >
          {`${t('general.pollinationyear')} ${t('general.byline')}`}
        </MenuItem>
      </Menu>

      {selection === 1 && <Manage />}
      {selection === 2 && <ManageByLine />}
      {selection === 3 && <LoadExcel />}
      {selection === 5 && <LoadExcelByLine />}
      {selection === 4 && <ReportPollinationYear />}
      {selection === 6 && <ReportPollinationYearByLine />}
    </>
  )
}
