import { Button } from '@material-ui/core'

export default function ButtonControl({
  color = 'primary',
  disabled = false,
  loading,
  text,
  icon,
  onClick,
}) {
  return (
    <Button
      variant="contained"
      color={color}
      disabled={disabled || loading}
      startIcon={icon}
      onClick={onClick}
      style={{width: 200, margin: 3}}
    >
      {text}
    </Button>
  )
}
