import { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import MaterialTable from 'material-table'
import dayjs from 'dayjs'
import Swal from 'sweetalert2/src/sweetalert2.js'
import { ButtonGroup, Button, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CropFreeIcon from '@material-ui/icons/CropFree'
import AttachmentIcon from '@material-ui/icons/Attachment'
import { useTranslation } from 'react-i18next'
import { userStore, cadastreStore } from '../../stores'
import { ROUTES } from '../../constants/routes'
import { URL_SERVER, paxios, downloadFile } from '../../constants/http'
import tableIcons from '../../constants/IconsMaterialTable'
import tableTranslations from '../../constants/tableTranslations'
import {TitlePage} from '../../components'
import ModalLoadExcel from './ModalLoadExcel'
import AssignSowingAllPalms from './AssignSowingAllPalms'
import AssignMaterialAllPalms from './AssignMaterialAllPalms'

const cellStyle = { align: 'center', headerStyle: { textAlign: 'center' } }

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
  },
}))

function Palms({ match }) {
  const classes = useStyles()
  //const plot = match.params.plot
  const { setUser, currentPlantation, plotSelected } = userStore()
  const { plots, materials, reasonsEradication } = cadastreStore()
  const { t, i18n } = useTranslation()
  useEffect(() => {
    setUser({ currentUrl: ROUTES.palms})
  }, [setUser, plotSelected])
  
  const [columns, setColumns] = useState([])
  const [palms, setPalms] = useState([])
  const [materialsSelector, setMaterialsSelector] = useState({})
  const [reasonsEradicationSelector, setReasonsEradicationSelector] = useState(
    {}
  )
  const [showModalLoadExcel, setShowModalLoadExcel] = useState(false)

  const getPalms = async (plot) => {
    try {
      const response = await paxios.get(`${URL_SERVER}/palms/byPlot/${plot}`)
      if (response.data.status === 'ok') {
        setPalms(response.data.data)
      }
    } catch (error) {}
  }

  useEffect(() => {
    getPalms(plotSelected)
  }, [plotSelected])

  useEffect(() => {
    const materialsTemp = {}
    for (const material of materials) {
      materialsTemp[material.id] = material.name
    }
    setMaterialsSelector(materialsTemp)
  }, [materials])

  useEffect(() => {
    const reasonsTemp = {}
    for (const reason of reasonsEradication) {
      reasonsTemp[reason.id] = reason.name
    }
    setReasonsEradicationSelector(reasonsTemp)
  }, [reasonsEradication])

  useEffect(() => {
    const validateEmpty = (rowData, field) => {
      return String(rowData[field]) === '' || isNaN(rowData[field])
        ? t('general.noempty')
        : true
    }

    const validateNoZero = (rowData, field) => {
      return String(rowData[field]) === '0' ? t('general.nozero') : true
    }

    const validateNoZeroEmpty = (rowData, field) => {
      if (
        validateNoZero(rowData, field) === true &&
        validateEmpty(rowData, field) === true
      ) {
        return true
      } else {
        return `${
          validateNoZero(rowData, field) === true
            ? ''
            : validateNoZero(rowData, field)
        } ${
          validateEmpty(rowData, field) === true
            ? ''
            : ', ' + validateEmpty(rowData, field)
        }`
      }
    }

    const validateReasonEradication = (rowData) => {
      const date = dayjs(rowData.date_eradication).format('YYYY-MM-DD')
      if (date !== '1969-12-31') {
        if (String(rowData.reason_eradication) !== '0') {
          return true
        } else {
          return t('general.option_invalid')
        }
      } else {
        return true
      }
    }

    const validateDateEradication = (rowData) => {
      const date = dayjs(rowData.date_eradication).format('YYYY-MM-DD')
      if (String(rowData.reason_eradication) !== '0') {
        if (date === '1969-12-31') {
          return t('general.date_invalid')
        } else {
          return true
        }
      } else {
        return true
      }
    }

    setColumns([
      {
        title: 'ID',
        field: 'id',
        editable: 'never',
        ...cellStyle,
      },
      {
        title: t('general.line'),
        field: 'line',
        type: 'numeric',
        initialEditValue: 0,
        validate: (rowData) => validateNoZeroEmpty(rowData, 'line'),
        width: 20,
        ...cellStyle,
      },
      {
        title: t('general.palm'),
        field: 'palm',
        type: 'numeric',
        initialEditValue: 0,
        validate: (rowData) => validateNoZeroEmpty(rowData, 'palm'),
        width: 20,
        ...cellStyle,
      },
      {
        title: t('general.sowing'),
        field: 'sowing',
        type: 'date',
        initialEditValue: '1969-12-31 00:00:00',
        ...cellStyle,
      },
      {
        title: t('general.state'),
        field: 'state',
        lookup: { 1: t('general.active'), 0: t('general.inactive') },
        initialEditValue: 1,
        ...cellStyle,
      },
      {
        title: t('general.material'),
        field: 'material',
        lookup: { 0: t('general.notassigned'), ...materialsSelector },
        initialEditValue: 0,
        ...cellStyle,
      },
      {
        title: t('general.date_eradication'),
        field: 'date_eradication',
        type: 'date',
        initialEditValue: '1969-12-31 00:00:00',
        validate: (rowData) => validateDateEradication(rowData),
        ...cellStyle,
      },
      {
        title: t('general.reason_eradication'),
        field: 'reason_eradication',
        lookup: { 0: t('general.notassigned'), ...reasonsEradicationSelector },
        initialEditValue: 0,
        validate: (rowData) => validateReasonEradication(rowData),
        ...cellStyle,
      },
    ])
  }, [palms, t, materialsSelector, reasonsEradicationSelector])

  const updatePalm = async (data) => {
    try {
      const send = {
        ...data,
        sowing:
          data.sowing !== null ? dayjs(data.sowing).format('YYYY-MM-DD') : null,
        date_eradication:
          data.date_eradication !== null
            ? dayjs(data.date_eradication).format('YYYY-MM-DD')
            : null,
      }
      const response = await paxios.put(`${URL_SERVER}/palms/${data.id}`, send)
      if (response.data.status === 'ok') {
        Swal.fire(t('general.finished'), t('general.savesuccess'), 'success')
        getPalms(plotSelected)
      } else {
        Swal.fire(t('general.finished'), t('general.datawasnotsaved'), 'error')
      }
    } catch (error) {
      Swal.fire(t('general.finished'), t('general.datawasnotsaved'), 'error')
    }
  }

  const onRowUpdate = (newData, oldData) =>
    new Promise(async (resolve) => {
      await updatePalm(newData)
      resolve()
    })

  const createPalm = async (data) => {
    try {
      const send = {
        ...data,
        sowing:
          data.sowing !== null ? dayjs(data.sowing).format('YYYY-MM-DD') : null,
        date_eradication:
          data.date_eradication !== null
            ? dayjs(data.date_eradication).format('YYYY-MM-DD')
            : null,
        plot:plotSelected,
        plantation: currentPlantation.plantation,
      }
      const response = await paxios.post(`${URL_SERVER}/palms`, send)
      if (response.data.status === 'ok') {
        Swal.fire(t('general.finished'), t('general.savesuccess'), 'success')
        getPalms(plotSelected)
      } else {
        Swal.fire(t('general.finished'), response.data.message, 'error')
      }
    } catch (error) {
      Swal.fire(t('general.finished'), t('general.datawasnotsaved'), 'error')
    }
  }

  const onRowAdd = (newData) =>
    new Promise(async (resolve) => {
      createPalm(newData)
      resolve()
    })

  let rowsSelect = []
  const selectRow = (row) => {
    rowsSelect = []
    for (const item of row) {
      rowsSelect.push(item)
    }
  }

  const generateQrPalmsSelected = () => {
    if (rowsSelect.length <= 0) {
      Swal.fire(t('general.check'), t('general.selectatleastoneitem'), 'error')
      return
    }
    const send = {
      plantation: currentPlantation.plantation,
      palms: rowsSelect.map((x) => x.id),
    }
    generateQr(send)
  }

  const generateQrAllPalms = () => {
    const send = {
      plantation: currentPlantation.plantation,
      plot: plotSelected,
    }
    generateQr(send)
  }

  const generateQr = (data) => {
    downloadFile(
      `${URL_SERVER}/palms/generateQrPalms`,
      `Qrs_${t('general.palms')}.pdf`,
      data
    )
  }

  const handleClickLoadExcel = () => {
    setShowModalLoadExcel(true)
  }

  return (
    <>
      <TitlePage
        text={`${t('general.palms')} ${t('general.plot')}: ${
          plots.filter((x) => String(x.id) === String(plotSelected))[0]?.name
        }`}
      />
      <ButtonGroup
        variant="text"
        color="primary"
        aria-label="text primary button group"
        style={{
          width: '100%',
          justifyContent: 'center',
          marginBottom: '20px',
        }}
      >
        <Button startIcon={<CropFreeIcon />} onClick={generateQrPalmsSelected}>
          {`${t('general.selectpalmsqr')}`}
        </Button>
        <Button startIcon={<CropFreeIcon />} onClick={generateQrAllPalms}>{`${t(
          'general.generateqr'
        )} ${t('general.palms')}`}</Button>
        <Button startIcon={<AttachmentIcon />} onClick={handleClickLoadExcel}>
          {t('general.loadexcel')}
        </Button>
      </ButtonGroup>

      {palms.length > 0 && (
        <MaterialTable
          icons={tableIcons}
          columns={columns}
          title={t('general.palms')}
          data={palms.map((x) => ({
            ...x,
            sowing: x.sowing !== null ? x.sowing + ' 00:00:00' : null,
          }))}
          options={{
            exportButton: true,
            exportAllData: true,
            exportDelimiter: ';',
            selection: true,
            showSelectAllCheckbox: true,
            //selectionProps: (rowData) => criterialSelected(rowData.id),
          }}
          localization={i18n.language === 'es' ? tableTranslations : {}}
          editable={{
            onRowUpdate: onRowUpdate,
            onRowAdd: onRowAdd,
          }}
          onSelectionChange={selectRow}
        />
      )}

      <Grid container spacing={10} className={classes.root}>
        <Grid item xs={12} md={6}>
           <AssignMaterialAllPalms plot={plotSelected} getPalms={getPalms} />
        </Grid>
        <Grid item xs={12} md={6}>
          <AssignSowingAllPalms plot={plotSelected} getPalms={getPalms} />
        </Grid>
      </Grid>

      {showModalLoadExcel && (
        <ModalLoadExcel
          setOpen={setShowModalLoadExcel}
          open={showModalLoadExcel}
          plot={plotSelected}
          getPalms={getPalms}
        />
      )}
    </>
  )
}

export default withRouter(Palms)
