import { useEffect, useState } from 'react'
import Swal from 'sweetalert2/src/sweetalert2.js'
import MaterialTable from 'material-table'
import { useTranslation } from 'react-i18next'
import { laborsStore, userStore } from '../../../stores'
import { URL_SERVER, paxios } from '../../../constants/http'
import tableIcons from '../../../constants/IconsMaterialTable'
import tableTranslations from '../../../constants/tableTranslations'


export default function Units() {
  const { t, i18n } = useTranslation()
  const { headings, getHeadings } = laborsStore()
  const { currentPlantation, user } = userStore()
  const [columns, setColumns] = useState([])

  useEffect(() => {
    headings.length <= 0 && getHeadings()
  }, [headings.length, getHeadings])

  useEffect(() => {
    const validateEmpty = (rowData, field) => {
      return rowData[field] === '' || rowData[field] === undefined
        ? t('general.noempty')
        : true
    }

    setColumns([
      { title: 'ID', field: 'id', editable: 'never' },
      {
        title: t('general.name'),
        field: 'name',
        validate: (rowData) => validateEmpty(rowData, 'name'),
      },
      {
        title: t('general.description'),
        field: 'description',
        validate: (rowData) => validateEmpty(rowData, 'description'),
      },
    ])
  }, [t])

  const createHeading = async (data) => {
    try {
      const response = await paxios.post(`${URL_SERVER}/headings`, {
        ...data,
        user: user.id,
        plantation: currentPlantation.plantation,
      })
      if (response.data.status === 'ok') {
        Swal.fire(t('general.finished'), t('general.savesuccess'), 'success')
        getHeadings()
      } else {
        Swal.fire(t('general.finished'), response.data.message, 'error')
      }
    } catch (error) {
      Swal.fire(t('general.finished'), t('general.datawasnotsaved'), 'error')
    }
  }

  const onRowAddHeading = (newData) =>
    new Promise(async (resolve) => {
      await createHeading(newData)
      resolve()
    })

  const updateHeading = async (data) => {
    try {
      const response = await paxios.put(
        `${URL_SERVER}/headings/${data.id}`,
        {
          ...data,
          user: user.id,
          plantation: currentPlantation.plantation,
        }
      )
      if (response.data.status === 'ok') {
        Swal.fire(t('general.finished'), t('general.savesuccess'), 'success')
        getHeadings()
      } else {
        Swal.fire(t('general.finished'), t('general.datawasnotsaved'), 'error')
      }
    } catch (error) {
      Swal.fire(t('general.finished'), t('general.datawasnotsaved'), 'error')
    }
  }

  const onRowUpdateHeading = (newData, oldData) =>
    new Promise(async (resolve) => {
      await updateHeading(newData)
      resolve()
    })

  return (
    <>
      <MaterialTable
        icons={tableIcons}
        columns={columns}
        title={`${t('general.headings')}`}
        data={headings.map((x) => ({ ...x, ti: new Date() }))}
        options={{
          exportButton: true,
          exportAllData: true,
          exportDelimiter: ';',
          selection: false,
        }}
        localization={i18n.language === 'es' ? tableTranslations : {}}
        editable={{
          onRowUpdate: onRowUpdateHeading,
          onRowAdd: onRowAddHeading,
        }}
      />
    </>
  )
}
