import create from 'zustand'
import { devtools } from 'zustand/middleware'
import cadastreStore from './cadastreStore'
import sanitationStore from './sanitationStore'
import severalStore from './severalStore'
import mapsStore from './mapsStore'
import laborsStore from './laborsStore'
import { URL_SERVER, naxios, setJWT, setLanguage } from '../constants/http'
import { ROUTES } from '../constants/routes'
import {
  localStorageSelect,
  localStorageCreateUpdate,
  localStorageDelete,
} from '../localStorage'

const store = (set) => ({
  user: null,
  token: null,
  logged: false,
  currentUrl: '/',
  currentPlantation: null,
  i18nextLng: null,
  plotSelected: '0',
  setUser: (data) => {
    data.currentUrl && localStorageCreateUpdate('currentUrl', data.currentUrl)
    data.currentPlantation &&
      localStorageCreateUpdate('currentPlantation', data.currentPlantation)
    set({ ...data })
  },
  loadLocalStorage: () => {
    const user = localStorageSelect('user')
    const token = localStorageSelect('token')
    const currentUrl = localStorageSelect('currentUrl')
    const currentPlantation = localStorageSelect('currentPlantation')
    const language = localStorageSelect('i18nextLng')
    if(language === 'null'){
      //localStorageCreateUpdate('i18nextLng', "es")
      localStorage.setItem('i18nextLng', "es")
    }
    if (user === null && token === null) return null

    const payload = token.split('.')[1]
    const base64 = payload.replace('-', '+').replace('_', '/')
    const decodedToken = JSON.parse(atob(base64))

    let result = null
    if (decodedToken && decodedToken.exp) {
      const expirationDate = new Date(0)
      expirationDate.setUTCSeconds(decodedToken.exp)
      result = expirationDate.valueOf() < new Date().valueOf()
    } else {
      result = true
    }

    if (result) {
      localStorageDelete('user')
      localStorageDelete('token')
      return null
    }
    setJWT(token)
    setLanguage(language)
    set({
      user,
      token,
      logged: true,
      currentUrl,
      currentPlantation,
      i18nextLng: language,
    })
  },
  login: async (userData) => {
    try {
      const result = await naxios.post(`${URL_SERVER}/login`, userData)
      if (result.data.info.Status === 'Ok') {
        localStorageCreateUpdate('user', result.data.user)
        localStorageCreateUpdate('token', result.data.token)
        localStorageCreateUpdate('currentUrl', ROUTES.home)
        localStorageCreateUpdate(
          'currentPlantation',
          result.data.user.plantations[0]
        )
        const language = localStorageSelect('i18nextLng')
        setJWT(result.data.token)
        setLanguage(language)
        set({
          user: result.data.user,
          token: result.data.token,
          logged: true,
          currentUrl: ROUTES.home,
          currentPlantation: result.data.user.plantations[0],
        })
      }
      return { status: 'ok' }
    } catch (e) {
      return {
        status: 'error',
        error: e.data?.Error,
      }
    }
  },
  logout: () => {
    localStorageDelete('user')
    localStorageDelete('token')
    localStorageDelete('currentUrl')
    localStorageDelete('currentPlantation')
    localStorageDelete('plantations')
    localStorageDelete('sectors')
    localStorageDelete('plots')
    localStorageDelete('materials')
    localStorageDelete('reasonsEradication')
    localStorageDelete('diseases')
    localStorageDelete('eventsDiseases')
    localStorageDelete('treatments')
    localStorageDelete('pests')
    localStorageDelete('statesPests')
    localStorageDelete('workers')
    localStorageDelete('vehicles')
    localStorageDelete('quadrilles')
    localStorageDelete('companies')
    localStorageDelete('fertilizers')
    localStorageDelete('fertilizersTypes')
    localStorageDelete('phasesEventsDiseases')
    localStorageDelete('trapsRhynchophorus')
    

    set({
      user: null,
      token: null,
      logged: false,
      currentUrl: '/',
      currentPlantation: null,
    })

    cadastreStore.setState({
      plantations: [],
      sectors: [],
      plots: [],
      materials: [],
      reasonsEradication: [],
      error: null,
    })

    sanitationStore.setState({
      diseases: [],
      eventsDiseases: [],
      treatments: [],
      pests: [],
      statesPests: [],
      phasesEventsDiseases: {},
      error: null,
    })

    severalStore.setState({
      workers: [],
      vehicles: [],
      quadrilles: [],
      companies: [],
      errors: null,
    })

    mapsStore.setState({
      colors: {},
      error: null,
    })

    laborsStore.setState({
      labors: [],
      laborsGroups: [],
      unitOfMeasures: [],
      costCenters: [],
      laborsPhases: [],
      originsQuantity: [],
      error: null,
    })
  },
})

const userStore = create(devtools(store, 'user'))

export default userStore
