import { useState, useRef, useEffect } from 'react'
import { URL_SERVER, paxios } from '../../../constants/http'
import { useTranslation } from 'react-i18next'
import { Calendar, Button, SelectCreateSchedule } from '../../../components'
import Search from './Search'
import CreateEditEvent from './CreateEditEvent'
import CreateExecutionWithoutSchedule from './CreateExecutionWithoutSchedule'

export default function CalendarLabors() {
  const searchRef = useRef(null)
  const createRef = useRef(null)
  const { t } = useTranslation()
  const [events, setEvents] = useState([])
  const [eventSelected, setEventSelected] = useState(null)
  const [dateNew, setDateNew] = useState('')
  const [selectCreate, setSelectCreate] = useState(false)
  const [createWithoutSchedule, setCreateWithoutSchedule] = useState(false)

  useEffect(() => {
    eventSelected &&
      setEventSelected((d) => ({
        ...d,
        extendedProps: {
          ...d.extendedProps,
          ...events.find((x) => String(x.id) === String(d.publicId)),
        },
      }))
    // eslint-disable-next-line
  }, [events])

  const getSchedule = async (send) => {
    try {
      const response = await paxios.post(
        `${URL_SERVER}/laborsSchedule/getSchedule`,
        send
      )
      response.data.status === 'ok' && setEvents(response.data.data)
    } catch (error) {}
  }

  const getWithoutSchedule = async (send) => {
    try {
      const sendNew = {
        start: send.initialDate,
        end: send.endDate,
        plantation: send.plantation,
      }
      const response = await paxios.post(
        `${URL_SERVER}/laborsExecutionWithoutSchedule/getByDates`,
        sendNew
      )
      response.data.status === 'ok' &&
        setEvents((d) => [...d, ...response.data.data])
    } catch (error) {}
  }

  const handleClickSearch = async (send) => {
    await getSchedule(send)
    await getWithoutSchedule(send)
  }

  const handleClickEvent = (eventInfo) => {
    setEventSelected({ ...eventInfo.event._def })
    setDateNew('')
    if (eventInfo.event._def.extendedProps.is_schedule) {
      createRef.current.setOpen(true)
    }else {
      setCreateWithoutSchedule(true)
    }
  }

  const handleClickDay = (day) => {
    setDateNew(day.startStr)
    setSelectCreate(true)
  }

  const handleClickCreateSchedule = () => {
    setEventSelected(null)
    createRef.current.setOpen(true)
    setSelectCreate(false)
  }

  const handleClickCreateWithoutSchedule = () => {
    setEventSelected(null)
    setCreateWithoutSchedule(true)
    setSelectCreate(false)
  }

  const handleChangeDates = (data) => {}
  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          size={'small'}
          handleButtonClick={() => {
            searchRef.current.setOpen(true)
          }}
          text={`${t('general.show')} ${t('general.search')}`}
        />
      </div>
      <Search ref={searchRef} handleClickSearch={handleClickSearch} />

      <Calendar
        events={events}
        handleClickEvent={handleClickEvent}
        handleClickDay={handleClickDay}
        handleChangeDates={handleChangeDates}
      />
      <CreateEditEvent
        ref={createRef}
        event={eventSelected}
        dateNew={dateNew}
        searchRef={searchRef}
        search={handleClickSearch}
        setEventSelectedCalendar={setEventSelected}
      />

      {selectCreate && (
        <SelectCreateSchedule
          open={selectCreate}
          setOpen={setSelectCreate}
          handleClickSchedule={handleClickCreateSchedule}
          handleClickWithoutSchedule={handleClickCreateWithoutSchedule}
        />
      )}

      {createWithoutSchedule && (
        <CreateExecutionWithoutSchedule
          open={createWithoutSchedule}
          setOpen={setCreateWithoutSchedule}
          event={eventSelected}
          dateNew={dateNew}
          searchRef={searchRef}
          search={handleClickSearch}
          setEventSelectedCalendar={setEventSelected}
        />
      )}
    </>
  )
}
