import { useEffect, useState, useCallback } from 'react'
import Swal from 'sweetalert2/src/sweetalert2.js'
import dayjs from 'dayjs'
import MaterialTable from 'material-table'
import BorderStyleIcon from '@material-ui/icons/BorderStyle'
import { useTranslation } from 'react-i18next'
import { userStore } from '../../../stores'
import { URL_SERVER, paxios } from '../../../constants/http'
import tableIcons from '../../../constants/IconsMaterialTable'
import tableTranslations from '../../../constants/tableTranslations'
import { Modal } from '../../../components'

const cellStyle = { align: 'center', headerStyle: { textAlign: 'center' } }

export default function CostLabor({ labor, open, setOpen }) {
  const currentDate = dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss')
  const { t, i18n } = useTranslation()
  const { currentPlantation } = userStore()
  const [costs, setCosts] = useState([])
  const validateEmpty = (rowData, field) => {
    return String(rowData[field]) === '' || isNaN(rowData[field])
      ? t('general.noempty')
      : true
  }

  const validateNoZero = (rowData, field) => {
    if(! isNaN(rowData[field]))
     return String(rowData[field]) <= 0 ? t('general.nozero') : true

    return String(rowData[field]) === '0' ? t('general.nozero') : true
  }

  const validateNoZeroEmpty = (rowData, field) => {
    if (
      validateNoZero(rowData, field) === true &&
      validateEmpty(rowData, field) === true
    ) {
      return true
    } else {
      return `${
        validateNoZero(rowData, field) === true
          ? ''
          : validateNoZero(rowData, field)
      } ${
        validateEmpty(rowData, field) === true
          ? ''
          : ', ' + validateEmpty(rowData, field)
      }`
    }
  }

  const columns = [
    { title: 'ID', field: 'id', editable: 'never' },
    {
      title: t('general.initialdate'),
      field: 'start_date',
      type: 'date',
      initialEditValue: currentDate,
      ...cellStyle,
    },
    {
      title: t('general.enddate'),
      field: 'end_date',
      type: 'date',
      initialEditValue: currentDate,
      ...cellStyle,
    },
    {
      title: t('general.cost'),
      field: 'cost',
      type: 'numeric',
      initialEditValue: 0,
      validate: (rowData) => validateNoZeroEmpty(rowData, 'cost'),
      width: 20,
      ...cellStyle,
    },
  ]

  const getCosts = useCallback(async () => {
    try {
      const response = await paxios.get(
        `${URL_SERVER}/laborsCosts/${labor.id}/${currentPlantation.plantation}`
      )
      if (response.data.status === 'ok') {
        setCosts(response.data.data)
      }
    } catch (error) {}
  }, [currentPlantation.plantation, labor.id])

  useEffect(() => {
    getCosts()
  }, [getCosts])

  const createCost = async (data) => {
    try {
      const response = await paxios.post(`${URL_SERVER}/laborsCosts`, {
        ...data,
        start_date: dayjs(data.start_date).format('YYYY-MM-DD'),
        end_date: dayjs(data.end_date).format('YYYY-MM-DD'),
        labor: labor.id,
        plantation: currentPlantation.plantation,
      })
      if (response.data.status === 'ok') {
        Swal.fire(t('general.finished'), t('general.savesuccess'), 'success')
        getCosts()
      } else {
        Swal.fire(t('general.finished'), response.data.message, 'error')
      }
    } catch (error) {
      Swal.fire(t('general.finished'), t('general.datawasnotsaved'), 'error')
    }
  }

  const onRowAddCost = (newData) =>
    new Promise(async (resolve) => {
      await createCost(newData)
      resolve()
    })

  const updateCost = async (data) => {
    try {
      const response = await paxios.put(
        `${URL_SERVER}/laborsCosts/${data.id}`,
        {
          ...data,
          start_date: dayjs(data.start_date).format('YYYY-MM-DD'),
          end_date: dayjs(data.end_date).format('YYYY-MM-DD'),
          plantation: currentPlantation.plantation,
        }
      )
      if (response.data.status === 'ok') {
        Swal.fire(t('general.finished'), t('general.savesuccess'), 'success')
        getCosts()
      } else {
        Swal.fire(t('general.finished'), t('general.datawasnotsaved'), 'error')
      }
    } catch (error) {
      Swal.fire(t('general.finished'), t('general.datawasnotsaved'), 'error')
    }
  }

  const onRowUpdateCost = (newData, oldData) =>
    new Promise(async (resolve) => {
      await updateCost(newData)
      resolve()
    })

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title={`${t('general.costs')} ${t('general.labor')}: ${labor.name}`}
      icon={<BorderStyleIcon />}
      width="40%"
    >
      <MaterialTable
        icons={tableIcons}
        columns={columns}
        title={`${t('general.groups')}`}
        data={costs.map((x) => ({ ...x, ti: new Date() }))}
        options={{
          exportButton: true,
          exportAllData: true,
          exportDelimiter: ';',
          selection: false,
        }}
        localization={i18n.language === 'es' ? tableTranslations : {}}
        editable={{
          onRowUpdate: onRowUpdateCost,
          onRowAdd: onRowAddCost,
        }}
      />
    </Modal>
  )
}
