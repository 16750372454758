import { useEffect, useState } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  userStore,
  cadastreStore,
  sanitationStore,
  severalStore,
  mapsStore
} from './stores'
import { setLanguage } from './constants/http'
import Login from './pages/Login'
import { RoutePrivate } from './components'
import Home from './pages/Home'
import {ChangePass} from './components'
/* import Plantation from './pages/Plantation' */
/* import Sectors from './pages/Sectors' */
import Subdivision from './pages/subdivision/Subdivision'
import Plots from './pages/plots/Plots'
import Palms from './pages/palms/Palms'
import Eradications from './pages/eradications/Eradications'
import MapCadastre from './pages/mapCadastre/Home'
import Diseases from './pages/diseases/Diseases'
import Pests from './pages/pests/Pests'
import ExecutionTreatments from './pages/executionTreatments/ExecutionTreatments'
import Rhynchophorus from './pages/rhynchophorus/Rhynchophorus'
import MapSanitation from './pages/mapSanitation/Home'
import Pollination from './pages/pollination/Pollination'
import PollinationAna from './pages/pollinationAna/Pollination'
import Yield from './pages/yield/Yield'
import MapProduction from'./pages/mapProduction/Home'
import ScheduleLabors from './pages/labors/Schedule'
import fertilizationPlan from './pages/nutrition/fertilizationPlan/Home'
import SuperDiseases from './pages/supervision/diseases/Diseases'
import MapMachinery from './pages/mapMachinery/MachineryMap'
import AdminSanitation from './pages/administration/sanitation/Home'
import AdminProduction from './pages/administration/production/Home'
import AdminLabors from './pages/administration/labors/Home'
import AdminNutrition from './pages/administration/nutrition/Home'
import AdminMachinery from './pages/administration/machinery/Home'
import AdminSeveral from './pages/administration/several/Home'
import AdminUsers from './pages/administration/users/Users'
import { Logout } from './components'
import { ROUTES } from './constants/routes'


function App() {
  const [currentTab, setCurrentTab] = useState('/')
  if (window.location.pathname !== '/') {
    currentTab === '/' && setCurrentTab(window.location.pathname)
  }

  const { i18n } = useTranslation()
  const { token, loadLocalStorage, i18nextLng, setUser } = userStore()
  const {  colors, getColorsMaps } = mapsStore()
  const { loadLocalStorageCadastre } = cadastreStore()
  const { loadLocalStorageSanitation } = sanitationStore()
  const { loadLocalStorageSeveral } = severalStore()

  useEffect(() => {
    token === null && loadLocalStorage()
    if (i18nextLng === 'null' || i18nextLng === null) {
      setUser({ i18nextLng: i18n.language })
      setLanguage(i18n.language)
    }
    if (token !== null) {
      Object.keys(colors).length <= 0 && getColorsMaps()
      loadLocalStorageCadastre()
      loadLocalStorageSanitation()
      loadLocalStorageSeveral()
    }
  }, [
    token,
    loadLocalStorage,
    loadLocalStorageCadastre,
    loadLocalStorageSanitation,
    loadLocalStorageSeveral,
    i18nextLng,
    setUser,
    i18n.language,
    colors,
    getColorsMaps,
  ])

  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Login currentTab={currentTab} />
        </Route>
        <RoutePrivate exact path={ROUTES.home} component={Home} />
        {/* <RoutePrivate exact path={ROUTES.changepass} component={ChangePass} /> */}
        <Route exact path={ROUTES.changepass}>
            <ChangePass />
        </Route>
        {/* <RoutePrivate exact path={ROUTES.plantation} component={Plantation} /> */}
        {/* <RoutePrivate exact path={ROUTES.sectors} component={Sectors} /> */}
        <RoutePrivate exact path={ROUTES.subdivision} component={Subdivision} />
        <RoutePrivate exact path={ROUTES.plots} component={Plots} />
        <RoutePrivate exact path={ROUTES.palms} component={Palms} />
        <RoutePrivate
          exact
          path={ROUTES.eradications}
          component={Eradications}
        />
        <RoutePrivate exact path={ROUTES.mapcadastre} component={MapCadastre} />
        <RoutePrivate exact path={ROUTES.diseases} component={Diseases} />
        <RoutePrivate exact path={ROUTES.pests} component={Pests} />
        <RoutePrivate
          exact
          path={ROUTES.treatments}
          component={ExecutionTreatments}
        />
        <RoutePrivate exact path={ROUTES.rhynchophorus} component={Rhynchophorus} />
        <RoutePrivate exact path={ROUTES.mapsanitation} component={MapSanitation} />
        <RoutePrivate exact path={ROUTES.pollination} component={Pollination} />
        <RoutePrivate exact path={ROUTES.pollinationana} component={PollinationAna} />
        <RoutePrivate exact path={ROUTES.yield} component={Yield} />
        <RoutePrivate exact path={ROUTES.mapproduction} component={MapProduction} />
        <RoutePrivate exact path={ROUTES.schedulelabors} component={ScheduleLabors} />
        <RoutePrivate exact path={ROUTES.fertilizationplan} component={fertilizationPlan} />
        <RoutePrivate exact path={ROUTES.supervisiondiseases} component={SuperDiseases} />
        <RoutePrivate exact path={ROUTES.machinerymap} component={MapMachinery} />
        <RoutePrivate exact path={ROUTES.adminsanitation} component={AdminSanitation} />
        <RoutePrivate exact path={ROUTES.adminproduction} component={AdminProduction} />
        <RoutePrivate exact path={ROUTES.adminlabors} component={AdminLabors} />
        <RoutePrivate exact path={ROUTES.adminnutrition} component={AdminNutrition} />
        <RoutePrivate exact path={ROUTES.adminmachinery} component={AdminMachinery} />
        <RoutePrivate exact path={ROUTES.adminseveral} component={AdminSeveral} />
        <RoutePrivate exact path={ROUTES.adminusers} component={AdminUsers} />
        <RoutePrivate exact path={ROUTES.logout} component={Logout} />
      </Switch>
    </Router>
  )
}

export default App
