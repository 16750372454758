import create from "zustand";
import { devtools } from "zustand/middleware";
import { URL_SERVER, paxios } from "../constants/http";

import { localStorageSelect, localStorageCreateUpdate } from "../localStorage";

const getMachinesServer = async (set) => {
  try {
    const machines = await paxios.get(`${URL_SERVER}/machinery`);
    localStorageCreateUpdate("machines", machines.data.data);
    set({ machines: machines.data.data });
  } catch (error) {
    set({ error });
  }
};

const getEquipmentServer = async (set) => {
  try {
    const equipment = await paxios.get(`${URL_SERVER}/machineryEquipment`);
    localStorageCreateUpdate("equipment", equipment.data.data);
    set({ equipment: equipment.data.data });
  } catch (error) {
    set({ error });
  }
};

const units = [
  {
    id: 0,
    name: "Ninguna",
  },
  {
    id: 1,
    name: "Racimos",
  },
  {
    id: 2,
    name: "Hectareas",
  },
  {
    id: 3,
    name: "Bombas",
  },
  {
    id: 4,
    name: "Palmas",
  },
];
const getUnitsServer = async (set) => {
  try {
    localStorageCreateUpdate("unitsMachinery", units);
    set({ unitsMachinery: units });
  } catch (error) {
    set({ error });
  }
};

const store = (set) => ({
  machines: [],
  equipment: [],
  unitsMachinery: [],
  getMachines: async () => {
    await getMachinesServer(set);
  },
  getEquipment: async () => {
    await getEquipmentServer(set);
  },
  getUnitsMachinery: async () => {
    await getUnitsServer(set);
  },
  loadLocalStorageMachinery: async () => {
    const machinesLocal = localStorageSelect("machines");
    machinesLocal === null
      ? await getMachinesServer(set)
      : set({ machines: machinesLocal });

    const equipmentLocal = localStorageSelect("equipment");
    equipmentLocal === null
      ? await getEquipmentServer(set)
      : set({ equipment: equipmentLocal });

    const unitsLocal = localStorageSelect("unitsMachinery");
    unitsLocal === null
      ? await getUnitsServer(set)
      : set({ unitsMachinery: unitsLocal });
  },
});

const machineryStore = create(devtools(store, "machinery"));

export default machineryStore;
