import { useState, useEffect } from 'react'
import {
  IconButton,
  Paper,
  InputBase,
  makeStyles,
} from '@material-ui/core'
import SaveIcon from '@material-ui/icons/Save'
import AddIcon from '@material-ui/icons/Add'
import Swal from 'sweetalert2/src/sweetalert2.js'
import { userStore, cadastreStore } from '../../stores'
import { useTranslation } from 'react-i18next'
import { URL_SERVER, paxios } from '../../constants/http'
import {Select} from '../../components'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '5px',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
}))

export default function Assign({ order, level }) {
  const classes = useStyles()
  const { currentPlantation } = userStore()
  const { sectors, getSectors } = cadastreStore()
  const { t } = useTranslation()
  const [showSectors, setShowSectors] = useState([])
  const [parents, setParents] = useState([])
  const [indexNew, setIndexNew] = useState(1)

  useEffect(() => {
    ;(function () {
      setShowSectors(sectors.filter((x) => x.order === order))
      if (order > 1) {
        setParents(sectors.filter((x) => x.order === order - 1))
      } else {
        setParents([])
      }
    })()
  }, [sectors, setShowSectors, order])

  const handleChangeSector = (e, id) => {
    const { name, value } = e.target
    setShowSectors((d) =>
      d.map((x) => {
        if (x.id !== id) return { ...x }

        return { ...x, [name]: value }
      })
    )
  }

  const handleClickSaveSector = (id, order) => {
    const sector = showSectors.filter((x) => x.id === id)
    if (String(id).indexOf('n') === -1) {
      updateSector(sector[0])
    } else {
      createSector(sector[0])
    }
  }

  const updateSector = async (data) => {
    try {
      const response = await paxios.put(
        `${URL_SERVER}/sectors/${data.id}`,
        data
      )
      if (response.data.status === 'ok') {
        Swal.fire(t('general.finished'), t('general.savesuccess'), 'success')
        getSectors()
      } else {
        Swal.fire(t('general.finished'), t('general.datawasnotsaved'), 'error')
      }
    } catch (error) {
      Swal.fire(t('general.finished'), t('general.datawasnotsaved'), 'error')
    }
    return
  }

  const createSector = async (data) => {
    try {
      const response = await paxios.post(`${URL_SERVER}/sectors`, {
        ...data,
        plantation: currentPlantation.plantation,
      })
      if (response.data.status === 'ok') {
        Swal.fire(t('general.finished'), t('general.savesuccess'), 'success')
        getSectors()
      } else {
        Swal.fire(t('general.finished'), t('general.datawasnotsaved'), 'error')
      }
    } catch (error) {
      Swal.fire(t('general.finished'), t('general.datawasnotsaved'), 'error')
    }
    return
  }

  const handleClickAddSector = () => {
    setShowSectors((d) => [
      ...d,
      {
        id: `n-${indexNew}`,
        name: t('general.new'),
        area: 0,
        perimeter: 0,
        plantation: currentPlantation.plantation,
        parent: 0,
        order,
      },
    ])
    setIndexNew((d) => d + 1)
  }

  const simpleItem = (id, text, order) => {
    return (
      <Paper component="form" className={classes.root} key={id}>
        <InputBase
          className={classes.input}
          placeholder={`${t('general.add')} ${t('general.subdivision')}`}
          value={text}
          name={'name'}
          onChange={(e) => handleChangeSector(e, id)}
        />
        <IconButton
          type="button"
          aria-label="search"
          className={classes.iconButton}
          onClick={() => handleClickSaveSector(id, order)}
        >
          <SaveIcon />
        </IconButton>
      </Paper>
    )
  }

  const item = (id, text, order, parent) => {
    return (
      <Paper component="form" className={classes.root} key={id}>
        <Select
          name="parent"
          text={level}
          width={'50%'}
          items={parents}
          defaultOption0={true}
          value={parent}
          handleChange={(e) => handleChangeSector(e, id)}
          error={parent === '0' || parent === 0}
        />
        <InputBase
          className={classes.input}
          placeholder={`${t('general.add')} ${t('general.subdivision')}`}
          value={text}
          name={'name'}
          onChange={(e) => handleChangeSector(e, id)}
        />
        <IconButton
          type="button"
          aria-label="search"
          className={classes.iconButton}
          onClick={() => handleClickSaveSector(id, order)}
        >
          <SaveIcon />
        </IconButton>
      </Paper>
    )
  }

  return (
    <>
      <IconButton
        aria-label="delete"
        color={'primary'}
        style={{ backgroundColor: '#d4cfcf', marginBottom: 5 }}
        onClick={handleClickAddSector}
      >
        <AddIcon />
      </IconButton>
      {showSectors.length > 0 &&
        showSectors.map((x) => {
          if (order === 1) {
            return simpleItem(x.id, x.name, x.order)
          } else {
            return item(x.id, x.name, x.order, x.parent)
          }
        })}
    </>
  )
}
