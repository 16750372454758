import { useTranslation } from 'react-i18next'
import { userStore } from '../../../stores'
import { URL_SERVER, paxios, uploadFile } from '../../../constants/http'
import { formatNumber } from '../../../constants/utilities'
import { LoadExcel } from '../../../components'

export default function Details() {
  const { currentPlantation } = userStore()
  const { t } = useTranslation()

  const columns = [
    { id: 'tiket', label: 'Tiket' },
    { id: 'plot_name', label: t('general.plot') },
    { id: 'date_yield', label: `${t('general.date')} ${t('general.yield')}` },
    { id: 'remission', label: t('general.remission') },
    { id: 'bunches', label: t('general.bunches') },
    { id: 'weight_half', label: t('general.weighthalf') },
    { id: 'weight_net', label: t('general.weightnet') },
    { id: 'worker_name', label: t('general.worker') },
    { id: 'quadrille_name', label: t('general.quadrille') },
    { id: 'observations', label: t('general.observations') },
    { id: 'errors', label: t('general.errors') },
  ]

  const loadFile = async (file) => {
    try {
      const response = await uploadFile({
        url: `${URL_SERVER}/detailsTravelsYield/loadExcelDetailsTravels`,
        file,
        nameFile: 'file_details_travels',
        aditionalData: [
          { name: 'plantation', value: currentPlantation.plantation },
        ],
      })
      return {
        ...response,
        data: response.data.map((x) => ({
          ...x,
          weight_net: isNaN(x.weight_net) ? '' : formatNumber(x.weight_net),
        })),
      }
    } catch (error) {}
  }

  const saveData = async (records) => {
    try {
      const response = await paxios.post(
        `${URL_SERVER}/detailsTravelsYield/saveExcelDetailsTravels`,
        {
          plantation: currentPlantation.plantation,
          data: records,
        }
      )
      return response
    } catch (error) {}
  }
  return (
    <>
      <LoadExcel columns={columns} loadFile={loadFile} saveData={saveData} />
    </>
  )
}
