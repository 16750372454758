import { useState, useRef } from 'react'
import dayjs from 'dayjs'
import {
  Grid,
  Paper,
  Typography,
  makeStyles,
  IconButton,
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import { useTranslation } from 'react-i18next'
import {
  PlotsSelector,
  Button,
  DatePickerYM,
  DiseasesSelector,
} from '../../../components'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  gridContainer: {
    marginBottom: 10,
  },
}))

export default function IncidentStatisticsSearch({ handleClickSearch }) {
  const classes = useStyles()
  const { t } = useTranslation()
  const plotsSelectorRef = useRef(null)
  const diseasesSelectorRef = useRef(null)
  const [queryData, setQueryData] = useState({
    plot: 0,
    year: 0,
    census: 0,
    diseases: 0,
    date: null,
  })

  const handleSelectPlot = (plot) => {
    setQueryData((d) => ({ ...d, plot: plot.length > 0 ? plot : 0 }))
    plotsSelectorRef.current !== null && plotsSelectorRef.current.setOpen(false)
  }

  const handleSelectDiseases = (diseases) => {
    setQueryData((d) => ({
      ...d,
      diseases: diseases.length > 0 ? diseases : 0,
    }))
    diseasesSelectorRef.current !== null &&
      diseasesSelectorRef.current.setOpen(false)
  }

  const handleDateChange = (e) => {
    const { name, value } = e.target
    setQueryData((d) => ({ ...d, [name]: value }))
    const date = dayjs(value).format('YYYY-MM')
    const values = date.split('-')
    setQueryData((d) => ({ ...d, year: values[0] }))
    setQueryData((d) => ({ ...d, census: values[1] }))
  }

  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      spacing={2}
      className={classes.gridContainer}
    >
      <Grid item xs={12} md={4}>
        <Paper component="form" className={classes.root}>
          <Typography variant="subtitle1">
            {queryData.plot !== 0
              ? queryData.plot.reduce((acc, item) => {
                  return (acc += acc !== '' ? ',' + item.name : item.name)
                }, '')
              : `${t('general.select')} ${t('general.plot')}`}
          </Typography>
          <IconButton
            type="button"
            aria-label="search"
            className={classes.iconButton}
            onClick={() =>
              plotsSelectorRef.current !== null &&
              plotsSelectorRef.current.setOpen(true)
            }
          >
            <SearchIcon />
          </IconButton>
        </Paper>
        <PlotsSelector
          accept={handleSelectPlot}
          ref={plotsSelectorRef}
          single={false}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <DatePickerYM
          label={`${t('general.month')} ${t('general.year')}`}
          name={'date'}
          required={false}
          selectedDate={queryData.date}
          handleDateChange={handleDateChange}
          error={queryData.date === null || queryData.date === ''}
        />
      </Grid>
      <Grid item xs={12} md={5}>
        <Paper component="form" className={classes.root}>
          <Typography variant="subtitle1">
            {queryData.diseases !== 0
              ? queryData.diseases.reduce((acc, item) => {
                  return (acc += acc !== '' ? ',' + item.name : item.name)
                }, '')
              : `${t('general.select')} ${t('general.disease')}`}
          </Typography>
          <IconButton
            type="button"
            aria-label="search"
            className={classes.iconButton}
            onClick={() =>
              diseasesSelectorRef.current !== null &&
              diseasesSelectorRef.current.setOpen(true)
            }
          >
            <SearchIcon />
          </IconButton>
        </Paper>
        <DiseasesSelector
          accept={handleSelectDiseases}
          ref={diseasesSelectorRef}
          single={false}
        />
      </Grid>

      <Button
        icon={<SearchIcon />}
        handleButtonClick={() =>
          handleClickSearch({
            ...queryData,
          })
        }
        text={t('general.search')}
      />
    </Grid>
  )
}
