import { useTranslation } from 'react-i18next'
import { userStore } from '../../../stores'
import { URL_SERVER, paxios, uploadFile } from '../../../constants/http'
import { LoadExcel } from '../../../components'

export default function ByExcelPlan() {
  const { currentPlantation } = userStore()
  const { t } = useTranslation()

  const columns = [
    { id: 'year', label: t('general.year') },
    { id: 'plot', label: t('general.plot') },
    { id: 'product', label: t('general.product') },
    { id: 'palms', label: t('general.palms') },
    { id: 'labor', label: t('general.labor') },
    { id: 'date', label: t('general.date') },
    { id: 'dose', label: t('general.dose') },
    { id: 'errors', label: t('general.errors') },
  ]

  const loadFile = async (file) => {
    try {
      const response = await uploadFile({
        url: `${URL_SERVER}/fertilizationPlan/loadExcelPlan`,
        file,
        nameFile: 'file_plan',
        aditionalData: [
          { name: 'plantation', value: currentPlantation.plantation },
        ],
      })
      return response
    } catch (error) {}
  }

  const saveData = async (records) => {
    try {
      const response = await paxios.post(
        `${URL_SERVER}/fertilizationPlan/saveExcelPlan`,
        {
          plantation: currentPlantation.plantation,
          data: records,
        }
      )
      return response
    } catch (error) {}
  }

  return (
    <>
      <LoadExcel columns={columns} loadFile={loadFile} saveData={saveData} />
    </>
  )
}
