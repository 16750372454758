import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Swal from 'sweetalert2/src/sweetalert2.js'
import {
  userStore,
  sanitationStore,
  cadastreStore,
  severalStore,
} from '../../../stores'
import { URL_SERVER, paxios } from '../../../constants/http'
import MaterialTable, { MTableToolbar } from '@material-table/core'
import { Grid, makeStyles } from '@material-ui/core'
import SaveIcon from '@material-ui/icons/Save'
import tableIcons from '../../../constants/IconsMaterialTable'
import tableTranslations from '../../../constants/tableTranslations'
import {
  SanitationRecordsSearch,
  SubTitle,
  Select,
  Button,
} from '../../../components'

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    marginBottom: 10,
  },
}))

const Generate = () => {
  const classes = useStyles()
  const { t, i18n } = useTranslation()
  const { currentPlantation } = userStore()
  const { diseases, eventsDiseases } = sanitationStore()
  const { plots } = cadastreStore()
  const { workers } = severalStore()
  const [years, setYears] = useState([])
  const [census, setCensus] = useState([])
  const [columns, setColumns] = useState([])
  const [recodsSelected, setRecodsSelected] = useState([])
  const [workerSelected, setWorkerSelected] = useState(0)
  const [lastDataSearch, setLastDataSearch] = useState({})

  const getYears = async (plantation) => {
    try {
      const response = await paxios.get(
        `${URL_SERVER}/censusDiaseases/getYearsCensus/${plantation}`
      )
      if (response.data.status === 'ok') {
        setYears(response.data.data)
      }
    } catch (error) {}
  }

  useEffect(() => {
    getYears(currentPlantation.plantation)
  }, [currentPlantation.plantation])

  useEffect(() => {
    setColumns([
      { title: 'ID', field: 'id' },
      { title: t('general.census'), field: 'census' },
      { title: t('general.date'), field: 'date' },
      {
        title: t('general.plot'),
        field: 'plot',
        lookup: {
          0: t('general.notassigned'),
          ...plots.reduce((acc, item) => {
            return { ...acc, [item.id]: item.name }
          }, {}),
        },
      },
      { title: t('general.line'), field: 'line' },
      { title: t('general.palm'), field: 'palm' },
      {
        title: t('general.disease'),
        field: 'disease',
        lookup: {
          0: t('general.notassigned'),
          ...diseases.reduce((acc, item) => {
            return { ...acc, [item.id]: item.name }
          }, {}),
        },
      },
      {
        title: t('general.event'),
        field: 'event_disease',
        lookup: {
          0: t('general.notassigned'),
          ...eventsDiseases.reduce((acc, item) => {
            return { ...acc, [item.id]: item.name }
          }, {}),
        },
      },
      {
        title: t('general.worker'),
        field: 'worker',
        lookup: {
          0: t('general.notassigned'),
          ...workers.reduce((acc, item) => {
            return { ...acc, [item.id]: item.name }
          }, {}),
        },
      },
      { title: t('general.observations'), field: 'observations' },
    ])
  }, [t, plots, diseases, eventsDiseases, workers])

  const dataSearch = (data) => {
    const send = {
      ...data,
      plot:
        data.plot !== '0' && data.plot !== 0 ? data.plot.map((x) => x.id) : [],
      dates:
        data.initialDate !== null && data.endDate !== null
          ? `${data.initialDate},${data.endDate}`
          : '',
      plantation: currentPlantation.plantation,
    }
    setLastDataSearch(send)
    getCensus(send)
  }

  const getCensus = async (data) => {
    try {
      const response = await paxios.post(
        `${URL_SERVER}/censusDiaseases/getCensus`,
        data
      )
      if (response.data.status === 'ok') {
        setCensus(response.data.data.filter((x) => x.order_labor === '0'))
      }
    } catch (error) {
      Swal.fire(t('general.finished'), t('general.errortoquery'), 'error')
    }
  }

  const selectRow = (rows) => {
    setRecodsSelected(rows)
  }

  const handleChangeWorker = (e) => {
    const { value } = e.target
    setWorkerSelected(value)
  }

  const validateButtonGenerate = () => {
    if (recodsSelected.length <= 0) return true
    if (String(workerSelected) === '0') return true

    return false
  }

  const generate = async (worker, records) => {
    try {
      const response = await paxios.post(
        `${URL_SERVER}/ordersLabor/generateOrderLabor`,
        {
          worker,
          records,
          plantation: currentPlantation.plantation,
        }
      )
      if (response.data.status === 'ok') {
        setRecodsSelected([])
        getCensus(lastDataSearch)
        Swal.fire(
          t('general.finished'),
          `${t('general.savesuccess')} - ${t('general.order')}: ${
            response.data.data.id
          }`,
          'success'
        )
      }
    } catch (error) {
      Swal.fire(t('general.finished'), 'Error', 'error')
    }
  }

  const handleClickGenerate = () => {
    generate(workerSelected, recodsSelected)
  }

  return (
    <>
      <SubTitle text={t('general.generate')} />
      <SanitationRecordsSearch
        years={years.map((x) => ({ id: x.year, name: x.year }))}
        titleCensus={t('general.census')}
        handleClickSearch={dataSearch}
        datesOptional={true}
      />
      <MaterialTable
        icons={tableIcons}
        columns={columns}
        title={t('general.records')}
        data={census}
        options={{
          exportButton: true,
          exportAllData: true,
          exportDelimiter: ';',
          selection: true,
        }}
        localization={i18n.language === 'es' ? tableTranslations : {}}
        onSelectionChange={selectRow}
        components={{
          Toolbar: (props) => (
            <div>
              <MTableToolbar {...props} />
              <Grid
                container
                justify="center"
                spacing={2}
                className={classes.gridContainer}
              >
                <Grid item xs={12} md={3}></Grid>
                <Grid item xs={12} md={3}>
                  <Select
                    text={t('general.worker')}
                    width={'100%'}
                    items={workers.map((x) => ({ id: x.id, name: x.name }))}
                    defaultOption0={true}
                    value={workerSelected}
                    handleChange={handleChangeWorker}
                  />
                  <Button
                    icon={<SaveIcon />}
                    handleButtonClick={handleClickGenerate}
                    text={t('general.generate')}
                    disabled={validateButtonGenerate()}
                    loading={false}
                  />
                </Grid>
                <Grid item xs={12} md={3}></Grid>
              </Grid>
            </div>
          ),
        }}
      />
    </>
  )
}

export default Generate
