import { useState, useEffect } from 'react'
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Paper,
  InputBase,
  makeStyles,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import SaveIcon from '@material-ui/icons/Save'
import EditIcon from '@material-ui/icons/Edit'
import Swal from 'sweetalert2/src/sweetalert2.js'
import { userStore } from '../../stores'
import { useTranslation } from 'react-i18next'
import { ROUTES } from '../../constants/routes'
import { URL_SERVER, paxios } from '../../constants/http'
import {TitlePage} from '../../components'
import Assign from './Assign'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
}))

export default function Subdivision() {
  const classes = useStyles()
  const { setUser, currentPlantation } = userStore()
  const { t } = useTranslation()
  const [levels, setLevels] = useState([])
  const [inputAddLevel, setinputAddLevel] = useState(false)
  const [textNewLevel, setTextNewLevel] = useState('')
  const [levelSelected, setLevelSelected] = useState(0)

  useEffect(() => {
    setUser({ currentUrl: ROUTES.subdivision })
  }, [setUser])

  const getLevelsAggregation = async (plantation) => {
    try {
      const response = await paxios.get(
        `${URL_SERVER}/plantations/levesAggregation/${plantation}`
      )

      response.data.status === 'ok' && setLevels(response.data.data)
    } catch (error) {}
  }

  useEffect(() => {
    getLevelsAggregation(currentPlantation.plantation)
  }, [currentPlantation.plantation])


  const handleClickAddLevel = () => {
    setinputAddLevel(true)
  }

  const handleClickSaveLevel = () => {
    const order = levels.length + 1
    saveLevel(textNewLevel, order)
  }

  const hanldeClickSwitchUpdateLevel = (order) => {
    setLevels((d) =>
      d.map((x) => {
        if (x.order !== order) return { ...x }
        return { ...x, edit: true }
      })
    )
  }

  const handleChangeEditLevel = (text, order) => {
    setLevels((d) =>
      d.map((x) => {
        if (x.order !== order) return { ...x }
        return { ...x, level: text }
      })
    )
  }

  const handleClickUpdateLevel = (order) => {
    const updateLevel = levels.filter(x => x.order === order);
    updateLevel.length > 0 && saveLevel(updateLevel[0].level, order);
  }

  const saveLevel = async (name, order) => {
    try {
      const response = await paxios.post(
        `${URL_SERVER}/plantations/createLevelAggregation`,
        {
          order,
          name,
          plantation: currentPlantation.plantation,
        }
      )

      if (response.data.status === 'ok') {
        Swal.fire(t('general.finished'), t('general.savesuccess'), 'success')
        setTextNewLevel('')
        setinputAddLevel(false)
        getLevelsAggregation(currentPlantation.plantation)
      }
    } catch (error) {
      Swal.fire(t('general.finished'), t('general.datawasnotsaved'), 'error')
    }
  }

  return (
    <>
      <TitlePage
        text={`${t('general.subdivision')} ${t('general.plantation')}`}
      />
      <Grid container justify="center" >
        <Grid item xs={12} md={3}>
          <IconButton
            aria-label="delete"
            color={'primary'}
            style={{ backgroundColor: '#d4cfcf' }}
            disabled={levels.length >= 4}
            onClick={handleClickAddLevel}
          >
            <AddIcon />
          </IconButton>

          <List component="nav" aria-label="main mailbox folders">
            {levels.map((x) => {
              if (!x.edit) {
                return (
                  <ListItem button key={x.order} onClick={() => setLevelSelected(x.order)} selected={levelSelected === x.order?true:false}>
                    <ListItemText primary={x.level} />
                    <IconButton
                      type="button"
                      aria-label="search"
                      className={classes.iconButton}
                      onClick={() => hanldeClickSwitchUpdateLevel(x.order)}
                    >
                      <EditIcon />
                    </IconButton>
                  </ListItem>
                )
              } else {
                return (
                  <Paper
                    component="form"
                    className={classes.root}
                    key={x.order}
                  >
                    <InputBase
                      className={classes.input}
                      placeholder={`${t('general.add')} ${t(
                        'general.subdivision'
                      )}`}
                      value={x.level}
                      onChange={(e) =>
                        handleChangeEditLevel(e.target.value, x.order)
                      }
                    />
                    <IconButton
                      type="button"
                      aria-label="search"
                      className={classes.iconButton}
                      onClick={() => handleClickUpdateLevel(x.order)}
                    >
                      <SaveIcon />
                    </IconButton>
                  </Paper>
                )
              }
            })}
          </List>

          {inputAddLevel && (
            <Paper component="form" className={classes.root}>
              <InputBase
                className={classes.input}
                placeholder={`${t('general.add')} ${t('general.subdivision')}`}
                value={textNewLevel}
                onChange={(e) => setTextNewLevel(e.target.value)}
              />
              <IconButton
                type="button"
                aria-label="search"
                className={classes.iconButton}
                onClick={handleClickSaveLevel}
              >
                <SaveIcon />
              </IconButton>
            </Paper>
          )}
        </Grid>

        <Grid item xs={12} md={1} />

        <Grid item xs={12} md={6}>
          {levelSelected !== 0 && (
            <Assign order={levelSelected} level={levelSelected > 1 ? levels.filter(x => x.order === (levelSelected-1))[0].level : ""} />
          )

          }
        </Grid>
      </Grid>
    </>
  )
}
