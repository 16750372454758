import React, {useState} from 'react'

import Travels from './Travels'
import Details from './Details'

export default function Home() {
    const [travel, setTravel] = useState([])
    return (
        <>
           <Travels rowSelec={travel} setRowSelec={setTravel} />
           {travel.length > 0 && <Details travel={travel[0]} />}
        </>
    )
}
