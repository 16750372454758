import { useState } from 'react'
import {
  makeStyles,
  Card,
  CardContent,
  Typography,
  Divider,
  CardHeader,
} from '@material-ui/core'
import Swal from 'sweetalert2/src/sweetalert2.js'
import SaveIcon from '@material-ui/icons/Save'
import { useTranslation } from 'react-i18next'
import { cadastreStore } from '../../stores'
import { URL_SERVER, paxios } from '../../constants/http'
import { Button, Select } from '../../components'

export default function AssignMaterialAllPalms({ plot, getPalms }) {
  const useStyles = makeStyles((theme) => ({
    paper: {
      //height: '120px',
      backgroundColor: theme.palette.background.paper,
      outline: 'none',
      border: '2px solid #fff',
      //paddingBottom: 20,
      borderRadius: 0,
      marginTop: 30,
      //marginBottom: 20,
      boxShadow: theme.shadows[5],
      //padding: theme.spacing(2, 4, 3),
    },
    cardContent: {
      display: 'flex',
      justifyContent: 'space-around',
    },
  }))
  const classes = useStyles()
  const { t } = useTranslation()
  const { materials } = cadastreStore()
  const [saving, setSaving] = useState(false)
  const [materialSelect, setMaterialSelect] = useState(0)

  const handleChange = (e) => {
    const { value } = e.target
    setMaterialSelect(value)
  }

  const handleClickSave = () => {
    save(plot, materialSelect)
  }

  const save = async (plot, materialSelect) => {
    if (materialSelect === 0) {
      Swal.fire(t('general.check'), t('general.selectanitem'), 'error')
      return
    }
    try {
      setSaving(true)
      const send = {
        material: materialSelect,
      }
      const response = await paxios.put(
        `${URL_SERVER}/palms/assignMaterial/${plot}`,
        send
      )
      if (response.data.status === 'ok') {
        Swal.fire(t('general.finished'), t('general.savesuccess'), 'success')
        getPalms(plot)
      } else {
        Swal.fire(t('general.finished'), response.data.message, 'error')
      }
      setSaving(false)
    } catch (error) {
      Swal.fire(t('general.finished'), t('general.datawasnotsaved'), 'error')
      setSaving(false)
    }
  }

  return (
    <Card className={classes.paper}>
      <CardHeader
        title={
          <Typography variant="h6" component="h2" color={'primary'}>
            {t('general.assignmaterialtoallpalms')}
          </Typography>
        }
        subheader=""
      />
      <Divider />
      <CardContent className={classes.cardContent}>
        <Select
          text={'Material'}
          width={200}
          items={materials}
          defaultOption0={true}
          value={materialSelect}
          handleChange={handleChange}
        />
        <Button
          icon={<SaveIcon />}
          text={t('general.save')}
          loading={saving}
          handleButtonClick={handleClickSave}
        />
      </CardContent>
    </Card>
  )
}
