import { useEffect, useState, useRef } from "react";
import Swal from "sweetalert2/src/sweetalert2.js";
import MaterialTable from "material-table";
import EditIcon from "@material-ui/icons/Edit";
import { useTranslation } from "react-i18next";
import { severalStore, userStore } from "../../../../stores";
import { URL_SERVER, paxios } from "../../../../constants/http";
import tableIcons from "../../../../constants/IconsMaterialTable";
import tableTranslations from "../../../../constants/tableTranslations";
import UpdateSeveral from "./UpdateSeveral";

const cellStyle = { align: "center", headerStyle: { textAlign: "center" } };

export default function Workers() {
  const { t, i18n } = useTranslation();
  const { workers, getWorkers, quadrilles, companies } = severalStore();
  const { currentPlantation, user } = userStore();
  const [columns, setColumns] = useState([]);
  const updateSeveralDialogRef = useRef(null);

  useEffect(() => {
    const validateEmpty = (rowData, field) => {
      return rowData[field] === "" || rowData[field] === undefined
        ? t("general.noempty")
        : true;
    };

    setColumns([
      { title: "ID", field: "id", editable: "never" },
      {
        title: t("general.name"),
        field: "name",
        validate: (rowData) => validateEmpty(rowData, "name"),
      },
      {
        title: t("general.document"),
        field: "document",
        validate: (rowData) => validateEmpty(rowData, "document"),
      },
      {
        title: t("general.code"),
        field: "code",
        initialEditValue: "",
      },
      {
        title: t("general.state"),
        field: "state",
        lookup: { 1: t("general.active"), 0: t("general.inactive") },
        initialEditValue: 1,
        ...cellStyle,
      },
      {
        title: t("general.supervisor"),
        field: "supervisor",
        lookup: { 1: t("general.yes"), 0: "No" },
        initialEditValue: 0,
        ...cellStyle,
      },
      {
        title: t("general.device"),
        field: "device",
        initialEditValue: "",
      },
      {
        title: t("general.company"),
        field: "company",
        lookup: {
          0: t("general.notassigned"),
          ...companies.reduce((acc, item) => {
            return { ...acc, [item.id]: item.name };
          }, {}),
        },
        initialEditValue: 0,
        ...cellStyle,
      },
      {
        title: `${t("general.code")} ${t("general.mobile")}`,
        field: "code_mobile",
        type: "numeric",
        initialEditValue: 0,
      },
      {
        title: t("general.quadrille"),
        field: "quadrille",
        lookup: {
          0: t("general.notassigned"),
          ...quadrilles.reduce((acc, item) => {
            return { ...acc, [item.id]: item.name };
          }, {}),
        },
        initialEditValue: 0,
        ...cellStyle,
      },
    ]);
  }, [t, quadrilles, companies]);

  const createWorker = async (data) => {
    try {
      const response = await paxios.post(`${URL_SERVER}/workers`, {
        ...data,
        user: user.id,
        plantation: currentPlantation.plantation,
        code: data.code ? data.code : "",
        device: data.device ? data.device : "",
      });
      if (response.data.status === "ok") {
        Swal.fire(t("general.finished"), t("general.savesuccess"), "success");
        getWorkers();
      } else {
        Swal.fire(t("general.finished"), response.data.message, "error");
      }
    } catch (error) {
      Swal.fire(t("general.finished"), t("general.datawasnotsaved"), "error");
    }
  };

  const onRowAddWorker = (newData) =>
    new Promise(async (resolve) => {
      await createWorker(newData);
      resolve();
    });

  const updateWorker = async (data) => {
    try {
      const response = await paxios.put(`${URL_SERVER}/workers/${data.id}`, {
        ...data,
        user: user.id,
        plantation: currentPlantation.plantation,
      });
      if (response.data.status === "ok") {
        Swal.fire(t("general.finished"), t("general.savesuccess"), "success");
        getWorkers();
      } else {
        Swal.fire(t("general.finished"), t("general.datawasnotsaved"), "error");
      }
    } catch (error) {
      Swal.fire(t("general.finished"), t("general.datawasnotsaved"), "error");
    }
  };

  const onRowUpdateWorker = (newData, oldData) =>
    new Promise(async (resolve) => {
      await updateWorker(newData);
      resolve();
    });

  const openEditSeveral = (evt, data) => {
    updateSeveralDialogRef.current.setworkers(data);
    updateSeveralDialogRef.current.setOpen(true);
  };

  const updateSeveralWorkers = async (data, workers) => {
    try {
      const response = await paxios.post(
        `${URL_SERVER}/workers/updateSeveral`,
        {
          data,
          workers,
        }
      );
      if (response.data.status === "ok") {
        Swal.fire(t("general.finished"), t("general.savesuccess"), "success");
        getWorkers();
        updateSeveralDialogRef.current.setworkers([]);
        updateSeveralDialogRef.current.setOpen(false);
        updateSeveralDialogRef.current.reset();
      } else {
        Swal.fire(t("general.finished"), response.data.message, "error");
      }
    } catch (error) {
      Swal.fire(t("general.finished"), t("general.datawasnotsaved"), "error");
    }
  };

  return (
    <>
      <MaterialTable
        icons={tableIcons}
        columns={columns}
        title={`${t("general.worker")}(s)`}
        data={workers.map((x) => ({
          ...x,
          ti: new Date(),
        }))}
        options={{
          exportButton: true,
          exportAllData: true,
          exportDelimiter: ";",
          selection: true,
        }}
        localization={i18n.language === "es" ? tableTranslations : {}}
        actions={[
          {
            tooltip: t("general.edit"),
            icon: () => <EditIcon />,
            onClick: openEditSeveral,
          },
        ]}
        editable={{
          onRowUpdate: onRowUpdateWorker,
          onRowAdd: onRowAddWorker,
        }}
      />

      <UpdateSeveral
        ref={updateSeveralDialogRef}
        update={updateSeveralWorkers}
      />
    </>
  );
}
