import { useState, useEffect, useRef } from "react";
import { URL_SERVER, paxios } from "../../../../constants/http";
import { useTranslation } from "react-i18next";
import { Calendar, Button } from "../../../../components";
import ListPlan from "./ListPlan";
import CreateEditFraction from "./CreateEditFraction";

export default function Home() {
  const { t } = useTranslation();
  const [events, setEvents] = useState([]);
  const [eventSelected, setEventSelected] = useState(null);
  const [dateNew, setDateNew] = useState("");
  const [planSelected, setPlanSelected] = useState(0)
  const listPlanRef = useRef(null);
  const createRef = useRef(null);

  useEffect(() => {
    eventSelected &&
      setEventSelected((d) => ({
        ...d,
        extendedProps: {
          ...d.extendedProps,
          ...events.find((x) => String(x.id) === String(d.publicId)),
        },
      }));
    // eslint-disable-next-line
  }, [events]);

  const getFractions = async (planId) => {
    try {
      const response = await paxios.get(
        `${URL_SERVER}/fertilizationPlanFractions/plan/${planId}`
      );
      response.data.status === "ok" && setEvents(response.data.data);
    } catch (error) {}
  };

  const handleClickEvent = (eventInfo) => {
    setEventSelected({ ...eventInfo.event._def });
    setDateNew("");
    createRef.current.setOpen(true)
  };

  const handleClickDay = (day) => {
    setDateNew(day.startStr);
    setEventSelected(null)
    planSelected !== 0 && createRef.current.setOpen(true)
  };

  const handleChangeDates = (data) => {};

  const handleClickSearchFractions = (data) => {
    if (data.length > 0) {
      getFractions(data[0].id);
      setPlanSelected(data[0].id)
    } else {
      setEvents([]);
      setPlanSelected(0)
    }
  };
  return (
    <>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          size={"small"}
          handleButtonClick={() => listPlanRef.current.setOpen(true)}
          text={`${t("general.show")} ${t("general.plans")}`}
        />
      </div>

      <ListPlan
        ref={listPlanRef}
        handleClickSearchFractions={handleClickSearchFractions}
      />

      <Calendar
        events={events}
        handleClickEvent={handleClickEvent}
        handleClickDay={handleClickDay}
        handleChangeDates={handleChangeDates}
      />

      <CreateEditFraction
        ref={createRef}
        listPlanRef={listPlanRef}
        fraction={eventSelected}
        dateNew={dateNew}
        plan={planSelected}
        getFractions={getFractions}
        setEventSelectedCalendar={setEventSelected}
      />
    </>
  );
}
