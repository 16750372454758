import { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import Swal from 'sweetalert2/src/sweetalert2.js'
import SearchIcon from '@material-ui/icons/Search'
import { severalStore, userStore } from '../../../stores'
import { URL_SERVER, paxios } from '../../../constants/http'
//import MaterialTable from '@material-table/core'
import MaterialTable from 'material-table'
import tableIcons from '../../../constants/IconsMaterialTable'
import tableTranslations from '../../../constants/tableTranslations'
import { Grid, makeStyles } from '@material-ui/core'
import { SubTitle, Button, Select, DatePickerRange } from '../../../components'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  gridContainer: {
    marginBottom: 10,
  },
}))

const Administration = () => {
  const classes = useStyles()
  const { t, i18n } = useTranslation()
  const { currentPlantation } = userStore()
  const { workers } = severalStore()
  const [workerSelected, setWorkerSelected] = useState(0)
  const [columns, setColumns] = useState([])
  const [columnsDetails, setColumnsDetails] = useState([])
  const [dates, setDates] = useState({
    initialDate: null,
    endDate: null,
  })
  const [orders, setOrders] = useState([])
  const [rowSelec, setRowSelec] = useState([])
  const [datailsOrder, setDatailsOrder] = useState([])

  useEffect(() => {
    setColumns([
      { title: 'ID', field: 'id', editable: 'never' },
      { title: t('general.date'), field: 'date', editable: 'never' },
      {
        title: t('general.worker'),
        field: 'worker',
        lookup: {
          0: t('general.notassigned'),
          ...workers.reduce((acc, item) => {
            return { ...acc, [item.id]: item.name }
          }, {}),
        },
      },
      { title: t('general.records'), field: 'records', editable: 'never' },
      { title: t('general.state'), field: 'statetext', editable: 'never' },
    ])
  }, [t, workers])

  useEffect(() => {
    setColumnsDetails([
      { title: t('general.order'), field: 'order_labor' },
      { title: 'ID', field: 'id' },
      { title: t('general.date'), field: 'date' },
      { title: t('general.hour'), field: 'hour' },
      { title: t('general.census'), field: 'census' },
      { title: t('general.line'), field: 'line' },
      { title: t('general.palm'), field: 'palm' },
      { title: t('general.plot'), field: 'plot_name' },
      { title: t('general.disease'), field: 'disease_name' },
      { title: t('general.event'), field: 'event_name' },
      { title: t('general.worker'), field: 'worker_name' },
      { title: t('general.observations'), field: 'observations' },
      { title: t('general.state'), field: 'statetext' },
      {
        title: `${t('general.date')} ${t('general.treatment')}`,
        field: 'date_treatment',
      },
      {
        title: `${t('general.hour')} ${t('general.treatment')}`,
        field: 'hour_treatment',
      },
      { title: `${t('general.treatment')}`, field: 'treatment_name' },
      {
        title: `${t('general.disease')} ${t('general.treatment')}`,
        field: 'disease_treatment_name',
      },
      {
        title: `${t('general.event')} ${t('general.treatment')}`,
        field: 'event_disease_treatment_name',
      },
      {
        title: `${t('general.worker')} ${t('general.treatment')}`,
        field: 'treater_name',
      },
      {
        title: `${t('general.observations')} ${t('general.treatment')}`,
        field: 'observations_treatment',
      },
    ])
  }, [t])

  const searchDetailsOrder = useCallback(
    async (orderId) => {
      try {
        const response = await paxios.get(
          `${URL_SERVER}/ordersLabor/consultDetailsOrderLabor/${orderId}`
        )

        if (response.data.status === 'ok') {
          setDatailsOrder(response.data.data)
        }
      } catch (error) {
        Swal.fire(t('general.finished'), 'Error', 'error')
      }
    },
    [t]
  )

  useEffect(() => {
    if (rowSelec.length > 0) {
      searchDetailsOrder(rowSelec[0].id)
    } else {
      setDatailsOrder([])
    }
  }, [rowSelec, searchDetailsOrder])

  const handleChangeWorker = (e) => {
    const { value } = e.target
    setWorkerSelected(value)
  }

  const handleDateRangeChange = (e) => {
    const { name, value } = e.target
    setDates((d) => ({ ...d, [name]: value }))
  }

  const validateButtonSearch = () => {
    if (dates.initialDate === null) return true
    if (String(dates.initialDate) === 'Invalid Date') return true
    if (dates.endDate === null) return true
    if (String(dates.endDate) === 'Invalid Date') return true

    return false
  }

  const prepareData = () => {
    const initialDate = dayjs(dates.initialDate).format('YYYY-MM-DD')
    const endDate = dayjs(dates.endDate).format('YYYY-MM-DD')
    const worker = workerSelected

    return {
      dateInit: initialDate,
      dateEnd: endDate,
      worker,
      plantation: currentPlantation.plantation,
    }
  }

  const search = async (data) => {
    try {
      const response = await paxios.post(
        `${URL_SERVER}/ordersLabor/consultOrdersLabor`,
        data
      )

      if (response.data.status === 'ok') {
        setOrders(response.data.data)
      }
    } catch (error) {
      Swal.fire(t('general.finished'), 'Error', 'error')
    }
  }

  const handleClickSearch = () => {
    const data = prepareData()
    search(data)
  }

  const criterialDisabled = (currentId) => {
    if (rowSelec.length <= 0)
      return {
        disabled: false,
      }

    if (currentId !== rowSelec[0].id) {
      return {
        disabled: true,
        color: 'primary',
      }
    } else if (currentId === rowSelec[0].id) {
      return {
        disabled: false,
        checked: true,
      }
    }

    return {
      disabled: false,
    }
  }

  const selectRow = (rows) => {
    setRowSelec(rows)
  }

  const updateOrder = async (data) => {
    try {
      const response = await paxios.put(
        `${URL_SERVER}/ordersLabor/${data.id}`,
        data
      )
      if (response.data.status === 'ok') {
        Swal.fire(t('general.finished'), t('general.savesuccess'), 'success')
        const data = prepareData()
        search(data)
      } else {
        Swal.fire(t('general.finished'), t('general.datawasnotsaved'), 'error')
      }
    } catch (error) {
      Swal.fire(t('general.finished'), t('general.datawasnotsaved'), 'error')
    }
  }

  const onRowUpdate = (newData, oldData) =>
    new Promise(async (resolve) => {
      await updateOrder(newData)
      resolve()
    })
  return (
    <>
      <SubTitle text={t('general.administration')} />
      <Grid
        container
        justify="center"
        spacing={2}
        className={classes.gridContainer}
      >
        <Grid item xs={12} md={3}>
          <DatePickerRange
            initialDate={dates.initialDate}
            endDate={dates.endDate}
            handleDateChange={handleDateRangeChange}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Select
            text={t('general.worker')}
            width={'100%'}
            items={workers.map((x) => ({ id: x.id, name: x.name }))}
            defaultOption0={true}
            value={workerSelected}
            handleChange={handleChangeWorker}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Button
            icon={<SearchIcon />}
            disabled={validateButtonSearch()}
            handleButtonClick={handleClickSearch}
            text={t('general.search')}
          />
        </Grid>
      </Grid>

      <MaterialTable
        icons={tableIcons}
        columns={columns}
        title={t('general.orders')}
        data={orders}
        options={{
          exportButton: true,
          exportAllData: true,
          exportDelimiter: ';',
          selection: true,
          showSelectAllCheckbox: false,
          selectionProps: (rowData) => criterialDisabled(rowData.id),
        }}
        editable={{
          onRowUpdate: onRowUpdate,
        }}
        localization={i18n.language === 'es' ? tableTranslations : {}}
        onSelectionChange={selectRow}
      />
      {datailsOrder.length > 0 && (
        <MaterialTable
          style={{ marginTop: '20px' }}
          icons={tableIcons}
          columns={columnsDetails}
          title={t('general.details')}
          data={datailsOrder}
          options={{
            exportButton: true,
            exportAllData: true,
            exportDelimiter: ';',
            selection: false,
          }}
          localization={i18n.language === 'es' ? tableTranslations : {}}
        />
      )}
    </>
  )
}

export default Administration
