import { useEffect, useState } from 'react'
import Swal from 'sweetalert2/src/sweetalert2.js'
import MaterialTable from 'material-table'
import { useTranslation } from 'react-i18next'
import { sanitationStore } from '../../../stores'
import { URL_SERVER, paxios } from '../../../constants/http'
import tableIcons from '../../../constants/IconsMaterialTable'
import tableTranslations from '../../../constants/tableTranslations'

const cellStyle = { align: 'center', headerStyle: { textAlign: 'center' } }

export default function Diseases() {
  const { t, i18n } = useTranslation()
  const { diseases, eventsDiseases, phasesEventsDiseases, getDiseases, getEventsDiseases, } =
    sanitationStore()
  const [columns, setColumns] = useState([])
  const [columnsEvents, setColumnsEvents] = useState([])
  const [rowSelec, setRowSelec] = useState([])
  const [eventsShow, setEventsShow] = useState([])

  useEffect(() => {
    const validateEmpty = (rowData, field) => {
      return rowData[field] === '' || rowData[field] === undefined
        ? t('general.noempty')
        : true
    }
    setColumns([
      { title: 'ID', field: 'id', editable: 'never' },
      {
        title: t('general.name'),
        field: 'name',
        validate: (rowData) => validateEmpty(rowData, 'name'),
      },
      {
        title: t('general.description'),
        field: 'description',
        validate: (rowData) => validateEmpty(rowData, 'description'),
      },
      {
        title: t('general.category'),
        field: 'category',
        lookup: { 1: 'No letal', 2: 'Letal' },
        initialEditValue: 1,
        ...cellStyle,
      },
      {
        title: t("general.state"),
        field: "removed",
        lookup: { 0: t("general.active"), 1: t("general.inactive") },
        initialEditValue: 0,
        ...cellStyle,
      },
    ])

    setColumnsEvents([
      { title: 'ID', field: 'id', editable: 'never' },
      {
        title: t('general.name'),
        field: 'name',
        validate: (rowData) => validateEmpty(rowData, 'name'),
      },
      {
        title: t('general.description'),
        field: 'description',
        validate: (rowData) => validateEmpty(rowData, 'description'),
      },
      {
        title: t('general.phase'),
        field: 'phase',
        lookup: phasesEventsDiseases,
        initialEditValue: 1,
        ...cellStyle,
      },
      {
        title: t("general.state"),
        field: "removed",
        lookup: { 0: t("general.active"), 1: t("general.inactive") },
        initialEditValue: 0,
        ...cellStyle,
      },
    ])
  }, [t, phasesEventsDiseases])

  useEffect(() => {
    if (rowSelec.length > 0)
      setEventsShow(eventsDiseases.filter((x) => x.disease === rowSelec[0].id))

    if (rowSelec.length <= 0) setEventsShow([])
  }, [rowSelec, eventsDiseases])

  const selectRow = (row) => {
    setRowSelec(row)
  }

  const criterialDisabled = (currentId) => {
    if (rowSelec.length <= 0)
      return {
        disabled: false,
        checked: false,
      }

    if (currentId !== rowSelec[0].id) {
      return {
        disabled: true,
        color: 'primary',
        checked: false,
      }
    } else if (currentId === rowSelec[0].id) {
      return {
        disabled: false,
        checked: true,
      }
    }

    return {
      disabled: false,
      checked: false,
    }
  }

  const createDisease = async (data) => {
    try {
      const response = await paxios.post(`${URL_SERVER}/diseases`, data)
      if (response.data.status === 'ok') {
        Swal.fire(t('general.finished'), t('general.savesuccess'), 'success')
        getDiseases()
      } else {
        Swal.fire(t('general.finished'), response.data.message, 'error')
      }
    } catch (error) {
      Swal.fire(t('general.finished'), t('general.datawasnotsaved'), 'error')
    }
  }

  const onRowAddDisease = (newData) =>
    new Promise(async (resolve) => {
      await createDisease(newData)
      resolve()
    })

  const updateDisease = async (data) => {
    try {
      const response = await paxios.put(
        `${URL_SERVER}/diseases/${data.id}`,
        data
      )
      if (response.data.status === 'ok') {
        Swal.fire(t('general.finished'), t('general.savesuccess'), 'success')
        getDiseases()
      } else {
        Swal.fire(t('general.finished'), t('general.datawasnotsaved'), 'error')
      }
    } catch (error) {
      Swal.fire(t('general.finished'), t('general.datawasnotsaved'), 'error')
    }
  }

  const onRowUpdateDisease = (newData, oldData) =>
    new Promise(async (resolve) => {
      await updateDisease(newData)
      resolve()
    })

  const createEvent = async (data) => {
    try {
      const response = await paxios.post(`${URL_SERVER}/eventsDiseases`, {
        ...data,
        disease: rowSelec[0].id,
      })
      if (response.data.status === 'ok') {
        Swal.fire(t('general.finished'), t('general.savesuccess'), 'success')
        getEventsDiseases()
      } else {
        Swal.fire(t('general.finished'), response.data.message, 'error')
      }
    } catch (error) {
      Swal.fire(t('general.finished'), t('general.datawasnotsaved'), 'error')
    }
  }

  const onRowAddEvent = (newData) =>
    new Promise(async (resolve) => {
      await createEvent(newData)
      resolve()
    })

  const updateEvent = async (data) => {
    try {
      const response = await paxios.put(
        `${URL_SERVER}/eventsDiseases/${data.id}`,
        { ...data, disease: rowSelec[0].id }
      )
      if (response.data.status === 'ok') {
        Swal.fire(t('general.finished'), t('general.savesuccess'), 'success')
        getEventsDiseases()
      } else {
        Swal.fire(t('general.finished'), t('general.datawasnotsaved'), 'error')
      }
    } catch (error) {
      Swal.fire(t('general.finished'), t('general.datawasnotsaved'), 'error')
    }
  }

  const onRowUpdateEvent = (newData, oldData) =>
    new Promise(async (resolve) => {
      await updateEvent(newData)
      resolve()
    })

  return (
    <>
      <MaterialTable
        icons={tableIcons}
        columns={columns}
        title={t('general.diseases')}
        data={diseases.map(x => ({...x, ti: new Date()}))}
        options={{
          exportButton: true,
          exportAllData: true,
          exportDelimiter: ';',
          selection: true,
          showSelectAllCheckbox: false,
          selectionProps: (rowData) => criterialDisabled(rowData.id),
        }}
        localization={i18n.language === 'es' ? tableTranslations : {}}
        onSelectionChange={selectRow}
        editable={{
          onRowUpdate: onRowUpdateDisease,
          onRowAdd: onRowAddDisease,
        }}
      />
      <div style={{ marginBottom: 25 }}></div>
      {rowSelec.length > 0 && (
        <MaterialTable
          icons={tableIcons}
          columns={columnsEvents}
          title={`${t('general.event')}s ${rowSelec[0].name}`}
          data={eventsShow}
          options={{
            exportButton: true,
            exportAllData: true,
            exportDelimiter: ';',
            selection: false,
          }}
          localization={i18n.language === 'es' ? tableTranslations : {}}
          editable={{
            onRowUpdate: onRowUpdateEvent,
            onRowAdd: onRowAddEvent,
          }}
        />
      )}
    </>
  )
}
