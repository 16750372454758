import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
//import LanguageDetector from 'i18next-browser-languagedetector';
import  {Language} from './constants/languages';
const Languagues = [Language.ES, Language.EN];

//.use(LanguageDetector)
i18n.use(Backend).use(initReactI18next).init({
  fallbackLng: 'es',
  debug: false,
  whitelist: Languagues,
});

export default i18n;