import { useEffect, useState } from 'react'
import Swal from 'sweetalert2/src/sweetalert2.js'
import MaterialTable from 'material-table'
import { SubTitle } from '../../../components'
import SanitationRecordsSearch from '../../../components/SanitationRecordsSearch'
import { useTranslation } from 'react-i18next'
import { userStore } from '../../../stores'
import { URL_SERVER, paxios } from '../../../constants/http'
import tableIcons from '../../../constants/IconsMaterialTable'
import tableTranslations from '../../../constants/tableTranslations'

export default function PestsPlotPest() {
  const { t, i18n } = useTranslation()
  const { currentPlantation } = userStore()
  const [years, setYears] = useState([])
  const [reports, setReports] = useState([])
  const [columns] = useState([
    { title: t('general.plot'), field: 'plot' },
    { title: t('general.palms'), field: 'palms' },
    { title: t('general.pest'), field: 'pest' },
    { title: t('general.state'), field: 'state' },
    { title: t('general.sample'), field: 'sample' },
    { title: t('general.presence'), field: 'presence' },
    { title: t('general.founds'), field: 'found' },
    { title: t('general.alive'), field: 'alive' },
    { title: t('general.parasitized'), field: 'parasitized' },
    { title: t('general.palms_alive'), field: 'palms_alive' },
    { title: t('general.percent_sample'), field: 'percent_sample' },
    { title: t('general.percent_presence'), field: 'percent_presence' },
    { title: t('general.average_alive'), field: 'average_alive' },
    {
      title: t('general.average_palms_presence'),
      field: 'average_palms_presence',
    },
  ])

  const getYears = async (plantation) => {
    try {
      const response = await paxios.get(
        `${URL_SERVER}/readingsPests/getYearsReadings/${plantation}`
      )
      if (response.data.status === 'ok') {
        setYears(response.data.data)
      }
    } catch (error) { }
  }

  useEffect(() => {
    getYears(currentPlantation.plantation)
  }, [currentPlantation.plantation])

  const validateData = (data) => {
    const errors = {}
    if ((data.year === '0') && (data.initialDate === undefined || data.initialDate === null || data.endDate === undefined || data.endDate === null))
      errors.dates = t('general.date_invalid')
    return errors
  }

  const dataSearch = (data) => {
    const errors = validateData(data)
    if (Object.keys(errors).length <= 0) {
      const send = {
        ...data,
        plot:
          data.plot !== '0' && data.plot !== 0
            ? data.plot.map((x) => x.id)
            : [],
        dates:
          data.initialDate !== null && data.endDate !== null
            ? `${data.initialDate},${data.endDate}`
            : '',
        plantation: currentPlantation.plantation,
      }
      getReadings(send)
    } else {
      const message = Object.keys(errors).reduce((acc, item) => {
        return (acc += `<p>${errors[item]}</p>`)
      }, '')
      Swal.fire(t('general.finished'), `<div>${message}</div>`, 'error')
    }
  }

  const getReadings = async (data) => {
    try {
      const response = await paxios.post(
        `${URL_SERVER}/readingsPests/pestReportPlotByPest`,
        data
      )
      if (response.data.status === 'ok') {
        setReports(response.data.data)
      }
    } catch (error) {
      Swal.fire(t('general.finished'), t('general.errortoquery'), 'error')
    }
  }

  return (
    <>
      <SanitationRecordsSearch
        years={years.map((x) => ({ id: x.year, name: x.year }))}
        titleCensus={t('general.pests')}
        handleClickSearch={dataSearch}
        reportPest={true}
      />
      <SubTitle text={`${t('general.pests')} (${t('general.plot')}/${t('general.pest')})`} />
      <MaterialTable
        icons={tableIcons}
        columns={columns}
        title={`${t('general.pests')} (${t('general.plot')}/${t('general.pest')})`}
        data={reports}
        options={{
          exportButton: true,
          exportAllData: true,
          exportDelimiter: ';',
          selection: false,
        }}
        localization={i18n.language === 'es' ? tableTranslations : {}}
      />
    </>
  )
}
