import { Checkbox, FormControlLabel } from '@material-ui/core'
export default function CheckBox({name, value, label, checked, onChange}) {
  return (
    <FormControlLabel
      control={
        <Checkbox
          name={name}
          value={value}
          checked={checked}
          onChange={onChange}
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
      }
      label={label}
    />
  )
}
