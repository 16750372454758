import { useState, useEffect } from 'react'
import dayjs from 'dayjs'
import { Grid, makeStyles } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import MaterialTable from 'material-table'
import Swal from 'sweetalert2/src/sweetalert2.js'
import { useTranslation } from 'react-i18next'
import { userStore, severalStore } from '../../../stores'
import { URL_SERVER, paxios } from '../../../constants/http'
import tableIcons from '../../../constants/IconsMaterialTable'
import tableTranslations from '../../../constants/tableTranslations'
import { DatePickerRange, Select, Button } from '../../../components'

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    marginBottom: 10,
  },
}))

export default function Travels({rowSelec, setRowSelec}) {
  const classes = useStyles()
  const { currentPlantation, user } = userStore()
  const { vehicles } = severalStore()
  const { t, i18n } = useTranslation()
  const [queryData, setQueryData] = useState({
    initialDate: null,
    endDate: null,
    vehicle: '0',
  })
  const [travels, setTravels] = useState([])
  const [columns, setColumns] = useState([])

  useEffect(() => {
    const validateDate = (rowData, field) => {
      return rowData[field] === '' ||
        rowData[field] === undefined ||
        rowData[field] === null
        ? t('general.date_invalid')
        : true
    }

    const validateNoZero = (rowData, field) => {
      return rowData[field] <= 0 || rowData[field] === '0'
        ? t('general.nozero')
        : true
    }

    const validateEmpty = (rowData, field) => {
      return rowData[field] === '' ||
        rowData[field] === undefined ||
        rowData[field] === null ||
        isNaN(rowData[field])
        ? t('general.noempty')
        : true
    }

    const validateEmptyText = (rowData, field) => {
      return rowData[field] === '' ||
        rowData[field] === undefined ||
        rowData[field] === null
        ? t('general.noempty')
        : true
    }

    const validateNoZeroEmpty = (rowData, field) => {
      if (
        validateNoZero(rowData, field) === true &&
        validateEmpty(rowData, field) === true
      ) {
        return true
      } else {
        return `${
          validateNoZero(rowData, field) === true
            ? ''
            : validateNoZero(rowData, field)
        } ${
          validateEmpty(rowData, field) === true
            ? ''
            : ', ' + validateEmpty(rowData, field)
        }`
      }
    }

    setColumns([
      {
        title: 'Tiket',
        field: 'tiket',
        editable: 'onAdd',
        validate: (rowData) => validateEmptyText(rowData, 'tiket'),
      },
      {
        title: t('general.datedispatch'),
        field: 'date_dispatch',
        type: 'date',
        initialEditValue: dayjs(new Date()).format('YYYY-MM-DD') + ' 00:00:00',
        validate: (rowData) => validateDate(rowData, 'date_dispatch'),
      },
      { title: t('general.remission'), field: 'remission' },
      {
        title: t('general.vehicle'),
        field: 'vehicle',
        lookup: {
          0: t('general.notassigned'),
          ...vehicles.reduce((acc, item) => {
            acc[item.id] = item.plate
            return acc
          }, {}),
        },
        initialEditValue: 0,
        validate: (rowData) => validateNoZero(rowData, 'vehicle'),
      },
      {
        title: t('general.weightgross'),
        field: 'weight_gross',
        type: 'numeric',
        initialEditValue: 0,
        validate: (rowData) => validateNoZeroEmpty(rowData, 'weight_gross'),
      },
      {
        title: 'Tara',
        field: 'tara',
        type: 'numeric',
        initialEditValue: 0,
        validate: (rowData) => validateNoZeroEmpty(rowData, 'tara'),
      },
      {
        title: t('general.weightnet'),
        field: 'weight_net',
        type: 'numeric',
        initialEditValue: 0,
        editable: 'never',
      },
      {
        title: t('general.observations'),
        field: 'observations',
      },
      { title: t('general.user'), field: 'user_name', editable: 'never' },
    ])
  }, [vehicles, t])

  const handleDateRangeChange = (e) => {
    const { name, value } = e.target
    setQueryData((d) => ({ ...d, [name]: value }))
  }

  const handleChangeVehicle = (e) => {
    const { value } = e.target
    setQueryData((d) => ({ ...d, vehicle: value }))
  }

  const handleClickSearch = () => {
    search()
  }

  const search = async () => {
    try {
      const send = {
        ...queryData,
        dates: `${dayjs(queryData.initialDate).format('YYYY-MM-DD')},${dayjs(
          queryData.endDate
        ).format('YYYY-MM-DD')}`,
        plantation: currentPlantation.plantation,
      }

      const response = await paxios.post(
        `${URL_SERVER}/travelsYield/getTravels`,
        send
      )

      if (response.data.status === 'ok') {
        setTravels(response.data.data)
      }
    } catch (error) {}
  }

  const selectRow = (row) => {
    setRowSelec(row)
  }

  const criterialDisabled = (currentId) => {
    if (rowSelec.length <= 0)
      return {
        disabled: false,
      }

    if (currentId !== rowSelec[0].id) {
      return {
        disabled: true,
        color: 'primary',
      }
    } else if (currentId === rowSelec[0].id) {
      return {
        disabled: false,
        checked: true,
      }
    }

    return {
      disabled: false,
    }
  }

  const updateTravel = async (data) => {
    try {
      const send = {
        ...data,
        date_dispatch:
          data.date_dispatch !== null
            ? dayjs(data.date_dispatch).format('YYYY-MM-DD')
            : null,
      }
      const response = await paxios.put(
        `${URL_SERVER}/travelsYield/updateTravel/${data.id}`,
        send
      )
      if (response.data.status === 'ok') {
        Swal.fire(t('general.finished'), t('general.savesuccess'), 'success')
        search()
      } else {
        Swal.fire(t('general.finished'), response.data.message, 'error')
      }
    } catch (error) {
      Swal.fire(t('general.finished'), t('general.datawasnotsaved'), 'error')
    }
  }

  const onRowUpdate = (newData, oldData) =>
    new Promise(async (resolve) => {
      await updateTravel(newData)
      resolve()
    })

  const createTravel = async (data) => {
    try {
      const send = {
        ...data,
        date_dispatch:
          data.date_dispatch !== null
            ? dayjs(data.date_dispatch).format('YYYY-MM-DD')
            : null,
        user: user.id
      }
      const response = await paxios.post(
        `${URL_SERVER}/travelsYield/createTravel`,
        { ...send, plantation: currentPlantation.plantation }
      )
      if (response.data.status === 'ok') {
        Swal.fire(t('general.finished'), t('general.savesuccess'), 'success')
        setTravels((d) => [...d, { ...response.data.data[0] }])
      } else {
        Swal.fire(t('general.finished'), response.data.message, 'error')
      }
    } catch (error) {
      Swal.fire(t('general.finished'), t('general.datawasnotsaved'), 'error')
    }
  }

  const onRowAdd = (newData) =>
    new Promise(async (resolve) => {
      createTravel(newData)
      resolve()
    })

  return (
    <>
      <Grid
        container
        justify="center"
        spacing={2}
        className={classes.gridContainer}
      >
        <Grid item xs={12} md={3}>
          <Select
            text={t('general.vehicle')}
            width={'100%'}
            items={vehicles.map((x) => ({ id: x.id, name: x.plate }))}
            defaultOption0={true}
            value={queryData.vehicle}
            handleChange={handleChangeVehicle}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <DatePickerRange
            initialDate={queryData.initialDate}
            endDate={queryData.endDate}
            handleDateChange={handleDateRangeChange}
          />
        </Grid>
      </Grid>

      <Button
        icon={<SearchIcon />}
        handleButtonClick={handleClickSearch}
        text={t('general.search')}
        disabled={queryData.initialDate === null || queryData.endDate === null}
      />

      <MaterialTable
        icons={tableIcons}
        columns={columns}
        title={t('general.travels')}
        data={travels.map((x) => ({
          ...x,
          date_dispatch:
            x.date_dispatch !== null ? x.date_dispatch + ' 00:00:00' : null,
        }))}
        options={{
          exportButton: true,
          exportAllData: true,
          exportDelimiter: ';',
          selection: true,
          showSelectAllCheckbox: false,
          selectionProps: (rowData) => criterialDisabled(rowData.id),
        }}
        localization={i18n.language === 'es' ? tableTranslations : {}}
        editable={{
          onRowUpdate: onRowUpdate,
          onRowAdd: onRowAdd,
        }}
        onSelectionChange={selectRow}
      />
    </>
  )
}
