import { useState, useEffect, useCallback } from "react";
import dayjs from "dayjs";
import {
  Grid,
  makeStyles,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import MaterialTable, { MTableToolbar } from "material-table";
import Swal from "sweetalert2/src/sweetalert2.js";
import { useTranslation } from "react-i18next";
import { userStore, severalStore, cadastreStore } from "../../../stores";
import {
  URL_SERVER,
  paxios,
  getQuantityPalmsBetweenLines,
} from "../../../constants/http";
import { formatNumber } from "../../../constants/utilities";
import tableIcons from "../../../constants/IconsMaterialTable";
import tableTranslations from "../../../constants/tableTranslations";
import { Button } from "../../../components";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: 20,
  },
  gridContainer: {
    marginBottom: 10,
  },
}));

export default function Details({ travel }) {
  const classes = useStyles();
  const { currentPlantation } = userStore();
  const { plots } = cadastreStore();
  const { workers, quadrilles } = severalStore();
  const { t, i18n } = useTranslation();
  const [details, setDetails] = useState([]);
  const [columns, setColumns] = useState([]);
  const [saving, setSaving] = useState(false);

  const editComponentWorkers = useCallback(
    (props) => {
      const data = { ...props.rowData };
      return (
        <Select
          value={props.value !== undefined ? props.value : 0}
          onChange={(e) => {
            data.worker = parseInt(e.target.value);
            props.onRowDataChange(data);
          }}
          disabled={String(data.quadrille) !== "0"}
        >
          <MenuItem key={0} value={0}>
            {t("general.notassigned")}
          </MenuItem>
          {workers.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      );
    },
    [t, workers]
  );

  const editComponentQuadrille = useCallback(
    (props) => {
      const data = { ...props.rowData };
      return (
        <Select
          value={props.value !== undefined ? props.value : 0}
          onChange={(e) => {
            data.quadrille = parseInt(e.target.value);
            props.onRowDataChange(data);
          }}
          disabled={String(data.worker) !== "0"}
        >
          <MenuItem key={0} value={0}>
            {t("general.notassigned")}
          </MenuItem>
          {quadrilles.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      );
    },
    [t, quadrilles]
  );

  const handleChangeEndLine = useCallback(async (props, data) => {
    if (!data.plot) return;
    const palms = await getQuantityPalmsBetweenLines(
      data.plantation,
      data.plot,
      data.start_line,
      data.end_line
    );
    data.palms_quantity = palms;
    props.onRowDataChange(data);
  }, []);

  const editComponentStartLine = useCallback(
    (props) => {
      const data = { ...props.rowData };
      return (
        <div className="MuiFormControl-root MuiTextField-root MuiFormControl-fullWidth">
          <div className="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-fullWidth MuiInput-fullWidth MuiInputBase-formControl MuiInput-formControl">
            <input
              type="number"
              className="MuiInputBase-input MuiInput-input"
              min="0"
              value={props.value || 0}
              onChange={(e) => {
                data.start_line = parseInt(e.target.value) || 0;
                props.onRowDataChange(data);
                if (data.end_line > 0)
                  handleChangeEndLine(props, {
                    ...data,
                    plantation: currentPlantation.plantation,
                  });
              }}
            />
          </div>
        </div>
      );
    },
    [currentPlantation.plantation, handleChangeEndLine]
  );

  const editComponentEndLine = useCallback(
    (props) => {
      const data = { ...props.rowData };
      return (
        <div className="MuiFormControl-root MuiTextField-root MuiFormControl-fullWidth">
          <div className="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-fullWidth MuiInput-fullWidth MuiInputBase-formControl MuiInput-formControl">
            <input
              type="number"
              className="MuiInputBase-input MuiInput-input"
              min="0"
              value={props.value || 0}
              onChange={(e) => {
                data.end_line = parseInt(e.target.value) || 0;
                props.onRowDataChange(data);
                handleChangeEndLine(props, {
                  ...data,
                  plantation: currentPlantation.plantation,
                });
              }}
            />
          </div>
        </div>
      );
    },
    [currentPlantation.plantation, handleChangeEndLine]
  );

  useEffect(() => {
    const validateDate = (rowData, field) => {
      return rowData[field] === "" ||
        rowData[field] === undefined ||
        rowData[field] === null
        ? t("general.date_invalid")
        : true;
    };

    const validateNoZero = (rowData, field) => {
      return rowData[field] <= 0 || rowData[field] === "0"
        ? t("general.nozero")
        : true;
    };

    const validateEmpty = (rowData, field) => {
      return rowData[field] === "" ||
        rowData[field] === undefined ||
        rowData[field] === null ||
        isNaN(rowData[field])
        ? t("general.noempty")
        : true;
    };

    const validateNoZeroEmpty = (rowData, field) => {
      if (
        validateNoZero(rowData, field) === true &&
        validateEmpty(rowData, field) === true
      ) {
        return true;
      } else {
        return `${
          validateNoZero(rowData, field) === true
            ? ""
            : validateNoZero(rowData, field)
        } ${
          validateEmpty(rowData, field) === true
            ? ""
            : ", " + validateEmpty(rowData, field)
        }`;
      }
    };
    setColumns([
      {
        title: t("general.plot"),
        field: "plot",
        lookup: {
          0: t("general.notassigned"),
          ...plots.reduce((acc, item) => {
            acc[item.id] = item.name;
            return acc;
          }, {}),
        },
        initialEditValue: 0,
        validate: (rowData) => validateNoZero(rowData, "plot"),
      },
      {
        title: `${t("general.date")} ${t("general.yield")}`,
        field: "date_yield",
        type: "date",
        initialEditValue: dayjs(new Date()).format("YYYY-MM-DD") + " 00:00:00",
        validate: (rowData) => validateDate(rowData, "date_yield"),
      },
      {
        title: t("general.bunches"),
        field: "bunches",
        type: "numeric",
        initialEditValue: 0,
        validate: (rowData) => validateNoZeroEmpty(rowData, "bunches"),
      },
      {
        title: t("general.weighthalf"),
        field: "weight_half",
        type: "numeric",
        initialEditValue: 0,
        validate: (rowData) => validateNoZeroEmpty(rowData, "weight_half"),
      },
      {
        title: t("general.weightnet"),
        field: "weight_net",
        type: "numeric",
        initialEditValue: 0,
        editable: "never",
      },
      {
        title: t("general.worker"),
        field: "worker",
        lookup: {
          0: t("general.notassigned"),
          ...workers.reduce((acc, item) => {
            acc[item.id] = item.name;
            return acc;
          }, {}),
        },
        initialEditValue: 0,
        validate: (rowData) =>
          String(rowData.worker) === "0" && String(rowData.quadrille) === "0"
            ? t("general.nozero")
            : true,
        editComponent: editComponentWorkers,
      },
      {
        title: t("general.quadrille"),
        field: "quadrille",
        lookup: {
          0: t("general.notassigned"),
          ...quadrilles.reduce((acc, item) => {
            acc[item.id] = item.name;
            return acc;
          }, {}),
        },
        initialEditValue: 0,
        validate: (rowData) =>
          String(rowData.worker) === "0" && String(rowData.quadrille) === "0"
            ? t("general.nozero")
            : true,
        editComponent: editComponentQuadrille,
      },
      {
        title: t("general.start_line"),
        field: "start_line",
        type: "numeric",
        initialEditValue: 0,
        editComponent: editComponentStartLine,
      },
      {
        title: t("general.end_line"),
        field: "end_line",
        type: "numeric",
        initialEditValue: 0,
        editComponent: editComponentEndLine,
      },
      {
        title: t("general.palms"),
        field: "palms_quantity",
        type: "numeric",
        initialEditValue: 0,
        editable: "never",
      },
      {
        title: t("general.observations"),
        field: "observations",
        initialEditValue: "",
      },
    ]);
  }, [
    plots,
    workers,
    quadrilles,
    editComponentWorkers,
    editComponentQuadrille,
    editComponentStartLine,
    editComponentEndLine,
    t,
  ]);

  const getDetails = useCallback(async (idTravel, plantation) => {
    try {
      const response = await paxios.get(
        `${URL_SERVER}/detailsTravelsYield/getDetailsTravel/${idTravel}/${plantation}`
      );

      if (response.data.status === "ok") {
        const newDatails = response.data.data.map((x) => ({ ...x, old: true }));
        setDetails(newDatails);
      }
    } catch (error) {}
  }, []);

  useEffect(() => {
    getDetails(travel.id, currentPlantation.plantation);
  }, [getDetails, travel, currentPlantation.plantation]);

  const updateRow = (data) => {
    setDetails((d) => {
      return d.map((x) => {
        if (x.id !== data.id) return { ...x };

        return { ...data, weight_net: data.bunches * data.weight_half };
      });
    });
  };

  const onRowUpdate = (newData, oldData) =>
    new Promise(async (resolve) => {
      updateRow(newData);
      resolve();
    });

  const addRow = (data) => {
    setDetails((d) => [
      ...d,
      {
        ...data,
        weight_net: data.bunches * data.weight_half,
        id_travel: travel.id,
        old: false,
      },
    ]);
  };

  const onRowAdd = (newData) =>
    new Promise(async (resolve) => {
      addRow(newData);
      resolve();
    });

  const validateWeightEntered = () => {
    if (
      travel.weight_net * 1000 - 50 >
      details.reduce((acc, item) => (acc += item.weight_net), 0)
    )
      return `Faltan: ${formatNumber(
        travel.weight_net * 1000 -
          details.reduce((acc, item) => (acc += item.weight_net), 0)
      )} Kg`;

    if (
      travel.weight_net * 1000 + 50 <
      details.reduce((acc, item) => (acc += item.weight_net), 0)
    )
      return `Sobran: ${formatNumber(
        details.reduce((acc, item) => (acc += item.weight_net), 0) -
          travel.weight_net * 1000
      )} Kg`;

    return "";
  };

  const saveDetails = async () => {
    setSaving(true);
    try {
      const send = details.map((x) => ({
        ...x,
        plantation: currentPlantation.plantation,
        observations: x.observations ? x.observations : "",
        date_yield: dayjs(x.date_yield).format("YYYY-MM-DD"),
      }));

      const response = await paxios.post(
        `${URL_SERVER}/detailsTravelsYield/saveDetailsTravel`,
        { data: send }
      );

      if (response.data.status === "ok") {
        Swal.fire(t("general.finished"), t("general.savesuccess"), "success");
        getDetails(travel.id, currentPlantation.plantation);
      } else {
        Swal.fire(t("general.finished"), response.data.message, "error");
      }
    } catch (error) {
      Swal.fire(t("general.finished"), t("general.datawasnotsaved"), "error");
    }
    setSaving(false);
  };

  const handleClickSaveDetails = () => {
    saveDetails();
  };

  return (
    <div className={classes.container}>
      <MaterialTable
        icons={tableIcons}
        columns={columns}
        title={`${t("general.detailstravel")}: ${travel.tiket}`}
        data={details.map((x) => ({
          ...x,
          date_dispatch:
            x.date_yield !== null ? x.date_yield + " 00:00:00" : null,
        }))}
        options={{
          exportButton: true,
          exportAllData: true,
          exportDelimiter: ";",
          selection: false,
          showSelectAllCheckbox: false,
        }}
        localization={i18n.language === "es" ? tableTranslations : {}}
        editable={{
          onRowUpdate: onRowUpdate,
          onRowAdd: onRowAdd,
        }}
        components={{
          Toolbar: (props) => (
            <div>
              <MTableToolbar {...props} />
              <Grid
                container
                justify="center"
                spacing={2}
                className={classes.gridContainer}
              >
                <Grid item xs={12} md={4}>
                  <Typography variant="h6" color={"primary"} align="center">
                    {`${t("general.weightnet")} ${t("general.travel")} Ton: ${
                      travel.weight_net
                    } / Kg: ${formatNumber(travel.weight_net * 1000)}`}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={4}>
                  <Typography variant="h6" color={"primary"} align="center">
                    {`${t("general.weightnet")} ${t(
                      "general.current"
                    )}: ${formatNumber(
                      details.reduce((acc, item) => (acc += item.weight_net), 0)
                    )} Kg`}
                  </Typography>
                  <Typography variant="h6" color={"error"} align="center">
                    {validateWeightEntered()}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={4}>
                  <Button
                    icon={<SaveIcon />}
                    handleButtonClick={handleClickSaveDetails}
                    text={t("general.save")}
                    disabled={validateWeightEntered() !== ""}
                    loading={saving}
                  />
                </Grid>
              </Grid>
            </div>
          ),
        }}
      />
    </div>
  );
}
