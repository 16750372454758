import { useState, useEffect, useRef, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  addSourceLayer,
  setDataSourceLayer,
  addCircleLayer,
  addFillLayer,
  createSimpleTable,
  geoJsonEmpty,
  dinamicFillColorRange,
} from '../../components/map/utilities'
import { userStore, mapsStore } from '../../stores'
import { URL_SERVER, paxios, getTokenQgis, generateQgisQuery } from '../../constants/http'
import { Map } from '../../components'
import Filters from './Filters'

export default function Home() {
  const { t } = useTranslation()
  const mapRef = useRef(null)
  const filtersRef = useRef(null)
  const { currentPlantation } = userStore()
  const { colors, getColorsMaps } = mapsStore()
  const [layerSelected, setLayerSelected] = useState('')

  useEffect(() => {
    Object.keys(colors).length <= 0 && getColorsMaps()
  }, [colors, getColorsMaps])

  const featurePollination = (e) => {
    const data = [
      { name: 'date', value: e.properties.date },
      { name: 'plot', value: e.properties.plot_name },
      { name: 'line', value: e.properties.line },
      { name: 'palm', value: e.properties.palm },
      { name: 'good', value: e.properties.good },
      { name: 'helped', value: e.properties.helped },
      { name: 'double', value: e.properties.double },
      { name: 'worker', value: e.properties.worker_name },
      { name: 'observations', value: e.properties.observations },
    ]
    return createSimpleTable(data)
  }

  const featurePollinationAna = (e) => {
    const data = [
      { name: 'date', value: e.properties.date },
      { name: 'plot', value: e.properties.plot_name },
      { name: 'line', value: e.properties.line },
      { name: 'palm', value: e.properties.palm },
      { name: 'first', value: e.properties.first },
      { name: 'second', value: e.properties.second },
      { name: 'third', value: e.properties.third },
      { name: 'worker', value: e.properties.worker_name },
      { name: 'observations', value: e.properties.observations },
    ]
    return createSimpleTable(data)
  }

  const featureProductionYear = (e) => {
    const data = [
      { name: 'plot', value: e.properties.plot_name },
      { name: 'area_net', value: e.properties.area_net },
      { name: 'production', value: `${e.properties.production_ton} ton` },
      { name: 'production', value: `${e.properties.ton_ha} ton/ha` },
      { name: 'material', value: e.properties.material_name },
    ]
    return createSimpleTable(data)
  }

  const filtersLayer = useMemo(
    () => [
      {
        id: 'pollination_layer',
        text: `${t('general.filter')} ${t('general.pollination')}`,
        type: 'circle',
        dinamicColor: null,
        onClick: (id, map) => {
          setLayerSelected(id)
          if (filtersRef.current) {
            filtersRef.current.setOpen(true)
          }
          addSourceLayer(map, id)
        },
        onClickLayer: (mapboxgl, e, layerId, layerData) => {
          //const popup = createPopup(mapboxgl, e, layerData, featurePollination)
          //popup.addTo(mapRef.current.getMap())
          const html = featurePollination(layerData);
          mapRef.current.changeSidebarRightContent(html);
          mapRef.current.openSidebarRight()
        },
      },
      {
        id: 'pollination_ana_layer',
        text: `${t('general.filter')} ${t('general.pollination')} ANA`,
        type: 'circle',
        dinamicColor: null,
        onClick: (id, map) => {
          setLayerSelected(id)
          if (filtersRef.current) {
            filtersRef.current.setOpen(true)
          }
          addSourceLayer(map, id)
        },
        onClickLayer: (mapboxgl, e, layerId, layerData) => {
          /* const popup = createPopup(
            mapboxgl,
            e,
            layerData,
            featurePollinationAna
          )
          popup.addTo(mapRef.current.getMap()) */
          const html = featurePollinationAna(layerData);
          mapRef.current.changeSidebarRightContent(html);
          mapRef.current.openSidebarRight()
        },
      },
      {
        id: 'production_year_layer',
        text: `${t('general.filter')} ${t('general.productionyear')}`,
        type: 'fill',
        dinamicColor: colors.production_year
          ? dinamicFillColorRange(colors.production_year, 'production_ton')
          : null,
        onClick: (id, map) => {
          setLayerSelected(id)
          if (filtersRef.current) {
            filtersRef.current.setOpen(true)
          }
          addSourceLayer(map, id)
        },
        onClickLayer: (mapboxgl, e, layerId, layerData) => {
          /*const popup = createPopup(
            mapboxgl,
            e,
            layerData,
            featureProductionYear
          )
          popup.addTo(mapRef.current.getMap())*/
          const html = featureProductionYear(layerData);
          mapRef.current.changeSidebarRightContent(html);
          mapRef.current.openSidebarRight()
        },
      },
    ],
    [t, colors]
  )

  const validateDataPollination = (data) => {
    const errors = {}

    if (data.dates === '')
      errors.dates = `${t('general.select')}, ${t('general.initialdate')} ${t(
        'general.enddate'
      )}`

    return errors
  }

  const validateDataProductionYear = (data) => {
    const errors = {}

    if (data.year === '0' || data.year === 0)
      errors.dates = `${t('general.select')}, ${t('general.year')}`

    return errors
  }

  const getDataLayer = async (url, parameters, map, layerId) => {
    try {
      const layerObject = filtersLayer.find((x) => x.id === layerId)
      const layerType = layerObject.type
      const dinamicColor = layerObject.dinamicColor
      const response = await paxios.post(`${URL_SERVER}/${url}`, parameters)
      if (response.data.status === 'ok') {
        setDataSourceLayer(map, layerId, response.data.data)
        if (layerType === 'circle') addCircleLayer(map, layerId)

        if (layerType === 'fill')
          addFillLayer(map, layerId, dinamicColor ? dinamicColor : null)
      }
    } catch (error) {}
  }

  const getLayer = (data, layerSelected) => {
    for (const layer of filtersLayer) {
      setDataSourceLayer(mapRef.current.getMap(), layer.id, geoJsonEmpty)
    }
    let errors = {}
    switch (layerSelected) {
      case 'pollination_layer':
        errors = validateDataPollination(data)
        if (Object.keys(errors).length <= 0) {
          filtersRef.current.setErrors('')
          getDataLayer(
            'pollination/getPollinationMap',
            data,
            mapRef.current.getMap(),
            layerSelected
          )
        } else {
          const message = Object.keys(errors).reduce((acc, item) => {
            return (acc += `${errors[item]}`)
          }, '')
          filtersRef.current.setErrors(message)
        }
        break
      case 'pollination_ana_layer':
        errors = validateDataPollination(data)
        if (Object.keys(errors).length <= 0) {
          filtersRef.current.setErrors('')
          getDataLayer(
            'pollinationAna/getPollinationMap',
            data,
            mapRef.current.getMap(),
            layerSelected
          )
        } else {
          const message = Object.keys(errors).reduce((acc, item) => {
            return (acc += `${errors[item]}`)
          }, '')
          filtersRef.current.setErrors(message)
        }
        break
      case 'production_year_layer':
        errors = validateDataProductionYear(data)
        if (Object.keys(errors).length <= 0) {
          filtersRef.current.setErrors('')
          getDataLayer(
            'detailsTravelsYield/getProductionYearMap',
            data,
            mapRef.current.getMap(),
            layerSelected
          )
        } else {
          const message = Object.keys(errors).reduce((acc, item) => {
            return (acc += `${errors[item]}`)
          }, '')
          filtersRef.current.setErrors(message)
        }
        break
      default:
        break
    }
  }

  const generateUriQgis = async (parameters, layerSelected) => {
    const identifier = await generateQgisQuery(parameters)
    const token = await getTokenQgis();
    let url = ""
    switch (layerSelected) {
      case 'pollination_layer':
        url = "getPollination";
        break;
      case 'pollination_ana_layer':
        url = 'getPollinationAna';
        break;
      case 'production_year_layer':
        url = 'getProductionYear';
        break;
      default:
        break;
    }
    const uri = `${URL_SERVER}/qgis/${url}/${identifier}/${token}`;
    filtersRef.current.addUriQgis(uri)
  }

  const handleClickOk = (data) => {
    const send = {
      ...data,
      dates:
        data.initialDate !== null && data.endDate !== null
          ? `${data.initialDate},${data.endDate}`
          : '',
      plantation: currentPlantation.plantation,
    }
    getLayer(send, layerSelected)
    generateUriQgis(send, layerSelected)
  }
  return (
    <div>
      <Filters ref={filtersRef} layer={layerSelected} accept={handleClickOk} />
      <Map ref={mapRef} filtersLayerExternal={filtersLayer} layerExternalWithoutFilter={useMemo(() => [], [])} />
    </div>
  )
}
