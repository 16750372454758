import { useState } from 'react'
import { Redirect } from 'react-router-dom'
import {
  Avatar,
  Button,
  TextField,
  Typography,
  CssBaseline,
  Card,
  CardContent,
} from '@material-ui/core'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import { Alert } from '@material-ui/lab'
import { useTranslation } from 'react-i18next'
import { userStore } from '../stores'
import { ROUTES } from '../constants/routes'

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  main: {
    marginTop: '50px',
  },
  card: {
    padding: '30px',
  },
}))

export default function Login({ currentTab }) {
  const classes = useStyles()
  const { t } = useTranslation()
  const { login, logged, user } = userStore()
  const [userData, setUserData] = useState({})
  const [error, setError] = useState('')

  const handleChange = (e) => {
    error !== '' && setError('')
    const { value, name } = e.target
    setUserData({ ...userData, [name]: value })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const result = await login(userData)
    if (result.status === 'error') {
      result.error === 'Incorrect credentials' &&
        setError(t('login.incorrectCredentials'))

      result.error === undefined && setError('Error')
    }
  }

  if (!logged) {
    return (
      <div
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          backgroundColor: '#8b83b638',
        }}
      >
        <Container className={classes.main} component="main" maxWidth="xs">
          <CssBaseline />
          <Card className={classes.card}>
            <CardContent>
              <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                  <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                  {t('login.signIn')}
                </Typography>
                <form className={classes.form} onSubmit={handleSubmit}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label={t('general.user')}
                    name="username"
                    autoComplete="email"
                    autoFocus
                    onChange={handleChange}
                  />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label={t('login.password')}
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    onChange={handleChange}
                  />
                  {error !== '' && <Alert severity="error">{error}</Alert>}
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                  >
                    {t('login.signIn')}
                  </Button>
                </form>
              </div>
            </CardContent>
          </Card>
        </Container>
      </div>
    )
  } else if (logged) {
    if (user?.pass_check === 1) return <Redirect to={ROUTES.changepass} />

    return (
      <Redirect
        to={
          currentTab === '/' || currentTab === ROUTES.changepass
            ? '/home'
            : currentTab
        }
      />
    )
  }
}
