import { useEffect, useState } from 'react'
import Swal from 'sweetalert2/src/sweetalert2.js'
import MaterialTable from 'material-table'
import ResestPassIcon from '@material-ui/icons/RotateLeft'
import BorderStyleIcon from '@material-ui/icons/BorderStyle'
import { useTranslation } from 'react-i18next'
import { userStore } from '../../../stores'
import { URL_SERVER, paxios } from '../../../constants/http'
import tableIcons from '../../../constants/IconsMaterialTable'
import tableTranslations from '../../../constants/tableTranslations'
import { validateEmail } from '../../../constants/utilities'
import { TitlePage } from '../../../components'
import PermitsPlantation from './PermitsPlantation'

const cellStyle = { align: 'center', headerStyle: { textAlign: 'center' } }

export default function Users() {
  const { t, i18n } = useTranslation()
  const { currentPlantation, user } = userStore()
  const [columns, setColumns] = useState([])
  const [users, setUsers] = useState([])
  const [userPermissions, setUserPermissions] = useState({})
  const [showModalUserPermissions, setShowModalUserPermissions] =
    useState(false)

  useEffect(() => {
    const validateEmpty = (rowData, field) => {
      return rowData[field] === '' || rowData[field] === undefined
        ? t('general.noempty')
        : true
    }

    const validateEmailLocal = (rowData, field) => {
      return !validateEmail(rowData[field]) ? t('general.wrongemail') : true
    }

    setColumns([
      { title: 'ID', field: 'id', editable: 'never' },
      {
        title: t('general.user'),
        field: 'username',
        validate: (rowData) => validateEmpty(rowData, 'username'),
      },
      {
        title: t('general.name'),
        field: 'name',
        validate: (rowData) => validateEmpty(rowData, 'name'),
      },
      {
        title: t('general.lastname'),
        field: 'last_name',
        validate: (rowData) => validateEmpty(rowData, 'last_name'),
      },
      {
        title: t('general.employeetitle'),
        field: 'position',
        validate: (rowData) => validateEmpty(rowData, 'position'),
      },
      {
        title: 'Email',
        field: 'email',
        validate: (rowData) => validateEmailLocal(rowData, 'email'),
      },
      {
        title: t('general.entity'),
        field: 'entity',
        validate: (rowData) => validateEmpty(rowData, 'entity'),
      },
      {
        title: t('general.state'),
        field: 'state',
        lookup: { 1: t('general.active'), 0: t('general.inactive') },
        initialEditValue: 1,
        ...cellStyle,
      },
      {
        title: t('general.profile'),
        field: 'profile',
        lookup: { 1: 'Administrator', 2: 'User' },
        initialEditValue: 1,
        ...cellStyle,
      },
    ])
  }, [t])

  useEffect(() => {
    !showModalUserPermissions && setUserPermissions({})
  }, [showModalUserPermissions])

  const getUsers = async () => {
    try {
      const response = await paxios.get(`${URL_SERVER}/users`)
      if (response.data.status === 'ok') {
        setUsers(response.data.data)
      }
    } catch (error) {}
  }

  useEffect(() => {
    getUsers()
  }, [])

  const createUser = async (data) => {
    try {
      const response = await paxios.post(`${URL_SERVER}/users`, {
        ...data,
        user: user.id,
        plantation: currentPlantation.plantation,
      })
      if (response.data.status === 'ok') {
        Swal.fire(t('general.finished'), t('general.savesuccess'), 'success')
        getUsers()
      } else {
        Swal.fire(t('general.finished'), response.data.message, 'error')
      }
    } catch (error) {
      Swal.fire(t('general.finished'), t('general.datawasnotsaved'), 'error')
    }
  }

  const onRowAddUser = (newData) =>
    new Promise(async (resolve) => {
      await createUser(newData)
      resolve()
    })

  const updateUser = async (data) => {
    try {
      const response = await paxios.put(`${URL_SERVER}/users/${data.id}`, {
        ...data,
        user: user.id,
        plantation: currentPlantation.plantation,
      })
      if (response.data.status === 'ok') {
        Swal.fire(t('general.finished'), t('general.savesuccess'), 'success')
        getUsers()
      } else {
        Swal.fire(t('general.finished'), t('general.datawasnotsaved'), 'error')
      }
    } catch (error) {
      Swal.fire(t('general.finished'), t('general.datawasnotsaved'), 'error')
    }
  }

  const onRowUpdateUser = (newData, oldData) =>
    new Promise(async (resolve) => {
      await updateUser(newData)
      resolve()
    })

  const handleClickResetPass = (event, rowData) => {
    confirmResetPass(rowData)
  }

  const confirmResetPass = (data) => {
    Swal.fire({
      title: 'Reset pass',
      text: `${t('general.sureresetpass')} (${data.name} ${data.last_name})`,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonColor: '#d33',
    }).then((result) => {
      if (result.isConfirmed) {
        resetPass(data.id)
      }
    })
  }

  const resetPass = async (id) => {
    try {
      const response = await paxios.put(`${URL_SERVER}/users/resetPass/${id}`)
      if (response.data.status === 'ok') {
        Swal.fire(t('general.finished'), t('general.savesuccess'), 'success')
      } else {
        Swal.fire(t('general.finished'), t('general.datawasnotsaved'), 'error')
      }
    } catch (error) {
      Swal.fire(t('general.finished'), t('general.datawasnotsaved'), 'error')
    }
  }

  const showPermitsPlantation = (event, rowData) => {
    setShowModalUserPermissions(true)
    setUserPermissions(rowData)
  }

  return (
    <>
      <TitlePage text={`${t('general.users')}`} />
      <MaterialTable
        icons={tableIcons}
        columns={columns}
        title={`${t('general.users')}`}
        data={users.map((x) => ({ ...x, ti: new Date() }))}
        options={{
          exportButton: true,
          exportAllData: true,
          exportDelimiter: ';',
          selection: false,
        }}
        localization={i18n.language === 'es' ? tableTranslations : {}}
        editable={{
          onRowUpdate: onRowUpdateUser,
          onRowAdd: onRowAddUser,
        }}
        actions={[
          {
            icon: () => <ResestPassIcon />,
            tooltip: 'Reset pass',
            onClick: handleClickResetPass,
          },
          user.super === 1
            ? {
                icon: () => <BorderStyleIcon />,
                tooltip: t('general.plantationpermits'),
                onClick: showPermitsPlantation,
              }
            : null,
        ]}
      />

      {Object.keys(userPermissions).length > 0 && showModalUserPermissions && (
        <PermitsPlantation
          open={showModalUserPermissions}
          setOpen={setShowModalUserPermissions}
          user={userPermissions}
        />
      )}
    </>
  )
}
