import { useState } from 'react'
import { Container } from '@material-ui/core'
import Menu from './Menu'

export default function MyContainer({ children }) {
  const [menuOpen, setMenuOpen] = useState(false)
  const [closeMenuOrder, setCloseMenuOrder] = useState(0)

  const handleClickOutMenu = () => {
    menuOpen && setCloseMenuOrder(1)
  }
  return (
    <>
      <Menu globalsetMenuOpen={setMenuOpen} closeMenuOrder={closeMenuOrder} setCloseMenuOrder={setCloseMenuOrder} />
      <div style={{ marginTop: '90px', marginLeft: '85px' }} onClick={handleClickOutMenu}>
        <Container
          maxWidth={false}
          style={{
            justifyContent: 'center',
          }}
        >
          {children}
        </Container>
      </div>
    </>
  )
}
