import {useMemo} from 'react'
import { Map } from '../../components'

export default function Home() {
  return (
    <div>
      <Map filtersLayerExternal={[]} layerExternalWithoutFilter={useMemo(() => [], [])}/>
    </div>
  )
}
