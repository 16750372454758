import {
  makeStyles,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'

export default function SelectCustom({
  text,
  value,
  handleChange,
  width,
  items,
  defaultOption0 = false,
  defaultOptionEmpty = false,
  name='',
  error = false,
  required = false,
}) {
  const useStyles = makeStyles((theme) => ({
    formControl: {
      //margin: theme.spacing(-1),
      minWidth: 120,
      width: width,
    },
  }))
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <FormControl className={classes.formControl}>
      <InputLabel id="demo-simple-select-label">{text}</InputLabel>
      <Select value={value} onChange={handleChange} name={name} error={error} required={required}>
        {defaultOption0 && <MenuItem value={0}>{t('general.select')}</MenuItem>}
        {defaultOptionEmpty && (
          <MenuItem value={''}>{t('general.select')}</MenuItem>
        )}
        {items.map((x) => (
          <MenuItem key={x.id} value={x.id}>
            {x.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
