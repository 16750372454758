import { useState, useEffect, useRef, useMemo, useCallback } from "react";
import dayjs from "dayjs";
import { Grid, TextField, } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import Visibility from "@material-ui/icons/Visibility";
import { useTranslation } from "react-i18next";
import { URL_SERVER, paxios, getTokenQgis, generateQgisQuery } from "../../constants/http";
import {
  laborsStore,
  machineryStore,
  severalStore,
  userStore,
} from "../../stores";
import {
  TitlePage,
  MultiSelect,
  DatePickerRange,
  Button,
  Map,
} from "../../components";
import {
  addSourceLayer,
  setDataSourceLayer,
  addLineLayer,
  createSimpleTable,
  dynamicLineColor,
} from "../../components/map/utilities";
import ResultSearch from "./ResultSearch";

const layerName = "tracks_layer";

export default function MachineryMap() {
  const { t } = useTranslation();
  const { currentPlantation } = userStore();
  const { labors, getLabors } = laborsStore();
  const [laborsShow, setLaborsShow] = useState([]);
  const { machines, getMachines, equipment, getEquipment, unitsMachinery, getUnitsMachinery } = machineryStore();
  const { workers } = severalStore();
  const [data, setData] = useState({
    machines: [],
    equipment: [],
    labors: [],
    workers: [],
    initialDate: null,
    endDate: null,
  });
  const [result, setResult] = useState([]);
  const resultRef = useRef(null);
  const mapRef = useRef(null);
  const [uriQgis, setUriQgis] = useState("")

  useEffect(() => {
    labors.length <= 0 && getLabors();
    machines.length <= 0 && getMachines();
    equipment.length <= 0 && getEquipment();
    unitsMachinery.length <= 0 && getUnitsMachinery();
    labors.length > 0 && setLaborsShow(labors.filter((x) => x.machinery === 1));
  }, [
    getLabors,
    labors,
    machines.length,
    getMachines,
    equipment.length,
    getEquipment,
    unitsMachinery.length,
    getUnitsMachinery,
  ]);

  useEffect(() => {
    if (result.length > 0) {
      if (resultRef.current) {
        resultRef.current.setOpen(true);
      }
    }
  }, [result]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((d) => ({ ...d, [name]: value }));
  };

  const validateButtonSearch = () => {
    if (
      String(data.initialDate) === "Invalid Date" ||
      String(data.endDate) === "Invalid Date" ||
      data.initialDate === null ||
      data.endDate === null
    )
      return true;
    return false;
  };

  const getLaborsMachinery = async (data) => {
    try {
      const response = await paxios.post(
        `${URL_SERVER}/laborsMachinery/search`,
        data
      );
      if (response.data.status === "ok") {
        setResult(response.data.data);
      }
    } catch (error) {}
  };

  const handleCLickSearch = () => {
    getLaborsMachinery({
      ...data,
      plantation: currentPlantation.plantation,
      dates:
        data.initialDate !== null && data.endDate !== null
          ? `${dayjs(data.initialDate).format("YYYY-MM-DD")},${dayjs(
              data.endDate
            ).format("YYYY-MM-DD")}`
          : "",
    });
  };

  const loadLayer = (data) => {
    addSourceLayer(mapRef.current.getMap(), layerName);
    setDataSourceLayer(mapRef.current.getMap(), layerName, data);
    const arrayToColors = data.features.map(x => x.properties.machinery)
    addLineLayer(mapRef.current.getMap(), layerName, dynamicLineColor(arrayToColors));
  };

  const generateUriQgis = async (parameters) => {
    const identifier = await generateQgisQuery(parameters)
    const token = await getTokenQgis();
    let url = "getTracksMachinery"
    const uri = `${URL_SERVER}/qgis/${url}/${identifier}/${token}`;
    setUriQgis(uri)
  }

  const getTracks = async (labors) => {
    try {
      //setTracks({});
      const response = await paxios.post(
        `${URL_SERVER}/tracksMachinery/getTracks`,
        {
          ids: labors.map((x) => x.id_unique),
        }
      );
      if (response.data.status === "ok") {
        loadLayer(response.data.data);
        generateUriQgis({
          ids: labors.map((x) => x.id_unique),
        })
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getLaborsSelected = (labors) => {
    getTracks(labors);
    resultRef.current.setOpen(false);
  };

  const featureTrack = useCallback((e) => {
    const data = [
      { name: "machine", value: machines.find(x => String(x.id) === String(e.machine_id)).description  },
      { name: "equipment", value: equipment.find(x => String(x.id) === String(e.equipment_id)).description  },
      { name: "labor", value: labors.find(x => String(x.id) === String(e.labor_id)).name  },
      { name: "worker", value: workers.find(x => String(x.id) === String(e.worker)).name },
      { name: "initialdate", value: e.start_date },
      { name: "start_hour", value: e.start_hour },
      { name: "enddate", value: e.end_date },
      { name: "end_hour", value: e.end_hour },
      { name: "time", value: e.time },
      { name: "distance", value: e.length_gps },
      { name: "start_hour_meter", value: e.start_hour_meter },
      { name: "end_hour_meter", value: e.end_hour_meter },
      { name: "hour_meter", value: e.hour_meter },
      { name: "start_odometer", value: e.start_odometer },
      { name: "end_odometer", value: e.end_odometer },
      { name: "odometer", value: e.odometer },
      { name: "gas", value: e.gas },
      { name: "unit", value: unitsMachinery.find(x => String(x.id) === String(e.unit))?.name || ""  },
      { name: "quantity", value: e.quantity },
      { name: "observations", value: e.observations },
    ];

    return createSimpleTable(data);
  },[machines, equipment, labors, workers, unitsMachinery]);

  const getDataTrack = useCallback(
    (id) => {
      const track = result.find((x) => x.id_unique === id);
      return featureTrack(track);
    },
    [result, featureTrack]
  );

  const tracksLayer = useMemo(
    () => [
      {
        id: layerName,
        onClickLayer: (mapboxgl, e, layerId, layerData) => {
          const html = getDataTrack(layerData.properties.machinery);
          mapRef.current.changeSidebarRightContent(html);
          mapRef.current.openSidebarRight();
        },
      },
    ],
    [getDataTrack]
  );
  return (
    <>
      <TitlePage text={`${t("general.map")} ${t("general.machinery")}`} />
      <Grid container justify="center" spacing={2}>
        <Grid item xs={12} md={2}>
          <MultiSelect
            options={machines.map((x) => ({
              value: x.id,
              label: x.description,
            }))}
            label={`${t("general.machine")}s`}
            onChangeExternal={(values) =>
              handleChange({ target: { name: "machines", value: values } })
            }
            valuesExternal={data.machines}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <MultiSelect
            options={equipment.map((x) => ({
              value: x.id,
              label: x.description,
            }))}
            label={`${t("general.equipment")}`}
            onChangeExternal={(values) =>
              handleChange({ target: { name: "equipment", value: values } })
            }
            valuesExternal={data.equipment}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <MultiSelect
            options={laborsShow.map((x) => ({
              value: x.id,
              label: x.name,
            }))}
            label={`${t("general.labors")}`}
            onChangeExternal={(values) =>
              handleChange({ target: { name: "labors", value: values } })
            }
            valuesExternal={data.labors}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <MultiSelect
            options={workers.map((x) => ({
              value: x.id,
              label: x.name,
            }))}
            label={`${t("general.workers")}`}
            onChangeExternal={(values) =>
              handleChange({ target: { name: "workers", value: values } })
            }
            valuesExternal={data.workers}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <DatePickerRange
            initialDate={data.initialDate}
            endDate={data.endDate}
            handleDateChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={1}>
          <Button
            icon={<SearchIcon />}
            disabled={validateButtonSearch()}
            handleButtonClick={handleCLickSearch}
            text={t("general.search")}
          />
          <Button
            icon={<Visibility />}
            disabled={result.length <= 0}
            handleButtonClick={() => resultRef.current.setOpen(true)}
            text={t("general.show")}
          />
        </Grid>
      </Grid>
      <ResultSearch
        ref={resultRef}
        getLaborsSelected={getLaborsSelected}
        resultLabors={result}
      />
      <TextField
        fullWidth
        label={'URI Qgis'}
        name="uriqgis"
        type={'text'}
        value={uriQgis}
        disabled={true}
      />
      <Map
        ref={mapRef}
        filtersLayerExternal={useMemo(() => [], [])}
        layerExternalWithoutFilter={tracksLayer}
      />
    </>
  );
}
