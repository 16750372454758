import { useState, useEffect } from 'react'
import DateFnsUtils from '@date-io/date-fns'
import enLocale from 'date-fns/locale/en-US'
import esLocale from 'date-fns/locale/es'
import { Grid } from '@material-ui/core'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import { useTranslation } from 'react-i18next'

export default function DatePickerRange({
  initialDate,
  endDate,
  handleDateChange,
  required = false,
  error = false,
}) {
  const { t, i18n } = useTranslation()
  const [locale, setLocale] = useState(esLocale)

  useEffect(() => {
    setLocale(i18n.language === 'es' ? esLocale : enLocale)
  }, [i18n])

  useEffect(() => {
    if (initialDate !== null) {
      endDate === null &&
        handleDateChange({ target: { name: 'endDate', value: initialDate } })

      initialDate > endDate &&
        handleDateChange({ target: { name: 'endDate', value: initialDate } })
    } else if (initialDate === null) {
      handleDateChange({ target: { name: 'endDate', value: null } })
    }
    // eslint-disable-next-line
  }, [initialDate, endDate])

  useEffect(() => {
    if (endDate !== null)
      endDate < initialDate &&
        handleDateChange({ target: { name: 'initialDate', value: endDate } })
    // eslint-disable-next-line
  }, [endDate, initialDate])

  return (
    <Grid container justify="center" spacing={1}>
      <Grid item>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
          <KeyboardDatePicker
            label={t('general.initialdate')}
            format="dd/MM/yyyy"
            value={initialDate}
            showTodayButton
            onChange={(date) =>
              handleDateChange({ target: { name: 'initialDate', value: date } })
            }
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            required={required}
            error={error}
            style={{ width: 150 }}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
          <KeyboardDatePicker
            label={t('general.enddate')}
            format="dd/MM/yyyy"
            value={endDate}
            showTodayButton
            onChange={(date) =>
              handleDateChange({ target: { name: 'endDate', value: date } })
            }
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            required={required}
            error={error}
            style={{ width: 150 }}
          />
        </MuiPickersUtilsProvider>
      </Grid>
    </Grid>
  )
}
