import { useState, useEffect } from 'react'
import DateFnsUtils from '@date-io/date-fns'
import enLocale from 'date-fns/locale/en-US'
import esLocale from 'date-fns/locale/es'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import { useTranslation } from 'react-i18next'

export default function DatePicker({
  label,
  selectedDate,
  handleDateChange,
  name = '',
  required = false,
  error = false,
}) {
  const { i18n } = useTranslation()
  const [locale, setLocale] = useState(esLocale)

  useEffect(() => {
    setLocale(i18n.language === 'es' ? esLocale : enLocale)
  }, [i18n])

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
      <KeyboardDatePicker
        label={label}
        format="dd/MM/yyyy"
        value={selectedDate}
        showTodayButton
        onChange={(date) =>
          handleDateChange({ target: { name: name, value: date } })
        }
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        required={required}
        error={error}
      />
    </MuiPickersUtilsProvider>
  )
}
