import { useState, useEffect, forwardRef, useImperativeHandle } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  makeStyles,
  Divider,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { cadastreStore, userStore } from '../stores'
import { URL_SERVER, paxios } from '../constants/http'
import { CheckBox, Select } from '../components'

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  gridFilter: {
    marginBottom: 10,
  },
}))

const PlotsSelector = forwardRef(({ accept, single = false, preSelected = [] }, ref) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { currentPlantation } = userStore()
  const { plots, getPlots, sectors, getSectors } = cadastreStore()
  const [open, setOpen] = useState(false)
  const [plotsShow, setPlotsShow] = useState([])
  const [plotsSelected, setPlotsSelected] = useState(preSelected)
  const [levels, setLevels] = useState([])
  const [sectorsFilter, setSectorsFilter] = useState({
    sector1: '0',
    sector2: '0',
    sector3: '0',
    sector4: '0',
  })

  useEffect(() => {
    plots.length <= 0 && getPlots()
    plots.length > 0 && setPlotsShow([...plots])
  }, [getPlots, plots])

  useEffect(() => {
    sectors.length <= 0 && getSectors()
  }, [getSectors, sectors])

  const getLevelsAggregation = async (plantation) => {
    try {
      const response = await paxios.get(
        `${URL_SERVER}/plantations/levesAggregation/${plantation}`
      )

      response.data.status === 'ok' && setLevels(response.data.data)
    } catch (error) {}
  }

  useEffect(() => {
    getLevelsAggregation(currentPlantation.plantation)
  }, [currentPlantation.plantation])

  useEffect(() => {
    String(sectorsFilter.sector4) !== '0'
      ? setPlotsShow((d) => [
          ...d.filter(
            (x) => String(x.sector4) === String(sectorsFilter.sector4)
          ),
        ])
      : setPlotsShow(
          plots.filter(
            (x) => String(x.sector3) === String(sectorsFilter.sector3)
          )
        )

    String(sectorsFilter.sector3) !== '0'
      ? setPlotsShow((d) => [
          ...d.filter(
            (x) => String(x.sector3) === String(sectorsFilter.sector3)
          ),
        ])
      : setPlotsShow(
          plots.filter(
            (x) => String(x.sector2) === String(sectorsFilter.sector2)
          )
        )

    String(sectorsFilter.sector2) !== '0'
      ? setPlotsShow((d) => [
          ...d.filter(
            (x) => String(x.sector2) === String(sectorsFilter.sector2)
          ),
        ])
      : setPlotsShow(
          plots.filter(
            (x) => String(x.sector1) === String(sectorsFilter.sector1)
          )
        )

    String(sectorsFilter.sector1) !== '0'
      ? setPlotsShow((d) => [
          ...d.filter(
            (x) => String(x.sector1) === String(sectorsFilter.sector1)
          ),
        ])
      : setPlotsShow([...plots])
  }, [sectorsFilter, plots])

  const getTitleColumnSectors = (levels, order) => {
    return levels.filter((x) => x.order === order).length > 0
      ? levels.filter((x) => x.order === order)[0].level
      : `Sector ${order}`
  }

  const onChangeCheckBox = (e) => {
    const { value, checked } = e.target
    if (!single) {
      if (checked) {
        setPlotsSelected((d) => [
          ...d,
          plotsShow.filter((x) => String(x.id) === value)[0],
        ])
      } else if (!checked) {
        setPlotsSelected((d) => [...d.filter((x) => String(x.id) !== value)])
      }
    } else {
      setPlotsSelected([plotsShow.filter((x) => String(x.id) === value)[0]])
    }
  }

  const handleChangeSectorsFilter = (e, order) => {
    const { value } = e.target
    setSectorsFilter((d) => ({ ...d, [`sector${order}`]: value }))
    const filters = {}
    for (let i = order + 1; i <= 4; i++) {
      filters[`sector${i}`] = 0
    }
    setSectorsFilter((d) => ({ ...d, ...filters }))
  }

  useImperativeHandle(ref, () => ({
    setOpen,
  }))

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="md"
      fullWidth
      aria-labelledby="confirmation-dialog-title"
      open={open}
    >
      <DialogTitle id="confirmation-dialog-title">
        {t('general.plots')}
      </DialogTitle>

      <DialogContent dividers className={classes.dialogContent}>
        <Grid container justify="center" className={classes.gridFilter}>
          <Grid item xs={12} md={3}>
            <Select
              text={getTitleColumnSectors(levels, 1)}
              width={200}
              items={sectors.filter((x) => x.order === 1)}
              defaultOption0={true}
              value={sectorsFilter[`sector${1}`]}
              handleChange={(e) => handleChangeSectorsFilter(e, 1)}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Select
              text={getTitleColumnSectors(levels, 2)}
              width={200}
              items={
                sectorsFilter.sector1 === '0'
                  ? []
                  : sectors.filter(
                      (x) => x.order === 2 && x.parent === sectorsFilter.sector1
                    )
              }
              defaultOption0={true}
              value={sectorsFilter[`sector${2}`]}
              handleChange={(e) => handleChangeSectorsFilter(e, 2)}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Select
              text={getTitleColumnSectors(levels, 3)}
              width={200}
              items={
                sectorsFilter.sector2 === '0'
                  ? []
                  : sectors.filter(
                      (x) => x.order === 3 && x.parent === sectorsFilter.sector2
                    )
              }
              defaultOption0={true}
              value={sectorsFilter[`sector${3}`]}
              handleChange={(e) => handleChangeSectorsFilter(e, 3)}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Select
              text={getTitleColumnSectors(levels, 4)}
              width={200}
              items={
                sectorsFilter.sector3 === '0'
                  ? []
                  : sectors.filter(
                      (x) => x.order === 4 && x.parent === sectorsFilter.sector3
                    )
              }
              defaultOption0={true}
              value={sectorsFilter[`sector${4}`]}
              handleChange={(e) => handleChangeSectorsFilter(e, 4)}
            />
          </Grid>
        </Grid>
        <Divider />
        {!single ? (
          plotsShow.map((x) => (
            <CheckBox
              key={x.id}
              name={'plot'}
              value={x.id}
              label={x.name}
              checked={plotsSelected.some((y) => y.id === x.id)}
              onChange={onChangeCheckBox}
            />
          ))
        ) : (
          <RadioGroup
            aria-label="plots"
            name="plots"
            value={plotsSelected.length <= 0 ? 0 : plotsSelected[0].id}
            onChange={onChangeCheckBox}
          >
            {plotsShow.map((x) => (
              <FormControlLabel
                key={x.id}
                value={x.id}
                control={<Radio />}
                label={x.name}
              />
            ))}
          </RadioGroup>
        )}
      </DialogContent>

      <DialogActions>
        <Button
          onClick={() => {
            setPlotsSelected([])
            accept([])
          }}
          color="primary"
        >
          {t('general.clean')}
        </Button>
        <Button onClick={() => setOpen(false)} color="primary">
          {t('general.close')}
        </Button>
        <Button onClick={() => accept([...plotsSelected])} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  )
})

PlotsSelector.displayName = 'PlotsSelector'

export default PlotsSelector
