import {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Paper,
  Typography,
  makeStyles,
  IconButton,
} from "@material-ui/core";
import dayjs from "dayjs";
import SearchIcon from "@material-ui/icons/Search";
import { useTranslation } from "react-i18next";
import { laborsStore, userStore } from "../../../stores";
import {
  PlotsSelector,
  MultiSelect,
  DatePickerRange,
  Button,
} from "../../../components";

const Search = forwardRef(({ handleClickSearch }, ref) => {
  const { t } = useTranslation();
  const plotsSelectorRef = useRef(null);
  const { currentPlantation } = userStore();
  const { labors, getLabors, costCenters, getCostCenters } = laborsStore();
  const [open, setOpen] = useState(false);
  const [queryData, setQueryData] = useState({
    labor: [],
    initialDate: null,
    endDate: null,
    cost_center: [],
    state: [],
    plot: [],
  });

  const useStyles = makeStyles((theme) => ({
    root: {
      padding: "2px 4px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    gridContainer: {
      marginBottom: 10,
    },
    selectPlot: {
      margin: "auto 0",
    },
    dialogContent: {
      display: "flex",
      flexDirection: "column",
    },
  }));

  const classes = useStyles();

  useEffect(() => {
    labors.length <= 0 && getLabors();
    costCenters.length <= 0 && getCostCenters();
  }, [labors.length, getLabors, costCenters.length, getCostCenters]);

  useImperativeHandle(ref, () => ({
    getData: prepareData,
    setOpen,
  }));

  const handleSelectPlot = (plot) => {
    setQueryData((d) => ({ ...d, plot: plot.length > 0 ? plot : [] }));
    plotsSelectorRef.current !== null &&
      plotsSelectorRef.current.setOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setQueryData((d) => ({ ...d, [name]: value }));
  };

  const prepareData = () => {
    const initialDate =
      queryData.initialDate !== null
        ? dayjs(queryData.initialDate).format("YYYY-MM-DD")
        : null;

    const endDate =
      queryData.endDate !== null
        ? dayjs(queryData.endDate).format("YYYY-MM-DD")
        : null;

    let dates = "";
    if (initialDate && endDate) {
      dates = `${initialDate},${endDate}`;
    }

    return {
      ...queryData,
      plot: queryData.plot.map((x) => x.id),
      initialDate,
      endDate,
      dates,
      plantation: currentPlantation.plantation,
    };
  };

  const handleClickButtonSearch = () => {
    const send = prepareData();
    handleClickSearch(send);
  };

  const validateBottomSearch = () => {
    if (queryData.initialDate === null || queryData.endDate === null)
      return true;
    if (
      String(queryData.initialDate) === "Invalid Date" ||
      String(queryData.endDate) === "Invalid Date"
    )
      return true;

    return false;
  };

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="xl"
      fullWidth
      aria-labelledby="confirmation-dialog-title"
      open={open}
    >
      <DialogTitle id="confirmation-dialog-title">
        {t("general.search")}
      </DialogTitle>

      <DialogContent dividers className={classes.dialogContent}>
        <Grid
          container
          justify="center"
          spacing={2}
          className={classes.gridContainer}
        >
          <Grid item xs={12} md={3} className={classes.selectPlot}>
            <Paper component="form" className={classes.root}>
              <Typography variant="subtitle1">
                {queryData.plot.length !== 0
                  ? queryData.plot.reduce((acc, item) => {
                      return (acc += acc !== "" ? "," + item.name : item.name);
                    }, "")
                  : `${t("general.select")} ${t("general.plot")}`}
              </Typography>
              <IconButton
                type="button"
                aria-label="search"
                className={classes.iconButton}
                onClick={() =>
                  plotsSelectorRef.current !== null &&
                  plotsSelectorRef.current.setOpen(true)
                }
              >
                <SearchIcon />
              </IconButton>
            </Paper>
            <PlotsSelector accept={handleSelectPlot} ref={plotsSelectorRef} />
          </Grid>

          <Grid item xs={12} md={3}>
            <MultiSelect
              options={labors.map((x) => ({ value: x.id, label: x.name }))}
              label={`${t("general.labors")}`}
              onChangeExternal={(values) =>
                handleChange({ target: { name: "labor", value: values } })
              }
              valuesExternal={queryData.labor}
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <MultiSelect
              options={costCenters.map((x) => ({ value: x.id, label: x.name }))}
              label={`${t("general.cost_centers")}`}
              onChangeExternal={(values) =>
                handleChange({ target: { name: "cost_center", value: values } })
              }
              valuesExternal={queryData.cost_center}
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <MultiSelect
              options={[
                { value: 0, label: "No iniciada" },
                { value: 1, label: "Iniciada" },
                { value: 2, label: "Finalizada" },
              ]}
              label={`${t("general.state")}`}
              onChangeExternal={(values) =>
                handleChange({ target: { name: "state", value: values } })
              }
              valuesExternal={queryData.state}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <DatePickerRange
              initialDate={queryData.initialDate}
              endDate={queryData.endDate}
              handleDateChange={handleChange}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          handleButtonClick={() => setOpen(false)}
          text={t("general.close")}
        />
        <Button
          handleButtonClick={handleClickButtonSearch}
          text={t("general.search")}
          disabled={validateBottomSearch()}
        />
      </DialogActions>
    </Dialog>
  );
});

Search.displayName = "Search";

export default Search;
