import { useEffect, useState } from 'react'
import Swal from 'sweetalert2/src/sweetalert2.js'
import MaterialTable from 'material-table'
import { useTranslation } from 'react-i18next'
import { severalStore, userStore } from '../../../stores'
import { URL_SERVER, paxios } from '../../../constants/http'
import tableIcons from '../../../constants/IconsMaterialTable'
import tableTranslations from '../../../constants/tableTranslations'

const cellStyle = { align: 'center', headerStyle: { textAlign: 'center' } }

export default function Quadrilles() {
  const { t, i18n } = useTranslation()
  const { quadrilles, getQuadrilles } = severalStore()
  const { currentPlantation, user } = userStore()
  const [columns, setColumns] = useState([])

  useEffect(() => {
    const validateEmpty = (rowData, field) => {
      return rowData[field] === '' || rowData[field] === undefined
        ? t('general.noempty')
        : true
    }

    setColumns([
      { title: 'ID', field: 'id', editable: 'never' },
      {
        title: t('general.name'),
        field: 'name',
        validate: (rowData) => validateEmpty(rowData, 'name'),
      },
      {
        title: t('general.observations'),
        field: 'observations',
      },
      {
        title: t('general.state'),
        field: 'state',
        lookup: { 1: t('general.active'), 0: t('general.inactive') },
        initialEditValue: 1,
        ...cellStyle,
      },
    ])
  }, [t])

  const createQuadrille = async (data) => {
    try {
      const response = await paxios.post(`${URL_SERVER}/quadrilles`, {
        ...data,
        user: user.id,
        plantation: currentPlantation.plantation,
      })
      if (response.data.status === 'ok') {
        Swal.fire(t('general.finished'), t('general.savesuccess'), 'success')
        getQuadrilles()
      } else {
        Swal.fire(t('general.finished'), response.data.message, 'error')
      }
    } catch (error) {
      Swal.fire(t('general.finished'), t('general.datawasnotsaved'), 'error')
    }
  }

  const onRowAddQuadrille = (newData) =>
    new Promise(async (resolve) => {
      await createQuadrille(newData)
      resolve()
    })

  const updateQuadrille = async (data) => {
    try {
      const response = await paxios.put(`${URL_SERVER}/quadrilles/${data.id}`, {
        ...data,
        user: user.id,
        plantation: currentPlantation.plantation,
      })
      if (response.data.status === 'ok') {
        Swal.fire(t('general.finished'), t('general.savesuccess'), 'success')
        getQuadrilles()
      } else {
        Swal.fire(t('general.finished'), t('general.datawasnotsaved'), 'error')
      }
    } catch (error) {
      Swal.fire(t('general.finished'), t('general.datawasnotsaved'), 'error')
    }
  }

  const onRowUpdateQuadrille = (newData, oldData) =>
    new Promise(async (resolve) => {
      await updateQuadrille(newData)
      resolve()
    })

  return (
    <>
      <MaterialTable
        icons={tableIcons}
        columns={columns}
        title={`${t('general.quadrille')}s`}
        data={quadrilles.map((x) => ({ ...x, ti: new Date() }))}
        options={{
          exportButton: true,
          exportAllData: true,
          exportDelimiter: ';',
          selection: false,
        }}
        localization={i18n.language === 'es' ? tableTranslations : {}}
        editable={{
          onRowUpdate: onRowUpdateQuadrille,
          onRowAdd: onRowAddQuadrille,
        }}
      />
    </>
  )
}
