import { useRef, useEffect } from 'react'
import { Button } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { useTranslation } from 'react-i18next'

export default function InputFile({ selectedFile, onFileChange, setRefInputFile }) {
  const refInputFile = useRef(null);
  const { t } = useTranslation()
  const styles = {
    containerFile: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    alert: {
        minWidth: '250px'
    },
    button: {
      maxHeight: '36px', 
      minWidth: '210px',
      marginLeft: '5px'
    }
  }

  useEffect(() => {
    setRefInputFile(refInputFile)
  },[setRefInputFile])
  return (
    <div style={styles.containerFile}>
        {selectedFile && (
          <Alert variant="outlined" severity="info">
            {selectedFile ? selectedFile.name : null}
          </Alert>
        )}
      <Button
        variant="contained"
        component="label"
        color={'primary'}
        style={styles.button}
      >
        {t('general.choosefile')}
        <input type="file" ref={refInputFile} hidden onChange={onFileChange} />
      </Button>
    </div>
  )
}
