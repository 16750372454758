import { useState } from "react";
import MaterialTable from "material-table";
import tableIcons from "../../../../constants/IconsMaterialTable";
import tableTranslations from "../../../../constants/tableTranslations";
import { useTranslation } from "react-i18next";
import { URL_SERVER, paxios } from "../../../../constants/http";
import { SubTitle } from "../../../../components";
import SearchReports from "./SearchReports";

export default function Executions() {
  const { t, i18n } = useTranslation();
  const [records, setRecords] = useState([]);
  const [columns, setColumns] = useState([
    { title: t("general.year"), field: "year", editable: "never" },
    { title: t("general.plot"), field: "plot_name", editable: "never" },
    { title: t("general.product"), field: "product_name", editable: "never" },
    {
      title: t("general.fractions"),
      field: "fractions_count",
      editable: "never",
    },
    { title: t("general.palms"), field: "palms", editable: "never" },
    { title: `${t("general.advance")} %`, field: "advance", editable: "never" },
  ]);

  const getData = (data) => {
    getReport(data);
  };

  const addColumns = (fractionMax) => {
    let columns = [];
    for (let i = 0; i < fractionMax; i++) {
      columns.push({
        title: `${t("general.date")} ${i + 1}`,
        field: `date${i + 1}`,
        editable: "never",
      });
      columns.push({
        title: `${t("general.dose")} ${i + 1}`,
        field: `dose${i + 1}`,
        editable: "never",
      });
      columns.push({
        title: `${t("general.completed")} ${i + 1}`,
        field: `completed${i + 1}`,
        editable: "never",
      });
    }
    setColumns((x) => [...x, ...columns]);
  };

  const getReport = async (dataSend) => {
    try {
      const response = await paxios.post(
        `${URL_SERVER}/fertilizationPlan/executionReport`,
        dataSend
      );
      if (response.data.status === "ok") {
        addColumns(response.data.data.fractionMax);
        setRecords(response.data.data.plans);
      }
    } catch (error) {}
  };
  return (
    <>
      <SubTitle text={t("general.executions")} />
      <SearchReports getData={getData} />
      <MaterialTable
        icons={tableIcons}
        columns={columns}
        title={``}
        data={records}
        options={{
          exportButton: true,
          exportAllData: true,
          exportDelimiter: ";",
          selection: false,
        }}
        localization={i18n.language === "es" ? tableTranslations : {}}
      />
    </>
  );
}
