import { useState, useEffect, useCallback } from 'react'
import MaterialTable from '@material-table/core'
import dayjs from 'dayjs'
import { Grid, Typography } from '@material-ui/core'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import EventBusyIcon from '@material-ui/icons/EventBusy'
import { userStore } from '../../stores'
import { useTranslation } from 'react-i18next'
import Swal from 'sweetalert2/src/sweetalert2.js'
import { URL_SERVER, paxios } from '../../constants/http'
import tableIcons from '../../constants/IconsMaterialTable'
import tableTranslations from '../../constants/tableTranslations'
import { DatePicker, Button, Modal } from '../../components'

const cellStyle = { align: 'center', headerStyle: { textAlign: 'center' } }

export default function ByCensusSanitary() {
  const { currentPlantation } = userStore()
  const { t, i18n } = useTranslation()
  const [palms, setPalms] = useState([])
  const [columns] = useState([
    {
      title: t('general.plot'),
      field: 'plot_name',
      editable: 'never',
      ...cellStyle,
    },
    {
      title: t('general.line'),
      field: 'line',
      editable: 'never',
      ...cellStyle,
    },
    {
      title: t('general.palm'),
      field: 'palm',
      editable: 'never',
      ...cellStyle,
    },
    {
      title: t('general.disease'),
      field: 'disease_name',
      editable: 'never',
      ...cellStyle,
    },
    {
      title: t('general.event'),
      field: 'event_disease_name',
      editable: 'never',
      ...cellStyle,
    },
    {
      title: t('general.date'),
      field: 'date',
      editable: 'never',
      ...cellStyle,
    },
  ])
  const [palmsSelected, setPalmsSelected] = useState([])
  const [openModal, setOpenModal] = useState(false)
  const [dateEradication, setDateEradication] = useState(null)
  const [saving, setSaving] = useState(false)

  const getPalms = useCallback(async () => {
    try {
      const response = await paxios.get(
        `${URL_SERVER}/palms/getPalmsEradicationCensusSanitary/${currentPlantation.plantation}`
      )

      setPalms(response.data.data)
    } catch (error) {}
  }, [currentPlantation.plantation])

  useEffect(() => {
    getPalms()
  }, [getPalms])

  const selectRow = (rows) => {
    setPalmsSelected(rows)
  }

  const handleClickEradicate = () => {
    eradicatePalms(palmsSelected, dateEradication)
  }

  const eradicatePalms = async (palmsSelected, dateEradication) => {
    try {
      setSaving(true)
      const date = dayjs(dateEradication).format('YYYY-MM-DD')
      const response = await paxios.put(
        `${URL_SERVER}/palms/eradicatePalmCensusSanitary`,
        {
          palms: palmsSelected,
          date,
        }
      )
      setOpenModal(false)
      if (response.data.status === 'ok') {
        Swal.fire(t('general.finished'), t('general.taskdone'), 'success')
        getPalms()
        setDateEradication(null)
        setPalmsSelected([])
      } else {
        Swal.fire(t('general.finished'), t('general.tasknotdone'), 'error')
      }
      setSaving(false)
    } catch (error) {
      setOpenModal(false)
      setSaving(false)
      Swal.fire(t('general.finished'), t('general.tasknotdone'), 'error')
    }
  }
  return (
    <>
      <MaterialTable
        icons={tableIcons}
        columns={columns}
        title={t('general.palms')}
        data={palms}
        options={{
          exportButton: true,
          exportAllData: true,
          exportDelimiter: ';',
          selection: true,
        }}
        localization={i18n.language === 'es' ? tableTranslations : {}}
        onSelectionChange={selectRow}
      />

      <Grid container justify="center" alignItems="center">
        <Button
          icon={<HighlightOffIcon />}
          text={t('general.eradicate')}
          loading={false}
          handleButtonClick={() => setOpenModal(true)}
          disabled={palmsSelected.length <= 0}
          color={'secondary'}
        />
      </Grid>

      <Modal
        open={openModal}
        setOpen={setOpenModal}
        title={t('general.eradicate')}
        width="30%"
        icon={<EventBusyIcon />}
      >
        <Typography
          variant="h6"
          color={'primary'}
          style={{ textAlign: 'center', marginBottom: 20 }}
        >
          {`${t('general.select')} ${t(
            'general.date_eradication'
          ).toLowerCase()}`}
        </Typography>

        <Grid container justify="center" alignItems="center">
          <DatePicker
            label={''}
            selectedDate={dateEradication}
            handleDateChange={(e) => setDateEradication(e.target.value)}
          />
        </Grid>

        <Grid container justify="center" alignItems="center">
          <Button
            icon={<HighlightOffIcon />}
            text={t('general.eradicate')}
            loading={saving}
            handleButtonClick={handleClickEradicate}
            color={'secondary'}
            disabled={dateEradication === null}
          />
        </Grid>
      </Modal>
    </>
  )
}
