import { Route, Redirect } from 'react-router-dom'
import { userStore } from '../stores'
import MyContainer from './MyContainer'

export default function PrivateRoute({ component: Component, ...rest }) {
  const { logged } = userStore()
  return (
    <Route
      {...rest}
      render={(props) =>
        logged ? (
          <MyContainer>
            <Component {...rest} />
          </MyContainer>
        ) : (
          <Redirect to={'/'} />
        )
      }
    />
  )
}
