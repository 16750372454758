import {
  useRef,
  useEffect,
  useState,
  useCallback,
  forwardRef,
  useImperativeHandle,
  useMemo,
} from 'react'
import 'mapbox-gl/dist/mapbox-gl.css'
import mapboxgl from '!mapbox-gl' // eslint-disable-line import/no-webpack-loader-syntax
import { userStore, cadastreStore } from '../../stores'
import { useTranslation } from 'react-i18next'
import { URL_SERVER, paxios, getTokenQgis, MAPBOX_ACCESS_TOKEN } from '../../constants/http'
import {
  featurePlantation,
  featurePlot,
  featurePlotAction,
  featurePalms,
  LayerCustomControl,
  toggleLayer,
  createInputUriQgis,
} from './utilities'
import './sidebar.css';

mapboxgl.accessToken = MAPBOX_ACCESS_TOKEN

const Map = forwardRef(({ filtersLayerExternal = [], layerExternalWithoutFilter=[] }, ref) => {
  const mapContainer = useRef(null)
  const { currentPlantation } = userStore()
  const { plots } = cadastreStore()
  const { t } = useTranslation()
  const [map, setMap] = useState(null)
  const sidebarRightContentRef = useRef(null)
  const [rightSidebarRight, setRightSidebarRight] = useState(-10);
  const toggleableLayer = useMemo(
    () => [
      {
        id: 'plantation_layer',
        text: t('general.plantation'),
        onClick: (id, map) => toggleLayer(id, map),
      },
      {
        id: 'plots_layer',
        text: t('general.plots'),
        onClick: (id, map) => toggleLayer(id, map),
      },
    ],
    [t]
  )
  //const [lng, setLng] = useState(-74.14)
  //const [lat, setLat] = useState(4.63)
  //const [zoom, setZoom] = useState(4)

  const getLayerPlots = useCallback(
    async (map) => {
      try {
        const response = await paxios.get(
          `${URL_SERVER}/plots/getMap/${currentPlantation.plantation}`
        )

        map.addSource('plots', {
          type: 'geojson',
          data: response.data,
        })

        map.addLayer({
          id: 'plots_layer',
          type: 'fill',
          source: 'plots',
          layout: {
            visibility: 'visible',
          },
          paint: {
            'fill-color': '#5FB404',
            'fill-outline-color': '#5FB404',
            'fill-opacity': 0.7,
          },
        })
      } catch (error) {}
    },
    [currentPlantation.plantation]
  )

  const fitBoundsPlantation = (geojson, map) => {
    const coordinates = geojson.features[0].geometry.coordinates[0][0]
    const bounds = new mapboxgl.LngLatBounds(coordinates[0], coordinates[0])

    for (const coord of coordinates) {
      bounds.extend(coord)
    }

    map.fitBounds(bounds, {
      padding: 20,
    })
  }

  const getLayerPlantation = useCallback(
    async (map) => {
      try {
        const response = await paxios.get(
          `${URL_SERVER}/plantations/getMap/${currentPlantation.plantation}`
        )

        map.addSource('plantation', {
          type: 'geojson',
          data: response.data,
        })

        map.addLayer(
          {
            id: 'plantation_layer',
            type: 'fill',
            source: 'plantation',
            layout: {
              visibility: 'visible',
            },
            paint: {
              'fill-color': '#DF7401',
              'fill-outline-color': '#DF7401',
              'fill-opacity': 0.5,
            },
          },
          'plots_layer'
        )
        return response.data
      } catch (error) {
        return null
      }
    },
    [currentPlantation.plantation]
  )

  const createLayersPalms = (plots, map) => {
    for (const plot of plots) {
      map.addSource('palms_' + plot.id, {
        type: 'geojson',
        data: { type: 'FeatureCollection', features: [] },
        cluster: false,
      })

      map.addLayer({
        id: 'palms_layer_' + plot.id,
        type: 'symbol',
        source: 'palms_' + plot.id,
        layout: {
          'icon-image': [
            'case',
            ['==', ['get','state'], 1],
            'palmpng',
            'palmdeadpng',
          ],
          'icon-size': 0.04,
          /* 'text-field': ['get', 'palm'],
          'text-font': [
            'Open Sans Semibold',
            'Arial Unicode MS Bold'
          ],
          'text-offset': [0, 0.25],
          'text-anchor': 'top' */
        },
      })

      const popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false
      });

      map.on('mouseenter', 'palms_layer_' + plot.id, function (e) {
        map.getCanvas().style.cursor = 'pointer';
        const coordinates = e.features[0].geometry.coordinates.slice();
        const description = `${e.features[0].properties.line}-${e.features[0].properties.palm}`;
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }
        popup.setLngLat(coordinates).setHTML(`<span style="opacity: 0.5;">${description}</span>`).addTo(map);
      })

      map.on('mouseleave', 'palms_layer_' + plot.id, function () {
        map.getCanvas().style.cursor = '';
        popup.remove();
      })
    }
  }

  const openSidebarRight = () => {
    setRightSidebarRight(300)
  }

  const closeSidebarRight = () => {
    setRightSidebarRight(-10)
  }

  const changeSidebarRightContent = (html) => {
    sidebarRightContentRef.current.innerHTML = '';
    sidebarRightContentRef.current.append(html)
  }

  const handleClickMap = useCallback((map, plots) => {
    map.on('click', (e) => {
      for (const layer of filtersLayerExternal) {
        const layerExist = map.getLayer(layer.id + '_layer')
        if (layerExist) {
          const layerFound = map.queryRenderedFeatures(e.point, {
            layers: [layer.id + '_layer'],
          })
          if (layerFound.length > 0) {
            e.originalEvent.cancelBubble = true
            layer.onClickLayer(mapboxgl, e, layer.id, layerFound[0])
          }
        }
      }

      if (e.originalEvent.cancelBubble) {
        return
      }

      for (const layer of layerExternalWithoutFilter) {
        const layerExist = map.getLayer(layer.id + '_layer')
        if (layerExist) {
          const layerFound = map.queryRenderedFeatures(e.point, {
            layers: [layer.id + '_layer'],
          })
          if (layerFound.length > 0) {
            e.originalEvent.cancelBubble = true
            layer.onClickLayer(mapboxgl, e, layer.id, layerFound[0])
          }
        }
      }

      if (e.originalEvent.cancelBubble) {
        return
      }

      for (const plot of plots) {
        const palmsLayer = map.queryRenderedFeatures(e.point, {
          layers: ['palms_layer_' + plot.id],
        })
        if (palmsLayer.length > 0) {
          e.originalEvent.cancelBubble = true
          //const popup = createPopup(mapboxgl, e, palmsLayer[0], featurePalms)
          //popup.addTo(map)
          const html = featurePalms(palmsLayer[0]);
          changeSidebarRightContent(html);
          openSidebarRight()
          return
        }
      }

      if (e.originalEvent.cancelBubble) {
        return
      }

      let plotsLayer = map.queryRenderedFeatures(e.point, {
        layers: ['plots_layer'],
      })
      if (plotsLayer.length > 0) {
        /* const popup = createPopup(mapboxgl, e, plotsLayer[0], featurePlot)
        popup.on('open', function (ev) {
          featurePlotAction(ev, plotsLayer[0].properties, map)
        })
        popup.addTo(map) */
        (async () => {
          const html = featurePlot(plotsLayer[0]);
          const token = await getTokenQgis();
          const uri = `${URL_SERVER}/qgis/getPalms/${plotsLayer[0].properties.id}/${token}`;
          const uriQgis = createInputUriQgis(uri, t("general.palms"));
          html.append(uriQgis)
          changeSidebarRightContent(html);
          featurePlotAction(null, plotsLayer[0].properties, map, sidebarRightContentRef.current)
          openSidebarRight()
        })()
        
        return
      }

      let plantationLayer = map.queryRenderedFeatures(e.point, {
        layers: ['plantation_layer'],
      })
      if (plantationLayer.length > 0) {
        //const popup = createPopup(mapboxgl,e,plantationLayer[0],featurePlantation)
        //popup.addTo(map)
        (async () => {
          const html = featurePlantation(plantationLayer[0]);
          const token = await getTokenQgis();
          const uri = `${URL_SERVER}/qgis/getPlantation/${currentPlantation.plantation}/${token}`;
          const uriQgis = createInputUriQgis(uri, t("general.plantation"));
          const uriPlots = `${URL_SERVER}/qgis/getPlots/${currentPlantation.plantation}/${token}`;
          const uriQgisPlots = createInputUriQgis(uriPlots, t("general.plots"));
          html.append(uriQgis)
          html.append(uriQgisPlots)
          changeSidebarRightContent(html);
          openSidebarRight()
        })()
        
        return
      }
    })
  },[filtersLayerExternal,layerExternalWithoutFilter, currentPlantation.plantation, t])

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainer.current,
      profile: 'mapbox/walking',
      //style: 'mapbox://styles/mapbox/streets-v11',
      style: {
        version: 8,
        sources: {
          openstreetmap: {
            type: 'raster',
            tiles: [
              'http://a.tile.openstreetmap.org/{z}/{x}/{y}.png',
              'http://b.tile.openstreetmap.org/{z}/{x}/{y}.png',
            ],
            tileSize: 256,
          },
        },
        layers: [
          {
            id: 'openstreetmap',
            type: 'raster',
            source: 'openstreetmap',
            minzoom: 0,
            maxzoom: 22,
          },
        ],
        glyphs: "mapbox://fonts/mapbox/{fontstack}/{range}.pbf"
      },
      center: [-74.14, 4.63],
      zoom: 8,
      maxZoom: 18,
      
    })

    map.loadImage('./img/palm-tree-1.png', (error, image) => {
      map.addImage('palmpng', image)
    })
    map.loadImage('./img/palm-tree-dead.png', (error, image) => {
      map.addImage('palmdeadpng', image)
    })

    map.addControl(new mapboxgl.FullscreenControl())
    map.addControl(new mapboxgl.NavigationControl())
    map.addControl(
      new LayerCustomControl(
        [...toggleableLayer, ...filtersLayerExternal],
        10,
        true
      ),
      'top-left'
    )

    map.on('load', async () => {
      await getLayerPlots(map)
      const dataLayerPlantation = await getLayerPlantation(map)
      fitBoundsPlantation(dataLayerPlantation, map)
      createLayersPalms(plots, map)
      handleClickMap(map, plots)
      setMap(map)
    })

    return () => {
      map && map.remove()
    }
  }, [
    getLayerPlots,
    getLayerPlantation,
    plots,
    toggleableLayer,
    filtersLayerExternal,
    handleClickMap
  ])

  const getMap = () => map

  useImperativeHandle(ref, () => ({
    getMap,
    changeSidebarRightContent,
    openSidebarRight,
    closeSidebarRight,
  }))

  return (
    <div
      ref={mapContainer}
      className="map-container"
      style={{ height: '85vh' }}
    >
      <div id="sidebarRight" className="sidebar flex-center right collapsed" style={{right: rightSidebarRight}}>
        <div style={{fontSize: 20, cursor: 'pointer'}} onClick={closeSidebarRight}>&rarr;</div>
        <div className="sidebar-content rounded-rect flex-center" ref={sidebarRightContentRef}>
          
        </div>
      </div>
    </div>
  )
})

Map.displayName = 'Map'

export default Map
