import { useTranslation } from 'react-i18next'
import { userStore } from '../../stores'
import { URL_SERVER, paxios, uploadFile } from '../../constants/http'
import { LoadExcel } from '../../components'

export default function ByExcel() {
  const { currentPlantation } = userStore()
  const { t } = useTranslation()

  const columns = [
    { id: 'date', label: t('general.date') },
    { id: 'plot', label: t('general.plot') },
    { id: 'line', label: t('general.line') },
    { id: 'palm', label: t('general.palm') },
    { id: 'first', label: t('general.first') },
    { id: 'second', label: t('general.second') },
    { id: 'third', label: t('general.third') },
    { id: 'worker', label: t('general.worker') },
    { id: 'observations', label: t('general.observations') },
    { id: 'errors', label: t('general.errors') },
  ]

  const loadFile = async (file) => {
    try {
      const response = await uploadFile({
        url: `${URL_SERVER}/pollinationAna/loadExcelPollination`,
        file,
        nameFile: 'file_pollination_ana',
        aditionalData: [
          { name: 'plantation', value: currentPlantation.plantation },
        ],
      })
      return response
    } catch (error) {}
  }

  const saveData = async (records) => {
    try {
      const response = await paxios.post(
        `${URL_SERVER}/pollinationAna/saveExcelPollination`,
        {
          plantation: currentPlantation.plantation,
          data: records,
        }
      )
      return response
    } catch (error) {}
  }

  return (
    <>
      <LoadExcel columns={columns} loadFile={loadFile} saveData={saveData} />
    </>
  )
}
