import { useState, useEffect } from 'react'
import dayjs from 'dayjs'
import { Grid, TextField } from '@material-ui/core'
import SaveIcon from '@material-ui/icons/Save'
import { Alert } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core/styles'
import { userStore, cadastreStore } from '../../stores'
import { useTranslation } from 'react-i18next'
import Swal from 'sweetalert2/src/sweetalert2.js'
import { URL_SERVER, paxios } from '../../constants/http'
import { generatePalmId } from '../../constants/utilities'
import { Select, DatePicker, Button } from '../../components'

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
  },
}))

const initialData = {
  plot: 0,
  line: 0,
  palm: 0,
  reason: 0,
  date: new Date(),
  palmId: 0,
}

export default function ByPalm() {
  const classes = useStyles()
  const { reasonsEradication, plots } = cadastreStore()
  const { currentPlantation } = userStore()
  const { t } = useTranslation()
  const [data, setData] = useState({ ...initialData })
  const [errors, setErrors] = useState({})

  useEffect(() => {
    const palmId = generatePalmId({
      plantation: currentPlantation.plantation,
      plot: data.plot,
      line: data.line,
      palm: data.palm,
    })
    setData((d) => ({ ...d, palmId }))
  }, [data.plot, data.line, data.palm, currentPlantation.plantation])

  const onChange = (e) => {
    const { name, value } = e.target
    setData((d) => ({ ...d, [name]: value }))
    setErrors({})
  }

  const validateData = (data) => {
    const errors = {}
    if (data.plot === '0' || data.plot === 0)
      errors.plot = t('general.selectanitem')
    if (data.line === '') errors.line = t('general.noempty')
    if (data.line === '0' || data.line === 0) errors.line = t('general.nozero')
    if (data.palm === '') errors.palm = t('general.noempty')
    if (data.palm === '0' || data.palm === 0) errors.palm = t('general.nozero')
    if (data.reason === '0' || data.reason === 0)
      errors.reason = t('general.selectanitem')
    if (data.date === null || data.date === '')
      errors.date = t('general.date_invalid')

    return errors
  }

  const handleClickSave = () => {
    validateErrors(data)
  }

  const validateErrors = (data) => {
    const errors = validateData(data)
    if (Object.keys(errors).length <= 0) {
      saveData(data)
    } else {
      setErrors(errors)
    }
  }

  const saveData = async (data) => {
    try {
      const send = {
        ...data,
        date: data.date !== null ? dayjs(data.date).format('YYYY-MM-DD') : null,
      }

      const response = await paxios.put(
        `${URL_SERVER}/palms/eradicatePalm/${data.palmId}`,
        send
      )
      if (response.data.status === 'ok') {
        Swal.fire(t('general.finished'), t('general.savesuccess'), 'success')
        setData({ ...initialData })
      } else {
        Swal.fire(t('general.finished'), t('general.datawasnotsaved'), 'error')
      }
    } catch (error) {
      Swal.fire(t('general.finished'), t('general.datawasnotsaved'), 'error')
    }
  }

  return (
    <>
      <Grid container spacing={5} className={classes.root} alignItems="center">
        <Grid item xs={12} md={2} style={{ justifyItems: 'center' }}>
          <Select
            name="plot"
            text={t('general.plot')}
            width={'100%'}
            items={plots}
            defaultOption0={true}
            value={data.plot}
            handleChange={onChange}
            required={true}
            error={data.plot === '0' || data.plot === 0}
          />
          {errors.plot && <Alert severity="error">{errors.plot}</Alert>}
        </Grid>
        <Grid item xs={12} md={2}>
          <TextField
            fullWidth
            required
            label={t('general.line')}
            name="line"
            type={'number'}
            value={data.line}
            onChange={onChange}
            error={data.line === '' || data.line === '0' || data.line === 0}
          />
          {errors.line && <Alert severity="error">{errors.line}</Alert>}
        </Grid>
        <Grid item xs={12} md={2}>
          <TextField
            fullWidth
            required
            label={t('general.palm')}
            name="palm"
            type={'number'}
            value={data.palm}
            onChange={onChange}
            error={data.palm === '' || data.palm === '0' || data.palm === 0}
          />
          {errors.palm && <Alert severity="error">{errors.palm}</Alert>}
        </Grid>
        <Grid item xs={12} md={2}>
          <Select
            name="reason"
            text={t('general.reason_eradication')}
            width={'100%'}
            items={reasonsEradication}
            defaultOption0={true}
            value={data.reason}
            handleChange={onChange}
            error={data.reason === '0' || data.reason === 0}
          />
          {errors.reason && <Alert severity="error">{errors.reason}</Alert>}
        </Grid>
        <Grid item xs={12} md={2}>
          <DatePicker
            label={'Fecha'}
            name={'date'}
            required={true}
            selectedDate={data.date}
            handleDateChange={onChange}
            error={data.date === null || data.date === ''}
          />
          {errors.date && <Alert severity="error">{errors.date}</Alert>}
        </Grid>
        <Grid item xs={12} md={2}>
          <Button
            icon={<SaveIcon />}
            text={t('general.save')}
            loading={false}
            handleButtonClick={handleClickSave}
          />
        </Grid>
      </Grid>
    </>
  )
}
