import { useEffect, useState, useCallback, useRef } from 'react'
import dayjs from 'dayjs'
import {
  MenuItem,
  Select,
  Grid,
  Typography,
  makeStyles,
  Paper,
  IconButton,
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import Swal from 'sweetalert2/src/sweetalert2.js'
import MaterialTable from 'material-table'
import { useTranslation } from 'react-i18next'
import {
  userStore,
  cadastreStore,
  sanitationStore,
  severalStore,
} from '../../stores'
import { URL_SERVER, paxios } from '../../constants/http'
import tableIcons from '../../constants/IconsMaterialTable'
import tableTranslations from '../../constants/tableTranslations'
import { Button, PlotsSelector, DatePickerRange } from '../../components'

const cellStyle = { align: 'center', headerStyle: { textAlign: 'center' } }

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  gridContainer: {
    marginBottom: 10,
  },
}))

export default function Manage() {
  const classes = useStyles()
  const { t, i18n } = useTranslation()
  const { currentPlantation } = userStore()
  const { diseases, eventsDiseases, treatments } = sanitationStore()
  const { plots } = cadastreStore()
  const { workers } = severalStore()
  const [records, setRecords] = useState([])
  const [columns, setColumns] = useState([])
  const plotsSelectorRef = useRef(null)
  const [queryData, setQueryData] = useState({
    plot: 0,
    initialDate: null,
    endDate: null,
  })

  const handleSelectPlot = (plot) => {
    setQueryData((d) => ({ ...d, plot: plot.length > 0 ? plot : 0 }))
    plotsSelectorRef.current !== null && plotsSelectorRef.current.setOpen(false)
  }

  const handleDateRangeChange = (e) => {
    const { name, value } = e.target
    setQueryData((d) => ({ ...d, [name]: value }))
  }

  const eventFilter = useCallback(
    (disease) => {
      return eventsDiseases.reduce((acc, item) => {
        return item.disease === disease || disease === 0
          ? { ...acc, [item.id]: item.name }
          : { ...acc }
      }, {})
    },
    [eventsDiseases]
  )

  const editComponentDiseases = useCallback(
    (props) => {
      const data = { ...props.rowData }
      return (
        <div className="MuiFormControl-root">
          <Select
            value={props.value !== undefined ? props.value : 0}
            onChange={(e) => {
              data.disease = parseInt(e.target.value)
              data.event_disease = 0
              props.onRowDataChange(data)
            }}
          >
            <MenuItem key={0} value={0}>
              {t('general.notassigned')}
            </MenuItem>
            {diseases.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
          {data.disease === 0 && (
            <p className="MuiFormHelperText-root Mui-error MuiFormHelperText-filled">
              {t('general.nozero')}
            </p>
          )}
        </div>
      )
    },
    [diseases, t]
  )

  const editComponentEventsDisease = useCallback(
    (props) => {
      const data = { ...props.rowData }
      return (
        <div className="MuiFormControl-root">
          <Select
            value={props.value !== undefined ? props.value : 0}
            onChange={(e) => {
              data.event_disease = parseInt(e.target.value)
              props.onRowDataChange(data)
            }}
          >
            <MenuItem key={0} value={0}>
              {t('general.notassigned')}
            </MenuItem>
            {Object.keys(eventFilter(data.disease)).map((item) => (
              <MenuItem key={item} value={item}>
                {eventsDiseases.find((x) => String(x.id) === item).name}
              </MenuItem>
            ))}
          </Select>
          {data.event_disease === 0 && (
            <p className="MuiFormHelperText-root Mui-error MuiFormHelperText-filled">
              {t('general.nozero')}
            </p>
          )}
        </div>
      )
    },
    [eventsDiseases, t, eventFilter]
  )

  useEffect(() => {
    const validateEmpty = (rowData, field) => {
      return rowData[field] === '' ||
        rowData[field] === undefined ||
        rowData[field] === null ||
        isNaN(rowData[field])
        ? t('general.noempty')
        : true
    }

    const validateDate = (rowData, field) => {
      return rowData[field] === '' ||
        rowData[field] === undefined ||
        rowData[field] === null
        ? t('general.date_invalid')
        : true
    }

    const validateNoZero = (rowData, field) => {
      return rowData[field] <= 0 || rowData[field] === '0'
        ? t('general.nozero')
        : true
    }

    const validateNoZeroEmpty = (rowData, field) => {
      if (
        validateNoZero(rowData, field) === true &&
        validateEmpty(rowData, field) === true
      ) {
        return true
      } else {
        return `${
          validateNoZero(rowData, field) === true
            ? ''
            : validateNoZero(rowData, field)
        } ${
          validateEmpty(rowData, field) === true
            ? ''
            : ', ' + validateEmpty(rowData, field)
        }`
      }
    }

    setColumns([
      { title: 'ID', field: 'id', editable: 'never' },
      {
        title: t('general.date'),
        field: 'date',
        type: 'date',
        validate: (rowData) => validateDate(rowData, 'date'),
      },
      {
        title: t('general.plot'),
        field: 'plot',
        lookup: {
          0: t('general.notassigned'),
          ...plots.reduce((acc, item) => {
            return { ...acc, [item.id]: item.name }
          }, {}),
        },
        initialEditValue: 0,
        ...cellStyle,
        validate: (rowData) => validateNoZero(rowData, 'plot'),
      },
      {
        title: t('general.line'),
        field: 'line',
        type: 'numeric',
        validate: (rowData) => validateNoZeroEmpty(rowData, 'line'),
      },
      {
        title: t('general.palm'),
        field: 'palm',
        type: 'numeric',
        validate: (rowData) => validateNoZeroEmpty(rowData, 'palm'),
      },
      {
        title: t('general.treatments'),
        field: 'treatment',
        lookup: {
          0: t('general.notassigned'),
          ...treatments.reduce((acc, item) => {
            return { ...acc, [item.id]: item.name }
          }, {}),
        },
        initialEditValue: 0,
        ...cellStyle,
        validate: (rowData) => validateNoZero(rowData, 'treatment'),
      },
      {
        title: t('general.disease'),
        field: 'disease',
        lookup: {
          0: t('general.notassigned'),
          ...diseases.reduce((acc, item) => {
            return { ...acc, [item.id]: item.name }
          }, {}),
        },
        initialEditValue: 0,
        ...cellStyle,
        validate: (rowData) => validateNoZero(rowData, 'disease'),
        editComponent: editComponentDiseases,
      },
      {
        title: t('general.event'),
        field: 'event_disease',
        lookup: eventFilter(0),
        initialEditValue: 0,
        ...cellStyle,
        validate: (rowData) => validateNoZero(rowData, 'event_disease'),
        editComponent: editComponentEventsDisease,
      },
      {
        title: t('general.worker'),
        field: 'worker',
        lookup: {
          0: t('general.notassigned'),
          ...workers.reduce((acc, item) => {
            return { ...acc, [item.id]: item.name }
          }, {}),
        },
        initialEditValue: 0,
        ...cellStyle,
        validate: (rowData) => validateNoZero(rowData, 'worker'),
      },
      { title: t('general.observations'), field: 'observations' },
    ])
  }, [
    plots,
    treatments,
    diseases,
    eventsDiseases,
    workers,
    eventFilter,
    editComponentDiseases,
    editComponentEventsDisease,
    t,
  ])

  const handleClickSearch = () => {
    search()
  }

  const search = async () => {
    try {
      const send = {
        ...queryData,
        plot:
          queryData.plot !== '0' && queryData.plot !== 0
            ? queryData.plot[0].id
            : 0,
        dates:
          queryData.initialDate !== null && queryData.endDate !== null
            ? `${dayjs(queryData.initialDate).format('YYYY-MM-DD')},${dayjs(
                queryData.endDate
              ).format('YYYY-MM-DD')}`
            : '',
        plantation: currentPlantation.plantation,
      }
      const response = await paxios.post(
        `${URL_SERVER}/executionTreatments/getExecutionTreatments`,
        send
      )

      if (response.data.status === 'ok') {
        setRecords(response.data.data)
      }
    } catch (error) {
      //console.log(error)
    }
  }

  const onRowAdd = (newData) =>
    new Promise(async (resolve) => {
      createExecutionTreatments(newData)
      resolve()
    })

  const createExecutionTreatments = async (data) => {
    try {
      const send = {
        ...data,
        date: data.date !== null ? dayjs(data.date).format('YYYY-MM-DD') : null,
      }
      const response = await paxios.post(
        `${URL_SERVER}/executionTreatments/registerExecutionTreatments`,
        { ...send, plantation: currentPlantation.plantation }
      )
      if (response.data.status === 'ok') {
        Swal.fire(t('general.finished'), t('general.savesuccess'), 'success')
        setRecords((d) => [...d, { ...response.data.data[0] }])
      } else {
        Swal.fire(t('general.finished'), response.data.message, 'error')
      }
    } catch (error) {
      Swal.fire(t('general.finished'), t('general.datawasnotsaved'), 'error')
    }
  }

  const onRowUpdate = (newData, oldData) =>
    new Promise(async (resolve) => {
      updateExecutionTreatments(newData)
      resolve()
    })

  const updateExecutionTreatments = async (data) => {
    try {
      const send = {
        ...data,
        date: data.date !== null ? dayjs(data.date).format('YYYY-MM-DD') : null,
      }
      const response = await paxios.put(
        `${URL_SERVER}/executionTreatments/updateExecutionTreatments/${data.id}`,
        send
      )
      if (response.data.status === 'ok') {
        Swal.fire(t('general.finished'), t('general.savesuccess'), 'success')
        setRecords((d) => {
          return d.map((x) => {
            if (String(x.id) === String(data.id)) return { ...send }
            return { ...x }
          })
        })
      } else {
        Swal.fire(t('general.finished'), response.data.message, 'error')
      }
    } catch (error) {
      Swal.fire(t('general.finished'), t('general.datawasnotsaved'), 'error')
    }
  }

  return (
    <>
      <Grid
        container
        justify="center"
        spacing={2}
        className={classes.gridContainer}
      >
        <Grid item xs={12} md={4}>
          <Paper component="form" className={classes.root}>
            <Typography variant="subtitle1">
              {queryData.plot !== 0
                ? queryData.plot.reduce((acc, item) => {
                    return (acc += acc !== '' ? ',' + item.name : item.name)
                  }, '')
                : `${t('general.select')} ${t('general.plot')}`}
            </Typography>
            <IconButton
              type="button"
              aria-label="search"
              className={classes.iconButton}
              onClick={() =>
                plotsSelectorRef.current !== null &&
                plotsSelectorRef.current.setOpen(true)
              }
            >
              <SearchIcon />
            </IconButton>
          </Paper>
          <PlotsSelector
            accept={handleSelectPlot}
            ref={plotsSelectorRef}
            single={true}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <DatePickerRange
            initialDate={queryData.initialDate}
            endDate={queryData.endDate}
            handleDateChange={handleDateRangeChange}
          />
        </Grid>
      </Grid>

      <Button
        icon={<SearchIcon />}
        handleButtonClick={handleClickSearch}
        text={t('general.search')}
        disabled={queryData.initialDate === null || queryData.endDate === null}
      />

      <MaterialTable
        icons={tableIcons}
        columns={columns}
        title={t('general.treatments')}
        data={records.map((x) => ({
          ...x,
          date: x.date !== null ? x.date + ' 00:00:00' : null,
        }))}
        options={{
          exportButton: true,
          exportAllData: true,
          exportDelimiter: ';',
          selection: false,
        }}
        localization={i18n.language === 'es' ? tableTranslations : {}}
        editable={{
          onRowUpdate: onRowUpdate,
          onRowAdd: onRowAdd,
        }}
      />
    </>
  )
}
