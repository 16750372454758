export const localStorageCreateUpdate = (item, data) => {
  localStorage.setItem(item, JSON.stringify(data))
}

export const localStorageSelect = (item) => {
  let a = localStorage.getItem(item)
  if(item === 'i18nextLng') a = `"${a}"`
  return JSON.parse(a)
}

export const localStorageDelete = (item) => {
  localStorage.removeItem(item)
}
