import { useState, useRef, useEffect, useCallback } from 'react'
import dayjs from 'dayjs'
import {
  Grid,
  Paper,
  Typography,
  makeStyles,
  IconButton,
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import { useTranslation } from 'react-i18next'
import { userStore, sanitationStore } from '../stores'
import { URL_SERVER, paxios } from '../constants/http'
import PlotsSelector from './PlotsSelector'
import Select from './Select'
import Button from './Button'
import DatePickerRange from './DatePickerRange'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  gridContainer: {
    marginBottom: 10,
  },
}))

export default function SanitationRecordsSearch({
  years,
  titleCensus,
  handleClickSearch,
  reportPest = false,
  datesOptional = false
}) {
  const classes = useStyles()
  const { currentPlantation } = userStore()
  const { t } = useTranslation()
  const plotsSelectorRef = useRef(null)
  const [census, setCensus] = useState([])
  const { pests } = sanitationStore()
  const [queryData, setQueryData] = useState({
    plot: 0,
    year: 0,
    census: 0,
    initialDate: null,
    endDate: null,
    pest: 0,
  })
  const [widthSelect] = useState(reportPest ? 2 : 3)

  const getRouteGetCensus = useCallback(
    (tilte, plantation, year) => {
      if (tilte === t('general.census'))
        return `${URL_SERVER}/censusDiaseases/getCensusByYear/${plantation}/${year}`
      if (tilte === 'rhynchophorus')
        return `${URL_SERVER}/censusRhynchophorus/getCensusByYear/${plantation}/${year}`

      return `${URL_SERVER}/readingsPests/getReadingsByYear/${plantation}/${year}`
    },
    [t]
  )

  const getCensus = useCallback(
    async (plantation, year) => {
      try {
        const response = await paxios.get(
          getRouteGetCensus(titleCensus, plantation, year)
        )
        if (response.data.status === 'ok') {
          setCensus(response.data.data)
        }
      } catch (error) { }
    },
    [titleCensus, getRouteGetCensus]
  )

  useEffect(() => {
    setQueryData((d) => ({ ...d, census: 0 }))
    if (String(queryData.year) !== '0') {
      getCensus(currentPlantation.plantation, queryData.year)
    } else {
      setCensus([])
    }
  }, [queryData.year, currentPlantation.plantation, getCensus])

  const handleSelectPlot = (plot) => {
    setQueryData((d) => ({ ...d, plot: plot.length > 0 ? plot : 0 }))
    plotsSelectorRef.current !== null && plotsSelectorRef.current.setOpen(false)
  }

  const handleChangePest = (e) => {
    const { value } = e.target
    setQueryData((d) => ({ ...d, pest: value }))
  }

  const handleChangeYear = (e) => {
    const { value } = e.target
    setQueryData((d) => ({ ...d, year: value }))
  }

  const handleChangeCensus = (e) => {
    const { value } = e.target
    setQueryData((d) => ({ ...d, census: value }))
  }

  const handleDateRangeChange = (e) => {
    const { name, value } = e.target
    setQueryData((d) => ({ ...d, [name]: value }))
  }

  const validateButtonSearch = () => {
    if ((String(queryData.year) === '0') && (String(queryData.initialDate) === 'Invalid Date' || String(queryData.endDate) === 'Invalid Date' || queryData.initialDate === null || queryData.endDate === null)) return true
    return false
  }

  const getTitleSelectCensus = (title) => {
    if (title === t('general.census'))
      return t('general.census')

    return t('general.reading')
  }

  const getDataSelectCensus = (title) => {
    if (title === t('general.census') || title === 'rhynchophorus')
      return census.map((x) => ({ id: x.census, name: x.census }))

    return census.map((x) => ({ id: x.reading, name: x.reading }))
  }

  const _handleClickSearch = () => {
    let initialDate = dayjs(queryData.initialDate).format('YYYY-MM-DD')
    let endDate = dayjs(queryData.endDate).format('YYYY-MM-DD')

    initialDate = String(initialDate) === 'Invalid Date' ? null : initialDate
    endDate = String(endDate) === 'Invalid Date' ? null : endDate
    handleClickSearch({
      ...queryData,
      reading: queryData.census,
      initialDate,
      endDate,
    })
  }
  return (
    <Grid
      container
      justify="center"
      spacing={2}
      className={classes.gridContainer}
    >
      <Grid item xs={12} md={3}>
        <Paper component="form" className={classes.root}>
          <Typography variant="subtitle1">
            {queryData.plot !== 0
              ? queryData.plot.reduce((acc, item) => {
                return (acc += acc !== '' ? ',' + item.name : item.name)
              }, '')
              : `${t('general.select')} ${t('general.plot')}`}
          </Typography>
          <IconButton
            type="button"
            aria-label="search"
            className={classes.iconButton}
            onClick={() =>
              plotsSelectorRef.current !== null &&
              plotsSelectorRef.current.setOpen(true)
            }
          >
            <SearchIcon />
          </IconButton>
        </Paper>
        <PlotsSelector accept={handleSelectPlot} ref={plotsSelectorRef} />
      </Grid>

      {reportPest && (
        <Grid item xs={12} md={2}>
          <Select
            text={t('general.pest')}
            width={'100%'}
            items={pests}
            defaultOption0={true}
            value={queryData.pest}
            handleChange={handleChangePest}
          />
        </Grid>
      )}
      <Grid item xs={12} md={widthSelect}>
        <Select
          text={t('general.year')}
          width={'100%'}
          items={years}
          defaultOption0={true}
          value={queryData.year}
          handleChange={handleChangeYear}
        />
      </Grid>
      <Grid item xs={12} md={widthSelect}>
        <Select
          text={getTitleSelectCensus(titleCensus)}
          width={'100%'}
          items={getDataSelectCensus(titleCensus)}
          defaultOption0={true}
          value={queryData.census}
          handleChange={handleChangeCensus}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <DatePickerRange
          initialDate={queryData.initialDate}
          endDate={queryData.endDate}
          handleDateChange={handleDateRangeChange}
        />
      </Grid>

      <Button
        icon={<SearchIcon />}
        disabled={validateButtonSearch()}
        handleButtonClick={_handleClickSearch}
        text={t('general.search')}
      />
    </Grid>
  )
}
