import { Grid, Typography } from '@material-ui/core'

export default function SubTitle({ text }) {
  return (
    <Grid container style={{ marginBottom: 10, justifyContent: 'center' }}>
       <Typography variant="h6" color={'primary'} align="center">
        {text}
      </Typography>
    </Grid>
  )
}
