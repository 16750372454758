import { Grid, Typography } from '@material-ui/core'

export default function TitlePage({ text }) {
  return (
    <Grid container style={{ marginBottom: 30, justifyContent: 'center' }}>
      <Typography variant="h4" color={'primary'}>
        {text}
      </Typography>
    </Grid>
  )
}
