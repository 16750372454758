import { useState, useRef, useEffect } from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import esLocale from '@fullcalendar/core/locales/es'
import { useTranslation } from 'react-i18next'
//import EventCalendar from './EventCalendar'

const Calendar = ({
  events,
  handleClickEvent = () => {},
  handleClickDay = () => {},
  getRef = null,
  handleChangeDates = () => {},
}) => {
  const { i18n } = useTranslation()
  const calendarRef = useRef(null)
  const [, setEventsFullCalendar] = useState([])
  const handleEvents = (events) => {
    setEventsFullCalendar(events)
  }

  useEffect(() => {
    if (events.length > 0) {
      const calendarApi = calendarRef.current.getApi()
      if (calendarApi !== null) {
        if (getRef !== null) getRef(calendarApi)

        calendarApi.gotoDate(events[0].start)
      }
    }
  }, [events, getRef])

  const handleClickEventLocal = (evento) => {
    handleClickEvent(evento)
  }

  const handleClickDayLocal = (selectInfo) => {
    handleClickDay(selectInfo)
  }

  return (
    <FullCalendar
      //timeZone="UTC"
      displayEventTime={false}
      ref={calendarRef}
      locale={i18n.language === 'es' ? esLocale : null}
      plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin]}
      initialView="dayGridMonth"
      events={events}
      headerToolbar={{
        right: 'prevYear,nextYear,today,prev,next',
        center: 'title',
        left: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek',
      }}
      selectable={true}
      eventClick={handleClickEventLocal}
      select={handleClickDayLocal}
      eventsSet={handleEvents}
      datesSet={(data) => handleChangeDates(data)}
      //eventContent={(eventInfo) => <EventCalendar eventInfo={eventInfo}  />}
    />
  )
}

export default Calendar
