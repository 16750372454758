import create from "zustand";
import { devtools } from "zustand/middleware";
import { URL_SERVER, paxios } from "../constants/http";
import { localStorageSelect, localStorageCreateUpdate } from "../localStorage";

const getFertilizersTypesServer = async (set) => {
  try {
    const types = await paxios.get(`${URL_SERVER}/fertilizersTypes`);
    localStorageCreateUpdate("fertilizersTypes", types.data.data);
    set({ fertilizersTypes: types.data.data });
  } catch (error) {
    set({ error });
  }
};

const getFertilizersServer = async (set) => {
  try {
    const fertilizers = await paxios.get(`${URL_SERVER}/fertilizers`);
    localStorageCreateUpdate("fertilizers", fertilizers.data.data);
    set({ fertilizers: fertilizers.data.data });
  } catch (error) {
    set({ error });
  }
};

const store = (set) => ({
  fertilizersTypes: [],
  fertilizers: [],
  getFertilizersTypes: async () => {
    await getFertilizersTypesServer(set);
  },
  getFertilizers: async () => {
    await getFertilizersServer(set);
  },
  loadLocalStorageNutrition: async () => {
    const fertilizersTypesLocal = localStorageSelect("fertilizersTypes");
    fertilizersTypesLocal === null
      ? await getFertilizersTypesServer(set)
      : set({ fertilizersTypes: fertilizersTypesLocal });

    const fertilizersLocal = localStorageSelect("fertilizers");
    fertilizersLocal === null
      ? await getFertilizersServer(set)
      : set({ fertilizers: fertilizersLocal });
  },
});

const nutritionStore = create(devtools(store, 'nutrition'))

export default nutritionStore
