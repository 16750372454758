const localization = {
  body: {
    emptyDataSourceMessage: 'No hay registros que mostra',
    editRow: {
      deleteText: 'Esta seguro de eliminar el registro',
    },
  },
  toolbar: {
    searchTooltip: 'Buscar',
    searchPlaceholder: 'Buscar',
  },
  header: {
    actions: 'Acciones',
  },
  pagination: {
    labelRowsSelect: 'Registros',
    labelDisplayedRows: '{count} de {from}-{to}',
    firstTooltip: 'Primera pagina',
    previousTooltip: 'Pagina anterior',
    nextTooltip: 'Próxima pagina',
    lastTooltip: 'Última pagina',
  },
}

export default localization
