import { useState, useEffect, useRef, useCallback } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Paper,
  Typography,
  makeStyles,
  IconButton,
  MenuItem,
  Select,
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import { Alert } from '@material-ui/lab'
import MaterialTable from 'material-table'
import dayjs from 'dayjs'
import Swal from 'sweetalert2/src/sweetalert2.js'
import { v4 as uuidv4 } from 'uuid'
import { useTranslation } from 'react-i18next'
import {
  laborsStore,
  userStore,
  cadastreStore,
  severalStore,
} from '../../../stores'
import { URL_SERVER, paxios, getQuantityPalmsBetweenLines } from '../../../constants/http'
import {
  Button,
  PlotsSelector,
  FormInput,
  Select as SelectCustom,
  DeleteConfirmation,
  DatePicker,
  SubTitle,
} from '../../../components'
import tableIcons from '../../../constants/IconsMaterialTable'
import tableTranslations from '../../../constants/tableTranslations'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  gridContainer: {
    marginBottom: 10,
  },
  selectPlot: {
    margin: 'auto 0',
  },
  dialogContent: {
    /* display: 'flex',
      flexDirection: 'row', */
  },
  executionsButtonWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
}))

const executionInitial = {
  id: null,
  schedule: null,
  date: new Date(),
  cost: 0,
  supervisor: 0,
  time: 0,
  observations: '',
  workers: [],
  problemQuantity: false,
  plot: [],
  labor: 0,
  cost_center: 0,
  quantity: 0,
}

const CreateExecutionWithoutSchedule = ({
  event,
  dateNew,
  searchRef,
  search,
  setEventSelectedCalendar,
  open,
  setOpen,
}) => {
  const { t, i18n } = useTranslation()
  const plotsSelectorRef = useRef(null)
  const classes = useStyles()
  const { currentPlantation, user } = userStore()
  const { labors, costCenters } = laborsStore()
  const { workers, quadrilles } = severalStore()
  const { plots } = cadastreStore()
  const [execution, setExecution] = useState({
    ...executionInitial,
  })
  const [columns, setColumns] = useState([])
  const [palmsPlot, setPalmsPlot] = useState(0)
  const [openDelete, setOpenDelete] = useState(false)

  const editComponentWorkers = useCallback(
    (props) => {
      const data = { ...props.rowData }
      return (
        <Select
          value={props.value !== undefined ? props.value : 0}
          onChange={(e) => {
            data.worker = parseInt(e.target.value)
            props.onRowDataChange(data)
          }}
          disabled={String(data.quadrille) !== '0'}
        >
          <MenuItem key={0} value={0}>
            {t('general.notassigned')}
          </MenuItem>
          {workers.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      )
    },
    [t, workers]
  )

  const editComponentQuadrille = useCallback(
    (props) => {
      const data = { ...props.rowData }
      return (
        <Select
          value={props.value !== undefined ? props.value : 0}
          onChange={(e) => {
            data.quadrille = parseInt(e.target.value)
            props.onRowDataChange(data)
          }}
          disabled={String(data.worker) !== '0'}
        >
          <MenuItem key={0} value={0}>
            {t('general.notassigned')}
          </MenuItem>
          {quadrilles.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      )
    },
    [t, quadrilles]
  )

  const handleChangeEndLine = useCallback(
    async (props, data) => {
      if (execution.plot.length <= 0) return
      const palms = await getQuantityPalmsBetweenLines(
        currentPlantation.plantation,
        execution.plot[0].id,
        data.start_line,
        data.end_line
      )
      data.quantity = palms
      props.onRowDataChange(data)
    },
    [execution.plot, currentPlantation.plantation]
  )

  const editComponentStartLine = useCallback(
    (props) => {
      const data = { ...props.rowData }
      return (
        <div className="MuiFormControl-root MuiTextField-root MuiFormControl-fullWidth">
          <div className="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-fullWidth MuiInput-fullWidth MuiInputBase-formControl MuiInput-formControl">
            <input
              type="number"
              className="MuiInputBase-input MuiInput-input"
              min="0"
              value={props.value || 0}
              disabled={execution.plot.length === 0 ? 'disabled' : ''}
              onChange={(e) => {
                data.start_line = parseInt(e.target.value) || 0
                props.onRowDataChange(data)
                if (data.end_line > 0)
                  handleChangeEndLine(props, {
                    ...data,
                    plantation: currentPlantation.plantation,
                  })
              }}
            />
          </div>
        </div>
      )
    },
    [currentPlantation.plantation, execution.plot.length, handleChangeEndLine]
  )

  const editComponentEndLine = useCallback(
    (props) => {
      const data = { ...props.rowData }
      return (
        <div className="MuiFormControl-root MuiTextField-root MuiFormControl-fullWidth">
          <div className="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-fullWidth MuiInput-fullWidth MuiInputBase-formControl MuiInput-formControl">
            <input
              type="number"
              className="MuiInputBase-input MuiInput-input"
              min="0"
              value={props.value || 0}
              disabled={execution.plot.length === 0 ? 'disabled' : ''}
              onChange={(e) => {
                data.end_line = parseInt(e.target.value) || 0
                props.onRowDataChange(data)
                handleChangeEndLine(props, {
                  ...data,
                  plantation: currentPlantation.plantation,
                })
              }}
            />
          </div>
        </div>
      )
    },
    [currentPlantation.plantation, execution.plot.length, handleChangeEndLine]
  )

  const editComponentQuantity = useCallback(
    (props) => {
      const data = { ...props.rowData }
      return (
        <div className="MuiFormControl-root MuiTextField-root MuiFormControl-fullWidth">
          <div className="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-fullWidth MuiInput-fullWidth MuiInputBase-formControl MuiInput-formControl">
            <input
              type="number"
              className="MuiInputBase-input MuiInput-input"
              min="0"
              value={props.value || 0}
              disabled={data.end_line !== 0 ? 'disabled' : ''}
              onChange={(e) => {
                data.quantity = parseInt(e.target.value) || 0
                props.onRowDataChange(data)
              }}
            />
          </div>
          {data.quantity === 0 && (
            <p className="MuiFormHelperText-root Mui-error MuiFormHelperText-filled">
              {t('general.nozero')}
            </p>
          )}
        </div>
      )
    },
    [t]
  )

  useEffect(() => {
    const validateNoZero = (rowData, field) => {
      return rowData[field] <= 0 || rowData[field] === '0'
        ? t('general.nozero')
        : true
    }

    const validateEmpty = (rowData, field) => {
      return rowData[field] === '' ||
        rowData[field] === undefined ||
        rowData[field] === null ||
        isNaN(rowData[field])
        ? t('general.noempty')
        : true
    }
    const validateNoZeroEmpty = (rowData, field) => {
      if (
        validateNoZero(rowData, field) === true &&
        validateEmpty(rowData, field) === true
      ) {
        return true
      } else {
        return `${
          validateNoZero(rowData, field) === true
            ? ''
            : validateNoZero(rowData, field)
        } ${
          validateEmpty(rowData, field) === true
            ? ''
            : ', ' + validateEmpty(rowData, field)
        }`
      }
    }
    setColumns([
      {
        title: t('general.worker'),
        field: 'worker',
        lookup: {
          0: t('general.notassigned'),
          ...workers.reduce((acc, item) => {
            acc[item.id] = item.name
            return acc
          }, {}),
        },
        initialEditValue: 0,
        validate: (rowData) =>
          String(rowData.worker) === '0' && String(rowData.quadrille) === '0'
            ? t('general.nozero')
            : true,
        editComponent: editComponentWorkers,
      },
      {
        title: t('general.quadrille'),
        field: 'quadrille',
        lookup: {
          0: t('general.notassigned'),
          ...quadrilles.reduce((acc, item) => {
            acc[item.id] = item.name
            return acc
          }, {}),
        },
        initialEditValue: 0,
        validate: (rowData) =>
          String(rowData.worker) === '0' && String(rowData.quadrille) === '0'
            ? t('general.nozero')
            : true,
        editComponent: editComponentQuadrille,
      },
      {
        title: t('general.quantity'),
        field: 'quantity',
        type: 'numeric',
        initialEditValue: 0,
        validate: (rowData) => validateNoZeroEmpty(rowData, 'quantity'),
        editComponent: editComponentQuantity
      },
      {
        title: t('general.start_line'),
        field: 'start_line',
        type: 'numeric',
        initialEditValue: 0,
        editComponent: editComponentStartLine
      },
      {
        title: t('general.end_line'),
        field: 'end_line',
        type: 'numeric',
        initialEditValue: 0,
        editComponent: editComponentEndLine
      },
    ])
  }, [workers, quadrilles, t, editComponentWorkers, editComponentQuadrille, editComponentStartLine, editComponentEndLine, editComponentQuantity])

  useEffect(() => {
    if (event) {
      setExecution(
        event.publicId
          ? {
              ...event,
              id: event.publicId,
              ...event.extendedProps,
              plot: event.extendedProps.plot
                ? plots.filter((x) => x.id === event.extendedProps.plot)
                : [],
              date: new Date(event.extendedProps.start_date + '  00:00:00'),
              workers: event.extendedProps.execution_workers,
            }
          : { ...executionInitial }
      )
      //setPalmsPlot(event.extendedProps.quantity)
    }
  }, [event, plots])

  useEffect(() => {
    if (
      String(execution.labor) !== '0' &&
      (String(execution.cost) === '' || String(execution.cost) === '0')
    ) {
      setExecution((d) => ({
        ...d,
        cost: labors.find((x) => x.id === d.labor)?.cost,
      }))
    }
  }, [execution.labor, execution.cost, labors])

  useEffect(() => {
    if (dateNew !== '') {
      setExecution((d) => ({
        ...d,
        date: dateNew !== '' ? new Date(dateNew + '  00:00:00') : new Date(),
      }))
    }
  }, [dateNew])

  useEffect(() => {
    ;(async () => {
      const labor = labors.find((x) => x.id === execution.labor)
      if (labor !== undefined && execution.plot.length > 0) {
        if (labor.origin_quantity === 1) {
          const response = await paxios.get(
            `${URL_SERVER}/palms/getQuantityActivePalmsPlot/${execution.plot[0].id}`
          )
          if (response.data.status === 'ok') {
            /* if (
              String(execution.quantity) === '0' ||
              String(execution.quantity) === ''
            ) {
              setExecution((d) => ({ ...d, quantity: response.data.data }))
            } */
            setPalmsPlot(response.data.data)
          } else if (response.data.status === 'error') {
            /* setExecution((d) => ({ ...d, quantity: 0 })) */
            setPalmsPlot(0)
          }
        } else {
          palmsPlot !== 0 && setPalmsPlot(0)
        }
      }

      if (execution.plot.length <= 0){
        palmsPlot !== 0 && setPalmsPlot(0)
      }
    })()
  }, [execution.labor, execution.plot, labors, palmsPlot, execution.quantity])

  useEffect(() => {
    const quantityRegister = execution.workers.reduce((acc, item) => {
      if (typeof item.id === 'string') {
        return acc + item.quantity
      } else {
        if (item.edit) {
          return acc + item.diff
        }
      }
      return acc
    }, 0)

    const labor = labors.find((x) => x.id === execution.labor)
    if (quantityRegister > palmsPlot && labor.requires_plot === 1) {
      setExecution((d) => ({ ...d, problemQuantity: true }))
    } else {
      setExecution((d) => ({ ...d, problemQuantity: false }))
    }
  }, [execution.workers, palmsPlot, execution.labor, labors])

  const handleSelectPlot = (plot) => {
    setExecution((d) => ({ ...d, plot: plot.length > 0 ? plot : [] }))
    plotsSelectorRef.current !== null && plotsSelectorRef.current.setOpen(false)
  }

  const handleOnChange = (e) => {
    let { name, value } = e.target
    setExecution((d) => ({ ...d, [name]: value }))
  }

  const validateButtonSaveActive = () => {
    if (execution.labor === '0' || execution.labor === 0) return true
    //if (execution.plot.length <= 0) return true
    if (execution.supervisor === '0' || execution.supervisor === 0) return true
    if (execution.date === null || execution.date === '') return true
    if (execution.time === '' || String(execution.time) === '0') return true

    if (execution.cost === '' || String(execution.cost) === '0') return true

    if (execution.workers.length <= 0) return true

    return false
  }

  const onRowAdd = (newData) =>
    new Promise(async (resolve) => {
      setExecution((d) => ({
        ...d,
        workers: [
          ...d.workers,
          { id: uuidv4(), ...newData, edit: false, diff: 0 },
        ],
      }))
      resolve()
    })

  const calculateDiffQuantityEdit = (newData, oldData) => {
    if (parseFloat(newData.quantity) > parseFloat(oldData.quantity))
      return parseFloat(newData.quantity) - parseFloat(oldData.quantity)

    if (parseFloat(oldData.quantity) > parseFloat(newData.quantity)) return 0

    return 0
  }

  const onRowUpdate = (newData, oldData) =>
    new Promise(async (resolve) => {
      setExecution((d) => ({
        ...d,
        workers: [
          ...d.workers.map((x) =>
            String(x.id) === String(newData.id)
              ? {
                  ...newData,
                  edit:
                    calculateDiffQuantityEdit(newData, oldData) === 0
                      ? false
                      : true,
                  diff: calculateDiffQuantityEdit(newData, oldData),
                }
              : { ...x }
          ),
        ],
      }))
      resolve()
    })

  const prepareData = () => {
    const send = {
      ...execution,
      plot: execution.plot.length > 0 ? execution.plot[0].id : null,
      date:
        execution.date !== null
          ? dayjs(execution.date).format('YYYY-MM-DD')
          : null,
      workers: execution.workers.map((x) => ({
        ...x,
        id: typeof x.id === 'string' ? null : x.id,
      })),
      plantation: currentPlantation.plantation,
      observations: '',
    }

    return send
  }

  const saveData = () => {
    const send = prepareData()
    if (send.id) {
      const sendEdit = {
        ...send,
        workers: send.workers.map((y) => ({
          ...y,
          execution: send.id,
        })),
      }
      updateExecution(sendEdit)
    } else {
      createExecution(send)
    }
  }

  const createExecution = async (send) => {
    try {
      const response = await paxios.post(
        `${URL_SERVER}/laborsExecutionWithoutSchedule`,
        send
      )
      if (response.data.status === 'ok') {
        Swal.fire(t('general.finished'), t('general.savesuccess'), 'success')
        search(searchRef.current.getData())
        setExecution({
          labor: 0,
          plot: [],
          cost_center: 0,
          supervisor: 0,
          time: 0,
          observations: '',
          workers: [],
          problemQuantity: false,
        })
        setOpen(false)
      } else {
        Swal.fire(t('general.finished'), t('general.datawasnotsaved'), 'error')
      }
    } catch (error) {
      Swal.fire(t('general.finished'), t('general.datawasnotsaved'), 'error')
    }
  }

  const updateExecution = async (send) => {
    try {
      const response = await paxios.put(
        `${URL_SERVER}/laborsExecutionWithoutSchedule/${send.id}`,
        send
      )
      if (response.data.status === 'ok') {
        Swal.fire(t('general.finished'), t('general.savesuccess'), 'success')
        search(searchRef.current.getData())
      } else {
        Swal.fire(t('general.finished'), t('general.datawasnotsaved'), 'error')
      }
    } catch (error) {
      Swal.fire(t('general.finished'), t('general.datawasnotsaved'), 'error')
    }
  }

  const handleClickSave = () => {
    saveData()
  }

  const handleClickDelete = () => {
    setOpenDelete(true)
  }

  const deleteExecution = async (execution) => {
    try {
      const response = await paxios.delete(
        `${URL_SERVER}/laborsExecutionWithoutSchedule/${execution.id}`
      )
      if (response.data.status === 'ok') {
        Swal.fire(t('general.finished'), t('general.savesuccess'), 'success')
        search(searchRef.current.getData())
        setOpenDelete(false)
        setEventSelectedCalendar(null)
        setExecution({ ...executionInitial })
        setOpen(false)
      } else {
        Swal.fire(t('general.finished'), t('general.datawasnotsaved'), 'error')
      }
    } catch (error) {
      Swal.fire(t('general.finished'), t('general.datawasnotsaved'), 'error')
    }
  }
  return (
    <>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="md"
        fullWidth
        aria-labelledby="confirmation-dialog-title"
        open={open}
      >
        <DialogTitle id="confirmation-dialog-title">
          {t('general.register_without_schedule')}
        </DialogTitle>

        <DialogContent dividers>
          <Grid container justify="center" spacing={2}>
            <Grid item xs={12} md={6} className={classes.selectPlot}>
              <Paper component="form" className={classes.root}>
                <Typography variant="subtitle1">
                  {execution.plot !== 0
                    ? execution.plot.reduce((acc, item) => {
                        return (acc += acc !== '' ? ',' + item.name : item.name)
                      }, '')
                    : `${t('general.select')} ${t('general.plot')}`}
                </Typography>
                <IconButton
                  type="button"
                  aria-label="search"
                  className={classes.iconButton}
                  onClick={() =>
                    plotsSelectorRef.current !== null &&
                    plotsSelectorRef.current.setOpen(true)
                  }
                >
                  <SearchIcon />
                </IconButton>
              </Paper>
              <PlotsSelector
                accept={handleSelectPlot}
                ref={plotsSelectorRef}
                single={true}
              />
              {palmsPlot !== 0 && (
                <Alert severity="success">
                  {`${t('general.palms')} ${t('general.plot')}: ${palmsPlot}`}
                </Alert>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <SelectCustom
                name="cost_center"
                text={t('general.cost_centers')}
                width={'100%'}
                items={costCenters}
                defaultOption0={true}
                value={execution.cost_center}
                handleChange={handleOnChange}
                required={true}
                error={
                  execution.cost_center === '0' || execution.cost_center === 0
                }
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <SelectCustom
                name="labor"
                text={t('general.labor')}
                width={'100%'}
                items={labors}
                defaultOption0={true}
                value={execution.labor}
                handleChange={handleOnChange}
                required={true}
                error={execution.labor === '0' || execution.labor === 0}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <SelectCustom
                name="supervisor"
                text={t('general.supervisor')}
                width={'100%'}
                items={workers.filter((x) => x.supervisor === 1)}
                defaultOption0={true}
                value={execution.supervisor}
                handleChange={handleOnChange}
                required={true}
                error={
                  execution.supervisor === '0' || execution.supervisor === 0
                }
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <DatePicker
                label={`${t('general.date')}`}
                selectedDate={execution.date}
                handleDateChange={handleOnChange}
                name="date"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormInput
                name={'cost'}
                title={t('general.cost')}
                value={execution.cost}
                onChange={handleOnChange}
                type="number"
                error={execution.cost === '' || String(execution.cost) === '0'}
                errorMessage={`${t('general.value_invalid')}`}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormInput
                name={'time'}
                title={t('general.time')}
                value={execution.time}
                onChange={handleOnChange}
                type="number"
                error={execution.time === '' || String(execution.time) === '0'}
                errorMessage={`${t('general.value_invalid')}`}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <FormInput
                name={'observations'}
                title={t('general.observations')}
                value={execution.observations}
                onChange={handleOnChange}
                multiline={true}
              />
            </Grid>
          </Grid>

          <Grid container justify="center" spacing={2}>
            <SubTitle text={t('general.workers')} />
            {execution.problemQuantity && (
              <Typography variant="h6" color={'error'} align="center">
                {t('general.error_register_more_quantity')}
              </Typography>
            )}
            <Grid item xs={12} md={12}>
              <MaterialTable
                icons={tableIcons}
                columns={columns}
                title={''}
                data={execution.workers}
                options={{
                  exportButton: false,
                  exportAllData: false,
                  exportDelimiter: ';',
                  selection: false,
                }}
                localization={i18n.language === 'es' ? tableTranslations : {}}
                editable={{
                  onRowUpdate: onRowUpdate,
                  onRowAdd: onRowAdd,
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button
            handleButtonClick={() => {
              setExecution({ ...executionInitial })
              setEventSelectedCalendar(null)
              setOpen(false)
            }}
            text={t('general.close')}
          />
          {user.profile === 1 && execution.id && (
            <Button
              handleButtonClick={handleClickDelete}
              text={`${t('general.delete')}`}
              color={'secondary'}
            />
          )}
          <Button
            handleButtonClick={handleClickSave}
            text={t('general.save')}
            disabled={validateButtonSaveActive() || execution.problemQuantity}
          />
        </DialogActions>
      </Dialog>

      {openDelete && (
        <DeleteConfirmation
          open={openDelete}
          setOpen={setOpenDelete}
          item={execution}
          handleOk={deleteExecution}
        />
      )}
    </>
  )
}

export default CreateExecutionWithoutSchedule
