import create from 'zustand'
import { devtools } from 'zustand/middleware'
import { URL_SERVER, paxios } from '../constants/http'
import userStore from './userStore'

import { localStorageSelect, localStorageCreateUpdate } from '../localStorage'

const getPlantationsServer = async (set) => {
  try {
    const plantations = await paxios.get(`${URL_SERVER}/plantations`)
    localStorageCreateUpdate('plantations', plantations.data.data)
    const currentPlantation = plantations.data.data.filter(
      (x) => String(x.id) === userStore.getState().currentPlantation.plantation
    )
    currentPlantation.length > 0 &&
      userStore.getState().setUser({
        currentPlantation: {
          plantation: String(currentPlantation[0].id),
          name: currentPlantation[0].name,
        },
      })
    set({ plantations: plantations.data.data })
  } catch (error) {
    set({ error })
  }
}

const getSectorsServer = async (set, plantation) => {
  try {
    const sectors = await paxios.get(
      `${URL_SERVER}/sectors/byPlantation/${plantation}`
    )
    localStorageCreateUpdate('sectors', sectors.data.data)
    set({ sectors: sectors.data.data })
  } catch (error) {
    set({ error })
  }
}

const getPlotsServer = async (set, plantation) => {
  try {
    const plots = await paxios.get(
      `${URL_SERVER}/plots/byPlantation/${plantation}`
    )
    localStorageCreateUpdate('plots', plots.data.data)
    set({ plots: plots.data.data })
  } catch (error) {
    set({ error })
  }
}

const getMaterialsServer = async (set) => {
  try {
    const materials = await paxios.get(`${URL_SERVER}/materials`)
    localStorageCreateUpdate('materials', materials.data.data)
    set({ materials: materials.data.data })
  } catch (error) {
    set({ error })
  }
}

const getReasonsEradicationServer = async (set) => {
  try {
    const reasons = await paxios.get(`${URL_SERVER}/palms/getReasonsEradication`)
    localStorageCreateUpdate('reasonsEradication', reasons.data.data)
    set({ reasonsEradication: reasons.data.data })
  } catch (error) {
    set({ error })
  }
}

const store = (set) => ({
  plantations: [],
  sectors: [],
  plots: [],
  materials: [],
  reasonsEradication: [],
  error: null,
  getPlantations: async () => {
    await getPlantationsServer(set)
  },
  getSectors: async () => {
    const currentPlantation = userStore.getState().currentPlantation
    await getSectorsServer(set, currentPlantation.plantation)
  },
  getPlots: async () => {
    const currentPlantation = userStore.getState().currentPlantation
    await getPlotsServer(set, currentPlantation.plantation)
  },
  getMaterials: async () => {
    await getMaterialsServer(set)
  },
  getReasonsEradication: async () => {
      await getReasonsEradicationServer()
  },
  loadLocalStorageCadastre: async () => {
    const plantationsLocal = localStorageSelect('plantations')
    plantationsLocal === null
      ? await getPlantationsServer(set)
      : set({ plantations: plantationsLocal })

    const sectorsLocal = localStorageSelect('sectors')
    if (sectorsLocal === null) {
      const currentPlantation = userStore.getState().currentPlantation
      await getSectorsServer(set, currentPlantation.plantation)
    } else {
      set({ sectors: sectorsLocal })
    }

    const plotsLocal = localStorageSelect('plots')
    if (plotsLocal === null) {
      const currentPlantation = userStore.getState().currentPlantation
      await getPlotsServer(set, currentPlantation.plantation)
    } else {
      set({ plots: plotsLocal })
    }

    const materialsLocal = localStorageSelect('materials')
    if (materialsLocal === null) {
      await getMaterialsServer(set)
    } else {
      set({ materials: materialsLocal })
    }

    const reasonsEradicationLocal = localStorageSelect('reasonsEradication')
    if (reasonsEradicationLocal === null) {
      await getReasonsEradicationServer(set)
    } else {
      set({ reasonsEradication: reasonsEradicationLocal })
    }
  },
})

const cadastreStore = create(devtools(store, 'cadastre'))

export default cadastreStore
