import create from 'zustand'
import { devtools } from 'zustand/middleware'
import { URL_SERVER, paxios } from '../constants/http'
import userStore from './userStore'

const getColorsMapsServer = async (set, plantation) => {
  try {
    const colors = await paxios.get(`${URL_SERVER}/colorsMaps/${plantation}`)
    set({ colors: colors.data.data })
  } catch (error) {
    set({ error })
  }
}

const store = (set) => ({
  colors: {},
  error: null,
  getColorsMaps: async () => {
    const currentPlantation = userStore.getState().currentPlantation
    await getColorsMapsServer(set, currentPlantation.plantation)
  },
})

const mapsStore = create(devtools(store, 'maps'))

export default mapsStore
