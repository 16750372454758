import { useState, useRef, useEffect } from 'react'
import {
  Grid,
  Typography,
  makeStyles,
  Paper,
  IconButton,
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import MaterialTable from 'material-table'
import { useTranslation } from 'react-i18next'
import { userStore } from '../../../stores'
import { URL_SERVER, paxios } from '../../../constants/http'
import tableIcons from '../../../constants/IconsMaterialTable'
import tableTranslations from '../../../constants/tableTranslations'
import { Button, PlotsSelector, Select, SubTitle } from '../../../components'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  gridContainer: {
    marginBottom: 10,
  },
}))

const getYears = () => {
  let years = []

  for (let i = 2000; i <= 2050; i++) {
    years.push({ id: i, name: i })
  }

  return years
}

export default function ProductionYear() {
  const classes = useStyles()
  const { currentPlantation } = userStore()
  const plotsSelectorRef = useRef(null)
  const { t, i18n } = useTranslation()
  const [records, setRecords] = useState([])
  const [columns, setColumns] = useState([])
  const [queryData, setQueryData] = useState({
    plot: 0,
    year: '0',
  })

  useEffect(() => {
    setColumns([
      { title: t('general.plot'), field: 'plot_name' },
      { title: t('general.area_net'), field: 'area_net' },
      { title: t('general.january'), field: 'January' },
      { title: t('general.february'), field: 'February' },
      { title: t('general.march'), field: 'March' },
      { title: t('general.april'), field: 'April' },
      { title: t('general.may'), field: 'May' },
      { title: t('general.june'), field: 'June' },
      { title: t('general.july'), field: 'July' },
      { title: t('general.august'), field: 'August' },
      { title: t('general.september'), field: 'September' },
      { title: t('general.october'), field: 'October' },
      { title: t('general.november'), field: 'November' },
      { title: t('general.december'), field: 'December' },
      { title: "Total", field: 'total' },
    ])
  }, [setColumns, t])

  const handleSelectPlot = (plot) => {
    setQueryData((d) => ({ ...d, plot: plot.length > 0 ? plot : 0 }))
    plotsSelectorRef.current !== null && plotsSelectorRef.current.setOpen(false)
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setQueryData((d) => ({ ...d, [name]: value }))
  }

  const handleClickSearch = () => {
    search()
  }

  const search = async () => {
    try {
      const send = {
        ...queryData,
        plot: queryData.plot.length > 0 ? queryData.plot.map((x) => x.id) : 0,
        plantation: currentPlantation.plantation,
      }
      const response = await paxios.post(
        `${URL_SERVER}/reportsYield/getProductionYear`,
        send
      )

      if (response.data.status === 'ok') {
        setRecords(response.data.data)
      }
    } catch (error) {
      //console.log(error)
    }
  }

  return (
    <>
      <SubTitle text={t('general.productionyear')} />

      <Grid
        container
        justify="center"
        spacing={2}
        className={classes.gridContainer}
      >
        <Grid item xs={12} md={4}>
          <Paper component="form" className={classes.root}>
            <Typography variant="subtitle1">
              {queryData.plot !== 0
                ? queryData.plot.reduce((acc, item) => {
                    return (acc += acc !== '' ? ',' + item.name : item.name)
                  }, '')
                : `${t('general.select')} ${t('general.plot')}`}
            </Typography>
            <IconButton
              type="button"
              aria-label="search"
              className={classes.iconButton}
              onClick={() =>
                plotsSelectorRef.current !== null &&
                plotsSelectorRef.current.setOpen(true)
              }
            >
              <SearchIcon />
            </IconButton>
          </Paper>
          <PlotsSelector accept={handleSelectPlot} ref={plotsSelectorRef} />
        </Grid>

        <Grid item xs={12} md={4}>
          <Select
            name="year"
            text={t('general.year')}
            width={'100%'}
            items={getYears()}
            defaultOption0={true}
            value={queryData.year}
            handleChange={handleChange}
            required={true}
            error={queryData.year === '0' || queryData.year === 0}
          />
        </Grid>
      </Grid>

      <Button
        icon={<SearchIcon />}
        handleButtonClick={handleClickSearch}
        text={t('general.search')}
        disabled={queryData.year === '0' || queryData.year === 0}
      />

      <MaterialTable
        icons={tableIcons}
        columns={columns}
        title={t('general.productionyear')}
        data={records}
        options={{
          exportButton: true,
          exportAllData: true,
          exportDelimiter: ';',
          selection: false,
        }}
        localization={i18n.language === 'es' ? tableTranslations : {}}
      />
    </>
  )
}
