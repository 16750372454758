import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Typography,
  makeStyles,
} from "@material-ui/core";
import dayjs from "dayjs";
import Swal from "sweetalert2/src/sweetalert2.js";
import { useTranslation } from "react-i18next";
import { userStore } from "../../../../stores";
import { URL_SERVER, paxios } from "../../../../constants/http";
import {
  Button,
  FormInput,
  DeleteConfirmation,
  DatePicker,
} from "../../../../components";
import ShowExecutions from "./ShowExecutions";
import CreateEditExecution from "./CreateEditExecution";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  gridContainer: {
    marginBottom: 10,
  },
  selectPlot: {
    margin: "auto 0",
  },
  dialogContent: {
    /* display: 'flex',
      flexDirection: 'row', */
  },
  executionsButtonWrapper: {
    display: "flex",
    justifyContent: "center",
  },
}));

const fractionInitial = {
  id: null,
  title: "",
  start_date: new Date(),
  end_date: new Date(),
  observations: "",
  actualization: "",
  plantation: 0,
  palms: 0,
  year: 0,
  completed: 0,
  dose: 0,
};

const CreateEditFraction = forwardRef(
  (
    { fraction, dateNew, plan, getFractions, setEventSelectedCalendar, listPlanRef },
    ref
  ) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { currentPlantation, user } = userStore();
    const [open, setOpen] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [openShow, setOpenShow] = useState(false);
    const [openRegister, setOpenRegister] = useState(false);
    const [fractionActive, setFractionActive] = useState({
      ...fractionInitial,
      start_date:
        dateNew !== "" ? new Date(dateNew + "  00:00:00") : new Date(),
      end_date: dateNew !== "" ? new Date(dateNew + "  00:00:00") : new Date(),
      plan: plan,
    });

    useEffect(() => {
      if (fraction) {
        setFractionActive(
          fraction.publicId
            ? {
                ...fraction,
                id: fraction.publicId,
                ...fraction.extendedProps,

                start_date: new Date(
                  fraction.extendedProps.start_date + "  00:00:00"
                ),
                end_date: new Date(
                  fraction.extendedProps.end_date + "  00:00:00"
                ),
              }
            : {
                ...fractionInitial,
                plan,
                start_date:
                  dateNew !== ""
                    ? new Date(dateNew + "  00:00:00")
                    : new Date(),
                end_date:
                  dateNew !== ""
                    ? new Date(dateNew + "  00:00:00")
                    : new Date(),
              }
        );
      } else {
        setFractionActive({
          ...fractionInitial,
          plan,
          start_date:
            dateNew !== "" ? new Date(dateNew + "  00:00:00") : new Date(),
          end_date:
            dateNew !== "" ? new Date(dateNew + "  00:00:00") : new Date(),
        });
      }
    }, [fraction, plan, dateNew]);

    useImperativeHandle(ref, () => ({
      setOpen,
    }));

    const handleOnChange = (e) => {
      let { name, value } = e.target;
      setFractionActive((d) => ({ ...d, [name]: value }));
    };

    const prepareData = () => {
      const send = {
        id: fractionActive.id,
        plan: fractionActive.plan,
        date:
          fractionActive.start_date !== null
            ? dayjs(fractionActive.start_date).format("YYYY-MM-DD")
            : null,
        dose: fractionActive.dose,
        observations: fractionActive.observations,
        plantation: currentPlantation.plantation,
      };

      return send;
    };

    const createFraction = async (send) => {
      try {
        const response = await paxios.post(
          `${URL_SERVER}/fertilizationPlanFractions`,
          send
        );
        if (response.data.status === "ok") {
          Swal.fire(t("general.finished"), t("general.savesuccess"), "success");
          listPlanRef.current.getPlans(listPlanRef.current.getData())
          getFractions(send.plan);
          setFractionActive({
            ...fractionActive,
            plan,
            start_date:
              dateNew !== "" ? new Date(dateNew + "  00:00:00") : new Date(),
            end_date:
              dateNew !== "" ? new Date(dateNew + "  00:00:00") : new Date(),
          });
          setOpen(false);
        } else {
          Swal.fire(
            t("general.finished"),
            t("general.datawasnotsaved"),
            "error"
          );
        }
      } catch (error) {
        Swal.fire(t("general.finished"), t("general.datawasnotsaved"), "error");
      }
    };

    const updateFraction = async (send) => {
      try {
        const response = await paxios.put(
          `${URL_SERVER}/fertilizationPlanFractions/${send.id}`,
          send
        );
        if (response.data.status === "ok") {
          Swal.fire(t("general.finished"), t("general.savesuccess"), "success");
          listPlanRef.current.getPlans(listPlanRef.current.getData())
          getFractions(send.plan);
        } else {
          Swal.fire(
            t("general.finished"),
            t("general.datawasnotsaved"),
            "error"
          );
        }
      } catch (error) {
        Swal.fire(t("general.finished"), t("general.datawasnotsaved"), "error");
      }
    };

    const saveData = async () => {
      const send = prepareData();

      if (send.id) {
        updateFraction(send);
      } else {
        createFraction(send);
      }
    };

    const handleClickSave = () => {
      saveData();
    };

    const validateButtonSaveActive = () => {
      if (fractionActive.dose === "" || String(fractionActive.dose) === "0")
        return true;
      if (String(fractionActive.start_date) === "") return true;

      if (String(fractionActive.start_date) === "Invalid Date") return true;

      if (fractionActive.start_date === null) return true;

      return false;
    };

    const handleClickDelete = () => {
      setOpenDelete(true);
    };

    const deleteFraction = async (fraction) => {
      try {
        const response = await paxios.delete(
          `${URL_SERVER}/fertilizationPlanFractions/${fraction.id}`
        );
        if (response.data.status === "ok") {
          Swal.fire(t("general.finished"), t("general.savesuccess"), "success");
          listPlanRef.current.getPlans(listPlanRef.current.getData())
          getFractions(fraction.plan);
          setOpenDelete(false);
          setEventSelectedCalendar(null);
          setFractionActive({ ...fractionInitial, plan });
          setOpen(false);
        } else {
          Swal.fire(
            t("general.finished"),
            t("general.datawasnotsaved"),
            "error"
          );
        }
      } catch (error) {
        Swal.fire(t("general.finished"), t("general.datawasnotsaved"), "error");
      }
    };

    const handleClickShow = () => {
      setOpenShow(true);
    };

    const handleClickRegister = () => {
      setOpenRegister(true);
    };
    return (
      <>
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          maxWidth="md"
          fullWidth
          aria-labelledby="confirmation-dialog-title"
          open={open}
        >
          <DialogTitle id="confirmation-dialog-title">
            {fraction?.publicId
              ? `${t("general.edit")}: ${fractionActive.year} / ${
                  fractionActive.plot_name
                } / ${fractionActive.product_name}`
              : t("general.new")}
          </DialogTitle>
          <DialogContent dividers className={classes.dialogContent}>
            {fraction?.publicId && (
              <Typography variant="h6" color={"primary"} align="center">
                {`${t("general.programmed")}: ${fractionActive.palms} / ${t(
                  "general.completed"
                )}: ${fractionActive.completed} / ${t("general.remaining")}: ${
                  fractionActive.palms - fractionActive.completed
                }`}
              </Typography>
            )}
            <Grid
              container
              justify="center"
              spacing={2}
              className={classes.gridContainer}
            >
              <Grid item xs={12} md={6}>
                <DatePicker
                  label={t("general.date")}
                  name={"start_date"}
                  selectedDate={fractionActive.start_date}
                  handleDateChange={handleOnChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormInput
                  name={"dose"}
                  title={t("general.dose")}
                  value={fractionActive.dose}
                  onChange={handleOnChange}
                  type={"number"}
                  error={
                    fractionActive.dose === "" ||
                    String(fractionActive.dose) === "0"
                  }
                  errorMessage={`${t("general.value_invalid")}`}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <FormInput
                  name={"observations"}
                  title={t("general.observations")}
                  value={fractionActive.observations}
                  onChange={handleOnChange}
                  multiline={true}
                />
              </Grid>
              {fraction?.publicId && (
                <Grid
                  item
                  xs={12}
                  md={12}
                  className={classes.executionsButtonWrapper}
                >
                  <Button
                    handleButtonClick={handleClickShow}
                    text={`${t("general.show")} ${t("general.executions")}`}
                  />
                  <Button
                    handleButtonClick={handleClickRegister}
                    text={`${t("general.register")} ${t("general.execution")}`}
                  />
                  {user.profile === 1 && (
                    <Button
                      handleButtonClick={handleClickDelete}
                      text={`${t("general.delete")}`}
                      color={"secondary"}
                    />
                  )}
                </Grid>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              handleButtonClick={() => {
                setFractionActive({ ...fractionInitial });
                setOpen(false);
              }}
              text={t("general.close")}
            />
            <Button
              handleButtonClick={handleClickSave}
              text={t("general.save")}
              disabled={validateButtonSaveActive()}
            />
          </DialogActions>
        </Dialog>

        {openDelete && (
          <DeleteConfirmation
            open={openDelete}
            setOpen={setOpenDelete}
            item={fractionActive}
            handleOk={deleteFraction}
          />
        )}

        {openShow && (
          <ShowExecutions
            fraction={fractionActive}
            open={openShow}
            setOpen={setOpenShow}
            listPlanRef={listPlanRef}
            getFractions={getFractions}
          />
        )}

        {openRegister && (
          <CreateEditExecution
            fraction={fractionActive}
            open={openRegister}
            setOpen={setOpenRegister}
            listPlanRef={listPlanRef}
            getFractions={getFractions}
          />
        )}
      </>
    );
  }
);

export default CreateEditFraction;
