import axios from 'axios'
import fileDownload from 'js-file-download'

let normalAxios = axios.create()
normalAxios.defaults.headers.common['cache-control'] = 'no-cache'
normalAxios.defaults.headers.post['Content-Type'] = 'no-cache'
normalAxios.defaults.headers.put['Content-Type'] = 'no-cache'
normalAxios.interceptors.response.use(
  (response) => response,
  (error) => {
    const { status } = error.response
    if (status === 400) {
      return Promise.reject(error.response)
    }
    return Promise.reject(error)
  }
)

let privateAxios = axios.create()
privateAxios.defaults.headers.common['cache-control'] = 'no-cache'
privateAxios.defaults.headers.post['Content-Type'] = 'no-cache'
privateAxios.defaults.headers.put['Content-Type'] = 'no-cache'
privateAxios.interceptors.response.use(
  (response) => response,
  (error) => {
    let status = null
    try {
      status = error.response.status
    } catch (error) {}

    if (status === 401) {
      return Promise.reject(error.response)
    } else if (status === 400) {
      return Promise.reject(error.response)
    }
    return Promise.reject(error)
  }
)

export const setJWT = (jwt) => {
  privateAxios.defaults.headers.common['Authorization'] = `Bearer ${jwt}`
}

export const setLanguage = (lng) => {
  privateAxios.defaults.headers.common['language-agro'] = lng || 'es'
}

export const naxios = normalAxios
export const paxios = privateAxios

export const URL_SERVER = process.env.REACT_APP_SERVER_API || `${window.location.origin}/api`
export const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN || 'pk.eyJ1IjoiZGllZ290cmk4OSIsImEiOiJjazlrbzRzemMxeDdpM2ZvMWg0emttNWRqIn0.PFjuNt19FLl_ZCRhZ7Wh9w'

export const downloadFile = (url, filename, data = {}) => {
  privateAxios({
    url,
    responseType: 'blob',
    method: 'POST',
    data,
  }).then((res) => {
    fileDownload(res.data, filename)
  })
}

export const uploadFile = async (data) => {
  const { url, file, nameFile, aditionalData } = data
  const formData = new FormData()
  formData.append(nameFile, file)
  for (const item of aditionalData) {
    formData.append(item.name, item.value)
  }
  const response = await privateAxios.post(url, formData)

  return response.data
}

export const getQuantityPalmsBetweenLines = async (
  plantation,
  plot,
  initialLine,
  endLine
) => {
  try {
    if(String(initialLine) === '0' || String(endLine) === '0') return 0;
    if(parseInt(initialLine) > parseInt(endLine)) return 0;
    const response = await paxios.post(
      `${URL_SERVER}/palms/getQuantityPalmsBetweenLines`,
      {
        plantation,
        plot,
        initial_line: initialLine,
        end_line: endLine,
      }
    )
    if (response.data.status === 'ok') {
      return parseInt(response.data.data)
    }
  } catch (error) {
    return 0
  }
}

export const getTokenQgis = async () => {
  try {
      const response = await paxios.get(`${URL_SERVER}/qgis/getToken`)
      if (response.data.status === 'ok') {
        return response.data.data
      }
  } catch (error) {
    return ""
  }
}

export const generateQgisQuery = async (parameters) => {
  const identifier = new Date().getTime()
  const _parameters = JSON.stringify(parameters)
  try {
    const response = await paxios.post(`${URL_SERVER}/qgis/createQuery`,
    {
      identifier,
      paramaters: _parameters
    })
    if (response.data.status === 'ok') {
      return identifier
    }
  } catch (error) {
    return 0
  }
}
