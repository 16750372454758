import { useEffect, useState } from "react";
import { nutritionStore } from "../../../stores";
import Swal from "sweetalert2/src/sweetalert2.js";
import MaterialTable from "material-table";
import { useTranslation } from "react-i18next";
import { URL_SERVER, paxios } from "../../../constants/http";
import tableIcons from "../../../constants/IconsMaterialTable";
import tableTranslations from "../../../constants/tableTranslations";

const cellStyle = { align: "center", headerStyle: { textAlign: "center" } };

export default function Fertilizers() {
  const { t, i18n } = useTranslation();
  const { fertilizers, getFertilizers, fertilizersTypes, getFertilizersTypes } =
    nutritionStore();
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    fertilizers.length <= 0 && getFertilizers();
    fertilizersTypes.length <= 0 && getFertilizersTypes();
  }, [
    fertilizers.length,
    getFertilizers,
    fertilizersTypes,
    getFertilizersTypes,
  ]);

  useEffect(() => {
    const validateEmpty = (rowData, field) => {
      return rowData[field] === "" || rowData[field] === undefined
        ? t("general.noempty")
        : true;
    };

    setColumns([
      { title: "ID", field: "id", editable: "never" },
      {
        title: `${t("general.name")} ${t("general.common")}`,
        field: "common_name",
        validate: (rowData) => validateEmpty(rowData, "common_name"),
      },
      {
        title: t("general.source"),
        field: "source",
        validate: (rowData) => validateEmpty(rowData, "source"),
      },
      {
        title: t("general.type"),
        field: "type",
        lookup: {
          ...fertilizersTypes.reduce((acc, item) => {
            return { ...acc, [item.id]: item.description };
          }, {}),
        },

        ...cellStyle,
      },
      {
        title: t("general.price"),
        field: "package_price",
        type: "numeric",
        validate: (rowData) => validateEmpty(rowData, "package_price"),
      },
      {
        title: t("general.flooring"),
        field: "flooring",
        lookup: { 1: t("general.yes"), 0: "No" },
        initialEditValue: 0,
        ...cellStyle,
      },
      {
        title: t("general.nursery"),
        field: "nursery",
        lookup: { 1: t("general.yes"), 0: "No" },
        initialEditValue: 0,
        ...cellStyle,
      },
      {
        title: "N",
        field: "n",
        type: "numeric",
        validate: (rowData) => validateEmpty(rowData, "n"),
      },
      {
        title: "P205",
        field: "p",
        type: "numeric",
        validate: (rowData) => validateEmpty(rowData, "p"),
      },
      {
        title: "K2O",
        field: "k",
        type: "numeric",
        validate: (rowData) => validateEmpty(rowData, "k"),
      },
      {
        title: "SO4",
        field: "s",
        type: "numeric",
        validate: (rowData) => validateEmpty(rowData, "s"),
      },
      {
        title: "CaO",
        field: "ca",
        type: "numeric",
        validate: (rowData) => validateEmpty(rowData, "ca"),
      },
      {
        title: "MgO",
        field: "mg",
        type: "numeric",
        validate: (rowData) => validateEmpty(rowData, "mg"),
      },
      {
        title: "Fe2O5",
        field: "fe",
        type: "numeric",
        validate: (rowData) => validateEmpty(rowData, "fe"),
      },
      {
        title: "CuO",
        field: "cu",
        type: "numeric",
        validate: (rowData) => validateEmpty(rowData, "cu"),
      },
      {
        title: "MnO",
        field: "mn",
        type: "numeric",
        validate: (rowData) => validateEmpty(rowData, "mn"),
      },
      {
        title: "ZnO",
        field: "zn",
        type: "numeric",
        validate: (rowData) => validateEmpty(rowData, "zn"),
      },
      {
        title: "B2O3",
        field: "b",
        type: "numeric",
        validate: (rowData) => validateEmpty(rowData, "b"),
      },
      {
        title: "MoO",
        field: "mo",
        type: "numeric",
        validate: (rowData) => validateEmpty(rowData, "mo"),
      },
      {
        title: "Cl",
        field: "cl",
        type: "numeric",
        validate: (rowData) => validateEmpty(rowData, "cl"),
      },
    ]);
  }, [t, fertilizersTypes]);

  const createFertilizer = async (data) => {
    try {
      const response = await paxios.post(`${URL_SERVER}/fertilizers`, {
        ...data,
      });
      if (response.data.status === "ok") {
        Swal.fire(t("general.finished"), t("general.savesuccess"), "success");
        getFertilizers();
      } else {
        Swal.fire(t("general.finished"), response.data.message, "error");
      }
    } catch (error) {
      Swal.fire(t("general.finished"), t("general.datawasnotsaved"), "error");
    }
  };

  const onRowAdd = (newData) =>
    new Promise(async (resolve) => {
      await createFertilizer(newData);
      resolve();
    });

  const updateFertilizer = async (data) => {
    try {
      const response = await paxios.put(
        `${URL_SERVER}/fertilizers/${data.id}`,
        { ...data }
      );
      if (response.data.status === "ok") {
        Swal.fire(t("general.finished"), t("general.savesuccess"), "success");
        getFertilizers();
      } else {
        Swal.fire(t("general.finished"), t("general.datawasnotsaved"), "error");
      }
    } catch (error) {
      Swal.fire(t("general.finished"), t("general.datawasnotsaved"), "error");
    }
  };

  const onRowUpdate = (newData, oldData) =>
    new Promise(async (resolve) => {
      await updateFertilizer(newData);
      resolve();
    });

  return (
    <>
      <MaterialTable
        icons={tableIcons}
        columns={columns}
        title={`${t("general.fertilizers")}`}
        data={fertilizers}
        options={{
          exportButton: true,
          exportAllData: true,
          exportDelimiter: ";",
          selection: false,
        }}
        localization={i18n.language === "es" ? tableTranslations : {}}
        editable={{
          onRowUpdate: onRowUpdate,
          onRowAdd: onRowAdd,
        }}
      />
    </>
  );
}
